<template>
  <div class="addChat">
    <el-dialog
      title="配置话术本"
      class="dialogVisible"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      width="840px"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="dialogVisible_bottom">
        <p class="title"><span style="color: #FF3535;">*</span> 话术本</p>
        <el-button type="primary" @click="openText" size="medium" :disabled="isHasSave">
          添加话术本
        </el-button>
        <span style="margin-left: 30px;" v-if="isHasSave">已添加：{{ save.name }} <i class="el-icon-close cp" @click="save = {},stop_time=undefined"></i></span>
        <p class="first">首条消息发送时间：<span v-if="save&&save.one_msg_time">{{ save.one_msg_time|formateTime }}</span></p>
        <p class="title"><span style="color: #FF3535;">*</span> 设置发送间隔时间<span style="margin-left: 50px;color: #999999;font-size: 12px;">直播推流后多少秒开始发送话术本第一条消息</span></p>
        <div class="type">
          <el-radio-group v-model="write" @input="changeSelect">
          <el-radio :label="1">手动输入</el-radio>
          <el-radio :label="2">快捷设置 <span v-if="write==2" style="margin-left: 50px;color: #999999;font-size: 12px;">择话术本历史直播时间进行快捷计算</span></el-radio>
        </el-radio-group>
        </div>
        <div class="box">
          <el-time-picker
            v-if="write==2"
            style="display: block;margin:10px 0;"
            v-model="quick_time"
            placeholder="选择时间"
            :picker-options="pickerOptions"
            @change="changeQuickTime"
            ></el-time-picker>
          <el-input-number style="display: inline-block;margin:10px 0;" v-model="stop_time" controls-position="right" @change="changeNum" :precision="0" :min="0" :max="86400"></el-input-number>
          <span style="margin-left: 20px;">秒</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="medium">
          取 消
        </el-button>
        <el-button type="primary" @click="submit" size="medium">
          确 定
        </el-button>
      </span>
      <addText
        :openClassId="openClassId"
        :dialogstatus.sync="addText.dialogstatus"
        @setText="setText"
      ></addText>
    </el-dialog>
  </div>
</template>

<script>
import addText from './addText.vue'
export default {
    name:'addChat',
    props:{
      dialogstatus: {
        type: Boolean,
        default: false,
      },
      openClassId: [Number, String],
    },
    components:{
      addText
    },
    data(){
        return {
          write:1,
          firstTime:'',
          stop_time:undefined,
          stop_time_cp:undefined,
          quick_time:null,
          addText:{
            dialogstatus:false,
          },
          save:{},
          pickerOptions:{},
        }
    },
    computed:{
      isHasSave(){
        return Object.keys(this.save).length>0
      },
    },
    filters:{
      formateTime(time) {
        const h = parseInt(time / 3600)
        const minute = parseInt(time / 60 % 60)
        const second = Math.ceil(time % 60)
        const hours = h < 10 ? '0' + h : h
        const formatSecond = second > 59 ? 59 : second
        return `${hours > 0 ? `${hours}:` : '00:'}${minute < 10 ? '0' + minute : minute}:${formatSecond < 10 ? '0' + formatSecond : formatSecond}`
      },
    },
    methods:{
      openText(){
        this.addText.dialogstatus=true
      },
      // 切换状态 快捷设置保存值
      changeSelect(e){
        if(e==2){
          this.stop_time = this.stop_time_cp
        }else{
          this.stop_time_cp = this.stop_time
          this.stop_time=undefined
        }
        this.write = e
      },
      // 转换时间 保存话术本
      setText(row){
        this.$http({
          url: 'chatMessage/getOneMsgTime',
          data: {
            msg_id:row.msg_id,
          },
          callback: ({code,data}) => {
            if(code == 200){
              this.save = Object.assign(row,{
                one_msg_time:data,
              })
              if(row.msg_records_id){
                this.stop_time = row.stop_time
                this.write = Number(row.write)
                if(row.write==2&&row.quick_time!=0){
                  this.stop_time_cp = row.stop_time
                  this.quick_time=new Date(row.quick_time*1000)
                }
              } else if(this.save.one_msg_time&&this.quick_time){
                this.setNumber()
              }
            }
          },
        })
      },
      changeQuickTime(e){
        if(e&&this.save.one_msg_time){
          this.setNumber()
        }else{
          this.stop_time = undefined
        }
      },
      // 计算时间差 首条消息发送时间减去快捷计算选择的时间
      setNumber(){
        const ETime = new Date(this.quick_time)
        const [EHours,EMinutes,ESeconds] = [ETime.getHours(),ETime.getMinutes(),ETime.getSeconds()]
        const time = Number(this.save.one_msg_time)-(Number(EHours*3600)+Number(EMinutes*60)+Number(ESeconds))
        this.stop_time=time>=0?time:0
      },
      changeNum(e){
        this.stop_time=e
      },
      edit(row){
        this.setText(row)
      },
      submit() {
        if(!this.save.msg_id){
          this.$root.prompt('请选择话术本！')
        }else if(!this.stop_time&&this.stop_time!=0){
          this.$root.prompt('请填写间隔时间！')
        }else{
          this.$http({
            url: '/console/setMsgRecords',
            data: {
              open_class_id: parseInt(this.openClassId),
              stop_time:parseInt(this.stop_time),
              msg_id:this.save.msg_id,
              write:this.write,
              quick_time:parseFloat(new Date(this.quick_time).getTime()/1000),
            },
            callback: ({code,info}) => {
              if(code == 200){
                this.$emit('submit')
                this.$root.prompt({
                  msg: info,
                  type: 'success',
                })
                this.cancel()
              }
            },
          })
        }
      },
      cancel() {
        this.stop_time = undefined
        this.stop_time_cp = undefined
        this.quick_time=null
        this.write=1
        this.save = {}
        // 取消后回复选择数据原样
        this.$emit('update:dialogstatus', false)
      },
    },
}
</script>

<style scoped lang="scss">
.dialogVisible {
  .dialogVisible_bottom {
    font-size: 14px;
    color: #333333;
    .title{
      margin:0 0 20px 0;
    }
    .first {
      margin:20px 0;
    }
    .box{
      ::v-deep .el-input__inner{
        border: 1px solid #DCDFE6 !important;
        color: #606266 !important;
        background:none !important;
      }
    }
  }
}
</style>