<!--
 * @Author: cyy
 * @Date: 2021-12-17 14:17:19
 * @LastEditTime: 2021-12-27 10:42:50
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \ teacher.dingdingkaike\src\views\OpenClass_openClassLive\components\reset.vue
-->
<template >
  <div class="tu2" @click="relo">
    <img src="@ass/img/1.4.2.8/icon_sykzt@2x.png" alt="" title="刷新" />
  </div>
</template>

<script>
export default {
  name: 'reset',
  data() {
    return {}
  },

  methods: {
    relo() {
      location.reload()
    },
  },
}
</script>

<style lang="scss" scoped>
.tu2 {
  cursor: pointer;
  width: 40px;
  height: 28px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 14px;
    height: auto;
  }
}
</style>
