<!--
 * @Author: cyy
 * @version: 
 * @Date: 2023-01-31 16:38:37
 * @LastEditTime: 2023-02-16 16:46:55
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacherdingding.com\src\views\OpenClass_openClassLive\components\liveDataComponents\yingXiao\winningRecord.vue
-->
<template>
  <div class="winningrecord">
    <el-dialog
      title="中奖记录"
      class="dialogVisible"
      width="840px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="dialogVisible_bottom">
        <div class="search">
          <el-select
            size="medium"
            v-model="post.search_type"
            style="width:130px"
            placeholder="请选择"
          >
            <el-option
              v-for="item in option"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input
            v-model="post.search"
            size="medium"
            @keyup.enter.native="toSearch"
            style="width:180px"
            class="ml10 el-input"
            clearable
            :placeholder="
              post.search_type == 1 ? '请输入中奖人昵称' : '请输入奖品名称'
            "
          ></el-input>
          <el-button
            @click="toSearch"
            type="primary"
            class="ml10"
            size="medium"
            icon="el-icon-search"
          >
            搜索
          </el-button>
        </div>
        <pagination2
          :option="post2"
          url="/liveLottery/awardsRecord"
          ref="childDialogBox"
          @complete="complete"
        >
          <template>
            <div class="pagination2Box">
              <el-table
                ref="dialogTable"
                :header-cell-style="{
                  background: 'rgba(245,245,245,1)',
                  color: '#333333',
                }"
                max-height:200
                :data="tableData"
              >
                <el-table-column label="中奖人">
                  <template slot-scope="{ row }">
                    <div class="person">
                      <div class="tu">
                        <img :src="row.uphoto" alt="" />
                      </div>
                      <div class="right">
                        <div class="name hidetext">
                          {{ row.uname }}
                        </div>
                        <div class="status">
                          {{ row.online == 1 ? '在线' : '在线' }}
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="中奖时间">
                  <template slot-scope="{ row }">
                    {{ row.winning_time | formatTimeStamp }}
                  </template>
                </el-table-column>

                <el-table-column label="参与时间">
                  <template slot-scope="{ row }">
                    {{ row.c_time | formatTimeStamp }}
                  </template>
                </el-table-column>

                <el-table-column label="奖项">
                  <template slot-scope="{ row }">
                    {{ row.prize_name }}
                  </template>
                </el-table-column>

                <el-table-column label="奖品">
                  <template slot-scope="{ row }">
                    {{ row.prize }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
        </pagination2>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      option: [
        {
          value: 1,
          label: '中奖人',
        },
        {
          value: 2,
          label: '奖品名称',
        },
      ],
      tableData: [],
      post: {
        lottery_id: this.lottery_id,
        search: '',
        search_type: 1,
      },
      post2: {
        lottery_id: this.lottery_id,
        search: '',
        search_type: 1,
      },
    }
  },
  props: {
    lottery_id: {
      type: String,
      default: '',
    },
    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    toSearch() {
      this.post2 = _.assign({}, this.post)
    },

    complete({ data }) {
      this.tableData = data
    },

    cancel() {
      // 取消后回复选择数据原样
      this.$emit('complete')
    },
  },
}
</script>
<style lang="scss" scoped>
.winningrecord {
  ::v-deep .el-dialog__body {
    padding: 10px 20px;
  }
  .pagination2Box {
    margin-top: 20px;
    overflow: auto;
    height: 280px;
    @extend %scroll;
    .person {
      display: flex;
      align-items: center;
      .tu {
        flex-shrink: 0;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #f5f5f5;
        img {
          width: 100% !important;
          height: 100% !important;
        }
      }
      .right {
        overflow: hidden;
        line-height: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 50px;
        margin-left: 10px;
      }
    }
  }
  .dialogVisible_bottom {
    .search {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
</style>
