<template>
  <div class="minGanCi">
    <div class="item">
      <div class="name">屏蔽方式</div>
      <help-icon
        class="ml10"
        content="<p>**号：敏感词以**号代替</p><p>仅发送人可见：包含敏感词内容的消息仅发送人自己可见，其他学生看不到消息</p>"
      ></help-icon>
      <el-radio-group class="ml30" v-model="hide_type" @change="hideTypeChange">
        <el-radio label="1">**号</el-radio>
        <el-radio label="2">仅发送人可见</el-radio>
      </el-radio-group>
    </div>
    <div class="prompt">
      *注：控制台的助教老师可以看到敏感词内容，以红色字体以及删除线标注
    </div>
    <div class="prompt">提示：建议输入10个字符以内，使用回车键添加</div>
    <div class="tags-content" @click.self="$refs.tag.focus()">
      <span
        v-for="(item, index) in words"
        :key="`${item}${index}`"
        class="vt tag"
      >
        <i class="vm text">{{ item }}</i>
        <i class="vm icon" @click="removeSensitiveWords(index)"></i>
      </span>
      <span class="vt send-tag">
        <input
          ref="tag"
          v-focus
          maxlength="255"
          v-model.trim="password"
          placeholder="请输入敏感词"
          @blur="addSensitiveWords({ keyCode: 13, target: {} })"
          @keyup="addSensitiveWords"
        />
      </span>
    </div>
    <el-button class="save-btn" type="primary" @click="saveSensitiveWords">
      保存
    </el-button>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('OpenClassopenClassLive')

export default {
  name: 'minGanCi',

  components: {},

  computed: {
    ...mapState(['openClassId', 'liveInfo', 'sensitiveWords']),
  },

  watch: {
    sensitiveWords: {
      handler(val) {
        this.words = val
      },
      immediate: true,
    },
    liveInfo: {
      handler(val) {
        this.hide_type = val.hide_type
      },
      immediate: true,
    },
  },

  data() {
    return {
      status: false,
      password: '',
      words: [],
      hide_type: '1',
    }
  },

  methods: {
    // 添加 删除敏感词
    addSensitiveWords({ keyCode, target }) {
      if (keyCode === 13 && this.password) {
        if (
          /[^\w|[\u3400-\u4DB5\u4E00-\u9FEA\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\uD840-\uD868\uD86A-\uD86C\uD86F-\uD872\uD874-\uD879][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6\uDF00-\uDFFF]|\uD86D[\uDC00-\uDF34\uDF40-\uDFFF]|\uD86E[\uDC00-\uDC1D\uDC20-\uDFFF]|\uD873[\uDC00-\uDEA1\uDEB0-\uDFFF]|\uD87A[\uDC00-\uDFE0]]/gi.test(
            this.password
          )
        ) {
          this.status = true
          this.$root.prompt('敏感词暂不支持特殊符号，仅支持中文、英文、数字')
          return
        }
        this.words.push(this.password.slice(0, 50))
        this.password = ''
        this.status = false
        this.oldValueEmpty = true
      } else if (keyCode === 8 && this.oldValueEmpty) {
        this.removeSensitiveWords(this.words.length - 1)
        this.status = false
      } else {
        this.status = false
        this.oldValueEmpty = !target.value
      }
    },

    // 删除敏感词
    removeSensitiveWords(index) {
      this.words.splice(index, 1)
    },

    async saveSensitiveWords() {
      if (this.status) {
        this.$root.prompt('敏感词暂不支持特殊符号，仅支持中文、英文、数字')
        return
      }
      await this.$http({
        url: '/console/setKeywords',
        data: {
          open_class_id: this.openClassId,
          content: this.words.join('&_'),
        },
        callback: () => {
          // 发送敏感词消息
          this.$root.$emit('sendCustomMsg', {
            data: 'keywords',
            description: this.words.join('&_'),
            extension: '',
            roomId: this.liveInfo.chat_room_id2,
          })
        },
      })
      this.$root.prompt({
        type: 'success',
        msg: '保存成功',
      })
    },

    async hideTypeChange() {
      await this.$http({
        url: '/console/setKeywordsHideType',
        data: {
          open_class_id: this.openClassId,
          hide_type: this.hide_type,
        },
      })
      this.$root.prompt({
        type: 'success',
        msg: '保存成功',
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.minGanCi {
  margin: 20px;
  .prompt {
    font-size: 12px;
    color: #6a6a6a;
    padding: 10px 0 20px;
  }
  .save-btn {
    width: 76px;
    height: 36px;
    margin-top: 20px;
    border-radius: 6px;
    background: #fa6400;
    border-color: #fa6400;
  }
}
.tags-content {
  min-height: 170px;
  background: #2c2c2c;
  border-radius: 4px;
  border-color: #2c2c2c;
  padding: 10px 10px 10px 0;
  box-sizing: border-box;
  overflow: auto;
  max-height: 190px;
  @extend %scroll;
  .tag {
    font-size: 0;
    border-radius: 4px;
    padding: 7px 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    background: rgba(237, 237, 237, 1);
    border: 1px solid rgba(237, 237, 237, 1);
    .text {
      font-size: 12px;
      line-height: 16px;
      font-style: normal;
      color: rgba(102, 102, 102, 1);
    }
    .icon {
      width: 12px;
      height: 12px;
      cursor: pointer;
      margin-left: 10px;
      background: url('~@ass/img/ico_gbts.png') no-repeat center center;
      background-size: 10px;
    }
    &:hover {
      border-color: #ffadad;
    }
  }
  .send-tag {
    height: 31px;
    margin-left: 10px;
    input {
      border: 0;
      color: #fff;
      height: 100%;
      display: block;
      font-size: 14px;
      background: #2c2c2c;
      &::placeholder {
        color: rgba(204, 204, 204, 1);
      }
    }
  }
}
::v-deep .item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .el-input__inner,
  .el-input-number__increase,
  .el-input-number__decrease {
    background-color: #323232;
    border-color: #323232;
    .el-icon-arrow-up,
    .el-icon-arrow-down {
      color: #fff;
    }
  }
  .el-input__inner {
    color: #fff;
  }
  .el-radio__input.is-checked .el-radio__inner {
    border-color: #fa6400;
    background: #fa6400;
  }
  .el-radio {
    color: #ffffff;
    .el-radio__input.is-checked + .el-radio__label {
      color: #fa6400;
    }
  }
  .name {
    font-size: 14px;
    color: #fff;
  }
}
</style>
