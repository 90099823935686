<template>
  <div class="d_3">
    <el-button v-if="time <= 0" @click="sendMessage" type="primary" class="btn">
      发送
    </el-button>
    <el-button v-else type="info" class="btn2">已发送{{ time }}s</el-button>
  </div>
</template>

<script>
export default {
  name: 'robotTimer',
  data() {
    return {
      time: 0,
    }
  },
  props: {
    item: Object,
  },

  destroyed() {
    this.clear()
  },

  methods: {
    clear() {
      clearInterval(this._timer)
      this.time = 0
    },

    setTimer() {
      this._timer = setInterval(() => {
        if (this.time <= 0) {
          this.clear()
        } else {
          this.time--
        }
      }, 1000)
    },

    // 发送消息
    sendMessage() {
      if (!this.item.text) {
        this.$root.prompt('请先输入内容！')
        return
      }
      this.$http({
        url: '/console/speak',
        data: {
          data: this.item.text.trim(),
          robot_relation_id: this.item.robot_relation_id,
        },
        callback: () => {
          this.time = 3
          this.setTimer()
          this.$root.prompt({
            msg: '操作成功',
            type: 'success',
          })
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.btn,
.btn2 {
  width: 70px;
  padding: 12px 0;
  font-size: 12px;
}
%bg1 {
  background: #fa6400;
  border-color: #fa6400;
}
%bg2 {
  background: #292929;
  border-color: #292929;
}
.btn {
  @extend %bg1;
}
::v-deep .btn:hover,
::v-deep .btn:active,
::v-deep .btn:focus {
  @extend %bg1;
}
.btn2 {
  @extend %bg2;
}
::v-deep .btn2:hover,
::v-deep .btn2:active,
::v-deep .btn2:focus {
  @extend %bg2;
}
</style>