<template>
  <div id="consoleCommodity">
    <div class="header">
      <div></div>
      <div class="flex-center">
        <div style="color:#fff" class="fs12 ">
          功能说明
        </div>
        <helpIcon
          style="margin-left: 6px"
          content="
          1.添加商品成功后，直播间的购物车会显示与当前列表对应的商品，移除后隐藏；</br> 
2.秒杀开始后，学生只要提交了订单，就会占用一个剩余数量，5分种之内未支付成功，剩余数量会被释放；</br> 
3.点击讲解，直播间会在右下角显示商品橱窗，点击取消讲解会隐藏橱窗；</br> 
4.剩余数量修改为0时，直播间商品会显示已抢光。"
        ></helpIcon>
        <el-input
          size="medium"
          clearable
          v-model="post.search"
          class="ml20"
          style="width: 218px"
          placeholder="请输入商品名称"
        ></el-input>
        <el-button
          type="text"
          class="ml10"
          @click="$refs.childDialogBox.reset()"
        >
          刷新
        </el-button>
        <el-button
          type="primary"
          @click="toCommerce"
          size="medium"
          class="ml20"
        >
          添加商品
        </el-button>
      </div>
    </div>

    <pagination2
      :option="post"
      url="/Seckill/liveSeckillList"
      ref="childDialogBox"
      class="console_childDialogBox"
      @complete="complete"
    >
      <template v-slot:default="{}">
        <el-table
          :header-cell-style="{
            background: '#212121',
            color: '#fff',
          }"
          :data="completeData"
        >
          <el-table-column label="序号" width="60px">
            <template slot-scope="{ row, $index }">
              <div style="width:40px">
                <el-popover
                  popper-class="consolecommoditypopper"
                  placement="right"
                  :ref="`popover1${$index}`"
                  width="410"
                  trigger="click"
                >
                  <div class="contain">
                    排列至第
                    <el-input
                      v-model="rankNumber"
                      class="ml30 mr10"
                      style="width:100px"
                      @keyup.enter.native="setSort(row)"
                      placeholder="输入数字"
                      size="medium"
                      onkeyup="value = value.replace(/[^\d]/g, '')"
                    ></el-input>
                    位
                    <el-button
                      size="medium"
                      type="primary"
                      class="ml10 mr10"
                      @click="setSort(row)"
                    >
                      确定
                    </el-button>
                    <el-button type="" size="medium" @click="cancel1()">
                      取消
                    </el-button>
                  </div>
                  <div
                    class="cp flex-center"
                    style="width:30px"
                    slot="reference"
                  >
                    {{ row.sort }}
                    <i class="el-icon-edit"></i>
                  </div>
                </el-popover>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="商品信息" min-width="270">
            <template slot-scope="{ row }">
              <!-- {{ downTime2 }} -->
              <div class="good_contain">
                <div class="pic">
                  <img :src="row.goods_photo" alt="" />
                  <div class="tips">{{ row | getGoodtype }}</div>
                </div>
                <div class="name_price hidetext">
                  <div class="name" :title="row.goods_name">
                    {{ row.goods_name }}
                  </div>
                  <div>
                    <span class="price">原价￥{{ row.goods_price }}</span>
                    <span
                      class="miaosha ml10"
                      v-if="row.status == 2 && row.downTime2 > 0"
                    >
                      秒杀价：￥{{ row.seckill_price }}
                    </span>
                  </div>
                  <div
                    class="miaosha"
                    v-if="row.status == 2 && row.downTime2 > 0"
                  >
                    <div>
                      剩余时间：{{
                        row.downTime2 | formatTimeLength2('dd天hh时mm分ss秒')
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="剩余数量">
            <template slot-scope="{ row }">
              <el-popover
                @show="popoverShow(row)"
                v-if="row.status == 2"
                popper-class="consolecommoditypopper2"
                placement="right"
                trigger="click"
              >
                <div class="contain">
                  <el-input-number
                    placeholder="请输入数量"
                    style="width:150px"
                    v-model="stock_num"
                    size="medium"
                    :min="0"
                    :precision="0"
                    :max="100000"
                    :step="1"
                  ></el-input-number>

                  <el-button
                    size="medium"
                    type="primary"
                    class="ml20 "
                    @click="setresNumber(row)"
                  >
                    确定
                  </el-button>
                  <el-button type="" size="medium" @click="cancel1()">
                    取消
                  </el-button>
                </div>
                <div class="cp" style="width:40px" slot="reference">
                  {{ row.stock_num }}
                  <i class="el-icon-edit"></i>
                </div>
              </el-popover>
              <span v-else>-</span>
            </template>
          </el-table-column>

          <el-table-column label="秒杀成交信息" min-width="120">
            <template slot-scope="{ row }">
              <p>已购数量：{{ row.purchased_num }}</p>
              <p>成交金额：{{ row.purchased_price }}</p>
            </template>
          </el-table-column>

          <el-table-column label="操作" width="220px" align="right">
            <template slot-scope="{ row }">
              <div class="btns">
                <!-- <p style="color:#fff">{{ row.downTime2 }} dfdsf</p> -->
                <template v-if="row.goods_class == 1">
                  <div
                    class="tubiao1"
                    v-if="!row.downTime2 || row.downTime2 < 0"
                    @click="setting(row)"
                  >
                    <div class="miaosha_tips">秒杀</div>
                    <img src="~@ass/img/1.4.28/icon_mszh.svg" alt="" />
                  </div>
                  <div class="tubiao2" v-else @click="stopAcitvity(row)">
                    <img src="~@ass/img/1.4.28/icon_msdl.svg" alt="" />
                  </div>
                </template>

                <el-button
                  type="primary"
                  size="medium"
                  v-if="row.explain == 2"
                  @click="setExplain(row, 1)"
                >
                  <div class="flex-center ">
                    <img
                      class="mr10"
                      style="width:8px;height:auto"
                      src="~@ass/img/1.4.28/icon_jj.svg"
                      alt=""
                    />
                    讲解
                  </div>
                </el-button>
                <div
                  class="cp cancel"
                  style="width:86px"
                  @click="setExplain(row, 2)"
                  v-else
                >
                  取消讲解
                </div>

                <span
                  class="fs14 cp ml10"
                  @click="remove(row)"
                  style="color:#fff;flex-shrink: 0;"
                >
                  移除
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>

    <el-drawer
      class="elDrawer"
      title="设置秒杀"
      :visible.sync="drawer"
      direction="rtl"
      size="800px"
      :wrapperClosable="false"
    >
      <div class="contain">
        <el-form
          ref="form"
          label-position="left"
          :model="settingList"
          label-width="130px"
        >
          <el-form-item label="活动名称">
            <el-input
              style="width:554px"
              size="medium"
              v-model="settingList.name"
              placeholder="请输入活动名称"
              maxlength="10"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="秒杀持续时间">
            <el-input-number
              placeholder="秒杀持续时间"
              v-model="settingList.duration"
              size="medium"
              :min="1"
              :max="100000"
              :precision="0"
              :step="1"
            ></el-input-number>
            <span class="ml10 fs12">分钟后结束（设置成功后，立即生效）</span>
          </el-form-item>
          <el-form-item label="秒杀价">
            <el-input-number
              placeholder="秒杀价"
              v-model="settingList.seckill_price"
              size="medium"
              :min="0.01"
              :max="Number(settingList.price)"
              :precision="2"
              :step="0.01"
            ></el-input-number>
            <span class="ml10 fs12">元</span>
          </el-form-item>
          <el-form-item label="剩余数量">
            <el-input-number
              placeholder="请输入数量"
              v-model="settingList.stock_num"
              size="medium"
              :min="0"
              :precision="0"
              :max="100000"
              :step="1"
            ></el-input-number>
            <span class="ml10 fs12">设置为0时，商品标识为"已抢光"</span>
          </el-form-item>
          <el-form-item label="活动标签">
            <el-input
              maxlength="5"
              v-model="settingList.seckill_tag"
              style="width:160px"
              placeholder="请输入活动标签"
            ></el-input>
            <!-- <div class="tips">
              活动期间展示于商品详情的价格旁边，2至5个字
              <el-popover placement="top-start" trigger="hover" width="300px">
                <img
                  style="width:100%;height: 300px; "
                  src="~@ass/img/1.4.28/tapd_23590331_base64_1669802296_85.png"
                  alt=""
                />
                <span slot="reference" class="ml30 example">查看示例</span>
              </el-popover>
            </div> -->
          </el-form-item>
        </el-form>
        <div class="drawBtn">
          <el-button size="medium" class="mr20" @click="drawer = false">
            取消
          </el-button>
          <el-button size="medium" type="primary" @click="beginMiaosha">
            开始秒杀
          </el-button>
        </div>
      </div>
    </el-drawer>

    <networkschoolSelect
      :goodList="goodList"
      @selectChangenetworkschool="closeGoodsConfirm"
      @singleselectcontentval="updateCourse"
      title="添加择商品"
      v-if="addCourseBool"
      :goodlistType="true"
      :dialogstatus.sync="addCourseBool"
      :template="17"
      :singleselect="false"
    ></networkschoolSelect>
  </div>
</template>

<script>
import networkschoolSelect from '@cm/base/networktypeSelect/networkschoolSelect'
export default {
  name: 'consolecommodity',

  components: {
    networkschoolSelect,
  },

  data() {
    return {
      completeData: [],

      settingListID: '',

      //  列表剩余数量
      stock_num: '',

      settingList: {
        seckill_tag: '秒杀',
        duration: 30,
        name: '',
        seckill_price: undefined,
        stock_num: undefined,
        price: 0,
      },

      drawer: false,

      goodList: [],

      addCourseBool: false,

      rankNumber: '',

      post: {
        search: '',
        open_class_id: this.$route.params.id,
      },
    }
  },

  filters: {
    getGoodtype(val) {
      let text = ''
      switch (Number(val.goods_type)) {
        case 1:
          text = '直播课'
          break
        case 2:
          text = '小班课'
          break
        case 3:
          text = '录播课'
          break
        case 4:
          text = '系列课'
          break
        case 6:
          text = '图文'
          break
        case 8:
          if (val.goods_class == 1) {
            text = '知识商品'
          } else {
            text = '外部商品'
          }
          break
      }
      return text
    },
  },

  methods: {
    // 确认修改列表剩余数量
    async setresNumber(row) {
      await this.$http({
        url: '/Seckill/setStockNum',
        data: {
          live_seckill_id: row.live_seckill_id,
          stock_num: this.stock_num,
        },
        successMsg: true,
      })
      this.stock_num = ''
      this.cancel1()
      this.$refs.childDialogBox.reset()
    },

    popoverShow(row) {
      this.stock_num = row.stock_num
    },

    // 停止秒杀
    async stopAcitvity(row) {
      this.$confirm('是否停止秒杀？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          await this.$http({
            url: '/Seckill/end',
            data: { live_seckill_id: row.live_seckill_id },
            successMsg: true,
          })
          this.$refs.childDialogBox.reset()
        })
        .catch(() => {})
    },

    async setExplain(row, explain) {
      await this.$http({
        url: '/Seckill/setExplain',
        data: {
          live_seckill_id: row.live_seckill_id,
          explain,
        },
        successMsg: true,
      })
      this.$refs.childDialogBox.reset()
    },

    // 开始秒杀
    async beginMiaosha() {
      if (!this.settingList.name.trim()) {
        this.$root.prompt('请输入活动名称')
        return
      }
      if (!this.settingList.duration) {
        this.$root.prompt('请输入秒杀持续时间')
        return
      }
      if (!this.settingList.seckill_price) {
        this.$root.prompt('请输入秒杀价')
        return
      }
      if (this.settingList.stock_num != 0 && !this.settingList.stock_num) {
        this.$root.prompt('请输入剩余数量')
        return
      }
      if (this.settingList.seckill_tag.length == 1) {
        this.$root.prompt('活动标签2至5个字')
        return
      }
      try {
        await this.$http({
          url: '/Seckill/setLiveSeckill',
          data: _.assign({}, this.settingList, {
            live_seckill_id: this.settingListID,
          }),
          successMsg: true,
        })
        this.$refs.childDialogBox.reset()
        this.drawer = false
        this.settingListID = ''
      } catch (e) {
        console.log(e)
      }
    },

    // 秒杀设置
    async setting(row) {
      const { data } = await this.$http({
        url: '/Seckill/getLiveSeckillDetails',
        data: {
          live_seckill_id: row.live_seckill_id,
        },
      })

      this.settingList = _.assign({}, data, {
        duration: data.duration ? data.duration : undefined,
        name: data.name,
        seckill_price: Number(data.seckill_price)
          ? data.seckill_price
          : undefined,
        stock_num: Number(data.stock_num) ? data.stock_num : undefined,
      })

      this.settingListID = row.live_seckill_id

      this.$nextTick(() => {
        this.drawer = true
      })
    },

    async remove(row) {
      this.$confirm('移除该商品后，直播间购物车将不显示该商品！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          await this.$http({
            url: '/Seckill/remove',
            data: {
              live_seckill_id: row.live_seckill_id,
            },
            successMsg: true,
          })
          this.$refs.childDialogBox.reset()
        })
        .catch(() => {})
    },

    complete(val) {
      const isTimer = val.data.some(item => item.downTime)
      this.completeData = val.data.map(item => {
        return {
          ...item,
          downTime: Date.now() + item.downTime * 1000,
          downTime2:
            item.downTime > 0
              ? Math.ceil(
                  (Date.now() + item.downTime * 1000 - Date.now()) / 1000
                )
              : 0,
        }
      })
      this.goodList = val.goodList

      // 开启商品倒计时
      clearInterval(this.goodTimer)
      if (isTimer) {
        this.goodTimer = setInterval(() => {
          this.completeData = this.completeData.map(item =>
            _.assign({}, item, {
              downTime2: Math.ceil((item.downTime - Date.now()) / 1000),
            })
          )
        }, 1000)
      }
    },

    // 获取课程类型
    getcourseType(val) {
      let num = 0
      if ('course_id' in val) {
        num = val.course_type
      } else if ('graphic_id' in val) {
        num = 6
      } else if ('live_goods_id' in val) {
        num = 8
      }
      return num
    },
    async updateCourse(val) {
      const goodList = val.map(item => {
        return {
          goods_type: this.getcourseType(item),
          goods_id: item.course_id || item.graphic_id || item.live_goods_id,
        }
      })
      await this.$http({
        url: '/Seckill/addLiveSeckill',
        data: {
          open_class_id: this.$route.params.id,
          goodList,
        },
        successMsg: true,
      })
      this.$refs.childDialogBox.reset()
    },

    closeGoodsConfirm() {
      this.addCourseBool = false
    },
    // 排序
    async setSort(item) {
      if (!this.rankNumber) {
        this.$root.prompt('请输入序号！')
        return
      }
      await this.$http({
        url: '/Seckill/setLiveSeckillSort',
        data: {
          live_seckill_id: item.live_seckill_id,
          sort: this.rankNumber,
        },
        successMsg: true,
      })
      this.$refs.childDialogBox.reset()
      this.cancel1(item.course_id)
    },

    // 取消排序
    cancel1() {
      document.body.click()
      this.rankNumber = ''
    },
    toCommerce() {
      this.addCourseBool = true
    },

    upDownLink(id, status) {
      this.$http({
        url: '/LiveGoods/push',
        data: {
          open_class_id: this.$route.params.id,
          live_goods_id: id,
          status: status,
        },
        callback: () => {
          this.$refs.childDialogBox.reset()
          this.$root.prompt({
            msg: status == 1 ? '链接已推送到直播间！' : '操作成功',
            type: 'success',
          })
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#consoleCommodity {
  padding: 10px 20px;
  position: absolute;
  left: 0;
  top: 55px;
  right: 0;
  bottom: 0;
  ::v-deep .console_childDialogBox .el-pagination {
    white-space: normal;
    word-break: break-all;
  }

  ::v-deep .console_childDialogBox .el-pagination button {
    color: #fff !important;
    background: #292929 !important;
  }

  ::v-deep .console_childDialogBox .pagination {
    height: auto;
    padding-bottom: 0;
    padding-top: 0;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }
  ::v-deep .console_childDialogBox .pagination2Box {
    position: absolute;
    left: 0;
    top: 106px;
    right: 0;
    bottom: 59px;
    height: auto;
    overflow-y: auto;
  }
  ::v-deep .console_childDialogBox .pagination2Box {
    // margin: 20px 0;
    // @extend %liveRoomScroll;
    &::-webkit-scrollbar-track {
      background: none;
      box-shadow: none;
      border-radius: 0;
    }
  }

  ::v-deep .console_childDialogBox .el-table th.is-leaf {
    border-bottom: 1px solid #171717;
    background: #212121 !important;
    color: #fff !important;
  }
  ::v-deep .console_childDialogBox .el-table td,
  ::v-deep .console_childDialogBox .el-table__empty-block {
    background: #171717;
    color: #fff !important;
    border-bottom: 1px solid #212121;
  }

  ::v-deep .console_childDialogBox .el-table {
    position: absolute;
    left: 0;
    top: 68px;
    width: 100%;
    height: auto;
    bottom: 10px;
    overflow-y: auto;
    // overflow-x: auto !important;
    background-color: inherit;
    @extend %liveRoomScroll;
    &::-webkit-scrollbar-track {
      background: none;
      box-shadow: none;
      border-radius: 0;
    }
    .el-table__body-wrapper {
      height: auto;
      // overflow-x: auto;
      @extend %liveRoomScroll;
      // overflow: hidden !important;
    }
  }
  .pagination {
    padding: 0 !important;
  }

  //   ::v-deep .pagination {
  //     padding: 17px 0 0;
  //   }
  //   ::v-deep .el-table {
  //     .el-table__body-wrapper {
  //       overflow: auto !important;
  //       height: 88%;
  //     }
  //   }
  .redtip {
    font-size: 12px;
    color: #ff3535;
    line-height: 16px;
    margin: 8px 0 20px;
  }

  ::v-deep .elDrawer {
    .contain {
      position: relative;
      height: 100%;
      .drawBtn {
        position: absolute;
        bottom: 20px;
        right: 20px;
      }
    }

    .el-form {
      padding: 0 20px;
    }
    .el-drawer__header {
      font-size: 18px;
      color: #333333;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    ::v-deep .el-input__inner {
      border: 0;
      color: #fff;
      background: #292929;
    }
    ::v-deep .el-button--primary,
    ::v-deep .el-button--primary:hover,
    ::v-deep .el-button--primary:focus {
      border: 0;
      background: #fa6400;
    }
  }
  .console_childDialogBox {
    .good_contain {
      display: flex;

      .pic {
        width: 75px;
        height: 54px;
        position: relative;
        flex-shrink: 0;
        background: #d0d0d0;
        .tips {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          bottom: 0;
          left: 0;
          height: 16px;
          line-height: 16px;
          background: rgba(0, 0, 0, 087);
          text-align: center;
          font-size: 12px;
          color: #ffffff;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .name_price {
        margin-left: 10px;
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          line-height: 14px;
          color: #fff;
        }
        .price {
          font-size: 12px;
          color: #7e7e7e;
          line-height: 12px;
          .corss_price {
            margin-left: 10px;
            text-decoration: line-through;
            color: #999999;
          }
        }
      }
    }
    .miaosha {
      font-size: 12px;
      line-height: 12px;
      font-weight: bold;
      color: #fa6400;
    }
    .btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      ::v-deep .el-button--primary,
      ::v-deep .el-button--primary:hover,
      ::v-deep .el-button--primary:focus {
        border: 0;
        background: #fa6400;
      }
      .cancel {
        flex-shrink: 0;
        width: 76px;
        height: 36px;
        background: #452d1e;
        border-radius: 6px;
        font-size: 13px;
        font-weight: bold;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .tubiao1,
      .tubiao2 {
        cursor: pointer;
        margin-right: 10px;
        flex-shrink: 0;
        width: 36px;
        height: 36px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 20px;
          height: auto;
        }
      }
      .tubiao1 {
        position: relative;
        background: #282828;
        .miaosha_tips {
          display: none;
          position: absolute;
          left: -7px;
          width: 37px;
          font-size: 12px;
        }
        &:hover .miaosha_tips {
          display: block;
        }
      }
      .tubiao2 {
        background: #9d6946;
      }
      .sp_dir {
        color: #1b9d97;
        margin: 0 10px;
      }
    }
    .footer {
      margin-top: 20px;
      font-size: 14px;
      color: #333333;
    }
  }

  ::v-deep .console_childDialogBox .el-table--group::after,
  ::v-deep .console_childDialogBox .el-table--border::after {
    background-color: #171717 !important;
  }
  ::v-deep .console_childDialogBox .el-table::before {
    display: none;
  }
  ::v-deep .console_childDialogBox .el-table tr {
    background: #2a2a2a;
  }
  ::v-deep
    .console_childDialogBox
    .el-table--enable-row-hover
    .el-table__body
    tr:hover
    > td {
    background: #393939;
  }
  ::v-deep .console_childDialogBox .el-table th.is-leaf {
    border-bottom: 1px solid #171717;
    background: #212121 !important;
    color: #fff !important;
  }
  ::v-deep .console_childDialogBox .el-table td,
  ::v-deep .console_childDialogBox .el-table__empty-block {
    background: #171717;
    color: #fff !important;
    border-bottom: 1px solid #212121;
  }
  ::v-deep .console_childDialogBox .btn-prev .el-icon,
  ::v-deep .console_childDialogBox .btn-next .el-icon {
    color: #fff !important;
  }
  ::v-deep.console_childDialogBox .el-pagination {
    white-space: normal;
    word-break: break-all;
  }

  ::v-deep .console_childDialogBox .el-pagination button {
    color: #fff !important;
    background: #292929 !important;
  }

  ::v-deep .console_childDialogBox .el-pager li {
    color: #fff;
    background-color: #292929;
  }

  ::v-deep .console_childDialogBox .el-pager li.active {
    background-color: #fa6400 !important;
  }

  ::v-deep .search {
    padding: 0;
  }
  ::v-deep .console_childDialogBox .pagination {
    height: auto;
    padding-bottom: 0;
    padding-top: 0;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }
  ::v-deep .console_childDialogBox .pagination2Box {
    position: absolute;
    left: 0;
    top: 106px;
    right: 0;
    bottom: 59px;
    height: auto;
    overflow-y: auto;
  }
  ::v-deep .console_childDialogBox .pagination2Box {
    // margin: 20px 0;
    // @extend %liveRoomScroll;
    &::-webkit-scrollbar-track {
      background: none;
      box-shadow: none;
      border-radius: 0;
    }
  }

  ::v-deep .console_childDialogBox .selectDialogBox {
    position: absolute;
    left: 0;
    top: 77px;
    right: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    .rightContent {
      flex: 1;
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      padding: 0 20px;
      box-sizing: border-box;
      .el-table__body-wrapper {
        // overflow: hidden;
      }
    }
    .console_childDialogBox .pagination2Box {
      width: 100%;
      position: static;
      height: 100%;
      box-sizing: border-box;
      margin: 20px 0;
    }
  }
}
</style>
<style lang="scss">
// .consolecommoditypopper {
//   left: 407px !important;
// }

// .consolecommoditypopper2 {
//   left: 800px !important;
// }
</style>
