<!--
 * @Author: cyy
 * @Date: 2022-01-14 11:38:47
 * @LastEditTime: 2022-12-01 14:47:14
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacherdingding.com\src\components\base\networktypeSelect\commoditySelection.vue
-->
<template>
  <div id="commoditySelection">
    <div class="search">
      <div class="flex-center">
        <el-select
          v-if="!goods_class"
          v-model="post.goods_class"
          style="width: 120px"
          size="medium"
          placeholder="请选择"
          class="mr20"
        >
          <el-option
            v-for="item in options2"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>

        <el-select
          v-if="!goods_class  "
          v-model="post.goods_type"
          style="width: 120px"
          size="medium"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>

        <el-input
          size="medium"
          v-model="post.name"
          class="ml20"
          style="width: 218px"
          placeholder="输入名称搜索"
        ></el-input>
      </div>
    </div>
    <pagination2 :option="post" url="/LiveGoods/goodsList" ref="childDialogBox">
      <template v-slot:default="{ tableData }">
        <div class="childDialogBox">
          <el-table
            ref="dialogTable"
            @row-click="handleRowClick"
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333',
            }"
            :row-key="getRowkey"
            :data="tableData"
            @selection-change="handleSelectionChange"
          >
            <el-table-column label width="30" v-if="isSingleSelect">
              <template slot-scope="scope">
                <el-radio :label="scope.row.live_goods_id" v-model="radioId">
                  &nbsp;
                </el-radio>
              </template>
            </el-table-column>
            <el-table-column
              min-width="45px"
              type="selection"
              v-else
            ></el-table-column>
            <el-table-column label="商品">
              <template slot-scope="{ row }">
                <div class="good_contain">
                  <div class="pic">
                    <img :src="row.cover_img | imageslim(5)" alt="" />
                  </div>
                  <div class="name_price">
                    <div class="name">{{ row.name }}</div>
                    <div class="price">
                      <span v-if="row.price">￥{{ row.price }}</span>
                      <span class="corss_price" v-if="row.cost_price">
                        ￥{{ row.cost_price }}
                      </span>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="类型" width="100px">
              <template slot-scope="{ row }">
                {{ row.goods_class | gettype2 }}
              </template>
            </el-table-column>

            <el-table-column label="平台" width="100px">
              <template slot-scope="{ row }">
                {{ row.goods_type | gettype }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
    </pagination2>
  </div>
</template>
<script>
export default {
  name: 'commoditySelection',
  data() {
    return {
      options: [
        {
          value: '0',
          label: '全部平台',
        },
        {
          value: '1',
          label: '淘宝',
        },
        {
          value: '2',
          label: '天猫',
        },
        {
          value: '3',
          label: '京东',
        },
        {
          value: '4',
          label: '其他',
        },
      ],

      options2: [
        {
          value: 0,
          label: '全部类型',
        },
        {
          value: 1,
          label: '知识商品',
        },
        {
          value: 2,
          label: '外部商品',
        },
      ],

      radioId: '',

      listSave: [],

      post: {
        goodlistType: this.goodlistType,
        goodList: this.goodList
          ? this.goodList.length == 0
            ? 1
            : this.goodList
          : undefined,
        goods_type: '0',
        name: '',
        goods_class: this.goods_class ? Number(this.goods_class) : 0,
        installment_id: this.installment_id,
        seckill_id: this.seckill_id,
        is_seckill: this.is_seckill,
      },
    }
  },

  props: {
    // 秒杀
    is_seckill: {
      type: Boolean,
      default: undefined,
    },
    // 秒杀--一个课程或内容只能被秒杀选择一次
    seckill_id: {
      type: String,
      default: undefined,
    },
    // 是否需要过滤免费课成
    goodlistType: {
      type: Boolean,
      default: false,
    },
    // 商品的选择
    goodList: [Array, Object, String],

    ids: {
      type: Array,
      default: () => [],
    },

    // 免息券id
    installment_id: {
      type: String,
      default: undefined,
    },

    //true是单选 false:多选
    isSingleSelect: {
      type: Boolean,
      default: true,
    },

    // 商品的选择
    goods_class: {
      type: Number,
      default: undefined,
    },
  },

  filters: {
    gettype(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '淘宝'
          break
        case 2:
          text = '天猫'
          break
        case 3:
          text = '京东'
          break
        case 4:
          text = '其他'
          break
        default:
          text = '-'
      }
      return text
    },
    gettype2(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '知识商品'
          break
        case 2:
          text = '外部商品'
          break
      }
      return text
    },
  },

  methods: {
    getRowkey(row) {
      return row.live_goods_id
    },

    // 单选时需要
    handleRowClick(row) {
      this.radioId = row.live_goods_id
      if (this.isSingleSelect) {
        this.listSave = [row]
        this.$emit('singleselectcontent', row)
      } else {
        this.$refs.dialogTable.toggleRowSelection(row)
      }
    },

    handleSelectionChange(val) {
      this.listSave = val
      this.$emit('listSave', val)
    },
  },
}
</script>

<style lang="scss" scoped>
#commoditySelection {
  height: 406px;
  display: flex;
  flex-direction: column;
  .search {
    flex-shrink: 0;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  .childDialogBox {
    height: 285px;
    overflow: auto;
    @extend %scroll;
    margin-bottom: 1px;
  }
  .good_contain {
    display: flex;

    .pic {
      background-color: #f5f5f5;
      width: 60px;
      height: 60px;
      min-width: 60px;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .name_price {
      flex: 1;
      overflow: hidden;
      .name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
        color: #333333;
      }
      .price {
        margin-top: 20px;
        font-size: 14px;
        color: #ff3535;
        .corss_price {
          margin-left: 10px;
          text-decoration: line-through;
          color: #999999;
        }
      }
    }
  }
}
</style>
