import TIM from 'tim-js-sdk/tim-js-friendship.js'
import { from, forkJoin, mergeMap, bufferCount, of, timer } from 'rxjs';
import { map } from 'rxjs/operators';
// 历史消息
export const getMsgList = (im, info) => {
  return new Promise((res) => {
    const promise = im.getMessageList({
      conversationID: `C2C${info.userID}`,
      count: 1,
      nextReqMessageID: null,
    })
    promise.then((imResponse) => {
      let list = imResponse.data.messageList
      res(list)
    })
  })
}
// 删好友
export const delFriend = (im, info = []) => {
  if (info.length <= 0) return Promise.resolve();
  // 创建一个 Observable，将 info 数组转换为一个数据流
  const source$ = from(info);
  // 使用 bufferCount 将数据流分割成大小为 100 的批次
  const batched$ = source$.pipe(bufferCount(100));
  // 使用 mergeMap 顺序处理每一个批次，发送 deleteFriend 请求，并在每次请求间增加 200ms 的延迟
  return batched$.pipe(
    mergeMap((batch, index) => {
      // 在每个批次请求前添加 200ms 的延迟，除了第一个批次
      if (index > 0) {
        return timer(200).pipe(
          mergeMap(() => {
            return from(im.deleteFriend({
              userIDList: batch,
              // type: TIM.TYPES.SNS_DELETE_TYPE_SINGLE,
              type: TIM.TYPES.SNS_DELETE_TYPE_BOTH,
            }));
          })
        );
      } else {
        return from(im.deleteFriend({
          userIDList: batch,
          type: TIM.TYPES.SNS_DELETE_TYPE_BOTH,
        }));
      }
    }, 1) // 设置并发数为 1，确保请求顺序执行
  )
    .toPromise()
    .then(() => {
      // 所有请求完成后返回成功
      return Promise.resolve();
    })
    .catch((error) => {
      // 捕获并处理任何错误
      return Promise.reject(error);
    });
};


// 检出删除列表
export const checkDelList = (im, info = []) => {
  if (info.length === 0) return of([]);
  // 创建一个 Observable 数组，每个 Observable 都代表一个异步的 getMsgList 调用
  const observables = info.map(item =>
    from(getMsgList(im, item)).pipe(
      map(list => list.length === 0 ? item.userID : null)
    )
  );
  // 使用 forkJoin 将所有 Observables 结合成一个，当所有 Observables 都完成时，才发出结果
  return forkJoin(observables).pipe(
    map(results => results.filter(Boolean)) // 过滤掉 null 值，只保留 userID
  );
};