<!--
 * @Author: cyy
 * @Date: 2021-12-23 17:36:22
 * @LastEditTime: 2021-12-24 16:39:50
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \ teacher.dingdingkaike\src\views\OpenClass_openClassLive\components\centercomponents\giftAnimation.vue
-->
<template>
  <transition-group
    :name="`queue_${direction}`"
    tag="ul"
    @after-enter="afterEnter"
    @after-leave="afterLeave"
  >
    <slot :list="item"></slot>
  </transition-group>
</template>
<script>
export default {
  name: 'giftAnimation',

  props: {
    list: Array,
    max: {
      type: Number,
      default: 1,
    },
    direction: String,
    duration: {
      type: Number,
      default: 3000,
    },
  },

  data() {
    return {
      item: [],
    }
  },

  watch: {
    // 监听列表变化 如果有变化就执行动画
    list: {
      handler(val) {
        if (val.length > 0) {
          this.setList(val)
        }
      },
      immediate: true,
    },
  },

  methods: {
    // 执行动画
    setList(list) {
      // 如果执行列表小于最大的数量 添加进去剩余的
      if (this.item.length < this.max) {
        const val = list.slice(this.item.length, this.max)
        if (val.length > 0) {
          this.item.push(...val)
        }
      }
    },

    // 动画完成后删除它
    afterEnter() {
      setTimeout(() => {
        this.item.shift()
        this.$emit('leaveEnd')
      }, this.duration)
    },

    // 动画结束后
    afterLeave() {},
  },
}
</script>
<style lang="scss" >
.queue_toLeft-leave-active,
.queue_toRight-leave-active {
  opacity: 0;
  position: absolute;
}
.queue_toLeft-enter {
  opacity: 0;
  transform: translateX(100%);
}
.queue_toRight-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.queue_toLeft-enter-active,
.queue_toRight-enter-active {
  transition: all 0.25s ease;
}
.queue_toLeft-move,
.queue_toRight-move {
  transition: all 0.15s linear;
}
</style>