<!--
 * @Author: cyy
 * @Date: 2022-02-18 11:26:18
 * @LastEditTime: 2022-03-28 16:22:03
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \ teacher.dingdingkaike\src\views\OpenClass_openClassLive\components\liveDataComponents\openclassDatastatistics\main.vue
-->
<template>
  <div id="openclassDatastatistics">
    <myMenu :data="menuList" class="menuList" v-model="menuId" />
    <div class="childComponent">
      <online
        :openClassId="openClassId"
        :rightOpen="rightOpen"
        v-if="menuId == 0"
      />
      <enterRecord :openClassId="openClassId" v-if="menuId == 1" />
      <invitationList
        v-if="menuId == 2"
        :openInfo="openInfo"
        :openClassId="openClassId"
      />
      <reward v-if="menuId == 3" :openClassId="openClassId" />
      <gift-giving v-if="menuId == 4" :openClassId="openClassId" />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapMutations } = createNamespacedHelpers(
  'OpenClassopenClassLive'
)
import online from './components/online'
import enterRecord from './components/enterRecord'
import invitationList from './components/invitationList'
import reward from './components/reward'
import myMenu from '../menu'
import giftGiving from './components/giftGiving'

export default {
  name: 'openclassDatastatistics',

  components: {
    online,
    myMenu,
    enterRecord,
    invitationList,
    reward,
    giftGiving,
  },

  computed: {
    ...mapState([
      'openClassId',
      'rightOpen',
      'openInfo',
      'openclassDatastatisticsActiveval',
    ]),
  },

  data() {
    return {
      menuId: 0,

      menuList: [
        {
          name: '在线人数',
          id: 0,
        },
        {
          name: '进入记录',
          id: 1,
        },
        {
          name: '邀请榜单',
          id: 2,
        },
        {
          name: '打赏榜单',
          id: 3,
        },
        {
          name: '送礼榜单',
          id: 4,
        },
      ],
    }
  },

  watch: {
    openclassDatastatisticsActiveval: {
      handler(val) {
        if (Array.isArray(val)) {
          this.menuId = val[1]
          this.openclassDatastatisticsActive(null)
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapMutations(['openclassDatastatisticsActive']),
  },
}
</script>

<style lang="scss" scoped>
#openclassDatastatistics {
  display: flex;
  flex-direction: column;
  height: 100%;
  .menuList {
    height: 60px;
    flex-shrink: 0;
  }
  .childComponent {
    flex: 1;
  }
}
</style>