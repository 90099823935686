<template>
    <div class="addText">
      <el-dialog
        :title="type=='add'?'添加话术本':'发送记录'"
        class="dialogVisible"
        :visible="dialogstatus"
        @update:visible="v => $emit('update:dialogstatus', v)"
        v-if="dialogstatus"
        width="840px"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        :append-to-body="appendToBody"
        @close="cancel"
      >
        <div class="dialogVisible_bottom">
          <div class="header">
            <div :class="['header-bet',type=='msgRecordLogList'&&'flBox']">
                <div v-if="type=='add'">
                  <el-button type="primary" @click="toCommerce">创建话术本</el-button>
                  （仅支持聊天记录类型）
                </div>
                <div class="flex-center">
                  <el-select
                    v-if="type!='add'"
                    style="width: 120px"
                    v-model="post.search_type"
                    size="medium"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                  <el-input
                      size="medium"
                      v-model="post.search"
                      style="width: 218px"
                      placeholder="输入名称搜索"
                  ></el-input>
                  <el-button
                      type="primary"
                      class="ml10"
                      @click="$refs.childDialogBox.reset()"
                  >
                  搜索
                  </el-button>
                </div>
            </div>
          </div>

          <pagination2
            @complete="complete"
            :option="post"
            :url="type == 'add'
              ?'/chatMessage/chatMsgList'
              :'/console/msgRecordLogList'"
            ref="childDialogBox"
            :allFn="true"
          >
            <template v-slot:default="{ tableData }">
              <div class="pagination2Box" ref="pagination2Box">
                <el-table
                  max-height:200
                  :header-cell-style="{
                    background: 'rgba(245,245,245,1)',
                    color: '#333333',
                  }"
                  @row-click="handleRowClick"
                  @sort-change="sortChange"
                  :data="tableData"
                  :key="'tableRefreshKey'+tableRefreshKey"
                >
                  <template v-if="type=='add'">
                    <el-table-column label width="30" v-if="isSingleSelect">
                      <template slot-scope="scope">
                        <el-radio :label="scope.row.msg_id" v-model="radioId" @input="setText">
                          &nbsp;
                        </el-radio>
                      </template>
                    </el-table-column>
                    <el-table-column
                      min-width="45px"
                      type="selection"
                      v-else
                    ></el-table-column>

                    <el-table-column label="名称" min-width="100">
                      <template slot-scope="{ row }">
                        <div class="people-contain">
                          <div class="name" :title="row.name">
                            {{ row.name }}
                          </div>
                        </div>
                      </template>
                    </el-table-column>
  
                    <el-table-column label="评论条数" min-width="100">
                      <template slot-scope="{ row }">
                        <div class="select-send-time">
                          {{ row.num || '-' }}
                        </div>
                      </template>
                    </el-table-column>
  
                    <el-table-column label="创建人">
                      <template slot-scope="{ row }">
                        {{ row.uname }}
                      </template>
                    </el-table-column>

                    <el-table-column label="创建时间" sortable="custom" prop="c_time">
                      <template slot-scope="{ row }">
                        <span v-if="row.c_time">
                          {{ row.c_time | formatTimeStamp }}
                        </span>
                        <span v-else>-</span>
                      </template>
                    </el-table-column>
                  </template>

                  <template v-else>
                    <el-table-column label="名称">
                      <template slot-scope="{ row }">
                        {{ row.name }}
                      </template>
                    </el-table-column>

                    <el-table-column label="发送人">
                      <template slot-scope="{ row }">
                        {{ row.uname }}
                      </template>
                    </el-table-column>

                    <el-table-column label="发送条数">
                      <template slot-scope="{ row }">
                        {{ row.num }}
                      </template>
                    </el-table-column>

                    <el-table-column label="发送时间" sortable="custom" prop="c_time">
                      <template slot-scope="{ row }">
                        {{ row.c_time | formatTimeStamp }}
                      </template>
                    </el-table-column>
                  </template>
                </el-table>
              </div>
            </template>
          </pagination2>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel" size="medium">
            取 消
          </el-button>
          <el-button type="primary" @click="submit" size="medium">
            确 定
          </el-button>
        </span>
      </el-dialog>
    </div>
  </template>

<script>
export default {
    name:'addText',
    props:{
      openClassId: [Number, String],
      dialogstatus: {
        type: Boolean,
        default: false,
      },
      appendToBody: {
        type: Boolean,
        default: true,
      },
      type: {
          type:String,
          default:"add"
      },
      //true是单选 false:多选
      isSingleSelect: {
        type: Boolean,
        default: true,
      },
    },
    data(){
        return {
          iscomplete: false,
          tableList:[],
          post:{},
          radioId: '',
          listSave:[],
          tableRefreshKey:0,
          options: [
            {
              value: 1,
              label: '话术本名称',
            },
            {
              value: 2,
              label: '发送人',
            },
          ],
          save:{},
        }
    },
    created() {
      if(this.type == 'add'){
        this.post = {
          type: 3,
          search: '',
          sort: '',
          // pagesize: 5,
          // sizes: 5,
          // num: 5,
        }
      } else {
        this.post = {
          open_class_id:this.openClassId,
          search_type: 1,
          search: '',
          sort: '',
          // pagesize: 5,
          // sizes: 5,
          // num: 5,
        }
      }
    },
    methods:{
      toCommerce(){
        let url = this.$router.resolve({
            path: '/messageTemplate',
        })
        this.$store.dispatch('open', url.href)
      },
      // 多选保存
      handleRowClick(row) {
        if(this.type=='add'){
          this.setText(row.msg_id)
          this.radioId=row.msg_id
        }
        this.listSave = [row]
        this.$emit('singleselectcontent', row)
      },
      // 单选保存
      setText(row){
        this.save = this.tableList.filter(v=>v.msg_id==row)[0]
      },
      // 获取列表数据
      complete(val) {
        this.tableList = val.data
        this.iscomplete = true
      },
      sortChange(value) {
        var sort = null
        switch (value.order) {
          case 'descending':
            sort = this.type=='add'?2:'DESC'
            break
          case 'ascending':
            sort = this.type=='add'?1:'ASC'
            break
        }
        this.post.sort = sort
      },
      submit() {
        if(this.type == 'add') {
          if(this.radioId){
            this.$emit('setText',this.save)
            this.cancel()
          } else {
            this.$root.prompt('请选择话术本')
          }
        } else {
          this.cancel()
        }
      },
      cancel() {
        this.radioId = ''
        // 取消后回复选择数据原样
        this.$emit('update:dialogstatus', false)
      },
    },
}
</script>

<style lang="scss" scoped>
.dialogVisible {
  // ::v-deep .el-dialog {
  //   min-height: 400px;
  // }
  .dialogVisible_bottom {
    font-size: 14px;
    color: #333333;
    .header {
      .header-bet{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        ::v-deep .el-input__inner{
          border: 1px solid #DCDFE6 !important;
          color: #606266 !important;
          background:none !important;
        }
      }
      .flBox {
        float: right;
      }
    }
    ::v-deep .el-table__body-wrapper{
      // min-height: 100px;
      height: 200px;
      overflow-y: auto;
    }
  }
}
</style>