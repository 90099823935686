<template>
  <div class="qian-dao">
    <div class="qian-dao-first">
      <el-button
        type="primary"
        style="background: #fa6400; border: 0"
        :class="{ btn: true, dangerbtn: qiandaoState == true }"
        @click="initiateSignInClick"
      >
        {{ !qiandaoState ? '发起签到' : '停止签到' }}
      </el-button>
      <span v-if="qiandaoState" class="has-time">
        {{
          `签到倒计时 : 00:${numberFull10(minutes)}:${numberFull10(seconds)}`
        }}
      </span>
    </div>

    <div class="qiandao-span">
      <div
        :class="{ span: true, spanc: defaultSelectState == 1 }"
        @click="qiandaoClick(1)"
      >
        <span :class="{ green: defaultSelectState == 1 }">签到记录</span>
      </div>
      <div
        :class="{ span: true, spanc: defaultSelectState == 2 }"
        @click="qiandaoClick(2)"
      >
        <span :class="{ green: defaultSelectState == 2 }">签到设置</span>
      </div>
      <span class="update" @click="updateClick" v-if="defaultSelectState == 1">
        刷新列表
      </span>
    </div>

    <div class="divier"></div>

    <div class="content">
      <div class="qiandao-now flex-column" v-if="defaultSelectState == 1">
        <span class="no-data" v-if="optionTime.length == 0">暂无数据</span>
        <div class="q-time"><span>发起时间</span></div>
        <div class="q-time-ul">
          <ul class="absolute scroll">
            <li
              v-for="(item, index) in optionTime"
              :key="index"
              :class="{
                qtimeli: true,
                selectActive: selectState == item.sign_id,
              }"
              @click="timeClick(item.sign_id)"
            >
              <span>{{ timeTranslate(item.s_time) }}</span>
            </li>
          </ul>
        </div>
      </div>

      <pagination2
        v-if="defaultSelectState == 1"
        :allFn="true"
        :option="post"
        :pagerCount="5"
        url="/OpenClassSign/studentSignList"
        class="list"
      >
        <template v-slot:default="{ tableData }">
          <el-table
            ref="dialogTable"
            :header-cell-style="{
              background: '#212121',
              color: '#fff',
            }"
            :data="tableData"
            style="width: 100%"
            class="table_row"
            @sort-change="changeSort"
          >
            <el-table-column label="签到学员" min-width="200">
              <template slot-scope="{ row }">
                <div class="student-name">
                  <div class="img">
                    <img :src="row.uphoto" alt="" />
                  </div>
                  <div
                    class="student-text"
                    :title="
                      row.student_remarks != ''
                        ? row.uname + '(' + row.student_remarks + ')'
                        : row.uname
                    "
                  >
                    {{
                      row.student_remarks != ''
                        ? row.uname + '(' + row.student_remarks + ')'
                        : row.uname
                    }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="签到时间"
              min-width="120"
              sortable="custom"
              prop="qiaodaoTime"
              :sort-orders="['ascending', 'descending']"
            >
              <template slot-scope="{ row }">
                {{ timeTranslate(row.c_time) }}
              </template>
            </el-table-column>
          </el-table>
        </template>
      </pagination2>

      <div class="set" v-if="defaultSelectState == 2">
        <div class="switch">
          <el-switch
            v-model="switchValue"
            active-color="#0aa29b"
            inactive-color="#C5C5C5"
            @change="switchChange"
          ></el-switch>
        </div>
        <div class="text">
          <div><span>签到成功信息展示</span></div>
          <div>
            <span>
              开启后，会在直播间展示学员成功签到的信息（小程序端暂不支持显示）
            </span>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="发起签到"
      :visible.sync="dialogVisible1"
      width="450px"
      top="25vh"
      append-to-body
    >
      <div class="dialog-tip">
        <span>签到时长</span>
        <help-icon
          class="help"
          prompt="指发起签到后,在这个时长内签到有效,过后则不会显示签到按钮"
          v-if="qiandaoState == false"
        ></help-icon>
        <el-select
          size="small"
          v-model="value"
          class="qian-time-select"
          placeholder="请选择签到时长"
          @change="selectChange(value)"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false" style="width: 100px">
          取&nbsp;&nbsp;&nbsp;&nbsp;消
        </el-button>
        <el-button type="primary" @click="footerClick" style="width: 100px">
          确定发起
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import helpIcon from '@/components/helpIcon'
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapMutations } = createNamespacedHelpers(
  'OpenClassopenClassLive'
)

export default {
  name: 'SignIn',
  data() {
    return {
      options: [
        {
          value: '30秒',
          label: '30秒',
        },
        {
          value: '1分钟',
          label: '1分钟',
        },
        {
          value: '5分钟',
          label: '5分钟',
        },
        {
          value: '10分钟',
          label: '10分钟',
        },
        {
          value: '15分钟',
          label: '15分钟',
        },
        {
          value: '30分钟',
          label: '30分钟',
        },
      ],
      options1: [],
      value: '',
      value1: '',
      post: {
        sign_id: -1,
      },
      optionTime: [],
      selectState: -1,
      switchValue: true,
      qiandaoState: false,
      timer: null,
      minutes: 0,
      seconds: 0,
      time: -1,
      messages: ``,
      dialogVisible1: false,
    }
  },
  props: {
    openClassId: {
      type: String,
      default: '',
    },
  },
  components: {
    helpIcon,
  },
  computed: {
    ...mapState(['defaultSelectState']),
  },
  watch: {},
  created() {},
  methods: {
    ...mapMutations(['setDefaultSelectState']),
    initiateSignInClick() {
      // console.log('initiateSignInClick')
      if (this.qiandaoState == false) {
        //发起签到判断
        // if (this.value == '') {
        //   this.$confirm('请先选择签到时长！', '温馨提示', {
        //     confirmButtonText: '确定',
        //     cancelButtonText: '取消',
        //     // callback: action => {
        //     //   console.log('action', action)
        //     // }
        //   })

        // } else {
        //   //发起签到

        //   //选中的时间
        //   this.timeF()

        //   this.$http({
        //     url: '/OpenClassSign/initiateSign',
        //     data: {
        //       open_class_id: parseInt(this.openClassId),
        //       sign_time: this.time
        //     },
        //     callback: ({ code }) => {
        //       // console.log('发起签到', code)
        //       if (code == 200) {
        //         //一开始发起签到就更新左侧数据
        //         this.signInStartTime()

        //         this.qiandaoState = !this.qiandaoState

        //         //设置定时器
        //         this.timer = setInterval(this.timeFunc, 1000)
        //         this.timeFunc()
        //       }
        //     }
        //   })
        // }

        this.dialogVisible1 = true
      } else {
        //结束签到
        if (this.time > 0) {
          this.$confirm('是否确定停止签到？', '温馨提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            callback: action => {
              // console.log('action', action)
              if (action == 'confirm') {
                //强制结束签到
                this.$http({
                  url: '/OpenClassSign/stopSign',
                  data: {
                    open_class_id: parseInt(this.openClassId),
                  },
                  callback: ({ code }) => {
                    // console.log('强制结束签到', code)
                    if (code == 200) {
                      //初始化
                      this.time = -1
                      this.minutes = 0
                      this.seconds = 0
                      this.qiandaoState = !this.qiandaoState
                      this.value = ''
                      clearInterval(this.timer)
                      this.signInStartTime() //签到结束之后请求一下记录
                    }
                  },
                })
              }
            },
          })
        }
      }
    },
    timeF() {
      if (this.value == '30秒') {
        this.time = 30
        this.seconds = 30
      } else if (this.value == '1分钟') {
        this.time = 60
        this.seconds = 0
      } else if (this.value == '5分钟') {
        this.time = 5 * 60
        this.minutes = 5
      } else if (this.value == '10分钟') {
        this.time = 10 * 60
        this.minutes = 10
      } else if (this.value == '15分钟') {
        this.time = 15 * 60
        this.minutes = 15
      } else {
        this.time = 30 * 60
        this.minutes = 30
      }
    },
    timeFunc() {
      if (this.time > 0) {
        this.minutes = Math.floor(this.time / 60)
        this.seconds = Math.floor(this.time % 60)
        this.time--
      } else {
        clearInterval(this.timer)

        //初始化
        this.time = -1
        this.minutes = 0
        this.seconds = 0
        this.qiandaoState = !this.qiandaoState
        this.value = ''
        clearInterval(this.timer)

        this.signInStartTime() //签到结束之后请求一下记录

        this.$root.prompt({
          type: 'warning',
          message: `签到已结束！`,
        })
      }
    },
    timeClick(sign_id) {
      this.selectState = sign_id

      this.post = {
        ...{ sign_id: sign_id },
      }
    },
    selectChange(value) {
      // console.log('label', value, this.value)
      this.value = value
    },
    qiandaoClick(index) {
      if (this.defaultSelectState == 1) {
        if (index == 2) {
          this.setDefaultSelectState(2)
        }
      } else {
        if (index == 1) {
          this.setDefaultSelectState(1)
        }
      }
    },
    updateClick() {
      // this.post = {
      //   ...{ sign_id: this.selectState }
      // }
      clearInterval(this.timer)
      this.getCountDown()
      this.signInStartTime()
    },
    // 填充小于10的0前缀
    numberFull10(value) {
      return value < 10 ? `0${value}` : value
    },
    changeSort(column) {
      // 从后台获取数据,重新排序
      // console.log('column', column.prop, column.order)
      if (column.order == 'descending') {
        this.post = {
          ...{ sign_id: this.selectState, sort: 2 },
        }
      } else if (column.order == 'ascending') {
        this.post = {
          ...{ sign_id: this.selectState, sort: 1 },
        }
      } else {
        this.post = {
          ...{ sign_id: this.selectState },
        }
      }
    },
    signInStartTime() {
      // console.log('signInStartTime')
      //初始化发起时间
      this.$http({
        url: '/OpenClassSign/signTimeList',
        data: {
          open_class_id: parseInt(this.openClassId),
        },
        callback: ({ data }) => {
          // console.log('初始化发起时间', data)

          this.optionTime = data

          if (!data || data.length === 0) return

          //如果selectState == -1,进行初始化,选中列表的第一个
          if (this.selectState == -1) {
            //左侧列表
            this.selectState = data[0].sign_id
            // console.log('this.selectState', this.selectState)
            this.post = {
              //右侧列表
              ...{ sign_id: this.selectState },
            }
          } else {
            this.post = {
              //右侧列表
              ...{ sign_id: this.selectState },
            }
          }
        },
      })
    },
    signInSetting() {
      //初始化签到设置
      this.$http({
        //get请求参数写在地址栏
        url: `/OpenClassSign/setSign?open_class_id=${parseInt(
          this.openClassId
        )}`,
        callback: ({ data }) => {
          // console.log('初始化发起时间', data)

          if (data.is_show_sign_msg == 1) {
            this.switchValue = true
          } else {
            this.switchValue = false
          }
        },
      })
    },
    timeTranslate(t) {
      let date = new Date()
      date.setTime(t * 1000)
      let testTime = `${date.getFullYear()}-${this.numberFull10(
        date.getMonth() + 1
      )}-${this.numberFull10(date.getDate())} ${this.numberFull10(
        date.getHours()
      )}:${this.numberFull10(date.getMinutes())}:${this.numberFull10(
        date.getSeconds()
      )}`
      // console.log(testTime)
      return testTime
    },
    switchChange() {
      // console.log('switchChange',this.switchValue)
      this.$http({
        url: '/OpenClassSign/setSign',
        data: {
          open_class_id: parseInt(this.openClassId),
          is_show_sign_msg: this.switchValue ? 1 : 2,
        },
        // callback: ({ data }) => {
        //   console.log('初始化发起时间', data)

        // }
      })
    },
    getCountDown() {
      //先从后台获取倒计时，然后再进行签到
      this.$http({
        url: '/OpenClassSign/countdown',
        data: {
          open_class_id: parseInt(this.openClassId),
        },
        callback: ({ data }) => {
          // console.log('获取当前签到倒计时', data)
          if (data.countdown != 0) {
            //后台传来的倒计时存在

            //初始化
            this.time = -1
            this.minutes = 0
            this.seconds = 0
            this.qiandaoState = true
            this.value = '1' //给个值

            this.time = parseInt(data.countdown)
            clearInterval(this.timer)
            this.timer = setInterval(this.timeFunc, 1000)
            this.timeFunc()
          } else {
            this.time = -1
            this.minutes = 0
            this.seconds = 0
            this.qiandaoState = false
            this.value = '' //初始化
            clearInterval(this.timer)
          }
        },
      })
    },
    dropCommand(value) {
      // console.log(value)
      this.value = value
    },
    footerClick() {
      this.dialogVisible1 = false
      if (this.value == '') {
        this.$root.prompt({
          message: '请先选择签到时长！',
          type: 'error',
        })
        this.dialogVisible1 = true
      } else {
        // 发起签到

        //选中的时间
        this.timeF()

        this.$http({
          url: '/OpenClassSign/initiateSign',
          data: {
            open_class_id: parseInt(this.openClassId),
            sign_time: this.time,
          },
          callback: ({ code }) => {
            // console.log('发起签到', code)
            if (code == 200) {
              //一开始发起签到就更新左侧数据
              this.signInStartTime()

              this.qiandaoState = !this.qiandaoState
              clearInterval(this.timer)

              //设置定时器
              this.timer = setInterval(this.timeFunc, 1000)
              this.timeFunc()
            }
          },
        })
      }
    },
  },

  mounted() {
    this.signInStartTime()
    this.getCountDown()
    this.signInSetting()
  },

  beforeDestroy() {
    clearInterval(this.timer)
  },
}
</script>

<style lang="scss" scoped>
.qian-dao {
  height: 100%;
  display: flex;
  flex-direction: column;

  .qian-dao-first {
    padding: 20px 20px 20px 20px;
    height: 40px;
    display: flex;
    align-items: center;
    .btn {
      height: 40px;
      width: 136px;
      background: #0aa29b;
      border-radius: 4px;
    }

    .has-time {
      margin: 0 10px 0 20px;
      height: 14px;
      font-size: 14px;
      color: #fff;
    }
  }

  .qiandao-span {
    height: 30px;
    padding: 0 20px 0 20px;
    position: relative;
    .span {
      height: 30px;
      display: inline-block;

      cursor: pointer;
      span {
        width: 56px;
        height: 14px;
        font-size: 14px;
        color: #fff;
      }
    }
    > div:first-child {
      margin-right: 40px;
    }
    .update {
      position: absolute;
      right: 20px;
      height: 14px;
      font-size: 14px;
      color: #0aa29b;
      cursor: pointer;
    }
  }
  .divier {
    height: 1px;
    background: #222222;
  }
}
.content {
  display: flex;
  flex: 1;
  .qiandao-now {
    flex-shrink: 0;
    position: relative;
    margin: 20px 0 0 20px;
    width: 200px;
    border-radius: 1px;
    .q-time-ul {
      flex: 1;
      position: relative;
      background: #2a2a2a;
      .scroll {
        overflow-y: auto;
        @extend %liveRoomScroll;
      }
    }
    .no-data {
      position: absolute;
      height: 14px;
      font-size: 14px;
      color: #909399;
      line-height: 14px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .q-time {
      height: 48px;
      line-height: 48px;
      background: #212121;
      border-radius: 1px;
      text-align: center;

      span {
        font-size: 14px;
        font-weight: bold;
        color: #fff;
      }
    }
    .qtimeli {
      height: 36px;
      text-align: center;
      line-height: 36px;
      cursor: pointer;
      span {
        font-size: 14px;
        color: #fff;
      }
    }
  }
  .list {
    flex: 1;
    overflow: hidden;
    margin: 20px 20px 0 0;
    background: #171717;
    position: relative;

    .table_row {
      ::v-deep .el-table__header-wrapper tr th {
        padding: 7px 0; //因为排序图标大小导致行高无法改变
      }
    }

    .student-name {
      height: 20px;
      margin-left: 10px;

      //可以使两个行内元素对齐,1.使用display: flex;align-items: center;2.或者不对齐元素设置vertical-align: top;
      display: flex;
      align-items: center;

      .img {
        display: inline-block;
        height: 20px;
        width: 20px;
        border-radius: 10px;
        margin-right: 10px;

        img {
          height: 20px;
          width: 20px;
        }
      }
      .student-text {
        display: inline-block;
        height: 20px;
        // vertical-align: top;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  .set {
    margin-top: 10px;
    height: 60px;
    display: flex;
    align-items: center;
    .switch {
      width: 81px;
      text-align: center;
    }
    .text div:first-child {
      margin-bottom: 14px;
      font-size: 14px;
      color: #ffffff;
    }
    .text div:last-child {
      font-size: 12px;
      color: #ffffff;
    }
  }
}

::v-deep .gutter {
  background: #f5f5f5;
}
::v-deep .el-table {
  color: #fff;
  background: #171717;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  @extend %liveRoomScroll;
}
::v-deep .el-table::before,
::v-deep .el-table--group::after,
::v-deep .el-table--border::after {
  background-color: #171717 !important;
}
::v-deep .el-table tr,
::v-deep .el-table th.el-table__cell {
  background: #2a2a2a;
}
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background: #393939 !important;
}
::v-deep .el-table th.is-leaf {
  background: #171717;
  color: #fff !important;
  border-bottom: 1px solid #171717 !important;
}
::v-deep .el-table td,
::v-deep .el-table__empty-block {
  background: #171717;
  height: 99% !important;
  color: #fff !important;
  border-bottom: 1px solid #212121 !important;
}

::v-deep tr .cell {
  padding-left: 20px !important;
}
::v-deep .pagination .btn-prev .el-icon,
::v-deep .pagination .btn-next .el-icon {
  color: #fff !important;
}
::v-deep .pagination .el-pagination {
  white-space: normal;
  word-break: break-all;
  padding: 10px 20px 0 20px !important;
}
::v-deep .pagination .el-pagination button {
  color: #fff !important;
  background: #292929 !important;
}

::v-deep .pagination .el-pager li {
  color: #fff;
  background-color: #292929 !important;
}

::v-deep .pagination .el-pager li.active {
  background-color: #fa6400 !important;
}
::v-deep .pagination2Box {
  margin: 20px 0;
}
.selectActive {
  background: #393939;
  border-radius: 1px;
}
.spanc {
  border-bottom: solid 1px #fa6400;
}
.dangerbtn {
  background: #ff3535 !important;
  border-color: #ff3535 !important;
}
.green {
  color: #fa6400 !important;
}
.el-dropdown-link {
  cursor: pointer;
}
.dialog-tip {
  height: 50px !important;
  .qian-time-select {
    width: 315px;
    ::v-deep .el-input > .el-input__inner {
      height: 36px;
      line-height: 36px;
    }
  }
  .help {
    height: 14px;
    width: 14px;
    margin-left: 5px;
    margin-right: 20px;
  }
}
</style>
