<!--
 * @Author: cyy
 * @Date: 2021-12-20 17:30:32
 * @LastEditTime: 2021-12-30 17:10:11
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \ teacher.dingdingkaike\src\views\OpenClass_openClassLive\components\centercomponents\smallotherfnContain.vue
-->
<template>
  <div id="otherfnContain">
    <el-popover
      popper-class="smallotherfnContain_popperclass"
      :visible-arrow="false"
      placement="bottom"
      :trigger="trigger"
      v-model="popoverShow"
      @show="show"
      ref="popover"
      @after-enter="afterEnter"
      width="86"
    >
      <div class="otherfnContainPopcontain">
        <template>
          <div v-for="item in options" :key="item.id">
            <div class="item" v-if="item.isShow" @click="selected(item.id)">
              {{ item.name }}
            </div>
          </div>
        </template>
      </div>
      <div slot="reference">
        <slot></slot>
      </div>
    </el-popover>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('OpenClassopenClassLive')
import auth from '../../uilts/auth'
export default {
  name: 'smallotherfnContain',

  mixins: [auth],

  data() {
    return {
      popoverShow: false,
      currMember: [],
      // options: [],
    }
  },
  props: {
    /**
     * @desc: 是否是学员列表过来的
     * @params {*} isMemberlist true false
     * @return: {*}true:是学员列表
     */
    isMemberlist: {
      type: Boolean,
      default: false,
    },
    /**
     * @desc: 是否老师
     * @params {*} isrole true false
     * @return: {*}true: 老师
     */
    isrole: {
      type: Boolean,
      default: false,
    },
    /**
     * @desc: 当前选择的聊天内容的详细信息
     * @params {*} currMemberlist
     * @return: {*} 对象
     */
    currMemberlist: {
      type: Object,
      default: () => {},
    },
    /**
     * @desc: 触发列表的类型
     * @params {*} trigger
     * @return: {*} click/focus/hover/manual
     */
    trigger: {
      type: String,
      default: 'click',
    },

    /**
     * @desc: 是否机器人
     * @params {*} isRobot true false
     * @return: {*}true:是机器人，助教，老师
     */
    isRobot: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(['openClassId', 'liveInfo']),
    options() {
      let val =
        this.isrole || this.isRobot
          ? [
              {
                id: 5,
                name: '引用',
                isShow: true,
              },
              {
                id: 6,
                name: '删除',
                isShow: true,
              },
            ]
          : this.isMemberlist
          ? [
              {
                id: 1,
                name: '私信',
                isShow: true,
              },
              {
                id: 2,
                name: '禁言',
                isShow: this.currMember.is_mute == 2,
              },
              {
                id: 7,
                name: '取消禁言',
                isShow: this.currMember.is_mute == 1,
              },
              {
                id: 3,
                name: '踢出',
                isShow: this.currMember.is_kick_out == 2,
              },
              {
                id: 8,
                name: '取消踢出',
                isShow: this.currMember.is_kick_out == 1,
              },
              {
                id: 4,
                name: '拉黑',
                isShow: this.currMember.ustatus == 1,
              },
              {
                id: 9,
                name: '取消拉黑',
                isShow: this.currMember.ustatus == 2,
              },
            ]
          : [
              {
                id: 1,
                name: '私信',
                isShow: true,
              },
              {
                id: 2,
                name: '禁言',
                isShow: this.currMember.is_mute == 2,
              },
              {
                id: 7,
                name: '取消禁言',
                isShow: this.currMember.is_mute == 1,
              },
              {
                id: 3,
                name: '踢出',
                isShow: this.currMember.is_kick_out == 2,
              },
              {
                id: 8,
                name: '取消踢出',
                isShow: this.currMember.is_kick_out == 1,
              },
              {
                id: 4,
                name: '拉黑',
                isShow: this.currMember.ustatus == 1,
              },
              {
                id: 9,
                name: '取消拉黑',
                isShow: this.currMember.ustatus == 2,
              },
              {
                id: 5,
                name: '引用',
                isShow: true,
              },
              {
                id: 6,
                name: '删除',
                isShow: true,
              },
            ]
      return val
    },
  },

  methods: {
    afterEnter() {
      this.$refs.popover.updatePopper()
    },

    show() {
      if (this.currMemberlist) {
        this.popoverShow = true
        // 获取当前选中学员的详情
        this.$http({
          url: '/console/userInfo',
          data: {
            uid: this.currMemberlist.uid,
            open_class_id: this.openClassId,
          },
          callback: ({ data }) => {
            this.currMember = data
          },
        })
      } else {
        console.error('无选中用户/console/userInfo', this.currMemberlist)
      }
    },

    selected(val) {
      switch (Number(val)) {
        case 1:
          this.$emit('otherfnPrivateletter')
          break
        case 2:
          this.auth(1, 1)
          break
        case 3:
          this.auth(2, 1)
          break
        case 4:
          this.auth(3, 2)
          break
        case 5:
          this.$emit('otherfnQuote')
          break
        case 6:
          this.$emit('otherfnContainsDel')
          break
        case 7:
          this.auth(1, 2)
          break
        case 8:
          this.auth(2, 2)
          break
        case 9:
          this.auth(3, 1)
          break
      }
      this.popoverShow = false
    },
  },
}
</script>
<style lang="scss">
.smallotherfnContain_popperclass {
  margin-top: 0 !important;
  background-color: #252525 !important;
  border-color: #252525 !important;
  color: #fff !important;
  min-width: 0 !important;
  padding: 12px 0 !important;
  // .el-popover__reference {
  //   text-align: right;
  // }
  .otherfnContainPopcontain {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: space-between;
    height: 100%;

    .item {
      padding: 10px 16px;
      cursor: pointer;
      font-size: 12px;
      &:hover {
        color: #0aa29b;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
#otherfnContain {
}
</style>
