<!--
 * @Author: cyy
 * @Date: 2022-02-22 11:19:02
 * @LastEditTime: 2022-11-04 18:21:36
 * @LastEditors: pengmingjun 1317525216@qq.com
 * @Description: 
 * @FilePath: \teacherdingding.com\src\views\OpenClass_openClassLive\components\liveDataComponents\changKong\quicktResponse.vue
-->
<template>
  <div id="quicktResponse">
    <div class="item mb20 flex-center">
      <el-popover
        popper-class="quicktResponse_popper"
        placement="bottom"
        width="122"
        trigger="click"
      >
        <div class="popover_item">
          <div class="item" @click="libraryStatus = true">
            从机器人库选择
          </div>
          <div class="item" @click="groupsStatus = true">
            从机器人分组选择
          </div>
        </div>
        <el-button
          type="primary"
          :disabled="robotList.length >= limit"
          slot="reference"
          size="medium"
        >
          选择发送人来源
          <i class="el-icon-arrow-down"></i>
        </el-button>
      </el-popover>
      <div class="robot-origin">
        <div class="flex-center">
          <span class="tips ml20 mr30">
            已选来源：{{ robotList.length }}/{{ limit }}
          </span>
          <el-tag
            :key="tag.gid"
            v-for="(tag, index) in robotList"
            closable
            :disable-transitions="false"
            @close="handleClose(index)"
          >
            {{ tag.name || tag.gname }}
          </el-tag>
        </div>
        <div class="robot-local-text">
          最近选择的发送人来源将保留一天有效期！
        </div>
      </div>
    </div>
    <el-input
      class="textarea"
      type="textarea"
      ref="textarea"
      placeholder="请输入内容，将以机器人模拟学员身份发送"
      v-model="post.content"
      maxlength="200"
      show-word-limit
      :rows="7"
    ></el-input>
    <div class="timeselect">
      <!-- @change="handleChange" -->
      <span class="sp1">发送规则：</span>
      <el-input-number
        v-model="post.mtime"
        controls-position="right"
        size="medium"
        style="width: 150px"
      ></el-input-number>
      <span class="sp1 ml10 mr10">秒内发送</span>
      <el-input-number
        v-model="post.num"
        controls-position="right"
        style="width: 150px"
        size="medium"
      ></el-input-number>
      <span class="sp1 ml10">条</span>
    </div>
    <div class="flex-center">
      <el-button
        v-if="!sendsuccess"
        type="primary"
        @click="send"
        size="medium"
        style="width: 76px; background: #fa6400; border-color: #fa6400"
      >
        发送
      </el-button>
      <el-button
        v-else
        type="primary"
        @click="stop"
        size="medium"
        style="width: 76px; background: #ff3535; border-color: #ff3535"
      >
        停止
      </el-button>
      <div class="sp2">
        提示：输入的内容，选择身份库后，将内容随机按照身份库的机器人发送。适用于老师提问无人回应和冷场时的场景；1秒发送条数不能超过5条，发送时长最长支持到60秒。
      </div>
    </div>
    <div v-if="sendsuccess" class="timer">停止倒计时：{{ sendsuccess }}</div>
    <!-- 从机器人库选择 -->
    <library
      v-if="libraryStatus"
      :limit="limit"
      :allowance="allowance"
      :gids="gids"
      @robotselectComplete="robotselectComplete"
      :dialogstatus.sync="libraryStatus"
    ></library>

    <!-- 从机器人分组选择 -->
    <groups
      v-if="groupsStatus"
      :dialogstatus.sync="groupsStatus"
      :limit="limit"
      :allowance="allowance"
      :gids="gids"
      @robotselectComplete="robotselectComplete"
    ></groups>
  </div>
</template>

<script>
import library from '@view/LiveBroadcastManagement/messageTemplate/details/robotSelect/library'
import groups from '@view/LiveBroadcastManagement/messageTemplate/details/robotSelect/groups'
import robotListLocalStorage from './robotListLocalStorage'

export default {
  name: 'quicktResponse',

  mixins: [robotListLocalStorage],

  components: {
    library,
    groups,
  },

  computed: {
    allowance() {
      return this.robotList.length
    },

    gids() {
      return this.robotList.map(item => item.gid)
    },
  },

  data() {
    return {
      localStorageName: 'quicktResponse',
      // 机器库和分组选择限制的个数
      limit: 3,
      // 选择的机器人库或者机器人分组列表
      robotList: [{ gid: '-1', name: '系统默认', status: '1', type: '1' }],
      groupsStatus: false,
      libraryStatus: false,
      sendsuccess: 0,
      post: {
        open_class_id: this.openClassId,
        content: '',
        mtime: 1,
        num: 1,
        groupList: this.gids,
      },
    }
  },

  props: {
    openClassId: [Number, String],
  },

  methods: {
    robotselectComplete(val) {
      this.robotList.push(...val)
    },

    // 删除已选标签
    handleClose(index) {
      this.robotList.splice(index, 1)
    },

    async stop() {
      try {
        await this.$http({
          url: '/console/stopQuick',
          data: { open_class_id: this.openClassId },
        })
        this.clearval()
        this.$root.prompt({
          type: 'success',
          msg: '操作成功！',
        })
      } catch (e) {
        console.log(e)
      }
    },

    // 重置数据
    clearval() {
      if (this.intervalTimer) {
        clearInterval(this.intervalTimer)
      }
      // this.reset()
      this.sendsuccess = 0
    },

    async send() {
      if (!this.post.content.trim()) {
        this.$root.prompt('请输入内容')
        this.post.content = ''
        this.$refs.textarea.focus()
        return
      }

      if (this.post.mtime < 1) {
        this.$root.prompt(
          '发送规则错误，请重新输入（1秒发送上限为5条，发送时长上限为60秒）'
        )
        return
      }

      if (this.post.num < 1) {
        this.$root.prompt(
          '发送规则错误，请重新输入（1秒发送上限为5条，发送时长上限为60秒）'
        )
        return
      }

      if (!this.post.mtime) {
        this.$root.prompt('请输入发送规则')
        return
      }

      if (!this.post.num) {
        this.$root.prompt('请输入发送规则')
        return
      }

      if (this.post.mtime > 60) {
        this.$root.prompt(
          '发送规则错误，请重新输入（1秒发送上限为5条，发送时长上限为60秒）'
        )
        return
      }

      if (this.post.mtime * 5 < this.post.num) {
        this.$root.prompt(
          '发送规则错误，请重新输入（1秒发送上限为5条，发送时长上限为60秒）'
        )
        return
      }

      const post = _.assign({}, this.post, { groupList: this.gids })

      try {
        this.sendsuccess = this.post.mtime
        await this.$http({
          url: '/console/quick',
          data: post,
        })
        this.$root.prompt({
          type: 'success',
          msg: '操作成功！',
        })

        clearInterval(this.intervalTimer)
        this.intervalTimer = setInterval(() => {
          if (this.sendsuccess > 0) {
            this.sendsuccess--
          } else {
            this.clearval()
          }
        }, 1000)
      } catch (e) {
        this.sendsuccess = 0
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.tips {
  font-size: 14px;
  color: #fff;
}
::v-deep textarea::-webkit-input-placeholder {
  font-size: 12px;
  color: #5f5f5f;
}
#quicktResponse {
  padding: 20px;
  ::v-deep .el-tag {
    background-color: #323232;
    border-color: #323232;
    height: 25px;
    line-height: 25px;
    font-size: 12px;
    color: #fff;
    box-sizing: border-box;
    .el-tag__close {
      color: #fff;
      &:hover {
        background-color: #323232;
      }
    }
  }
  .el-tag--light {
    margin-right: 10px;
  }
  .timer {
    font-size: 12px;
    color: #ffffff;
    margin-top: 14px;
  }
  ::v-deep .textarea {
    .el-textarea__inner {
      background-color: #2c2c2c;
      border-color: #2c2c2c;
      color: #fff;
    }
    .el-input__count {
      background-color: #2c2c2c;
      color: #9f9f9f;
    }
  }
  ::v-deep .timeselect {
    margin: 20px 0 30px;
    display: flex;
    align-items: center;
    .el-input-number.is-controls-right .el-input-number__increase,
    .el-input-number.is-controls-right .el-input-number__decrease {
      border: 0 !important;
      background-color: #323232;
      color: #fff;
    }

    .el-input__inner {
      background-color: #323232;
      border: #323232;
      color: #fff;
    }
    .sp1 {
      font-size: 13px;
      color: #ffffff;
    }
  }
  .sp2 {
    font-size: 12px;
    color: #fa6400;
    line-height: 1.5;
    margin-left: 20px;
  }
}
</style>
<style lang="scss">
.quicktResponse_popper {
  width: 122px !important;
  min-width: auto;
  .popover_item {
    .item {
      text-align: center;
      font-size: 14px;
      color: #333333;
      line-height: 40px;
      cursor: pointer;
      &:hover {
        color: #0aa29b;
      }
    }
  }
}
.robot-local-text {
  font-size: 12px;
  color: #ff3535;
  margin: 5px 0 0 20px;
}
</style>
