<template>
  <div class="liveData">
    <div class="block1" :class="{ h0: !rightOpen }">
      <!--视频预览-->
      <div class="vedio_contain" v-if="rightOpen">
        <!--视频预览-->
        <videocontain></videocontain>
      </div>
      <div class="custom-message-contain">
        <ul
          ref="customMessage"
          class="custom-message-ul"
          @scroll="messageScroll"
        >
          <li class="item" v-for="item in customMessage" :key="item.id">
            <div class="time">{{ item.time | formatTimeStamp('hh:mm') }}</div>
            <div class="text">
              {{ item.text }}
            </div>
          </li>
        </ul>
        <!--底部有新消息-->
        <div
          class="bottomNewMsg"
          @click="toNewMsg"
          v-if="autoScrollBotom === 0"
        >
          底部有新消息 {{ newMsgCount }}
        </div>
      </div>
      <div class="cp" v-if="!isWindowsApp" @click="toCloseOpen()">
        <img
          title="收起"
          class="isclose"
          v-if="rightOpen"
          src="~@ass/img/1.4.5.9/icon_kzt_sq.svg"
          alt=""
        />
        <img
          title="展开"
          class="isopen"
          v-else
          src="~@ass/img/1.4.5.9/icon_kzt_zk.svg"
          alt=""
        />
      </div>
    </div>
    <div class="block2">
      <div class="menu">
        <div
          class="item flex-center"
          :class="{ active: active == 0 }"
          @click="activeChange(0)"
          title="数据统计"
        >
          <img
            class="icon mr10"
            src="~@ass/img/1.4.5.9/icon_kzt_sjtj.svg"
            alt=""
          />
          <img
            class="icon mr10 active-icon"
            src="~@ass/img/1.4.5.9/icon_kzt_sjtj_h.svg"
            alt=""
          />
          <div class="text">数据统计</div>
        </div>
        <div
          class="item"
          :class="{ active: active == 1 }"
          @click="activeChange(1)"
          title="直播场控"
        >
          <img
            class="icon mr10"
            src="~@ass/img/1.4.5.9/icon_kzt_zbck.svg"
            alt=""
          />
          <img
            class="icon mr10 active-icon"
            src="~@ass/img/1.4.5.9/icon_kzt_zbck_h.svg"
            alt=""
          />
          <div class="text">直播场控</div>
        </div>
        <div
          class="item"
          :class="{ active: active == 2 }"
          @click="activeChange(2)"
          title="带货推课"
        >
          <img
            class="icon mr10"
            src="~@ass/img/1.4.5.9/icon_kzt_dhtk.svg"
            alt=""
          />
          <img
            class="icon mr10 active-icon"
            src="~@ass/img/1.4.5.9/icon_kzt_dhtk_h.svg"
            alt=""
          />
          <div class="text">带货推课</div>
        </div>
        <div
          class="item"
          :class="{ active: active == 3 }"
          @click="activeChange(3)"
          title="营销互动"
        >
          <img
            class="icon mr10"
            src="~@ass/img/1.4.5.9/icon_kzt_yxhd.svg"
            alt=""
          />
          <img
            class="icon mr10 active-icon"
            src="~@ass/img/1.4.5.9/icon_kzt_yxhd_h.svg"
            alt=""
          />
          <div class="text">营销互动</div>
        </div>
        <div
          class="item"
          :class="{ active: active == 4 }"
          @click="activeChange(4)"
          title="直播设置"
        >
          <img
            class="icon mr10"
            src="~@ass/img/1.4.5.9/icon_kzt_zbsz.svg"
            alt=""
          />
          <img
            class="icon mr10 active-icon"
            src="~@ass/img/1.4.5.9/icon_kzt_zbsz_h.svg"
            alt=""
          />
          <div class="text">直播设置</div>
        </div>
      </div>
      <!--menu 一个个组件的内容区域-->
      <div class="menu-contain">
        <openclass-datastatistics v-if="active == 0" />
        <changKong v-if="active == 1"/>
        <daiHuo v-if="active == 2" />
        <yingXiao v-if="active == 3" />
        <liveSetting v-if="active == 4" />
      </div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions, mapMutations } = createNamespacedHelpers(
  'OpenClassopenClassLive'
)
import videocontain from './video'
import liveSetting from './liveSetting/main'
import changKong from './changKong/main'
import daiHuo from './daiHuo/main'
import yingXiao from './yingXiao/main'
import openclassDatastatistics from './openclassDatastatistics/main'

export default {
  name: 'liveData',

  components: {
    videocontain,
    changKong,
    daiHuo,
    yingXiao,
    liveSetting,
    openclassDatastatistics,
  },

  computed: {
    ...mapState([
      'customMessage',
      'openClassId',
      'openclassDatastatisticsActiveval',
    ]),

    isWindowsApp() {
      return this.$store.state.isWindowsApp
    },
  },

  watch: {
    // 其他模块控制跳转数据统计模块
    openclassDatastatisticsActiveval(val) {
      if (Array.isArray(val)) {
        this.activeChange(val[0])
      }
    },

    customMessage() {
      this.scrollBottom()
      // 如果处于 新消息状态 且 新消息数量大于100
      // 或者消息总数大于30
      if (
        (this.autoScrollBotom === 0 && this.newMsgCount > 100) ||
        (this.autoScrollBotom !== 0 && this.newMsgCount > 30)
      ) {
        this.toNewMsg()
        this.setMaxCustomMessageCout()
      }
    },
  },

  data() {
    return {
      rightOpen: true,
      active: 1,
      newMsgCount: 0,
      autoScrollBotom: true,
    }
  },

  methods: {
    ...mapActions(['consoleInfo']),
    ...mapMutations(['setMaxCustomMessageCout']),

    toCloseOpen() {
      this.rightOpen = !this.rightOpen
    },

    activeChange(val) {
      this.active = val
      this.consoleInfo(this.openClassId)
    },

    // 新消息
    toNewMsg() {
      this.autoScrollBotom = true
      this.newMsgCount = 0

      this.$nextTick(() => {
        this.scrollBottom()
      })
    },

    // 滚动到底部
    scrollBottom() {
      if (this.autoScrollBotom) {
        clearTimeout(this.scrollBottomTimer)
        this.scrollBottomTimer = setTimeout(() => {
          if (this.autoScrollBotom) {
            this.newMsgCount = 0
            let chatcontentmain = this.$refs.customMessage
            chatcontentmain.scrollTop = 999999999
          }
        }, 100)
      } else {
        this.newMsgCount += 1
        this.autoScrollBotom = 0
      }
    },

    // 判断滚动距离 是否要禁止自动滚动到底部的逻辑
    messageScroll() {
      if (this.$refs.customMessage) {
        const val = !(
          this.$refs.customMessage.clientHeight +
            this.$refs.customMessage.scrollTop <
          this.$refs.customMessage.scrollHeight - 30
        )
        if (val != this.autoScrollBotom) {
          this.autoScrollBotom = val
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.liveData {
  height: 100%;
  display: flex;
  flex-flow: column;
  .h0 {
    height: 0px !important;
    min-height: auto !important;
    flex: 0 !important;
  }
  .block1 {
    height: 190px;
    display: flex;
    background: #171717;
    position: relative;
    border-radius: 4px;
    .vedio_contain {
      margin: 8px;
      width: 303px;
      height: 170px;
      flex-shrink: 0;
      background: #000000;
    }

    .custom-message-contain {
      flex: 1;
      display: flex;
      flex-direction: column;
      position: relative;
      .custom-message-ul {
        flex: 1;
        overflow: auto;
        padding: 13px 30px 0 20px;
        @extend %liveRoomScroll;
        .item {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          .time {
            font-size: 13px;
            color: #5e5e5e;
          }
          .text {
            font-size: 13px;
            color: #d7d7d7;
            margin-left: 13px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .bottomNewMsg {
        background: #fa6400;
        position: absolute;
        bottom: 20px;
        right: 20px;
        color: #fff;
        cursor: pointer;
        font-size: 0.6rem;
        border-radius: 3px;
        padding: 0 0.35rem;
        line-height: 1.3rem;
      }
    }
    .isclose {
      z-index: 5;
      position: absolute;
      bottom: -14px;
      transform: translateX(-50%) rotate(-90deg);
      left: 50%;
    }

    .isopen {
      z-index: 1;
      position: absolute;
      bottom: -20px;
      transform: translateX(-50%) rotate(-90deg);
      left: 50%;
    }
  }

  .block2 {
    flex: 1;
    display: flex;
    overflow: hidden;
    margin-top: 10px;
    background: #171717;
    border-radius: 4px;
    flex-direction: column;
    .menu {
      height: 40px;
      display: flex;
      flex-shrink: 0;
      background: #222222;
      .item {
        flex: 1;
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        // flex-direction: column;

        justify-content: center;
        &.active {
          background: #171717;
          .icon {
            display: none;
          }
          .active-icon {
            display: block;
          }
          .text {
            color: #fa6400;
          }
        }
        .icon {
          height: 18px;
          width: 18px;
          display: block;
          // margin: 0 auto;
        }
        .active-icon {
          display: none;
        }
        .text {
          margin-top: 0.3rem;
          font-size: 13px;
          color: #999999;
          text-align: center;
        }
      }
    }
    .menu-contain {
      flex: 1;
      // overflow-y: auto;
      // overflow-x: hidden;
      // @extend %liveRoomScroll;
    }
  }
}
</style>
