<!--
 * @Author: cyy
 * @version: 
 * @Date: 2023-01-30 15:00:00
 * @LastEditTime: 2023-02-02 15:21:27
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacherdingding.com\src\views\OpenClass_openClassLive\components\liveDataComponents\yingXiao\addGIft.vue
-->
<template>
  <div class="addgift">
    <el-dialog
      title="添加奖品"
      class="dialogVisible"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      width="840px"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="dialogVisible_bottom">
        <el-tabs v-model="activeName">
          <el-tab-pane label="优惠券" name="1">
            <div slot="label">
              优惠券
              <helpIcon
                style="margin-left: 6px"
                content="仅支持进行中的通用类型优惠券"
              ></helpIcon>
            </div>
            <coupon
              :goodList="tempplateContentList"
              @listSave="listSave2"
              ref="dialogRef"
            ></coupon>
          </el-tab-pane>
          <el-tab-pane label="图文" name="2">
            <data-contain
              :ids="ids"
              @listSave="listSave"
              ref="dialogRef"
            ></data-contain>
          </el-tab-pane>
        </el-tabs>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="medium">
          取 消
        </el-button>
        <el-button type="primary" @click="submit" size="medium">
          保 存(
          <span
            :style="
              totalLength + tempplateContentList.length > 5 ? 'color:red' : ''
            "
          >
            {{ totalLength + tempplateContentList.length }}
          </span>
          / 5 )
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import dataContain from '@cm/base/networktypeSelect/graphic/dataContain'
import coupon from '@cm/base/networktypeSelect/coupon'

export default {
  components: {
    dataContain,
    coupon,
  },

  data() {
    return {
      couponList: [],
      graphicList: [],
      activeName: '1',
    }
  },
  computed: {
    totalLength() {
      return this.couponList.length + this.graphicList.length
    },
    ids() {
      return this.tempplateContentList.map(item => item.graphic_id)
    },
  },
  props: {
    dialogstatus: {
      type: Boolean,
      default: false,
    },
    tempplateContentList: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    submit() {
      if (this.totalLength + this.tempplateContentList.length > 5) {
        this.$root.prompt('最多添加5个奖品')
      } else {
        const list = this.couponList.concat(this.graphicList)
        // console.log(this.couponList, this.graphicList, list)
        this.$emit('complete', list)
      }
    },

    listSave2(val) {
      this.couponList = val
    },

    listSave(val) {
      this.graphicList = val
    },

    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>
<style lang="scss" scoped>
.addgift {
  ::v-deep .pagination2Box1 {
    margin-top: 20px;
    overflow: auto;
    height: 310px;
    @extend %scroll;
  }

  ::v-deep #dataSelect {
    height: 376px;
    .pagination2Box {
      margin-top: 20px;
      overflow: auto;
      height: 270px !important;
      @extend %scroll;
    }
  }

  ::v-deep .el-dialog__body {
    padding: 10px 20px !important;
  }
}
</style>
