<template>
  <div id="shareinfo">
    <el-dialog
      class="dialogVisible"
      width="800px"
      :visible="dialogstatus"
      @update:visible="(v) => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div slot="title" class="title">
        <el-tabs class="tabs" v-model="activeName">
          <el-tab-pane label="H5海报" name="1">
            <post
              :shareinfoval="shareinfoval"
              v-if="activeName == 1"
              :activeName="activeName"
            ></post>
          </el-tab-pane>
          <el-tab-pane label="小程序海报" name="2">
            <post
              :shareinfoval="shareinfoval"
              v-if="activeName == 2"
              :activeName="activeName"
            ></post>
          </el-tab-pane>
          <el-tab-pane label="二维码&链接分享" name="3">
            <share-ewm
              :shareinfoval="shareinfoval"
              v-if="activeName == 3"
            ></share-ewm>
          </el-tab-pane>
        </el-tabs>
        <div
          class="flex-center"
          v-if="fatherPath == '/publicClass'"
          style="justify-content: center"
        >
          <div class="redcolor">分享后可查看邀请记录</div>
          <el-button type="text" class="ml20" @click="todetail">
            前往查看
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import post from './post'
import shareEwm from './shareEwm'
export default {
  name: 'openingfunction',
  data() {
    return {
      activeName: '1',
    }
  },

  components: {
    shareEwm,
    post,
  },

  props: {
    fatherPath: {
      type: String,
      default: '',
    },
    shareinfoval: {
      type: Object,
      default: () => {},
    },
    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    todetail() {
      let url = this.$router.resolve({
        path: `/publicClass/detail/${this.shareinfoval.open_class_id}/4`,
      })
      this.$store.dispatch('open', url.href)
    },

    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>
<style lang="scss" scoped>
#shareinfo {
  ::v-deep .el-dialog__body {
    padding: 8px !important;
  }
  ::v-deep .el-dialog {
    background-color: #f5f5f5;
  }
  ::v-deep .tabs {
    .el-tabs__item {
      line-height: 17px;
      height: 32px;
    }
    .el-tabs__nav-wrap::after {
      height: 1px;
    }
    .el-tabs__nav-scroll {
      height: 32px;
    }
    .el-tabs__nav is-top {
      height: 32px;
    }
  }

  .title {
    font-size: 14px;
    color: #333333;
    line-height: 14px;
  }
  img {
    height: 100%;
    width: 100%;
  }
  .dialogVisible_bottom {
  }
}
</style>
