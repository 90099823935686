<template>
  <el-dialog
    :title="title ? title : `已选学员：${selectedData.uname}`"
    width="422px"
    top="25vh"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    append-to-body
  >
    <div> 
      <el-select v-model="selectGroup">
        <el-option label="无" :value="0">
          <span style="float: left">无</span>
        </el-option>
        <el-option
          v-for="item in groupList"
          :key="item.value"
          :label="`${item.label}（${item.count}人）`"
          :value="item.value"
        >
          <span style="float: left">
            {{ item.label }}
          </span>
          <span style="float: right; color: #999999; font-size: 12px">
            {{ item.count || 0 }}人
          </span>
        </el-option>
      </el-select>
    </div>
    <span slot="footer" class="dialog-footer">
      <div class="prompt">* 1位学员只可在1个分组</div>
      <el-button @click="handleClose">取消</el-button>
      <el-button type="primary" @click="complete">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: 'category',

  created() {
    this.uid = this.selectedData.uid
  },

  props: {
    selectedData: {
      type: [Object, Boolean],
      default() {
        return {}
      },
    },
    title: {
      type: String,
      default: '',
    },
    groupList: [Object, Array],
  },

  data() {
    return {
      selectGroup: this.selectedData.gid ? Number(this.selectedData.gid) : 0,
      dialogVisible: false,
    }
  },

  mounted() {
    this.dialogVisible = true
  },

  methods: {
    handleClose(cbk) {
      this.dialogVisible = false
      setTimeout(() => {
        if (typeof cbk === 'function') {
          cbk()
        }
        this.$emit('close')
      }, 300)
    },

    // 选择分组
    complete() {
      this.$http({
        url: '/pcUser/doGroup',
        data: {
          gid: this.selectGroup,
          uids: this.uid instanceof Array ? this.uid : [this.uid],
        },
        callback: () => {
          this.handleClose(() => {
            this.$emit('reset')
          })
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 10px 20px 28px 20px;
  .el-dialog__header {
    padding: 16px 0 0;
    .el-dialog__title {
      font-size: 18px;
    }
  }
  .el-input,
  .el-select {
    width: 100%;
    height: 40px;
  }
}
::v-deep .el-button {
  width: 74px;
  height: 36px;
  line-height: 12px;
}
.dialog-footer {
  position: relative;
  .prompt {
    font-size: 12px;
    line-height: 16px;
    position: absolute;
    color: #ff3535;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
