const result = {}

const emoji = [
  '[惊讶]',
  '[撇嘴]',
  '[色]',
  '[鼻涕]',
  '[酷]',
  '[流泪]',
  '[害羞]',
  '[睡觉]',
  '[哭哭]',
  '[尴尬]',
  '[愤怒]',
  '[吐舌头]',
  '[龇牙]',
  '[微笑]',
  '[难过]',
  '[皱眉]',
  '[抓狂]',
  '[吐]',
  '[偷笑]',
  '[羞羞笑]',
  '[白眼]',
  '[哼]',
  '[吃吃]',
  '[困困]',
  '[惊恐]',
  '[流汗]',
  '[哈哈]',
  '[头盔]',
  '[奋斗]',
  '[大骂]',
  '[疑问]',
  '[嘘]',
  '[晕]',
  '[难受]',
  '[糗]',
  '[骷髅头]',
  '[锤脑袋]',
  '[拜拜]',
  '[滑]',
  '[亲亲]',
  '[跳舞]',
  '[猪]',
  '[拥抱]',
  '[蛋糕]',
  '[太阳]',
  '[炸弹]',
  '[咖啡]',
  '[心]',
  '[玫瑰]',
  '[礼物]',
  '[耶]',
  '[夸奖]',
  '[礼花]',
  '[棒棒糖]',
  '[麻将]',
  '[象棋]',
].reverse()

const len = emoji.length - 1

for (let i = len; i >= 0; i--) {
  const key = emoji[i]
  result[key] = {
    i,
    key,
  }
}

export default result
