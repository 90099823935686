<template>
  <el-tabs :value="`${value}`" class="tabs" @input="activeChange">
    <el-tab-pane
      v-for="item in data"
      :key="item.id"
      :label="item.name"
      :name="`${item.id}`"
    >
      <template slot="label">
        {{ item.name }}
        <img
          class="newPane"
          v-if="item.new"
          src="../../../../assets/img/ico_new.png"
        />
      </template>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
export default {
  name: 'myMenu',

  props: {
    data: Array,
    value: Number,
  },

  methods: {
    activeChange(val) {
      this.$emit('input', Number(val))
    },
  },
}
</script>
<style lang="scss" scoped>
.tabs {
  font-size: 15px;
  margin: 15px 20px 0;
  ::v-deep .el-tabs__header {
    margin-bottom: 0;
  }
  ::v-deep .el-tabs__item {
    color: #fff;
    height: 45px;
    line-height: 45px;
  }
  ::v-deep .el-tabs__nav-wrap::after {
    height: 1px;
    background-color: #222222;
  }
  ::v-deep .el-tabs__active-bar {
    background-color: #fa6400;
  }
  ::v-deep .el-tabs__item.is-active {
    color: #fa6400;
  }
  .newPane {
    position: absolute;
    top: 0;
  }
}
</style>