<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-10-18 15:18:11
 * @LastEditTime: 2023-01-03 15:54:13
 * @LastEditors: cyy
 * @Description: 添加机器人
 * @FilePath: \teacherdingding.com\src\views\liveRobot\components\addRobot.vue
-->
<template>
  <div class="addRobot">
    <el-dialog
      :title="title"
      class="dialogVisible"
      width="800px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div slot="title" class="flex-center">
        <span style="font-size:18px">
          {{ title }}
        </span>
        <div
          class="redcolor fs12"
          v-if="!robot_relation_id"
          style="margin-left:56px"
        >
          提示：添加后可在直播控制台添加使用
        </div>
      </div>
      <div class="dialogVisible_bottom">
        <div class="group" v-if="!robot_relation_id">
          <span class="tips">分组</span>
          <el-select
            clearable
            class="mr20"
            v-model="newpost.gid"
            style="width:321px "
            size="medium"
            placeholder="请选择"
          >
            <el-option
              v-for="item in groupList"
              :key="item.gid"
              :label="item.gname"
              :value="item.gid"
            ></el-option>
          </el-select>
        </div>
        <div class="allcontain">
          <div
            class="contain"
            v-for="(item, index) in newpost.list"
            :key="item.id"
          >
            <span class="tips">头像</span>
            <div class="flex-center">
              <div class="photo" @click="upload(index)">
                <div class="img" v-if="item.photo">
                  <img :src="item.photo" style="object-fit: cover" alt="" />
                  <div class="mask1"></div>
                  <div class="tipdialogphoto">替换</div>
                </div>
                <div class="add_icon" v-else>+</div>
              </div>
            </div>
            <span class="tips" style="margin-left:50px">昵称</span>
            <el-input
              type="text"
              placeholder="请输入内容"
              size="medium"
              v-model="item.name"
              maxlength="10"
              show-word-limit
            ></el-input>
          </div>
        </div>
        <changePhotoimg
          pictureTitle="选择图片"
          :size="2"
          :ref="`changePhotoimg`"
          @complete="completechangePhotoimg"
          class="changePhotoimg"
        ></changePhotoimg>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="medium">
          取 消
        </el-button>
        <el-button type="primary" @click="submit" size="medium">
          保 存
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import changePhotoimg from '@cm/base/changePhotoimg'
export default {
  components: {
    changePhotoimg,
  },

  data() {
    return {
      title: '',
      groupList: [],
      newpost: {
        gid: '',
        list: [],
      },
    }
  },

  props: {
    robot_relation_id: [String, Number],

    addRobotList: {
      type: Object,
      default: () => {},
    },
    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.newpost = _.assign({}, this.addRobotList, {
      gid:
        this.addRobotList.gid && this.addRobotList.gid == 0
          ? ''
          : this.addRobotList.gid,
    })
    this.getGroupList()
    this.$nextTick(() => {
      this.title = this.addRobotList.isedit ? '编辑机器人' : '添加机器人'
    })
  },

  methods: {
    async submit() {
      const hadEnpty = this.newpost.list.findIndex(item => !item.name) > -1
      const photoEmpty = this.newpost.list.findIndex(item => !item.photo) > -1
      if (photoEmpty) {
        this.$root.prompt('请先选择图片')
        return
      }
      if (hadEnpty) {
        this.$root.prompt('请先输入机器人昵称')
        return
      }

      if (this.addRobotList.isedit) {
        const data = _.assign({}, this.newpost.list[0], {
          robot_id: this.newpost.robot_id,
          status: 1,
          gid: this.newpost.gid,
          robot_relation_id: this.robot_relation_id,
        })
        await this.$http({
          url: '/liveRobot/editRobot',
          data: data,
          successMsg: true,
        })
      } else {
        await this.$http({
          url: '/liveRobot/addRobot',
          data: this.newpost,
          successMsg: true,
        })
      }

      this.$emit('addComplete')
    },

    // 获取分组数据
    async getGroupList() {
      const { data } = await this.$http({
        url: '/pcUser/getGroupList',
        data: { user_type: 2, type: 2 },
      })
      this.groupList = data
    },
    // 选择图片
    upload(index) {
      this.photoChangeIndex = index
      this.$refs[`changePhotoimg`].changePicture()
    },

    completechangePhotoimg(val) {
      this.newpost.list[this.photoChangeIndex].photo = val
    },
    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.allcontain {
  height: 400px;
  overflow-y: auto;
  @extend %scroll;
  padding-right: 20px;
}
::v-deep #changePhotoimg {
  height: 0 !important;
  width: 0 !important;
}
.addRobot {
  img {
    width: 100%;
    height: 100%;
  }
  ::v-deep .el-dialog__body {
    padding-top: 0 !important;
    padding-right: 0 !important;
  }
  .dialogVisible_bottom {
    .contain {
      display: flex;
      align-items: center;
      padding: 20px 0;
      border-bottom: 1px #f5f5f5 solid;

      .photo {
        position: relative;
        width: 40px;
        height: 40px;
        background: #eeeeee;
        border-radius: 4px;
        .img {
          position: relative;
          width: 100%;
          height: 100%;
          .mask1 {
            display: none;
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            background-color: #000;
            opacity: 0.7;
          }
          .tipdialogphoto {
            cursor: pointer;
            display: none;
            width: 100%;
            height: 100%;
            text-align: center;
            position: absolute;
            top: 40%;
            font-size: 12px;
            font-weight: bold;
            color: #ffffff;
            line-height: 12px;
          }
        }
        .changePhotoimg {
          height: 0 !important;
          width: 0 !important;
        }

        .add_icon {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          height: 100%;
          width: 100%;
          box-sizing: border-box;
          font-size: 27px;
          color: #666666;
          line-height: 40px;
          background: #eeeeee;
          border-radius: 4px;
        }
        &:hover .mask1 {
          display: block;
        }
        &:hover .tipdialogphoto {
          display: block;
        }
      }
    }
    .tips {
      flex-shrink: 0;
      font-size: 14px;
      color: #333333;
      margin-right: 30px;
    }
  }
}
</style>
