/*
 * @Author: cyy
 * @Date: 2021-12-20 18:50:39
 * @LastEditTime: 2022-11-03 16:08:30
 * @LastEditors: pengmingjun 1317525216@qq.com
 * @Description: 
 * @FilePath: \ teacher.dingdingkaike\src\views\OpenClass_openClassLive\uilts\auth.js
 */

export default {

    methods: {
        // 授权
        // type 类型 1禁言 2踢出 3拉黑
        // state 1禁言/踢出/取消拉黑  2取消禁言/取消踢出/拉黑
        auth(type, state) {
            let payload
            switch (type) {
                case 1:
                    payload = {
                        content: {
                            data: 'mute',
                            description: `${state}`,
                            extension: JSON.stringify(this.currMember),
                        },
                        confirm: state == 1,
                        title: '禁言',
                        text: '是否禁言该学员？',
                    }
                    break
                case 2:
                    payload = {
                        content: {
                            data: 'kickout',
                            description: `${state}`,
                            extension: JSON.stringify(this.currMember),
                        },
                        confirm: state == 1,
                        title: '踢出',
                        text: '是否踢出该学员？踢出后，当前直播间学员无法再次进入，修改直播时间自动失效。',
                    }
                    break
                case 3:
                    payload = {
                        content: {
                            data: 'blackList',
                            description: `${state}`,
                            extension: JSON.stringify(this.currMember),
                        },
                        confirm: state == 2,
                        title: '拉黑',
                        text: '是否拉黑该学员？拉黑后学员无法进入网校、课程和直播间！',
                    }
                    break
                case 4:
                    payload = {
                        content: {
                            data: 'setting',
                            description: `${state}`,
                            extension: JSON.stringify(this.currMember),
                        },
                        confirm: state == 1,
                        title: '屏蔽',
                        text: '是否屏蔽该学员？',
                    }
                    break
            }
            document.body.click()
            const cbk = () => {
                this.$http({
                    url: '/console/mute',
                    data: {
                        uid: this.currMember.uid,
                        open_class_id: this.openClassId,
                        status: state,
                        type: type,
                    },
                    callback: () => {
                        this.$root.$emit(
                            'sendCustomMsg',
                            _.assign({ roomId: this.liveInfo.chat_room_id2 }, payload.content)
                        )

                        this.$root.prompt({
                            msg: '操作成功',
                            type: 'success'
                        })

                        /**
                         * rightcomponents>main  centercomponents>smallotherfnContain
                         * 两处使用到。centercomponents>smallotherfnContain没有这两个函数
                         */
                        // 判断是否存在这个列表 rightcomponents>main
                        if (this.copyMemberlist) {
                            this.resetMemberInfo()
                            this.getMemberList()
                        } else {
                            // centercomponents>smallotherfnContain
                            this.$emit('smallotherfnContainComplete')
                        }

                    },
                })
            }

            if (payload.confirm) {
                this.$confirm(payload.text, payload.title, {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    closeOnClickModal: false,
                }).then(cbk)
            } else {
                cbk()
            }
        },

    }

}