<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-06-07 10:23:33
 * @LastEditTime: 2022-12-09 10:17:33
 * @LastEditors: cyy
 * @Description: 机器人刷单
 * @FilePath: \teacherdingding.com\src\views\OpenClass_openClassLive\components\liveDataComponents\changKong\clickFarming.vue
-->
<template>
  <div class="clickFarming">
    <div class="header">
      温馨提示：请先在【带货推课】模块上链接商品(仅支持知识商品与直播间支付类型的代金券)；刷单人建议不要重复出现，避免出现破绽！刷单后会在直播间显示刷单信息，刷单人+购买了《商品名称》，如：张三购买了《流行弹唱VIP班》
    </div>
    <pagination2
      :option="post"
      url="/Seckill/liveSeckillList"
      ref="childDialogBox"
      class="childDialogBox flex1"
      @tableDate="tableDate"
    >
      <template v-slot:default="{}">
        <div class="pagination2Box">
          <el-table
            :row-style="{ background: '#171717', color: '#fff' }"
            :header-cell-style="{
              background: '#212121',
              color: '#fff',
            }"
            :data="tablelist"
          >
            <el-table-column label="已上链接的商品" min-width="120px">
              <template slot-scope="{ row }">
                <div class="produce_contain">
                  <div class="tu">
                    <img :src="row.goods_photo" alt="" />
                  </div>
                  <div class="name_money">
                    <div class="name hidetext" :title="row.goods_name">
                      {{ row.goods_name }}
                    </div>
                    <div class="money">
                      <span class="sp1">￥{{ row.goods_price }}</span>
                      <!-- <span class="sp2" v-if="row.cost_price">
                        ￥{{ row.cost_price }}
                      </span> -->
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="类型">
              <template slot-scope="{ row }">
                {{ row | getGoodtype }}
              </template>
            </el-table-column>
            <el-table-column label="" min-width="150px">
              <div slot="header">
                <span class="redcolor">*</span>
                刷单人(回车添加，最多支持10个)
              </div>
              <template slot-scope="{ row, $index }">
                <div
                  class="tags"
                  @click="
                    row.dynamicTags.length < 10 ? showInput(row, $index) : ''
                  "
                >
                  <el-tag
                    :key="index2"
                    v-for="(tag, index2) in row.dynamicTags"
                    closable
                    :disable-transitions="true"
                    @close="handleClose(index2, $index)"
                  >
                    {{ tag }}
                  </el-tag>
                  <!-- maxlength   
                    v-if="inputVisible == $index"
                   -->
                  <template v-if="row.dynamicTags.length < 10">
                    <el-input
                      v-if="inputVisible == $index"
                      style="width:100px"
                      class="input-new-tag"
                      v-model="inputValue"
                      :ref="`saveTagInput${$index}`"
                      maxlength="12"
                      size="small"
                      placeholder="输入刷单人昵称"
                      @keyup.enter.native="handleInputConfirm(row, $index)"
                      @blur="handleInputConfirm(row, $index)"
                    ></el-input>
                    <el-button
                      v-else
                      @click.stop="showInput(row, $index)"
                      class="button-new-tag "
                      size="small"
                      type="text"
                      style="color:#A1A1A1;width:100px;text-align: left;"
                    >
                      输入刷单人昵称
                    </el-button>
                  </template>
                  <!-- {{ inputVisible }}{{ $index }} -->
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="{ row, $index }">
                <el-button
                  type="text"
                  @click="
                    robotBrushing(row.dynamicTags, row.live_seckill_id, $index)
                  "
                >
                  确定刷单
                </el-button>
                <el-divider direction="vertical" class="ml10 mr10"></el-divider>
                <el-button type="text" @click="showRecord(row.live_seckill_id)">
                  记录
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
    </pagination2>
    <!-- 刷单记录 -->
    <el-dialog
      title="记录"
      :visible.sync="dialogVisible"
      width="800px"
      class="dialog"
    >
      <div class="dialog_contain">
        <pagination2
          :option="{ live_seckill_id: dislogid }"
          url="/console/robotBrushingLog"
          ref="childDialogBox"
        >
          <template #other="{ newDate: { list } }">
            <!-- {{ (newDate, tableData) }} -->
            <div class="produce_contain mb20" v-if="list">
              <div class="tu">
                <img :src="list.goods_photo" alt="" />
              </div>
              <div class="name_money">
                <div class="name" style="color:#333333">
                  {{ list.goods_name }}
                </div>
                <div class="money">
                  <span class="sp1">￥{{ list.goods_price }}</span>
                  <span class="sp2" v-if="list.cost_price">
                    ￥{{ list.cost_price }}
                  </span>
                  <span class="sp3  ml20">{{ list | getGoodtype }}</span>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:default="{ tableData }">
            <div class="record_table">
              <el-table
                :header-cell-style="{
                  background: 'rgba(245,245,245,1)',
                  color: '#333333',
                }"
                :data="tableData"
              >
                <el-table-column
                  label="录入的刷单人"
                  prop="name"
                ></el-table-column>
                <el-table-column label="操作时间">
                  <template slot-scope="{ row }">
                    {{ row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
        </pagination2>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'clickFarming',
  data() {
    return {
      //是否能添加刷担任
      dislogid: '',
      dialogVisible: false,
      tablelist: [],
      post: {
        open_class_id: this.openClassId,
      },
      inputVisible: -1,
      // 输入框的数据
      inputValue: '',
    }
  },

  props: {
    openClassId: [Number, String],
  },

  filters: {
    getGoodtype(val) {
      let text = ''
      switch (Number(val.goods_type)) {
        case 1:
          text = '直播课'
          break
        case 2:
          text = '小班课'
          break
        case 3:
          text = '录播课'
          break
        case 4:
          text = '系列课'
          break
        case 6:
          text = '图文'
          break
        case 8:
          if (val.goods_class == 1) {
            text = '知识商品'
          } else {
            text = '外部商品'
          }
          break
      }
      return text
    },
  },

  methods: {
    // btnclick(val) {
    //   if (val.dynamicTags.length > 10) {
    //     this.$root.prompt('最多支持10个')
    //     return
    //   }
    // },
    //   刷单
    async robotBrushing(item, id, index) {
      if (item.length == 0) {
        this.$root.prompt('请先输入刷单人昵称！')
        return
      }
      await this.$http({
        url: '/console/robotBrushing',
        data: {
          name: item,
          live_seckill_id: id,
        },
        successMsg: '刷单成功！信息已发送到直播间展示',
      })

      this.tablelist[index].dynamicTags = []
      this.tablelist = _.merge([], this.tablelist)
    },

    // 刷单记录
    showRecord(id) {
      let p = new Promise(res => {
        this.dislogid = id
        res()
      })

      p.then(() => {
        this.dialogVisible = true
      })
    },

    // 刷单列表数据回调
    tableDate(val) {
      val.forEach(item => {
        item.dynamicTags = []
        return item
      })
      this.tablelist = val
    },

    // 标签关闭
    handleClose(tagindex, index) {
      this.tablelist[index].dynamicTags.splice(tagindex, 1)
      let arr = []
      this.tablelist.forEach(item => {
        arr.push(item)
      })
      this.tablelist = _.merge([], arr)
    },

    //添加标签的输入框选中
    showInput(row, index) {
      if (row.dynamicTags.length == 10) {
        this.$root.prompt('最多支持10个')
      } else {
        this.inputVisible = index
        this.$nextTick(() => {
          this.$refs[`saveTagInput${index}`].$refs.input.focus()
        })
      }
    },

    // 输入框失去焦点后
    handleInputConfirm(row, index) {
      let inputValue = this.inputValue
      if (inputValue) {
        this.tablelist[index].dynamicTags.push(inputValue)
      }
      //   this.inputVisible = -1
      this.inputValue = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.el-table::before {
  height: 0;
}
::v-deep .el-tag {
  margin-left: 10px;
  font-size: 12px;
  color: #ffffff;
  background: #4a4a4a;
  border-radius: 4px;
  border: 0;
  margin-bottom: 10px;
  .el-icon-close {
    color: #999999;
  }
  .el-tag__close:hover {
    background-color: #d7d7d7;
  }
  &:first-child {
    margin-left: 0;
  }
}
.button-new-tag {
  margin-left: 10px;
  height: 30px;
  line-height: 30px;
  padding-top: 0;
  margin-bottom: 10px;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: center;
}
.clickFarming {
  padding: 20px 20px 0;

  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  ::v-deep .contain_page {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
  .header {
    font-size: 14px;
    color: #ff3535;
    line-height: 19px;
    margin-bottom: 20px;
  }
  ::v-deep .childDialogBox {
    position: relative;
    .pagination2Box {
      position: absolute;
      top: 0;
      bottom: 60px;
      left: 0;
      right: 0;
    }
    .el-divider {
      background-color: #0aa29b;
    }

    .tags {
      border: 1px solid #333333;
      padding: 10px 10px 0;
    }

    .pagination {
      padding-top: 20px;
      .el-pager li.active + li,
      .el-pagination button {
        background-color: #343434 !important;
        color: #fff;
      }
      .el-pager li {
        background-color: #343434;
        color: #fff;
      }
    }
    .el-pagination span {
      color: #ffffff;
    }

    .el-pager li.active {
      color: #fff;
      background-color: #fa6400;
    }

    .el-input .el-input__inner {
      background-color: transparent;
      border-color: transparent;
      color: #fff;
      height: 30px;
      margin-bottom: 10px;
      line-height: 30px;
      padding: 0;
      &::placeholder {
        color: rgb(161, 161, 161);
        font-size: 12px;
      }
    }
    .el-table {
      overflow-y: auto;
      @extend %liveRoomScroll;
      height: 100%;
    }
    .el-table td {
      border-color: #313131;
    }
    .el-table__body-wrapper {
      background-color: #171717;
    }
    .el-table--fit {
      background-color: #171717;
    }

    .el-table th.is-leaf {
      border-color: #3e3e3e;
    }
    .el-table--enable-row-hover .el-table__body tr:hover > td {
      background-color: #202020 !important;
    }
    .el-table::before {
      background-color: #313131;
    }
    // .el-table th.is-leaf {
    //   border-color: #313131;
    // }
  }
  .produce_contain {
    display: flex;
    .tu {
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      background: #4e4e4e;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .name_money {
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .name {
        font-size: 14px;
        color: #ffffff;
      }
      .money {
        .sp1 {
          font-size: 14px;
          color: #ff3535;
          margin-right: 10px;
        }
        .sp2 {
          font-size: 12px;
          color: #a1a1a1;
          text-decoration: line-through;
        }
        .sp3 {
          font-size: 12px;
          color: #999999;
        }
      }
    }
  }
  ::v-deep .dialog {
    .el-dialog__header {
      padding-bottom: 0;
    }
    .record_table {
      overflow-y: auto;
      @extend %scroll;
      height: 400px;
      margin-bottom: 55px;
    }
  }
}
</style>
