<template>
  <div id="video">
    <div
      ref="video"
      id="player"
      style="width: 100%; height: 100%; display: block"
    ></div>
    <img
      v-if="countDown > 0 || error || liveInfo.status == 2"
      :src="openInfo.cover_img"
      alt=""
    />
    <div v-if="error" class="live-state">
      <div class="t1">
        直播已断开，请
        <span class="t2" @click="refsh">刷新</span>
        重试
      </div>
    </div>
    <div class="tip" v-else-if="liveInfo.status == 2">
      距离开播：{{ countDown | formatTimeLength(2) }}
    </div>
    <div
      v-else-if="!isFullscreen && load"
      @click="requestPictureInPictureFn"
      ref="requestPictureInPicture"
      class="requestPictureInPicture"
    >
      画中画
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('OpenClassopenClassLive')

export default {
  name: 'myvideo',
  data() {
    return {
      countDown: 0,

      load: false,

      error: false,
      setCurrentTime: false,

      // 是否全屏
      isFullscreen: false,

      // 是否画中画
      requestPictureInPicture: false,
    }
  },

  computed: {
    ...mapState(['liveInfo', 'openInfo']),
  },

  // created() {
  //   try {
  //     document.exitPictureInPicture()
  //   } catch (e) {
  //     console.info(e)
  //   }
  // },

  mounted() {
    this.countDown = this.liveInfo.down_time
    // 腾讯云播放器
    const script = document.createElement('script')
    script.src =
      'https://web.sdk.qcloud.com/player/tcplayerlite/release/v2.4.1/TcPlayer-2.4.1.js'
    script.onload = () => {
      if (this.liveInfo.status == 1) {
        // 播放器
        this.tcPlayerInit()
      }
      // // 倒计时
      this.countDownStart()
    }
    document.body.appendChild(script)
  },

  destroyed() {
    clearInterval(this.countDownTimer)
  },

  methods: {
    tcPlayerInit() {
      let video
      const isMSE = this.IS_MSE()
      const option = {
        live: true,
        flash: false,
        h5_flv: true,
        autoplay: false,
        flv: this.liveInfo.httpPullUrl.replace(/^http:/, 'https:'),
        systemFullscreen: true,
        pausePosterEnabled: false,
        poster: { style: 'stretch', src: this.openInfo.cover_img },
        // controls: 'system',
        preload: 'none',
        width: '100%',
        height: '100%',
        flvConfig: {
          isLive: true,
          lazyLoad: false,
          enableStashBuffer: true,
          stashInitialSize: 128,
        },
        listener: (event) => {
          console.log(event.type);
          switch (event.type) {
            case 'error':
            case 'ended':
              this.error = true
              break
            case 'playing':
              this.setCurrentTime = false
              break
            case 'timeupdate':
              if (isMSE) {
                this.updtaeCurrTime(video)
              }
              break
            case 'fullscreen':
              this.isFullscreen = event.detail.isFullscreen
              break
          }
        },
      }
      // this.src = this.liveInfo.mp4
      // option.mp4 = this.src
      // option.live = false
      // 快直播 和 标准直播
      if (this.openInfo.live_mode == 2 && option.live) {
        this.src = this.liveInfo.webrtcPullUrl
        option.webrtc = this.src
      } else {
        this.src = this.liveInfo.hlsPullUrl.replace(/^http:/, 'https:')
        option.m3u8 = this.src
      }

      this.player = new window.TcPlayer('player', option)

      const div = document.createElement('div')
      div.className = 'play-btn'
      div.onclick = this.play
      document.querySelector('.vcp-poster').appendChild(div)

      this.video = video = document.getElementsByTagName('video')[0]

      this.requestPictureInPictureEvent()

      this.player.panel.el.style.display = 'none'

      this.load = true
    },

    requestPictureInPictureEvent() {
      if (this.video) {
        this.video.addEventListener('enterpictureinpicture', () => {
          this.requestPictureInPicture = true
        })
        this.video.addEventListener('leavepictureinpicture', () => {
          this.requestPictureInPicture = false
        })
      }
    },

    requestPictureInPictureFn() {
      if (this.requestPictureInPicture) {
        document.exitPictureInPicture()
      } else if (this.video) {
        this.video.requestPictureInPicture()
      }
    },

    // 未到开播时间 倒计时
    countDownStart() {
      if (this.countDown > 0) {
        this.countDownTimer = setInterval(() => {
          if (this.countDown <= 0) {
            clearInterval(this.countDownTimer)
            return
          }
          this.countDown -= 1
        }, 1000)
      }
    },

    IS_MSE() {
      var e = (window.MediaSource =
          window.MediaSource || window.WebKitMediaSource),
        t = (window.SourceBuffer =
          window.SourceBuffer || window.WebKitSourceBuffer),
        i =
          e &&
          'function' == typeof e.isTypeSupported &&
          e.isTypeSupported('video/mp4; codecs="avc1.42E01E,mp4a.40.2"'),
        o =
          !t ||
          (t.prototype &&
            'function' == typeof t.prototype.appendBuffer &&
            'function' == typeof t.prototype.remove)
      return i && o
    },

    updtaeCurrTime(video) {
      if (!video.buffered.length || this.setCurrentTime) {
        return
      }
      var end = video.buffered.end(0)
      var delta = end - video.currentTime
      // console.log(
      //   '___-___-____',
      //   delta,
      //   video.buffered.end(0),
      //   video.currentTime
      // )
      if (delta >= 3.5) {
        this.setCurrentTime = true
        video.currentTime = end - 0.1
      }
    },

    // 播放
    play() {
      if (this.player) {
        this.player.panel.el.style.display = ''
        setTimeout(() => {
          this.player.play()
        }, 50)
      }
    },

    // 刷新
    refsh() {
      this.error = false
      this.player.destroy()
      this.tcPlayerInit()
      this.player.play()
    },
  },
}
</script>

<style lang="scss" scoped>
#video {
  background-color: #000000;
  height: 100%;
  overflow: hidden;
  position: relative;
  .pinp {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    color: #ffffff;
    width: 60px;
    height: 26px;
    background: #000000;
    border-radius: 14px;
    opacity: 0.7;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }
  .tip {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
    line-height: 14px;
    width: 100%;
    z-index: 5;
    background-color: #000000;
  }
}
.live-state {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  .t1 {
    line-height: 1.4rem;
  }
  .t1 {
    flex: 1;
    font-size: 0.6rem;
    font-weight: 500;
    color: #ffffff;
  }
  .t2 {
    cursor: pointer;
    font-size: 0.6rem;
    font-weight: 500;
    color: #e6ad10;
    text-decoration: underline;
  }
}
.requestPictureInPicture {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 5;
  width: 60px;
  height: 26px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 14px;
  font-size: 13px;
  color: #ffffff;
  line-height: 26px;
  text-align: center;
}
</style>
<style lang="scss">
.vcp-player .vcp-error-tips {
  display: none !important;
}
.vcp-player .play-btn {
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTA3IiBoZWlnaHQ9IjEwNyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzIDMpIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxyZWN0IHN0cm9rZT0iI0ZGRiIgc3Ryb2tlLXdpZHRoPSIzIiBmaWxsLW9wYWNpdHk9Ii41IiBmaWxsPSIjMDAwIiB4PSItMSIgeT0iLTEiIHdpZHRoPSIxMDMiIGhlaWdodD0iMTAzIiByeD0iNTAiLz48cGF0aCBkPSJNNjYuOTU4IDUyLjUxMkw0Mi43NDIgNjguNTI1QTIuNDEyIDIuNDEyIDAgMCAxIDM5IDY2LjUxNFYzNC40ODZhMi40MTIgMi40MTIgMCAwIDEgMy43NDItMi4wMTFsMjQuMjE2IDE2LjAxM2EyLjQxMiAyLjQxMiAwIDAgMSAwIDQuMDI0eiIgZmlsbD0iI0ZGRiIvPjwvZz48L3N2Zz4=')
    no-repeat;
  background-size: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 52px;
  height: 52px;
  cursor: pointer;
  transform: translate(-50%, -50%);
}
// .vcp-player .vcp-poster {
//   z-index: -1 !important;
//   &:after {
//     content: '';
//     background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTA3IiBoZWlnaHQ9IjEwNyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzIDMpIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxyZWN0IHN0cm9rZT0iI0ZGRiIgc3Ryb2tlLXdpZHRoPSIzIiBmaWxsLW9wYWNpdHk9Ii41IiBmaWxsPSIjMDAwIiB4PSItMSIgeT0iLTEiIHdpZHRoPSIxMDMiIGhlaWdodD0iMTAzIiByeD0iNTAiLz48cGF0aCBkPSJNNjYuOTU4IDUyLjUxMkw0Mi43NDIgNjguNTI1QTIuNDEyIDIuNDEyIDAgMCAxIDM5IDY2LjUxNFYzNC40ODZhMi40MTIgMi40MTIgMCAwIDEgMy43NDItMi4wMTFsMjQuMjE2IDE2LjAxM2EyLjQxMiAyLjQxMiAwIDAgMSAwIDQuMDI0eiIgZmlsbD0iI0ZGRiIvPjwvZz48L3N2Zz4=')
//       no-repeat;
//     background-size: 100%;
//     position: absolute;
//     left: 50%;
//     top: 50%;
//     width: 50px;
//     height: 50px;
//     transform: translate(-50%, -50%);
//   }
// }
.vcp-player {
  font-family: inherit !important;
  .vcp-bigplay {
    z-index: 900;
    height: 100%;
  }
  .vcp-controls-panel {
    opacity: 1;
    transition: 0.3s ease;
    &.hide {
      opacity: 0;
      animation: none;
      transform: translateY(100%);
    }
    &.show {
      opacity: 1;
      animation: none;
      transform: translateY(0);
    }
    .vcp-panel-bg {
      background: rgba(0, 0, 0, 0.7);
    }
    .vcp-fullscreen-toggle {
      background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTUuMjA0IDEzLjc4NmwxLjAyIDEtMy43MjEgMy43ODVoMy4yMTFWMjBIMHYtNS43MTRoMS40Mjl2My4zNDNsMy43NzUtMy44NDN6bTkuNTg2LjM1NGwzLjc4MSAzLjc4MXYtMy42MzVIMjBWMjBoLTUuNzE0di0xLjQyOUgxNy4ybC0zLjQyLTMuNDIxIDEuMDExLTEuMDFoLS4wMDF6TTIwIDB2NS43MTRoLTEuNDI5VjIuNDM3bC0zLjc4IDMuNzgzLTEuMDExLTEuMDExIDMuNzgtMy43OGgtMy4yNzRWMEgyMHpNNS43MTQgMHYxLjQyOWgtMy4yMWwzLjcyIDMuNzg1LTEuMDIgMUwxLjQzIDIuMzcxdjMuMzQzSDBWMGg1LjcxNHoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=')
        no-repeat;
      background-position: center;
    }
    .vcp-playtoggle {
      background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTkiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE0LjAzMiAxMC40NzlMMi4zMjkgMTguMjhBMS4xNzYgMS4xNzYgMCAwIDEgLjUgMTcuM1YxLjY5OUExLjE3NiAxLjE3NiAwIDAgMSAyLjMzLjcybDExLjcwMiA3LjgwMmExLjE3NiAxLjE3NiAwIDAgMSAwIDEuOTU4eiIgZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+')
        no-repeat;
      background-position: center;
    }
    .vcp-volume {
      background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iI0ZGRiI+PHBhdGggZD0iTTQuMzQgNC4wMXY5LjMzOUgxLjQ0N2MtLjggMC0xLjQ0Ny0uNzAyLTEuNDQ3LTEuNTQyVjUuNTUzQzAgNC43LjY0IDQuMDEgMS40NDYgNC4wMWwyLjg5NS0uMDAyek04Ljk3NC4yNzJDOS42MS0uMjQyIDEwLjEzLjA1IDEwLjEzLjl2MTUuNTU4YzAgLjg2MS0uNTE0IDEuMTQ3LTEuMTU3LjYyNkw0LjM0IDEzLjM1VjQuMDFMOC45NzMuMjczem04LjE1IDE1LjgzOGMzLjgxNC00LjEwMyAzLjgxNC0xMC43NTYgMC0xNC44NTlhMS4wMyAxLjAzIDAgMCAwLTEuNTM0IDAgMS4yMzIgMS4yMzIgMCAwIDAgMCAxLjY1MWMyLjk2NiAzLjE5MyAyLjk2NiA4LjM2NiAwIDExLjU1OWExLjIzMiAxLjIzMiAwIDAgMCAwIDEuNjUgMS4wMzIgMS4wMzIgMCAwIDAgMS41MzQtLjAwMXoiLz48cGF0aCBkPSJNMTQuMDU0IDEyLjgwOWMyLjExOS0yLjI3OSAyLjExOS01Ljk3NiAwLTguMjU1YTEuMDMgMS4wMyAwIDAgMC0xLjUzNCAwIDEuMjMyIDEuMjMyIDAgMCAwIDAgMS42NTFjMS4yNzEgMS4zNjcgMS4yNzEgMy41ODYgMCA0Ljk1M2ExLjIzMiAxLjIzMiAwIDAgMCAwIDEuNjUgMS4wMyAxLjAzIDAgMCAwIDEuNTM0IDB6Ii8+PC9nPjwvc3ZnPg==')
        no-repeat;
      background-position: center;
      background-size: auto;
    }
    .vcp-volume-icon {
      height: auto;
    }
    .vcp-timelabel {
      font-size: 0.6rem;
      line-height: 4em;
      padding: 0;
      height: 100%;
    }
    .vcp-slider-track {
      background-color: #0aa29b;
    }
    .vcp-timeline,
    .vcp-timeline:hover {
      height: 25px;
      top: -15px;
      .vcp-slider-thumb {
        top: -7px;
        border-radius: 1em !important;
        height: 18px;
        margin-left: -10px;
        width: 18px;
      }
      .vcp-slider {
        height: 5px;
        opacity: 1;
        margin-top: 13px;
      }
    }
    .vcp-volume .vcp-slider-vertical {
      top: -6.4em;
    }
    .vcp-slider-vertical {
      height: 6em;
    }
  }
  &.vcp-playing {
    .vcp-playtoggle {
      background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEyLjE0OCAwYy0xLjE0MyAwLTIuMTQzIDEtMi4xNDMgMi4xNDN2MTUuNzE0YzAgMS4xNDMgMSAyLjE0MyAyLjE0MiAyLjE0MyAxLjE0MyAwIDIuMTQ0LTEgMi4xNDQtMi4xNDNWMi4xNDNDMTQuMjkxIDEgMTMuMjkxIDAgMTIuMTQ4IDB6bS0xMCAwQzEuMDA2IDAgLjAwNiAxIC4wMDYgMi4xNDN2MTUuNzE0Qy4wMDYgMTkgMS4wMDYgMjAgMi4xNDggMjBjMS4xNDQgMCAyLjE0NC0xIDIuMTQ0LTIuMTQzVjIuMTQzQzQuMjkyIDEgMy4yOTIgMCAyLjE1IDB6IiBmaWxsPSIjRkZGIi8+PC9zdmc+')
        no-repeat;
      background-position: center;
    }
  }
  &.vcp-fullscreen .vcp-fullscreen-toggle {
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEuMDIgMjBMMCAxOWwzLjcyMS0zLjc4NkguNTF2LTEuNDI4aDUuNzE0VjE5LjVINC43OTZ2LTMuMzQzTDEuMDIgMjB6bTE3Ljk3IDBsLTMuNzgxLTMuNzgxdjMuNjM1SDEzLjc4VjE0LjE0aDUuNzE0djEuNDI5SDE2LjU4TDIwIDE4Ljk5IDE4Ljk4OSAyMGguMDAxek0xMy43OCA2LjIyVi41MDZoMS40Mjl2My4yNzdMMTguOTg5IDAgMjAgMS4wMTFsLTMuNzggMy43OGgzLjI3NFY2LjIySDEzLjc4ek0uNTEgNi4yMTRWNC43ODZoMy4yMUwwIDFsMS4wMi0xIDMuNzc2IDMuODQzVi41aDEuNDI4djUuNzE0SC41MXoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=')
      no-repeat;
    background-position: center;
  }
}
</style>