import { render, staticRenderFns } from "./enterRecord.vue?vue&type=template&id=255f0408&scoped=true&"
import script from "./enterRecord.vue?vue&type=script&lang=js&"
export * from "./enterRecord.vue?vue&type=script&lang=js&"
import style0 from "./enterRecord.vue?vue&type=style&index=0&id=255f0408&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "255f0408",
  null
  
)

export default component.exports