<!--
 * @Author: cyy
 * @Date: 2022-02-18 14:22:30
 * @LastEditTime: 2022-08-19 16:57:52
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacherdingding.com\src\views\OpenClass_openClassLive\components\liveDataComponents\openclassDatastatistics\components\invitationList.vue
-->
<template>
  <div id="invitationList">
    <div class="contain" v-loading="loadingshow">
      <div class="detail">
        <div class="detailleft">
          <el-button
            type="primary"
            style="width: 128px"
            @click="toinvite"
            size="small"
          >
            邀请学员
          </el-button>
          <span class="ml20 mr20 tips">
            提示：通过分享的直播间海报进入直播间，即视为邀请的学员
          </span>
        </div>
        <div class="detailright">
          <span class="allText">统计时间</span>
          <el-select
            class="timeselect"
            style="width: 100px"
            v-model="day_type"
            size="medium"
            placeholder="请选择"
            @change="getInvitationList"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-button
            type="text"
            class="mr10"
            size="medium"
            @click="showHidden({}, 2, 1)"
          >
            全部隐藏
          </el-button>
          <el-divider direction="vertical"></el-divider>
          <el-button
            type="text"
            class="ml10"
            size="medium"
            @click="showHidden({}, 1, 1)"
          >
            全部显示
          </el-button>
        </div>
      </div>
      <div class="rank_maincotain" v-if="invite.length > 0">
        <ul class="rank_ul">
          <li class="rank_item" v-for="(item, index) in invite" :key="index">
            <div class="ranked">
              <!-- 第一名 -->
              <img
                src="~@ass/img/1.4.2.5/ico_dym@2x.png"
                v-if="index == 0"
                alt=""
              />
              <!-- 第二名 -->
              <img
                src="~@ass/img/1.4.2.5/ico_dem@2x.png"
                v-else-if="index == 1"
                alt=""
              />
              <!-- 第三名 -->
              <img
                src="~@ass/img/1.4.2.5/ico_dsm@2x.png"
                v-else-if="index == 2"
                alt=""
              />
              <span v-else>
                {{ index + 1 }}
              </span>
            </div>
            <div class="user_photo">
              <img :src="item.uphoto" alt="" />
            </div>
            <div class="rankright">
              <div class="title hidetext">{{ item.uname }}</div>
              <div class="flex-center" style="300px">
                <div class="count">累计邀请 {{ item.count }}</div>
                <el-button
                  type="text"
                  v-if="item.is_show == 2"
                  size="small"
                  class="redcolor ml20"
                  @click="showHidden(item, 1, 1)"
                >
                  显示学员
                </el-button>
                <el-button
                  v-else
                  @click="showHidden(item, 2, 1)"
                  type="text"
                  size="small"
                  class="ml20"
                >
                  隐藏学员
                </el-button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="empty" v-else-if="ishow">
        <img src="~@ass/img/empty.png" alt="" />
        <div class="no_data_tip">暂无数据</div>
      </div>
    </div>
    <!-- 分享 -->
    <shareinfo
      fatherPath="/publicClass"
      v-if="shareinfo"
      :dialogstatus.sync="shareinfo"
      :shareinfoval="openInfo"
    ></shareinfo>
  </div>
</template>

<script>
import shareinfo from '@/components/openCourse/shareinfo'
export default {
  name: 'invitationList',

  data() {
    return {
      shareinfo: false,

      loadingshow: true,

      invite: [],

      ishow: false,
      day_type:1,
      options: [
        {
          value: 1,
          label: '今天',
        },
        {
          value: 2,
          label: '昨天',
        },
      ],
    }
  },

  props: {
    openInfo: {
      type: Object,
      default: () => {},
    },
    openClassId: [Number, String],
  },

  components: {
    shareinfo,
  },

  created() {
    this.getInvitationList()
  },

  methods: {
    // 显示和隐藏学员
    async showHidden(item, val, type) {
      await this.$http({
        url: '/console/userShow',
        data: {
          is_show: val,
          uid: Object.keys(item).length > 0 ? item.uid : undefined,
          open_class_id: this.openClassId,
          type: type,
        },
        successMsg: true,
      })
      this.getInvitationList()
    },

    toinvite() {
      this.shareinfo = true
    },

    async getInvitationList() {
      try {
        const { data } = await this.$http({
          url: '/console/invitationList',
          data: {
            open_class_id: this.openClassId,
            type: 1,
            day_type: this.day_type,
          },
        })
        this.loadingshow = false
        this.invite = data
        this.ishow = true
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import './stylecss.scss';
#invitationList {
  height: 100%;

  .el-button--primary {
    color: #ffffff;
    background-color: #fa6400;
    border-color: #fa6400;
  }
  .tips {
    font-size: 12px;
    color: #6a6a6a;
  }

  .detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 40px;
    .el-divider--vertical {
      background: #1b9d97;
      line-height: 20px;
    }
    .detailleft {
      display: flex;
      align-items: center;
      .tips {
        word-break: break-all;
      }
    }
    .detailright {
      min-width: 350px;
      ::v-deep .el-input__inner{
        border: 0;
        color: #fff;
        background: #292929;
      }
      .allText {
        font-size: 14px;
        color: #FFFFFF;
        line-height: 19px;
        margin-right: 20px;
      }
      .timeselect {
        margin-right: 20px;
      }
    }
  }
}
</style>
