<template>
  <div class="luckdraw">
    <div class="qiandao-span">
      <el-button
        type="primary"
        size="medium"
        style="background: #fa6400; border: 0"
        @click="addCoupon"
      >
        添加抽奖
      </el-button>
      <div>
        <el-button type="text" @click="refresh" class="mr10">
          刷新
        </el-button>
        <el-select
          size="medium"
          v-model="post.status"
          style="width: 110px"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-input
          clearable
          v-model="post.search"
          size="medium"
          @keyup.enter.native="toSearch"
          style="width:180px"
          class="ml10 el-input"
          placeholder="输入抽奖名称搜索"
        ></el-input>
        <el-button
          @click="toSearch"
          type="primary"
          class="ml10"
          size="medium"
          icon="el-icon-search"
        >
          搜索
        </el-button>
      </div>
    </div>
    <div class="content">
      <pagination2
        ref="pagination2"
        :allFn="true"
        :option="post2"
        url="/liveLottery/lotteryList"
        class="list"
        @complete="pagination2Complete"
      >
        <template v-slot:default="{}">
          <el-table
            ref="dialogTable"
            :header-cell-style="{
              background: '#212121',
              color: '#fff',
            }"
            :data="pagination2List"
            style="width: 100%"
            class="table_row"
          >
            <el-table-column label="抽奖名称">
              <template slot-scope="{ row }">
                <div class="student-text" :title="row.name">
                  {{ row.name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="创建时间" min-width="120">
              <template slot-scope="{ row }">
                {{ row.c_time | formatTimeStamp }}
              </template>
            </el-table-column>
            <el-table-column label="参与条件">
              <template slot-scope="{ row }">
                {{ row.condition_name }}
              </template>
            </el-table-column>
            <el-table-column label="参与人数">
              <template slot-scope="{ row }">
                {{ row.user_num }}
              </template>
            </el-table-column>
            <el-table-column label="状态" min-width="90px">
              <template slot-scope="{ row }">
                <div style="color:#1ABC9C">
                  <div v-if="row.status == 2">
                    <p>进行中</p>
                    <p>
                      ({{
                        row.downTime2 | formatTimeLength2('hh:mm:ss')
                      }}后结束)
                    </p>
                  </div>
                  <span v-else>{{ row.status | getStatus }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" min-width="280">
              <template slot-scope="{ row }">
                <el-button
                  type="primary"
                  size="mini"
                  style="background: #fa6400; border: 0"
                  v-if="row.status == 2"
                  @click="stopLaunch(row)"
                >
                  停止抽奖
                </el-button>
                <el-button
                  v-else
                  type="primary"
                  size="mini"
                  style="background: #fa6400; border: 0"
                  :style="
                    isGoing || row.status == 4
                      ? 'color:rgba(255, 255, 255, 0.498)'
                      : ''
                  "
                  @click="launchFaffle(row)"
                  :disabled="isGoing || row.status == 4"
                >
                  发起抽奖
                </el-button>
                <el-button
                  type="primary"
                  size="mini"
                  style="background: #fa6400; border: 0"
                  :style="
                    row.status != 2 ? 'color:rgba(255, 255, 255, 0.498)' : ''
                  "
                  :disabled="row.status != 2"
                  @click="openGift(row)"
                >
                  立即开奖
                </el-button>

                <el-button
                  @click="record(row)"
                  type="text"
                  class="mr10"
                  size="mini"
                >
                  中奖记录
                </el-button>
                <el-button
                  title="编辑"
                  size="mini"
                  type="info"
                  icon="el-icon-edit"
                  @click="edit(row)"
                  :style="
                    row.status == 2 || row.status == 4
                      ? 'color:rgba(255, 255, 255, 0.498)'
                      : ''
                  "
                  :disabled="row.status == 2 || row.status == 4"
                ></el-button>
                <el-button
                  title="删除"
                  @click="delLottery(row)"
                  type="info"
                  size="mini"
                  icon="el-icon-delete"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </pagination2>
    </div>

    <!-- 添加抽奖 -->
    <el-dialog
      :title="title"
      :close-on-click-modal="false"
      class="dialogVisible1"
      v-if="dialogVisible1"
      :visible.sync="dialogVisible1"
      width="840px"
    >
      <div class="dialog-tip">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="抽奖名称" :required="true">
            <el-input
              v-model="form.name"
              maxlength="12"
              show-word-limit
              style="width:310px"
            ></el-input>
          </el-form-item>
          <el-form-item label="参与条件">
            <el-radio-group v-model="form.condition">
              <p style="margin-top:12px">
                <el-radio label="1">所有在线成员</el-radio>
              </p>
              <p class="mt10">
                <el-radio label="2">
                  观看直播
                  <el-input-number
                    style="width:120px"
                    v-model="form.member_num"
                    placeholder="1~1000"
                    size="medium"
                    controls-position="right"
                  ></el-input-number>
                  分钟以上的成员
                </el-radio>
              </p>
              <p class="mt10">
                <el-radio label="3">
                  邀请人数达
                  <el-input-number
                    v-model="form.invitation_num"
                    placeholder="1~1000"
                    style="width:120px"
                    size="medium"
                    controls-position="right"
                  ></el-input-number>
                  人以上的成员
                </el-radio>
              </p>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="奖品设置" :required="true">
            <el-button @click="addGiftClick">+添加奖品</el-button>
            <span class="redcolor ml10 fs14">
              最多添加5个奖品，单个奖品最多设置50份
            </span>
            <div class="gift_content">
              <el-table
                :header-cell-style="{
                  background: 'rgba(245,245,245,1)',
                  color: '#333333',
                }"
                :data="form.list"
              >
                <el-table-column label="奖品">
                  <template slot-scope="{ row }">{{ row.name }}</template>
                </el-table-column>
                <el-table-column label="类型">
                  <template slot-scope="{ row }">
                    {{ 'coupon_id' in row ? '优惠券' : '图文' }}
                  </template>
                </el-table-column>
                <el-table-column label="奖项名称(6个字以内)">
                  <template slot-scope="{ row }">
                    <el-input
                      maxlength="6"
                      show-word-limit
                      v-model="row.prize_name"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="{ $index }">
                    <el-button type="text" @click="del($index)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="medium"
          @click="addGiftSubmit"
          style="width: 100px"
        >
          保存
        </el-button>
      </span>
    </el-dialog>

    <!-- 添加奖品 -->
    <add-gift
      v-if="status"
      @complete="addGiftcomplete"
      :dialogstatus.sync="status"
      :tempplateContentList="tempplateContentList"
    ></add-gift>

    <!-- 发起抽奖 -->
    <el-dialog
      title="发起抽奖"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible2"
      width="452px"
      @closed="refresh"
    >
      <div class="flex-center">
        <span class="fs12">开奖时间</span>
        <el-select
          v-model="drawPrizeTime"
          class="ml10 mr10"
          style="width:140px"
          placeholder="请选择时间"
        >
          <el-option
            v-for="item in options2"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-input
          v-if="drawPrizeTime === 0"
          class="mr10"
          size="medium"
          placeholder="1~200(分钟)"
          style="width:150px"
          v-model="timeCustom"
        ></el-input>
        <span class="fs12">后开奖</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sureLaunch">
          确定发起
        </el-button>
      </span>
    </el-dialog>

    <!-- 立即开奖 -->
    <draw-toPrize
      v-if="drawToPrizestatus"
      @complete="drawToPrizeComplete"
      :lottery_id="lottery_id"
      :dialogstatus.sync="drawToPrizestatus"
    ></draw-toPrize>

    <!-- 立即开奖 -->
    <winning-record
      v-if="winningRecordstatus"
      @complete="winningRecordComplete"
      :lottery_id="lottery_id"
      :dialogstatus.sync="winningRecordstatus"
    ></winning-record>
  </div>
</template>

<script>
import drawToPrize from './drawToPrize'
import addGift from './addGift'
import winningRecord from './winningRecord'
export default {
  data() {
    return {
      // 自定义的时间
      timeCustom: undefined,

      winningRecordstatus: false,

      lottery_id: '',

      drawToPrizestatus: false,

      title: '添加抽奖',

      pagination2List: [],

      isGoing: false,

      dialogVisible2: false,

      options2: [
        {
          value: 30,
          label: '30秒',
        },
        {
          value: 60,
          label: '1分钟',
        },
        {
          value: 300,
          label: '5分钟',
        },
        {
          value: 600,
          label: '10分钟',
        },
        {
          value: 900,
          label: '15分钟',
        },
        {
          value: 1800,
          label: '30分钟',
        },
        {
          value: 0,
          label: '自定义抽奖',
        },
      ],

      drawPrizeTime: '',

      tempplateContentList: [],
      status: false,
      form: {
        open_class_id: this.openClassId,
        name: '',
        condition: '1',
        member_num: undefined,
        invitation_num: undefined,
        list: [],
      },
      dialogVisible1: false,
      post: {
        open_class_id: this.openClassId,
        search: '',
        status: 0,
      },
      post2: {
        search: '',
        status: 0,
        open_class_id: this.openClassId,
      },
      options: [
        {
          value: 0,
          label: '全部状态',
        },
        {
          value: 1,
          label: '未开始',
        },
        {
          value: 2,
          label: '进行中',
        },
        {
          value: 4,
          label: '已开奖',
        },
        {
          value: 3,
          label: '已结束',
        },
      ],
    }
  },
  components: {
    addGift,
    drawToPrize,
    winningRecord,
  },
  props: {
    openClassId: {
      type: String,
      default: '',
    },
  },
  filters: {
    getStatus(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '未开始'
          break
        case 2:
          text = '进行中'
          break
        case 3:
          text = '已结束'
          break
        case 4:
          text = '已开奖'
          break
      }
      return text
    },
  },

  destroyed() {
    clearInterval(this.goodTimer)
  },

  methods: {
    winningRecordComplete() {
      this.winningRecordstatus = false
      this.formReset()
    },

    record(row) {
      this.lottery_id = row.lottery_id
      this.winningRecordstatus = true
    },

    async delLottery(row) {
      this.$confirm('是否确定删除该抽奖', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          await this.$http({
            url: '/liveLottery/delLottery',
            data: {
              lottery_id: row.lottery_id,
            },
            successMsg: true,
          })

          clearInterval(this.goodTimer)
          this.refresh()
        })
        .catch(() => {})
    },

    // 立即开奖弹窗
    openGift(row) {
      this.drawToPrizestatus = true
      this.lottery_id = row.lottery_id
    },

    // 关闭立即开奖弹窗
    drawToPrizeComplete() {
      this.drawToPrizestatus = false
      this.lottery_id = ''
      this.refresh()
    },

    // 编辑抽奖
    async edit(row) {
      this.title = '编辑抽奖'
      this.lottery_id = row.lottery_id
      const { data } = await this.$http({
        url: `/liveLottery/setLottery?lottery_id=${row.lottery_id}`,
      })
      this.form = data
      this.tempplateContentList = data.list
      this.$nextTick(() => {
        this.dialogVisible1 = true
      })
    },

    // 停止抽奖
    async stopLaunch(row) {
      this.$confirm('是否确定停止抽奖', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          this.stopLottery(row)
        })
        .catch(() => {})
    },

    async stopLottery(row) {
      await this.$http({
        url: '/liveLottery/stopLottery',
        data: {
          lottery_id: row.lottery_id,
        },
        successMsg: row.countdown ? true : false,
      })

      clearInterval(this.goodTimer)
      this.refresh()
    },

    // 列表数据
    pagination2Complete({ data }) {
      // 时间到自动停止
      data.forEach(v=>{
        if(v.countdown==0&&v.status==2){
          this.stopLottery(v)
        }
      })
      this.pagination2List = data.map(item => {
        return {
          ...item,
          downTime: item.countdown > 0 ? Date.now() + item.countdown * 1000 : 0,
          downTime2:
            item.downTime > 0
              ? Math.ceil(
                  (Date.now() + item.downTime * 1000 - Date.now()) / 1000
                )
              : 0,
        }
      })
      this.isGoing = data.filter(item => item.status == 2).length > 0

      const goVal = this.pagination2List.filter(item => item.status == 2)

      if (goVal.length > 0) {
        this.openInterval()
      }
    },

    // 开启商品倒计时
    openInterval() {
      clearInterval(this.goodTimer)
      this.goodTimer = setInterval(() => {
        this.pagination2List = this.pagination2List.map(item =>
          _.assign({}, item, {
            downTime2: Math.ceil((item.downTime - Date.now()) / 1000),
          })
        )

        const list = this.pagination2List.filter(item => item.status == 2)
        if (list[0].downTime2 <= 0) {
          clearInterval(this.goodTimer)
          this.refresh()
        }
      }, 1000)
    },

    // 确定发起
    async sureLaunch() {
      if (this.drawPrizeTime === '') {
        this.$root.prompt('请选择开奖时间')
        return
      }
      if (
        this.drawPrizeTime === 0 &&
        (!this.timeCustom || this.timeCustom < 1 || this.timeCustom > 200)
      ) {
        this.$root.prompt('请填写1~200之间的数字')
        return
      }
      await this.$http({
        url: '/liveLottery/start',
        data: {
          lottery_id: this.lottery_id,
          countdown: this.drawPrizeTime || this.timeCustom * 60,
        },
        successMsg: true,
      })
      this.dialogVisible2 = false
      this.refresh()
    },

    // 发起抽奖
    launchFaffle(row) {
      this.dialogVisible2 = true
      this.formReset()
      this.lottery_id = row.lottery_id
    },

    toSearch() {
      this.post2 = _.assign({}, this.post)
    },

    del(index) {
      this.tempplateContentList.splice(index, 1)
    },

    addCoupon() {
      this.dialogVisible1 = true
      this.formReset()
    },

    formReset() {
      this.tempplateContentList = []
      this.title = '添加抽奖'
      this.drawPrizeTime = ''
      this.lottery_id = ''
      this.form = {
        open_class_id: this.openClassId,
        name: '',
        condition: '1',
        member_num: undefined,
        invitation_num: undefined,
        list: [],
      }
    },

    async addGiftSubmit() {
      this.form.list = this.tempplateContentList

      if (!this.form.name.trim()) {
        this.$root.prompt('请输入奖品名称')
        return
      }

      if (
        this.form.condition == 2 &&
        (!this.form.member_num ||
          this.form.member_num < 1 ||
          this.form.member_num > 1000)
      ) {
        this.$root.prompt(
          '参与条件观看直播的分钟数不能小于1分钟且不能大于1000分钟'
        )
        return
      }

      if (
        this.form.condition == 3 &&
        (!this.form.invitation_num ||
          this.form.invitation_num < 1 ||
          this.form.invitation_num > 1000)
      ) {
        this.$root.prompt('参与条件邀请人数不能小于1人且不能大于1000人')
        return
      }

      if (this.form.list.length == 0) {
        this.$root.prompt('至少添加一项奖品')
        return
      }
      const index = this.form.list.findIndex(item => !item.prize_name)
      if (index > -1) {
        this.$root.prompt(`第${index + 1}个奖品的奖项名称为空，请填写奖项名称`)
        return
      }
      if (this.title == '编辑抽奖') {
        await this.$http({
          url: '/liveLottery/setLottery',
          data: _.assign({}, this.form, { lottery_id: this.lottery_id }),
          successMsg: true,
        })
      } else {
        await this.$http({
          url: '/liveLottery/addLottery',
          data: this.form,
          successMsg: true,
        })
      }
      this.formReset()
      this.dialogVisible1 = false
      this.refresh()
    },

    refresh() {
      this.$refs.pagination2.reset()
      this.timeCustom = undefined
      this.toSearch()
    },

    addGiftClick() {
      if (this.tempplateContentList.length < 5) {
        this.status = true
      } else {
        this.$root.prompt('最多添加5个奖品')
      }
    },

    addGiftcomplete(val) {
      this.status = false
      this.tempplateContentList.push(...val)
      this.tempplateContentList.map(item => {
        return {
          ...item,
          prize_name: '',
        }
      })
      this.form.list = this.tempplateContentList
    },
  },
}
</script>

<style lang="scss" scoped>
.luckdraw {
  height: 100%;
  display: flex;
  flex-direction: column;

  ::v-deep .dialogVisible1 {
    .el-dialog__footer {
      padding-top: 0 !important;
    }
    .el-dialog__body {
      padding: 10px 20px 0 !important;
    }
    .el-input-number .el-input__inner {
      text-align: left;
    }
  }

  ::v-deep .qiandao-span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    padding: 0 20px 0 20px;
    position: relative;
    .el-input__inner {
      background-color: #171717;
      border-color: #333333;
      color: #fff;
    }
    .span {
      height: 30px;
      display: inline-block;

      cursor: pointer;
      span {
        width: 56px;
        height: 14px;
        font-size: 14px;
        color: #fff;
      }
    }
    > div:first-child {
      margin-right: 40px;
    }
    .update {
      position: absolute;
      right: 20px;
      height: 14px;
      font-size: 14px;
      color: #0aa29b;
      cursor: pointer;
    }
  }
}
.content {
  display: flex;
  flex: 1;
  ::v-deep .pagination {
    height: auto;
    padding-bottom: 0;
    padding-top: 0;
    position: absolute;
    bottom: 65px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 100%;
    .el-input__inner {
      border: 0;
      color: #fff;
      background: #292929;
    }
  }
  ::v-deep tr .cell {
    padding-left: 20px !important;
  }
  ::v-deep .pagination .btn-prev .el-icon,
  ::v-deep .pagination .btn-next .el-icon {
    color: #fff !important;
  }
  ::v-deep .pagination .el-pagination {
    white-space: normal;
    word-break: break-all;
    padding: 10px 20px 0 20px !important;
  }
  ::v-deep .pagination .el-pagination button {
    color: #fff !important;
    background: #292929 !important;
  }

  ::v-deep .pagination .el-pager li {
    color: #fff;
    background-color: #292929 !important;
  }

  ::v-deep .pagination .el-pager li.active {
    background-color: #fa6400 !important;
  }
  ::v-deep .pagination2Box {
    margin: 20px 0;
  }
  .qiandao-now {
    flex-shrink: 0;
    position: relative;
    margin: 20px 0 0 20px;
    width: 200px;
    border-radius: 1px;
    .q-time-ul {
      flex: 1;
      position: relative;
      background: #2a2a2a;
      .scroll {
        overflow-y: auto;
        @extend %liveRoomScroll;
      }
    }
    .no-data {
      position: absolute;
      height: 14px;
      font-size: 14px;
      color: #909399;
      line-height: 14px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .q-time {
      height: 48px;
      line-height: 48px;
      background: #212121;
      border-radius: 1px;
      text-align: center;

      span {
        font-size: 14px;
        font-weight: bold;
        color: #fff;
      }
    }
    .qtimeli {
      height: 36px;
      text-align: center;
      line-height: 36px;
      cursor: pointer;
      span {
        font-size: 14px;
        color: #fff;
      }
    }
  }
  .list {
    flex: 1;
    overflow: hidden;
    margin: 20px 20px 0 20px;
    background: #171717;
    position: relative;

    .table_row {
      ::v-deep .el-button--mini {
        padding: 7px 5px !important;
      }
      ::v-deep .el-table__header-wrapper tr th {
        padding: 7px 0; //因为排序图标大小导致行高无法改变
      }
    }

    .student-name {
      height: 20px;
      margin-left: 10px;

      //可以使两个行内元素对齐,1.使用display: flex;align-items: center;2.或者不对齐元素设置vertical-align: top;
      display: flex;
      align-items: center;

      .img {
        display: inline-block;
        height: 20px;
        width: 20px;
        border-radius: 10px;
        margin-right: 10px;

        img {
          height: 20px;
          width: 20px;
        }
      }
      .student-text {
        display: inline-block;
        height: 20px;
        // vertical-align: top;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
  ::v-deep .gutter {
    background: #f5f5f5;
  }
  ::v-deep .el-table {
    color: #fff;
    background: #171717;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: calc(100% - 124px);
    overflow-y: auto;
    @extend %liveRoomScroll;
  }
  ::v-deep .el-table::before,
  ::v-deep .el-table--group::after,
  ::v-deep .el-table--border::after {
    background-color: #171717 !important;
  }
  ::v-deep .el-table tr,
  ::v-deep .el-table th.el-table__cell {
    background: #2a2a2a;
  }
  ::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
    background: #393939 !important;
  }
  ::v-deep .el-table th.is-leaf {
    background: #171717;
    color: #fff !important;
    border-bottom: 1px solid #171717 !important;
  }
  ::v-deep .el-table td,
  ::v-deep .el-table__empty-block {
    background: #171717;
    height: 99% !important;
    color: #fff !important;
    border-bottom: 1px solid #212121 !important;
  }
}

.selectActive {
  background: #393939;
  border-radius: 1px;
}
.spanc {
  border-bottom: solid 1px #fa6400;
}
.dangerbtn {
  background: #ff3535 !important;
  border-color: #ff3535 !important;
}
.green {
  color: #fa6400 !important;
}
.el-dropdown-link {
  cursor: pointer;
}
.dialog-tip {
  .gift_content {
    margin-top: 10px;
    height: 180px;
    @extend %scroll;
    overflow: auto;
  }
  ::v-deep .el-tabs__content {
    overflow: hidden;
    position: relative;
  }
  ::v-deep .el-table__header-wrapper {
    line-height: 10px;
    height: 45px;
  }
  ::v-deep .el-table__cell {
    padding: 7px 0 !important;
  }
  .qian-time-select {
    width: 315px;
    ::v-deep .el-input > .el-input__inner {
      height: 36px;
      line-height: 36px;
    }
  }
  .help {
    height: 14px;
    width: 14px;
    margin-left: 5px;
    margin-right: 20px;
  }
}
</style>
