<!--
 * @Author: cyy
 * @Date: 2021-12-17 14:17:52
 * @LastEditTime: 2022-02-23 15:27:34
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \ teacher.dingdingkaike\src\views\OpenClass_openClassLive\components\oepnAndClose.vue
--> 
<template >
  <div class="open-close" @click="openCloseClick">
    <!-- 关闭 -->
    <img
      v-if="openAndClose"
      src="@ass/img/liveChar/icon_sxck.png"
      alt=""
      title="切换到小窗"
    />
    <!-- 打开 -->
    <img
      v-else
      src="@ass/img/liveChar/icon_fdck.png"
      alt=""
      title="切换到大窗"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapMutations, mapActions } = createNamespacedHelpers(
  'OpenClassopenClassLive'
)
export default {
  name: 'reset',
  computed: {
    ...mapState(['openAndClose', 'openClassId']),
  },

  data() {
    return {}
  },

  created() {
    const isWindowsApp = this.$store.state.isWindowsApp
    const isclick = !sessionStorage.getItem('openAndClose')
    if (!isWindowsApp && isclick) {
      this.setOpenAndClose(true)
    }
  },

  methods: {
    ...mapMutations(['setOpenAndClose']),
    ...mapActions(['sendToWindowsSizeInOpenClass']),

    openCloseClick() {
      // 如果没有放大页面 就去放大
      if (!this.openAndClose) {
        let url = this.$router.resolve({
          path: `/OpenClass_openClassLive/${this.openClassId}`,
        })
        this.$store.dispatch('open', url.href)
        // this.setOpenAndClose()
      } else {
        // 否则默认缩小
        window.resizeBy(-520, 0)
        if (this.$store.state.isWindowsApp) {
          this.sendToWindowsSizeInOpenClass({ width: -520, height: 0 })
        }
        this.setOpenAndClose()
      }
      // sessionStorage.setItem('openAndClose', this.openAndClose ? 1 : 0)
    },
  },
}
</script>

<style lang="scss" scoped>
.open-close {
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  img {
    height: 16px;
    width: 16px;
  }
}
</style>
