<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-06-27 15:38:45
 * @LastEditTime: 2023-01-30 10:09:36
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacherdingding.com\src\views\OpenClass_openClassLive\components\liveDataComponents\yingXiao\main.vue
-->
<template>
  <div class="yingxiao">
    <myMenu :data="menuList" class="menuList" v-model="menuId" />
    <luck-draw v-if="menuId == 0" :openClassId="openClassId"></luck-draw>
    <sign-in v-if="menuId == 1" :openClassId="openClassId" />
  </div>
</template>
<script>
import myMenu from '../menu'

import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('OpenClassopenClassLive')
import signIn from './signIn'
import luckDraw from './luckDraw'

export default {
  name: 'yingxiao',

  components: {
    signIn,
    myMenu,
    luckDraw,
  },

  computed: {
    ...mapState(['openClassId']),
  },

  data() {
    return {
      menuId: 0,

      menuList: [
        {
          name: '抽奖',
          id: 0,
        },
        {
          name: '签到',
          id: 1,
        },
      ],
    }
  },

  methods: {},
}
</script>
<style lang="scss" scoped>
.yingxiao {
  height: 100%;
  .menuList {
    height: 60px;
    flex-shrink: 0;
  }
}
</style>
