<template>
  <div id="otherfnContain">
    <el-popover
      popper-class="otherfnContain_popperclass"
      :visible-arrow="false"
      placement="bottom"
      width="86"
      :trigger="trigger"
      v-model="popoverShow"
    >
      <div class="otherfnContainPopcontain">
        <template
          v-if="(!isManager || isPictureFile || this.noPrivate) && !noYinyong"
        >
          <div v-for="item in options" :key="item.id">
            <div
              v-if="(isPictureFile && item.id == 3) || Quote"
              class="item"
              @click="selected(item.id)"
            >
              {{ item.name }}
            </div>
          </div>
        </template>
        <template v-else-if="noYinyong">
          <div v-for="item in options3" :key="item.id">
            <div class="item" @click="selected(item.id)">
              {{ item.name }}
            </div>
          </div>
        </template>
        <template v-else>
          <div v-for="item in options2" :key="item.id">
            <div class="item" @click="selected(item.id)">
              {{ item.name }}
            </div>
          </div>
        </template>
      </div>
      <div slot="reference" :style="toright ? 'text-align: right' : ''">
        <slot></slot>
      </div>
    </el-popover>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapMutations } = createNamespacedHelpers(
  'OpenClassopenClassLive'
)
export default {
  name: 'otherfnContain',
  data() {
    return {
      popoverShow: false,
      options: !this.noPrivate
        ? [
            {
              id: 1,
              name: '私信',
            },
            {
              id: 2,
              name: '引用',
            },
            {
              id: 3,
              name: '删除',
            },
          ]
        : [
            {
              id: 2,
              name: '引用',
            },
          ],
      options2: [
        {
          id: 2,
          name: '引用',
        },
        {
          id: 3,
          name: '删除',
        },
      ],
      options3: [
        {
          id: 1,
          name: '私信',
        },
        {
          id: 3,
          name: '删除',
        },
      ],
    }
  },
  props: {
    /**
     * @desc: 触发列表的类型
     * @params {*} trigger
     * @return: {*} click/focus/hover/manual
     */
    trigger: {
      type: String,
      default: 'hover',
    },

    /**
     * @desc: 如果是当前登录人发送的消息，则为true，反之为false
     * @params {*} toright
     * @return: {*} true false
     */
    toright: {
      type: Boolean,
      default: false,
    },

    noYinyong: {
      type: Boolean,
      default: false,
    },

    isPictureFile: {
      type: Boolean,
      default: false,
    },

    noPrivate: {
      type: Boolean,
      default: false,
    },

    //  只有文本可以引用
    Quote: {
      type: Boolean,
      default: false,
    },

    isManager: [Boolean, Number],
  },

  computed: {
    ...mapState(['openAndClose']),
  },

  methods: {
    ...mapMutations(['changerightActiveName']),
    selected(val) {
      if (val == 3) {
        this.$emit('otherfnContainsDel')
      } else if (val == 2) {
        this.$emit('otherfnQuote')
        if (!this.openAndClose) {
          this.changerightActiveName(String(7))
        }
      } else {
        this.$emit('otherfnPrivateletter')
      }
      this.popoverShow = false
    },
  },
}
</script>
<style lang="scss">
.otherfnContain_popperclass {
  margin-top: 0 !important;
  background-color: #000 !important;
  border-color: #000 !important;
  max-height: 116px !important;
  color: #fff !important;
  min-width: 0 !important;
  padding: 12px 0 !important;
  // .el-popover__reference {
  //   text-align: right;
  // }
  .otherfnContainPopcontain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .item {
      padding: 10px 25px;
      cursor: pointer;
      font-size: 12px;
      &:hover {
        color: #0aa29b;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
#otherfnContain {
}
</style>
