var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"enterRecord"}},[_c('div',{staticClass:"search"},[_c('div',{staticClass:"tip"},[_vm._v("提示：数据仅显示当天的记录")]),_c('div',{staticClass:"right"},[_c('el-select',{staticStyle:{"width":"140px"},attrs:{"size":"medium","placeholder":"请选择"},model:{value:(_vm.post.type),callback:function ($$v) {_vm.$set(_vm.post, "type", $$v)},expression:"post.type"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('el-input',{staticClass:"ml20",staticStyle:{"width":"210px"},attrs:{"size":"medium","placeholder":"输入名称搜索"},model:{value:(_vm.post.search),callback:function ($$v) {_vm.$set(_vm.post, "search", $$v)},expression:"post.search"}})],1)]),_c('pagination2',{ref:"childDialogBox",staticClass:"childDialogBox flex-column",attrs:{"option":_vm.post,"url":"/console/studentData"},scopedSlots:_vm._u([{key:"default",fn:function({ tableData }){return [_c('div',{staticClass:"table-box"},[_c('el-table',{staticClass:"absolute",attrs:{"header-cell-style":{
            background: '#212121',
            color: '#fff',
          },"row-style":{ background: '#171717', color: '#fff' },"data":tableData}},[_c('el-table-column',{attrs:{"label":"学员","prop":""},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('div',{staticClass:"student_contain"},[_c('div',{staticClass:"tu"},[_c('img',{attrs:{"src":row.user_photo,"alt":""}})]),_c('div',{staticClass:"name",attrs:{"title":`
                  ${row.user_name}${
                    row.student_remarks ? `(${row.student_remarks})` : ''
                  }`}},[_vm._v(" "+_vm._s(row.user_name)+" "),(row.student_remarks)?_c('span',[_vm._v(" ("+_vm._s(row.student_remarks)+") ")]):_vm._e()])])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"类型","prop":""},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(row.new == 1)?_c('span',{staticStyle:{"color":"#29bc53"}},[_vm._v("新学员")]):_c('span',{staticStyle:{"color":"#5990c0"}},[_vm._v("老学员")])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"注册时间","prop":""},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('span',[_vm._v(_vm._s(_vm._f("formatTimeStamp")(row.regdate,'yyyy-MM-dd hh:mm')))])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"今日首次进入","prop":""},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm._f("formatTimeStamp")(row.c_time,'yyyy-MM-dd hh:mm'))+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"label":"今日最后在线","prop":""},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm._f("formatTimeStamp")(row.e_time,'yyyy-MM-dd hh:mm'))+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"label":"今日进入次数","prop":"count"}})],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }