<template>
  <div id="InterestfreeCoupon">
    <div class="header">
      <div>
        <el-radio-group v-model="type" @change="radioChange" size="medium">
          <el-radio-button label="1">可发放免息券</el-radio-button>
          <el-radio-button label="2">已发放记录</el-radio-button>
        </el-radio-group>
        <el-button
          size="medium"
          class="ml20 installmentbtn"
          style="width: 100px; background: #fa6400; color: #fff; border: 0"
          @click="toInstallment"
        >
          创建免息券
        </el-button>
      </div>

      <div class="right">
        <el-select
          style="width: 100px"
          v-model="post.specs"
          class="mr20"
          size="medium"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-input
          style="width: 180px"
          v-model="post.name"
          size="medium"
          placeholder="输入名称搜索"
        ></el-input>
      </div>
    </div>

    <pagination2
      @complete="complete"
      :option="post"
      :url="url"
      ref="childDialogBox"
    >
      <template>
        <div class="pagination2Box" v-if="iscomplete" ref="pagination2Box">
          <el-table
            max-height:200
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333',
            }"
            :data="tableList"
          >
            <el-table-column label="名称">
              <template slot-scope="{ row }">
                {{ row.name }}
                <div class="tip">
                  领券后{{ row.receive_duration | formatTimeLength(2) }}失效
                </div>
              </template>
            </el-table-column>

            <el-table-column label="期数">
              <template slot-scope="{ row }">
                <div v-if="row.specs == 1">3期</div>
                <div v-else-if="row.specs == 2">6期</div>
                <div v-else>12期</div>
              </template>
            </el-table-column>

            <el-table-column label="库存数量">
              <div slot="header">
                库存数量
                <el-button
                  @click="refresh"
                  style="color: #fa6400; margin-left: 5px"
                  type="text"
                >
                  刷新
                </el-button>
              </div>
              <template slot-scope="{ row, $index }">
                <div v-if="type == 1">
                  <div v-if="row.selectStatus">
                    <el-input-number
                      :min="0"
                      :max="99999"
                      :key="$index"
                      :precision="0"
                      :step="1"
                      size="small"
                      v-model="row.stock_num"
                    ></el-input-number>
                    <div>
                      <el-button
                        type="text"
                        class="ml10"
                        style="color: #fa6400"
                        @click="saveNewNumber(row, 1, $index)"
                      >
                        保存
                      </el-button>
                      <el-button
                        style="color: #f5f5f5"
                        type="text"
                        class="ml10"
                        @click="saveNewNumber(row, 2)"
                      >
                        取消
                      </el-button>
                    </div>
                  </div>
                  <el-button
                    v-else
                    style="color: #fa6400"
                    type="text"
                    @click="changeStockNum($index, true)"
                  >
                    {{ row.stock_num }}
                    <i class="el-icon-edit" style="color: #fa6400"></i>
                  </el-button>
                </div>
                <div v-else>
                  {{ row.stock_num }}
                </div>
              </template>
            </el-table-column>

            <el-table-column label="已领取数量" v-if="type == 2">
              <template slot-scope="{ row }">
                {{ row.receive_num }}
              </template>
            </el-table-column>

            <el-table-column label="发放状态" v-if="type == 2">
              <template slot-scope="{ row }">
                <span v-if="row.status == 1">发放中</span>
                <span v-else>已下链接</span>
              </template>
            </el-table-column>

            <el-table-column label="领取失效时间" v-if="type == 2">
              <template slot-scope="{ row }">
                {{ row.expire_time | formatTimeStamp('YYYY-MM-dd hh:mm:ss') }}
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="{ row }">
                <el-popover
                  ref="popover"
                  v-if="type == 1"
                  placement="top-start"
                  popper-class="InterestfreeCoupon-popover"
                  width="150"
                  trigger="click"
                  :visible-arrow="false"
                >
                  <div class="popover">
                    <div class="popover-tip">领券时效：</div>
                    <div class="list">
                      <ul>
                        <li
                          @click="
                            item.value == 4
                              ? showDialog(row, item.value)
                              : sendOut(row, item.value)
                          "
                          v-for="item in options2"
                          class="item"
                          :key="item.value"
                        >
                          {{ item.label }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <el-button
                    slot="reference"
                    style="
                      width: 126px;
                      background: #fa6400;
                      border: 0;
                      color: #fff;
                    "
                    size="medium"
                  >
                    发放
                    <i class="el-icon-arrow-down"></i>
                  </el-button>
                </el-popover>
                <el-button
                  @click="pull(row)"
                  size="medium"
                  v-else-if="row.status == 1"
                  style="
                    color: #ff3535;
                    background: #212121;
                    border-color: #212121;
                  "
                >
                  下链接
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
    </pagination2>
    <!-- 自定义时间 -->
    <el-dialog
      title="自定义时效"
      :append-to-body="true"
      :visible.sync="dialogVisible"
      width="422px"
    >
      <el-date-picker
        style="width: 100%"
        v-model="etime"
        type="datetime"
        placeholder="选择日期时间"
        :picker-options="pickerOptions"
      ></el-date-picker>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sendOut(dialogValrow, dialogValval)">
          发放
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'InterestfreeCoupon',
  data() {
    return {
      iscomplete: false,
      dialogVisible: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7 + 180000 //设置选择今天以及今天之后的日期
        },
      },

      dialogValrow: {},
      dialogValval: '',

      etime: '',

      url: '/Installment/installmentList',

      tableList: [],

      type: 1,

      post: {
        name: '',
        specs: 0,
        open_class_id: '',
      },

      radio2: '1',

      options2: [
        {
          value: 1,
          label: '1分钟',
        },
        {
          value: 2,
          label: '3分钟',
        },
        {
          value: 3,
          label: '15分钟',
        },
        {
          value: 4,
          label: '自定义',
        },
      ],

      options: [
        {
          value: 0,
          label: '全部期数',
        },
        {
          value: 1,
          label: '3期',
        },
        {
          value: 2,
          label: '6期',
        },
        {
          value: 3,
          label: '12期',
        },
      ],
    }
  },

  created() {
    this.post.open_class_id = this.open_class_id
  },

  props: {
    open_class_id: [String, Number],
  },

  methods: {
    toInstallment() {
      let url = this.$router.resolve({
        path: '/installment',
      })
      this.$store.dispatch('open', url.href)
    },
    // 下链接
    async pull(row) {
      await this.$confirm(
        '下链接后，免息券将不在直播间展示，学员若是从其他入口进入领取页时，依然可领取',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      )

      await this.$http({
        url: '/Installment/pull',
        data: {
          installment_push_id: row.installment_push_id,
        },
      })
      this.refresh()
      this.$root.prompt({
        msg: '操作成功！',
        type: 'success',
      })
    },

    showDialog(row, val) {
      this.dialogValrow = row
      this.dialogValval = val
      this.dialogVisible = true
    },

    // 发放
    async sendOut(row, val) {
      if (val == 4 && !this.etime) {
        this.$root.prompt('请选择时间！')
        return
      }
      document.body.click()
      await this.$http({
        url: '/Installment/push',
        data: {
          type: val,
          open_class_id: this.open_class_id,
          installment_id: row.installment_id,
          etime: val == 4 ? this.etime / 1000 : undefined,
        },
      })
      this.type = 2
      this.radioChange()
      // this.refresh()
      this.$root.prompt({
        msg: '操作成功！',
        type: 'success',
      })

      if (val == 4) {
        this.dialogVisible = false
      }
    },
    refresh() {
      this.$refs.childDialogBox.reset()
    },
    // 获取列表数据
    complete(val) {
      val.data.forEach(item => {
        item.selectStatus = false
      })
      this.tableList = val.data
      this.iscomplete = true
    },

    // 点击库存数量，修改状态
    changeStockNum(index, status) {
      this.tableList[index].selectStatus = status

      const arr = []

      this.tableList.forEach(item => {
        arr.push(item)
      })

      this.tableList = arr
    },

    // 修改库存数量
    async saveNewNumber(row, type, index) {
      if (type == 1) {
        await this.$http({
          url: '/Installment/setStock',
          data: {
            num: row.stock_num,
            installment_id: row.installment_id,
          },
        })
        this.changeStockNum(index, false)
      } else {
        this.refresh()
      }
    },
    radioChange() {
      if (this.type == 1) {
        this.url = '/Installment/installmentList'
      } else {
        this.url = '/Installment/pushList'
      }
      this.tableList = []
      this.refresh()
    },
  },
}
</script>

<style lang="scss" scoped>
#InterestfreeCoupon {
  position: absolute;
  left: 0;
  top: 60px;
  right: 0;
  bottom: 0;
  padding: 20px 20px 0;
  display: flex;
  flex-direction: column;
  display: flex;
  flex-direction: column;
  .newPagination {
    flex: 1;
    position: relative;
  }
  ::v-deep .el-input-number__increase,
  .el-input-number {
    background-color: #292929;
    border: 0;
    color: #fff;
  }
  ::v-deep .el-input-number__decrease,
  .el-input-number {
    color: #fff;
    background-color: #292929;
    border: 0;
  }

  ::v-deep .el-radio-button__inner {
    color: #fff;
    background-color: #292929 !important;
    border-color: #292929;
  }

  ::v-deep .pagination2Box {
    height: 380px;
    overflow: auto;
    top: 0 !important;
    @extend %liveRoomScroll;
  }

  .tip {
    line-height: 1.5;
    margin-top: 10px;
    font-size: 12px;
    color: #666666;
  }
  ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: #fa6400;
    border-color: #292929;
    box-shadow: -1px 0 0 0 #373737;
  }
  .header {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .installmentbtn {
      &.el-button--medium {
        padding: 10px 15px !important;
      }
    }
    .right {
      display: flex;
      align-items: center;
    }
  }
}
</style>

<style lang="scss">
.InterestfreeCoupon-popover {
  background-color: #292929 !important;
  border-color: #292929 !important;
  box-sizing: border-box;
  padding: 20px;
  .popover-tip {
    font-size: 14px;
    color: #999999;
    margin-bottom: 15px;
  }
  .list {
    .item {
      cursor: pointer;
      font-size: 14px;
      color: #ffffff;
      padding: 12px 0;
      &:hover {
        color: #fa6400;
      }
    }
  }
}
</style>
