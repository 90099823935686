import { render, staticRenderFns } from "./chatHistory.vue?vue&type=template&id=6bc14a38&scoped=true&"
import script from "./chatHistory.vue?vue&type=script&lang=js&"
export * from "./chatHistory.vue?vue&type=script&lang=js&"
import style0 from "./chatHistory.vue?vue&type=style&index=0&id=6bc14a38&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bc14a38",
  null
  
)

export default component.exports