<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="900px"
    :append-to-body="true"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div slot="title">
      <div class="flex-center">
        <div class="title_font">
          {{ title }}
        </div>
      </div>
    </div>
    <upload
      ref="uploadTextFile"
      :meanwhile="2"
      @changedilog="changedilog"
      @complete="complete"
      @getJgInfo="$emit('getJgInfo')"
      :list="value2"
      :typeselection="typeselection"
      :menuName="menuName"
      :uploadType="uploadType"
      :accept="
        this.uploadType == 1
          ? '.ppt, .pptx , .doc , .docx  , .xls , .xlsx, .pdf , .txt , .rar , .zip, .xmind'
          : this.uploadType == 2
          ? '.jpg,.jpeg,.png'
          : this.uploadType == 3
          ? '.mp3,.m4a'
          : '.ppt, .pptx , .doc , .docx  , .xls , .xlsx, .pdf'
      "
      :maxSize="
        this.uploadType == 1 || this.uploadType == 4
          ? 1024 * 1024 * 1024 * 0.5
          : this.uploadType == 2
          ? 1024 * 1024 * 1024 * 0.02
          : 1024 * 1024 * 1024 * 0.5
      "
    >
      <template #uploadBtn="{ queue }" v-if="audioType != 2">
        <el-button
          type="primary"
          class="mb10"
          :disabled="queue.length == 1 && typeselection == 2"
        >
          选择文件
        </el-button>
        <div class="cover" v-if="queue.length == 1 && typeselection == 2"></div>
        <!-- <div class="upload-btn">
          <span>选择文件</span>
        </div> -->
        <!-- <a class="aaaaRed">建议上传1G以内的视频，避免学生观看时加载过久，可以使用格式工厂进行压缩</a>
        <div class="t2">
          支持mp4、m3u8文件，单个不可超过1GB，上传过程中请勿关闭该页面，否则上传失败！最多可同时选20个文件，逐个排队上传；建议用谷歌或者360极速模式浏览器上传；文件名请勿包含特殊字符(#^;<>)，以免上传失败！
        </div> -->
      </template>
      <template
        #select="{ uploadError, uploadCompleteList }"
        v-if="audioType != 2"
      >
        <div v-if="heardstatus">
          <div class="proposal">
            支持{{
              uploadType == 1
                ? 'ppt、pptx、pdf、doc、docx、xls、xlsx、zip、rar、xmind、txt文件，单个不可超过500MB；'
                : uploadType == 2
                ? 'jpg、jpeg、png文件，单个不可超过20MB；'
                : uploadType == 3
                ? 'mp3, m4a文件，单个不可超过500MB；'
                : 'ppt, pptx, pdf , doc , docx , xls , xlsx文件，单个文件仅支持200MB，5000页之内'
            }}最高同时支持2个文件上传；建议用谷歌或者360极速模式浏览器上传；文件名请勿包含特殊字符(#^;<>)，以免上传失败！
          </div>
          <div class="right-select2">
            <span>上传至</span>
            <!-- {{ list + 'aaaa' }}{{ value2 + 'bbbbbbbb' }} -->
            <el-select
              class="selected"
              v-model="value2"
              placeholder="请选择"
              style="width: 350px; margin-left: 40px"
            >
              <el-option
                v-for="item in menuList"
                :key="item.group_id"
                :label="item.name"
                :value="item.group_id"
              ></el-option>
            </el-select>
          </div>
        </div>

        <div
          class="after_upload"
          v-else-if="$refs.uploadTextFile.uploadingstatus"
        >
          <div class="flex-center">
            <div>分组：{{ heardvalue.group }}</div>
          </div>
          <div class="after_upload_font">
            提示：已选文件上传中，可添加新文件，不可修改分组和转码规格；如需修改，请等待当前列表文件上传完成后
          </div>
        </div>
        <div v-else>
          <div class="flex-center status3">
            <div>分组：{{ heardvalue.group }}</div>
          </div>
          <div class="after_upload_font2">
            上传完成，成功{{ uploadCompleteList.length }}个，失败{{
              uploadError.length
            }}个
          </div>
        </div>
      </template>

      <template
        #list="{
          queue,
          removeFile,
          selectButtonStatus,
          newQueue,
          uploadingstatus,
        }"
      >
        <el-table
          :empty-text="
            newQueue == 0 && uploadingstatus
              ? '请点击左上方【选择文件】按钮添加文件，然后点击底部【开始上传】按钮'
              : '请点击左上方【选择文件】按钮添加文件，然后点击底部【开始上传】按钮'
          "
          :data="queue"
          :class="{ isafterupload: !heardstatus }"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
        >
          <el-table-column prop="file_name" label="文件" width="300">
            <template slot-scope="{ row }">
              <div class="file_name_class">{{ row.file_name }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="file_size" label="大小">
            <template slot-scope="scope">
              {{ scope.row.file_size | getFileSize }}MB
            </template>
          </el-table-column>

          <el-table-column
            prop="speed"
            label="上传速度"
            v-if="selectButtonStatus"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.speed == 0">-</span>
              <span v-else-if="scope.row.speed > 1">
                {{ scope.row.speed }}Mb/s
              </span>
              <span v-else>{{ scope.row.speed * 1024 }}Kb/s</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="percent"
            label="上传进度"
            v-if="selectButtonStatus"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.percent == 0">-</span>
              <span v-else>{{ scope.row.percent }}%</span>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态">
            <template slot-scope="scope">
              <span v-if="scope.row.status === 0" style="color: #2791fb">
                上传成功
              </span>
              <span v-else-if="scope.row.status === 1">等待上传</span>
              <span v-else-if="scope.row.status === 2">上传中</span>
              <span v-else-if="scope.row.status === 5">删除中</span>
              <span v-else-if="scope.row.status === 6">删除失败</span>
              <span v-else style="color: #ff3535">上传失败</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" v-if="selectButtonStatus">
            <template slot-scope="scope">
              <div class="btns">
                <span
                  class="text"
                  v-if="scope.row.status !== 0"
                  @click="removeFile(scope.row.$index)"
                >
                  {{
                    scope.row.status === 1
                      ? '移除 '
                      : scope.row.status === 2
                      ? '取消上传'
                      : '删除'
                  }}
                </span>
              </div>
            </template>
          </el-table-column>
          >
        </el-table>
      </template>

      <template #footer="{ beginUploadStatus, uploadingstatus, newQueue }">
        <div class="_center mb10" v-if="beginUploadStatus">
          <el-button
            type="primary"
            style="width: 100px; margin-top: 7px"
            size="medium"
            @click="begin_upload"
          >
            开始上传
          </el-button>
        </div>
        <div class="warning-opennew" v-else-if="uploadingstatus">
          <i class="el-icon-warning redcolor"></i>
          <div class="redcolor mr10">
            上传过程中请勿关闭本页面，否则上传失败！如需要进行其他操作，请在新窗口打开。
          </div>
          <a @click="opennew('/')">点此打开</a>
          <a
            @click="continueupload"
            style="margin-left: 30px"
            v-if="newQueue == 0"
          >
            返回上一层
          </a>
        </div>
        <div class="_center mt10" v-else>
          <el-button
            size="medium"
            class="w100"
            @click="continueupload"
            v-if="typeselection != 2 && audioType != 2"
          >
            继续上传
          </el-button>
          <el-button
            type="primary"
            class="w100"
            size="medium"
            @click="closedialog"
          >
            完成
          </el-button>
        </div>
      </template>
    </upload>
  </el-dialog>
</template>
<script>
import { mapMutations } from 'vuex'
import upload from '@cm/base/Uploadtext'

export default {
  name: 'uploadFile',

  props: {
    // 1,普通上传 2，录音上传
    audioType: {
      type: Number,
      default: 1,
    },
    title: {
      type: String,
    },
    typeselection: {
      type: Number,
      default: 1,
    },
    leftStorage: [Number, String],
    list: {
      default: 0,
    },
    // 分组名
    menuName: String,
    uploadType: {
      //上传的引入模块1为文档,2图片,3音频
      type: Number,
      default: 1,
    },
  },

  components: {
    upload,
  },

  data() {
    return {
      //上传后顶部信息
      heardvalue: {},
      heardstatus: true,
      //1按量2不按量
      charge_mode: this.$store.state.userInfo.charge_mode,
      //1开启2，关闭
      is_drm: this.$store.state.userInfo.jg_is_drm,
      //1，加密转码 2，普通转码
      radio: 2,
      //分组列表
      menuList: [],
      //被选中的分组列表的id
      value2: Number(this.list),
      // value: 4,

      uploadSuccess: [],

      visible: true,

      previewSrc: '',
    }
  },
  created() {
    this.getGroupList()
  },

  filters: {
    getFileSize(value) {
      var size = (value / 1024 / 1024).toFixed(2)

      return size
    },
  },

  methods: {
    ...mapMutations('netSchoolDecration', ['groupid1Change']), //置空被选中的id值
    continueupload() {
      this.heardstatus = true
      this.$refs.uploadTextFile.destroyed11()
    },
    closedialog() {
      this.visible = false
      this.groupid1Change(0)
      this.$emit('uploadConfirmClose')
    },
    //点击完成，关闭弹窗
    changedilog(val) {
      this.visible = val
    },

    // begin_upload00() {
    //   if (this.queue.length == 0) {
    //     this.$root.prompt({ msg: '请先选择视频文件！' })
    //     return
    //   }
    //   this.beginUploadStatus = false
    //   this.next()
    //   this.$emit('click_begin_upload')
    // },

    //点击开始初始化数据
    begin_upload() {
      this.$store.dispatch('getAmount').then(() => {
        if (this.$refs.uploadTextFile.queue.length == 0) {
          this.$root.prompt({ msg: '请先选择文件！' })
          return
        }
        if (this.$store.state.amount <= 0) {
          this.$root.prompt({
            msg: '叮豆不足，无法上传文件！',
          })
          return
        }
        this.$refs.uploadTextFile.beginUploadStatus = false
        this.$refs.uploadTextFile.next()

        this.heardstatus = false
        let value = { type: '', Specifications: '', group: '' }
        value.group = this.menuList.find(f => f.group_id == this.value2).name
        this.heardvalue = value
      })
    },
    opennew(val) {
      let url = this.$router.resolve({
        path: val,
      })
      this.$store.dispatch('open', url.href)
    },
    //获取分组列表
    getGroupList() {
      const groupid =
        this.uploadType == 1
          ? 3
          : this.uploadType == 2
          ? 4
          : this.uploadType == 3
          ? 5
          : 7
      this.$http({
        url: `/DataBank/groupList?type=${groupid}`,
        callback: ({ data }) => {
          this.menuList = data.map(item => {
            return Object.assign({}, item, { group_id: Number(item.group_id) })
          })
        },
      })
    },
    handleClose(done) {
      const close = () => {
        done()
        setTimeout(() => {
          this.$emit('close')
        }, 300)
      }
      if (this.$refs.uploadTextFile.currUploadingFile.length > 0) {
        this.$confirm(
          '当前有文件正在上传，离开当前页面会上传失败，已上传成功的自动保存到资料库；是否确定离开？',
          '温馨提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }
        ).then(close)
      } else {
        close()
      }
      this.groupid1Change(0)
    },
    // 文档上传成功后调接口
    complete(val) {
      this.$root.prompt({
        msg: '上传成功',
        type: 'success',
      })
      this.$emit('completelist', val)
      this.uploadSuccess.push(val.serverId)
      // 重新获取剩余空间
      this.$emit('getJgInfo')
    },
  },
}
</script>
<style lang="scss" scoped>
.title_font {
  font-size: 14px;
  color: #333333;
}
.cover {
  cursor: no-drop;
  height: 50px;
  width: 104px;
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
}
.file_name_class {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.after_upload {
  width: 660px;
  position: absolute;
  top: 56px;
  left: 154px;
  .after_upload_font {
    margin-top: 10px;
    font-size: 13px;
    color: #999999;
    line-height: 13px;
  }
}
.status3 {
  font-size: 14px;
  color: #333333;
  line-height: 14px;
}
.after_upload_font2 {
  margin: 10px 0 16px;
  font-size: 13px;
  color: #999999;
  line-height: 13px;
}
::v-deep .isafterupload {
  height: 375px;
  & .el-table__body-wrapper {
    height: 330px;
  }
}
.popover_video {
  font-size: 12px;
  color: #4a4a4a;
  line-height: 22px;
}
.aaaaRed {
  width: 441px;
  height: 12px;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #ff3535;
  line-height: 12px;
}
::v-deep .el-table__empty-text {
  width: 100%;
}
// ::v-deep .el-input--suffix .el-input__inner {
//   border: 0;
// }
::v-deep .el-dialog {
  height: 550px;
}
// ::v-deep .el-table {
//   height: 244px;
// }
::v-deep .el-table__body-wrapper {
  height: 260px;
  overflow-y: auto;
}
::v-deep .el-table__header-wrapper {
  height: 42px;
}

::v-deep .el-dialog__body {
  padding: 10px 20px 0px;
}

.upload-btn {
  width: 100px;
  height: 36px;
  border-radius: 1px;
  font-size: 14px;
  color: #fff;
  background-color: rgba(10, 162, 155, 1);
  line-height: 38px;
  cursor: pointer;
  text-align: center;
  border: 1px solid rgba(10, 162, 155, 1);
  margin-bottom: 20px;
}
.proposal {
  font-size: 12px;
  color: #999999;
  line-height: 18px;
  width: 726px;
  position: absolute;
  top: 60px;
  left: 142px;
}

.right-select1,
.right-select2 {
  .t1 {
    font-size: 14px;
    font-weight: bold;
    color: rgba(10, 162, 155, 1);
    line-height: 19px;
  }
}
.right-select2 {
  margin-top: 20px;
  margin-bottom: 30px;
}

.t2 {
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 20px;
  color: rgba(153, 153, 153, 1);
  line-height: 16px;
}

.btns {
  @extend %btns;
}
</style>
