<template>
  <div id="dataSelect">
    <div class="search">
      <el-select
        v-if="type == 1 && !goodlistType"
        v-model="DialogBoxPost.price_type"
        placeholder="请选择"
        style="width: 140px"
        size="medium"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>

      <!-- 分组 -->
      <YSelect
        v-if="type == 5&&!this.group_id"
        class="mr20"
        style="width:160px;"
        v-model="groupId" 
        :hasAll="true"
        :clearable="true"
        :remote="true"
        :options="options2" 
        :loading="loading" 
        :placeholder="'请选择直播分组'" 
        defaultLabel="全部直播分组"
        @visibleChange="visibleChange" 
        @loadMore="loadMore" 
        @remoteMethod="remoteMethod" 
        @change="change"
      ></YSelect>

      <!-- 分类 -->
      <el-select
        v-if="type == 5"
        v-model="DialogBoxPost.live_mode"
        placeholder="请选择"
        style="width: 140px"
        size="medium"
      >
        <el-option label="全部直播类型" :value="0"></el-option>
        <el-option label="标准直播" :value="1"></el-option>
        <el-option label="快直播 " :value="2"></el-option>
        <el-option label="智能直播 " :value="3"></el-option>
      </el-select>

      <!-- 会员 -->
      <el-select
        v-if="type == 2 && isnaire"
        v-model="DialogBoxPost.is_show"
        placeholder="请选择"
        style="width: 140px"
        size="medium"
      >
        <el-option label="全部类型" :value="0"></el-option>
        <el-option label="展示到网校" :value="1"></el-option>
        <el-option label="不展示到网校 " :value="2"></el-option>
      </el-select>
      <el-select
        v-if="type == 2 && isnaire"
        class="ml20"
        v-model="DialogBoxPost.status"
        placeholder="请选择"
        style="width: 140px"
        size="medium"
      >
        <el-option label="全部状态" :value="0"></el-option>
        <el-option label="已启用" :value="1"></el-option>
        <el-option label="已停用 " :value="2"></el-option>
      </el-select>

      <el-input
        v-model="DialogBoxPost.search"
        :placeholder="
          type == 1
            ? '输入资料名称搜索'
            : type == 2
            ? '输入会员名称搜索'
            : type == 5
            ? '输入名称搜索'
            : '输入作业名称搜索'
        "
        style="width: 215px"
        size="medium"
        class="ml20"
      ></el-input>
    </div>
    <pagination2
      :option="DialogBoxPost"
      :url="
        type == 1
          ? '/HomeTemplate/electronicList'
          : type == 2
          ? '/courseMember/courseMemberList'
          : type == 5
          ? '/HomeTemplate/openClassList'
          : '/HomeTemplate/workbookList'
      "
      ref="childDialogBox"
      :allFn="true"
    >
      <template v-slot:default="{ tableData }">
        <div class="pagination2Box">
          <el-table
            :row-key="getRowkey"
            @selection-change="DialogBoxSelectionChange"
            @row-click="handleRowClick"
            :data="tableData"
            ref="dialogTable"
            max-height:200
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333',
            }"
          >
            <!-- 
             :disabled="
               template == 13
                 ? false
                 : scope.row.status == 2 || scope.row.is_show == 2
              "
                  直播课单选，没有上下架，和展示展示，所以全部的公开课都可以选择，  template == 13 是单选公开课
           -->
            <el-table-column label width="32" v-if="isSingleSelect">
              <template slot-scope="scope">
                <el-radio
                  v-show="template != 5"
                  :disabled="
                    'is_show' in scope.row &&
                      (scope.row.status == 2 || scope.row.is_show == 2)
                  "
                  :label="
                    type == 1
                      ? scope.row.ed_id
                      : type == 2
                      ? scope.row.member_id
                      : type == 5
                      ? scope.row.open_class_id
                      : scope.row.workbook_id
                  "
                  v-model="radioId"
                >
                  &nbsp;
                </el-radio>
              </template>
            </el-table-column>
            <el-table-column
              v-else
              reserve-selection
              type="selection"
              :selectable="selectionEvent"
              width="50"
            ></el-table-column>
            <el-table-column
              :label="
                type == 1
                  ? '资料'
                  : type == 2
                  ? '会员'
                  : type == 5
                  ? '公开课'
                  : '作业'
              "
              min-width="280px"
            >
              <template slot-scope="{ row }">
                <div class="contain">
                  <div class="tu">
                    <img
                      :src="
                        (type == 1
                          ? row.img_url
                          : type == 2 || type == 5
                          ? row.cover_img
                          : row.img_url) | imageslim
                      "
                      alt=""
                    />
                  </div>
                  <div class="right">
                    <div class="name" :title="row.name">
                      {{ row.name }}
                    </div>
                    <div class="teacher_count" v-if="type == 5">
                      {{ row.uname }}
                      <span style="margin-left: 5px; margin-right: 5px">|</span>
                      {{ row.number }}人参与
                    </div>
                    <div class="type" v-else-if="'is_show' in row">
                      <!-- 1展示 2不展示 -->
                      <img
                        src="~@ass/img/1.3.9.4/icon_dg.png"
                        alt=""
                        v-if="row.is_show == 1"
                      />
                      <img src="~@ass/img/1.3.9.4/icon_cw.png" alt="" v-else />
                      <span v-if="row.is_show == 1">展示到网校</span>
                      <span v-else>不展示到网校</span>

                      <!-- 1已上架  2已下架   -->
                      <img
                        v-if="row.status == 1"
                        class="ml20"
                        src="~@ass/img/1.3.9.4/icon_dg.png"
                        alt=""
                      />
                      <img
                        v-else
                        class="ml20"
                        src="~@ass/img/1.3.9.4/icon_cw.png"
                        alt=""
                      />

                      <span v-if="row.status == 1">
                        {{ type == 2 ? '已启用' : '已上架' }}
                      </span>
                      <span v-else>{{ type == 2 ? '已停用' : '已下架' }}</span>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column v-if="type == 5" label="直播类型">
              <template slot-scope="{ row }">
                {{ row.live_mode == 1 ? '标准直播' : row.live_mode == 2 ? '快直播' : '智能直播' }}
              </template>
            </el-table-column>
            <el-table-column label="售价" v-if="type != 2">
              <template slot-scope="{ row }">
                <div v-if="type == 5">
                  <span class="redcolor" v-if="row.play_type == 3">
                    ￥{{ row.play_price }}
                  </span>
                  <span class="redcolor" v-else-if="row.play_type == 2">
                    密码
                  </span>
                  <span class="redcolor" v-else>免费</span>
                </div>
                <div v-else>
                  <span class="redcolor" v-if="row.price > 0">
                    ￥{{ row.price }}
                  </span>
                  <span class="redcolor" v-else>免费</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="课程数" v-if="type == 2 && !isnaire">
              <template slot-scope="{ row }">
                {{ row.course_count }}
              </template>
            </el-table-column>
            <el-table-column label="操作" v-if="template == 5">
              <template slot-scope="{ row }">
                <el-button
                  v-if="row.status == 2 || row.is_show == 2"
                  type="text"
                  disabled
                  style="color: #676767"
                >
                  推送
                </el-button>
                <el-button
                  type="text"
                  v-else
                  style="color: #fa6400"
                  @click="$emit('push')"
                >
                  推送
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
    </pagination2>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listSave: [],
      radioId: '',
      groupId: '',
      DialogBoxPost: {
        live_mode: 0,
        goodlistType: this.goodlistType,
        goodList: this.goodList
          ? this.goodList.length == 0
            ? 1
            : this.goodList
          : undefined,
        installment_id: this.installment_id,
        p_group_id: this.p_group_id,
        is_naire: this.isnaire ? 1 : undefined,
        naire_id: this.isnaire ? this.$route.query.naire_id : undefined,
        status: this.isnaire ? 0 : undefined,
        is_show: this.isnaire ? 0 : undefined,
        id_type: this.id_type,
        group_id: '',
        id: this.id,
        search: '',
        price_type: 0,
        [this.type == 1
          ? 'ed_ids'
          : this.type == 5
          ? 'open_class_ids'
          : this.type == 2
          ? 'member_ids'
          : 'workbook_ids']: this.ids,
      },

      options: [
        {
          value: 0,
          label: '全部售价',
        },
        {
          value: 2,
          label: '免费',
        },
        {
          value: 1,
          label: '付费',
        },
      ],
      value: 0,

      // 分组
      options2: [],
      selectForm: {
        page: 1,
        num: 15,
        pagesize: 15,
        search: ''
      },
      loading: false,
    }
  },

  props: {
    // 是否需要过滤免费课程
    goodlistType: {
      type: Boolean,
      default: false,
    },
    goodList: [Array, Object, String],
    // 免息券选择
    installment_id: {
      type: String,
      default: undefined,
    },

    // 推广员 添加商品
    p_group_id: {
      type: String,
      default: undefined,
    },

    isnaire: {
      type: Boolean,
      default: false,
    },

    //1资料 2，会员 3，作业 5,公开课
    type: {
      type: Number,
      default: 1,
    },

    template: {
      type: Number,
    },

    //true是单选 false:多选
    isSingleSelect: {
      type: Boolean,
      default: true,
    },

    ids: {
      type: Array,
      default() {
        return []
      },
    },

    // 作业-公开课选择用到
    // 作业类型
    id_type: {
      type: Number,
      default: undefined,
    },

    // 分组id
    group_id: {
      type: String,
      default: '',
    },
    // 分组名字
    group_name: {
      type: String,
      default: '',
    },

    // 作业id
    id: {
      type: Number,
      default: undefined,
    },
  },
  created(){
    if(this.group_id){
      this.DialogBoxPost.group_id = this.group_id
      // this.init({
      //   search:this.group_name,
      // })
    }
  },
  methods: {
    DialogBoxSelectionChange(val) {
      //点击选择暂存选项数组
      this.listSave = val
      this.$emit('listSave', val)
    },
    getRowkey(row) {
      let val
      switch (this.type) {
        case 1:
          val = row.ed_id
          break
        case 2:
          val = row.member_id
          break
        case 3:
          val = row.workbook_id
          break
        case 5:
          val = row.open_class_id
          break
      }
      return val
    },
    handleRowClick(row) {
      clearTimeout(this.rowClicTimer)
      this.rowClicTimer = setTimeout(() => {
        if ('is_show' in row && (row.status == 2 || row.is_show == 2)) {
          return
        }
        switch (this.type) {
          case 1:
            this.radioId = row.ed_id
            row.type = 1
            break
          case 2:
            this.radioId = row.member_id
            row.type = 2
            break
          case 3:
            this.radioId = row.workbook_id
            row.type = 3
            break
          case 5:
            this.radioId = row.open_class_id
            row.type = 5
        }
        if (this.isSingleSelect) {
          this.$emit('singleselectcontent', row)
        }
        this.$refs.dialogTable.toggleRowSelection(row)
      }, 100)
    },
    // 判断课程是否可以被选择（下架 不展示到网校）
    selectionEvent(row) {
      return !('is_show' in row) || (row.is_show == 1 && row.status == 1)
    },

    // 分组
    async init(searchObj) {
      this.loading = true
      await this.$http({
        url: '/liveGroup/getGroupList',
        data: Object.assign(this.selectForm,searchObj),
        callback: ({data}) => {
          const list = data.map(v=>Object.assign(v,{
            id:v.group_id,
            name:v.group_name,
          }))
          this.options2 = _.merge(this.options2,list)
          this.loading = false
        },
        error: ({ info }) => {
          this.$root.prompt({
            msg: info,
          })
        },
      })
    },
    change(value) {
      this.DialogBoxPost.group_id = value
    },
    visibleChange(status) {
      if(!status){
        this.selectForm.search = ''
        this.options2=[]
      } else {
        this.init()
      }
    },
    loadMore(page) {
      this.selectForm.page = page
      this.init()
    },
    remoteMethod(query, page) {
      this.selectForm.page = page
      this.selectForm.search = query
      this.options2=[]
      this.init()
    },
  },
}
</script>

<style lang="scss" scoped>
#dataSelect {
  height: 396px;
  margin-bottom: 10px;
  .search {
    display: flex;
    justify-content: flex-end;
  }
  .contain {
    display: flex;
    align-items: center;

    .tu {
      img {
        width: 60px;
        height: 34px;
      }
    }
    .right {
      width: calc(100% - 70px);
      margin-left: 10px;
      .name {
        font-size: 13px;
        color: #333333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .teacher_count {
        font-size: 12px;
        color: #666666;
      }
      .type {
        align-items: center;
        display: flex;
        font-size: 12px;
        color: #666666;
        img {
          width: 8px;
          height: 7px;
          margin-right: 6px;
        }
        span {
          font-size: 12px;
          color: #666666;
        }
      }
    }
  }

  .pagination2Box {
    margin-top: 20px;
    overflow: auto;
    height: 290px;
    @extend %scroll;
  }
}
</style>
