<template>
  <div class="topGongGao">
    <div style="margin-bottom: 10px;">
      <span style="color:#fff;font-size: 14px;height: 45px;line-height: 45px;">启用置顶公告</span>
      <el-switch
        v-model="timeInfo.top_status"
        active-color="#fa6400"
        inactive-color="#dcdfe6"
        style="margin-left:10px;"
        @change="isClose"
        >
      </el-switch>
    </div>
    <el-input
      v-if="timeInfo.top_status"
      rows="4"
      type="textarea"
      placeholder="请输入内容，发送空内容即为不显示置顶公告"
      v-model.trim="timeInfo.top_notice"
      maxlength="255"
      show-word-limit
    ></el-input>
    <el-button class="save-btn" type="primary" @click="topNotice" v-if="timeInfo.top_status">发布</el-button>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState,mapActions } = createNamespacedHelpers(
  'OpenClassopenClassLive'
)
export default {
  name: 'topGongGao',

  computed: {
    ...mapState([
      'openClassId',
      'openInfo',
    ]),
  },
  data() {
    return {
      timeInfo:{
        top_notice:'',
        top_status:true,
      }
    }
  },
  created(){
    this.init()
  },
  methods: {
    ...mapActions(['consoleInfo']),
    init(){
      this.timeInfo = {
        top_notice:this.openInfo.top_notice,
        top_status:this.openInfo.top_status==1?true:false,
      }
    },
    async isClose(value){
      if(!value&&this.openInfo.top_status==1){
        await this.$http({
          url: '/console/topNotice',
          data: {
            open_class_id: this.openClassId,
            top_notice: this.timeInfo.top_notice,
            top_status:this.timeInfo.top_status?1:2,
          },
          successMsg: true,
        })
        this.consoleInfo(this.$route.params.id)
      }
    },
    async topNotice() {
      await this.$http({
        url: '/console/topNotice',
        data: {
          open_class_id: this.openClassId,
          top_notice: this.timeInfo.top_notice,
          top_status:this.timeInfo.top_status?1:2,
        },
        successMsg: true,
      })
      this.consoleInfo(this.$route.params.id)
      this.$root.prompt({
        type: 'success',
        msg: '发送成功',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.topGongGao {
  margin: 20px;
  ::v-deep .el-textarea {
    .el-textarea__inner {
      height: 152px;
      resize: none;
      font-size: 14px;
      color: #fff;
      background: #2c2c2c;
      border-radius: 4px;
      border-color: #2c2c2c;
    }
    .el-textarea__inner::-webkit-input-placeholder {
      font-size: 12px;
      color: #5f5f5f;
    }
    .el-input__count {
      background: #2c2c2c;
    }
  }
  ::v-deep .timeselect {
    color:#FFFFFF;
    font-size: 14px;
    margin: 20px 0px;

    .el-radio__input.is-checked .el-radio__inner {
      border-color: #fa6400;
      background: #fa6400;
    }
    .el-radio {
      color: #ffffff;
      .el-radio__input.is-checked + .el-radio__label {
        color: #fa6400;
      }
    }

    .el-input-number.is-controls-right .el-input-number__increase,
    .el-input-number.is-controls-right .el-input-number__decrease {
      border: 0 !important;
      background-color: #323232;
      color: #fff;
    }

    .el-input__inner {
      background-color: #323232;
      border: #323232;
      color: #fff;
    }
  }
  .save-btn {
    width: 76px;
    height: 36px;
    margin-top: 20px;
    border-radius: 6px;
    background: #fa6400;
    border-color: #fa6400;
  }
}
.el-switch{
  ::v-deep .el-switch__core{
    background-color:#dcdfe6 !important;
  }
}
.el-switch.is-checked{
  ::v-deep .el-switch__core{
    background-color:#fa6400 !important;
  }
}
</style>