<template>
  <!-- 图片选择+本地上传 -->
  <div id="changePhotoimg">
    <div class="mainclick" @click="changePicture">
      <div class="press" v-if="press && isshow">正在上传...</div>
      <slot name="img"></slot>
    </div>

    <!-- 本地上传弹窗 -->
    <upload
      v-if="uploadStart"
      @uploadConfirmClose="uploadConfirmClose"
      @close="uploadConfirmClose"
      :typeselection="1"
      :title="title"
      :uploadType="uploadType"
      :list="group_id1"
    ></upload>

    <!-- 图片选择弹窗 locationUpload本地上传触发 选中图片的回调 list  获取选中左侧列表的id group_id-->
    <addpicture
      :size="size"
      :isCheckbox="isCheckbox"
      v-if="addpicturestatus"
      :dialogstatus="addpicturestatus"
      @locationUpload="locationUpload"
      :type="type"
      ref="addpicture"
      @group_id="group_id"
      :limit="limit"
      :hadselectNum="hadselectNum"
      @list="list"
      @audio="audio"
      :pictureTitle="pictureTitle"
      @close="changgePicture(false)"
    ></addpicture>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
//本地上传弹窗
import upload from '@cm/base/UploadtextFile'
//图片组件
import addpicture from '@cm/base/addpicture'
export default {
  name: 'changePhotoimg',

  data() {
    return {
      press: false,
    }
  },

  components: {
    upload,
    addpicture,
  },

  computed: {
    ...mapState('netSchoolDecration', [
      'changgePicturestatus',
      'group_id1',
      'uploadStart',
    ]),

    ...mapState(['userInfo']),

    //添加图片弹窗控制
    addpicturestatus() {
      return this.changgePicturestatus
    },
  },

  props: {
    // 已经选择的个数
    hadselectNum: {
      type: Number,
      default: undefined,
    },
    // 限制选择的总数
    limit: {
      type: Number,
      default: undefined,
    },

    isCheckbox: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: '上传图片(多选)',
    },

    uploadType: {
      type: Number,
      default: 2,
    },

    size: {
      type: Number,
      default: undefined,
    },

    url: {
      type: String,
      default: '',
    },

    isshow: {
      type: Boolean,
      default: false,
    },

    uid: {
      type: [Number, String],
      default: undefined,
    },

    type: {
      type: Number,
      default: 1,
    },
    // 弹窗标题
    pictureTitle: {
      type: String,
      default: '添加图片(单选)',
    },
  },

  methods: {
    ...mapMutations('netSchoolDecration', [
      'uploadStartChange',
      'uploadStartChange',
      'changgePicture',
      'groupid1Change',
    ]),

    //修改头像
    changePicture() {
      this.changgePicture(true)
    },

    //选择的音频
    audio(val) {
      this.$emit('complete', val)
    },

    //选择的图片的回调
    list(val) {
      this.press = true
      let promise = new Promise(resolve => {
        // 修改用户头像
        if (this.url) {
          this.$http({
            url: this.url,
            data: {
              uid: this.uid ? this.uid : this.userInfo.uid,
              uphoto: val.url,
            },

            callback: ({ code }) => {
              if (code == 200) {
                this.$store.dispatch('getUserInfo')
                this.$store.commit('setUserInfoRequest', null)
                resolve()
              }
            },
          })
        } else {
          resolve()
        }
      })
      promise.then(() => {
        this.press = false
        if (this.isCheckbox) {
          this.$emit('complete', val)
        } else {
          this.$emit('complete', val.url)
        }
      })
    },

    // 上传弹窗关闭
    uploadConfirmClose() {
      this.$refs.addpicture.renew()
      this.uploadStartChange(false)
    },

    //获取图片上传中的分组id
    group_id(val) {
      this.groupid1Change(val)
      // this.group_id1 = val
    },

    //本地上传弹窗控制
    locationUpload() {
      this.uploadStartChange(true)
    },
  },
}
</script>

<style lang="scss" scoped>
#changePhotoimg {
  height: 110px;
  width: 100px;
  line-height: initial;

  .mainclick {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .press {
      font-size: 12px;
    }
  }
}
</style>
