<template>
  <div class="liveSetting">
    <myMenu :data="menuList" v-model="menuId" />
    <gongGao v-if="menuId == 0" />
    <minGanCi v-if="menuId == 1" />
    <topGongGao v-if="menuId == 2" />
  </div>
</template>
<script>
import myMenu from '../menu'
import gongGao from './gongGao'
import minGanCi from './minGanCi'
import topGongGao from './topGongGao'

export default {
  name: 'liveSetting',

  components: {
    myMenu,
    gongGao,
    minGanCi,
    topGongGao,
  },

  computed: {},

  data() {
    return {
      menuId: 0,
      menuList: [
        {
          name: '系统消息',
          id: 0,
        },
        {
          name: '敏感词',
          id: 1,
        },
        {
          name: '置顶公告',
          id: 2,
        },
      ],
    }
  },

  methods: {
    activeChange(val) {
      this.active = val
    },
  },
}
</script>
<style lang="scss" scoped></style>