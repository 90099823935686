<!--
 * @Author: cyy
 * @Date: 2022-02-22 15:08:30
 * @LastEditTime: 2022-11-07 18:05:18
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacherdingding.com\src\views\OpenClass_openClassLive\components\liveDataComponents\changKong\automaticSpeech.vue
-->
<template>
  <div class="automaticSpeech flex-column">
    <div class="heard">
      <template>
        <div class="speechProgrammecontain">
          <el-row>
            <el-col
              :span="24"
              class="sp2 flex-center"
              style="justify-content: space-between; flex-wrap: wrap;"
            >
              <div class="top_contain flex-center ">
                <el-popover
                  popper-class="automaticSpeech_popper"
                  placement="bottom"
                  width="122"
                  trigger="click"
                >
                  <div class="popover_item">
                    <div class="item" @click="libraryStatus = true">
                      从机器人库选择
                    </div>
                    <div class="item" @click="groupsStatus = true">
                      从机器人分组选择
                    </div>
                  </div>
                  <el-button
                    type="primary"
                    :disabled="robotList.length >= limit"
                    slot="reference"
                    size="medium"
                  >
                    选择发送人来源
                    <i class="el-icon-arrow-down"></i>
                  </el-button>
                </el-popover>
                <div class="robot-origin">
                  <div class="flex-center">
                    <span class="tips ml20 mr30">
                      已选来源：{{ robotList.length }}/{{ limit }}
                    </span>
                    <el-tag
                      :key="tag.gid"
                      v-for="(tag, index) in robotList"
                      closable
                      :disable-transitions="false"
                      @close="handleClose(index)"
                    >
                      {{ tag.name || tag.gname }}
                    </el-tag>
                  </div>
                  <div class="robot-local-text">
                    最近选择的发送人来源将保留一天有效期！
                  </div>
                </div>
              </div>
              <el-button
                style="flex-shrink:0"
                type="info"
                size="medium"
                :loading="loading"
                :disabled="loading"
                @click="submit"
              >
                发送
              </el-button>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6" class=" flex-center mr20">
              <div class="sp2 mr10" style="flex-shrink:0">选择话术本</div>
              <el-select
                filterable
                clearable
                v-model="post.msg_id"
                style="flex: 1;"
                placeholder="请选择话术本"
                @focus="liveChatFocus"
              >
                <el-option
                  v-for="item in options"
                  :key="item.msg_id"
                  :label="`${item.name}(${item.num})`"
                  :value="item.msg_id"
                ></el-option>
              </el-select>
            </el-col>

            <el-col :span="6" class="flex-center">
              <div class="sp2 mr10" style="flex-shrink:0">发言频率</div>
              <el-select
                filterable
                clearable
                v-model="post.send_rate_type"
                style="flex: 1;"
                placeholder="请选择发言频率"
                @focus="liveChatFocus"
              >
                <el-option
                  v-for="item in options2"
                  :key="item.id"
                  :label="`${item.name}`"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-col>

            <el-col :span="12" class=" flex-center mr20">
              <div class="sp2 mr10 ml20 " style="flex-shrink:0">
                发言时间
                <helpIcon
                  class="mr20"
                  content="创建多个发言方案时间有交叉时，系统同时只执行一个方案。如A方案和B方案发言时间相近，A方案没发言完B方案已经开始，则A方案自动停止。"
                ></helpIcon>
              </div>
              <div class=" flex-center">
                <el-radio-group v-model="post.time_type" style="flex-shrink:0">
                  <el-radio label="1">此刻</el-radio>
                  <el-radio label="2">定时</el-radio>
                </el-radio-group>
                <el-date-picker
                  class="ml10"
                  v-if="post.time_type == 2"
                  style="flex: 1; "
                  size="medium"
                  v-model="post.s_time"
                  type="datetime"
                  placeholder="选择日期时间"
                  value-format="timestamp"
                  format="yyyy-MM-dd HH:mm"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </div>
            </el-col>
          </el-row>
        </div>
      </template>
    </div>
    <pagination2
      :option="post2"
      url="/liveChat/taskList"
      ref="childDialogBox"
      class="childDialogBox flex1"
    >
      <template v-slot:default="{ tableData }">
        <el-table
          class="absolute"
          :header-cell-style="{
            background: '#212121',
            color: '#fff',
          }"
          :row-style="{ background: '#171717', color: '#fff' }"
          :data="tableData"
        >
          <el-table-column label="发送开始时间" prop="s_time">
            <template slot-scope="{ row }">
              {{ row.s_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
            </template>
          </el-table-column>
          <el-table-column label="话术本" prop="name">
            <template slot-scope="{ row }">
              {{ row.name }}
            </template>
          </el-table-column>
          <el-table-column label="发送人来源" prop="name">
            <template slot-scope="{ row }">
              <div v-for="(i, index) in row.g_name" :key="index">{{ i }}</div>
            </template>
          </el-table-column>
          <el-table-column label="发言频率" prop="send_rate_type">
            <template slot-scope="{ row }">
              {{ row.send_rate_type | getSendRateType }}
            </template>
          </el-table-column>
          <el-table-column label="状态" prop="status">
            <template slot-scope="{ row }">
              <span :style="`color:${getProgrammeStatus(row.status).color}`">
                {{ getProgrammeStatus(row.status).statusname }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="操作" prop="">
            <template slot-scope="{ row }">
              <el-button
                type="text"
                v-if="row.status == 2"
                style="color: #fa6400"
                @click="cancelAutoSendMsg(row.id)"
              >
                停用
              </el-button>
              <el-divider
                v-if="row.status == 2"
                direction="vertical"
                class="ml10 mr10"
              ></el-divider>
              <el-button
                type="text"
                style="color: #fa6400"
                @click="removeProgramme(row.id)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>

    <!-- 从机器人库选择 -->
    <library
      v-if="libraryStatus"
      :limit="limit"
      :allowance="allowance"
      :gids="gids"
      @robotselectComplete="robotselectComplete"
      :dialogstatus.sync="libraryStatus"
    ></library>

    <!-- 从机器人分组选择 -->
    <groups
      v-if="groupsStatus"
      :dialogstatus.sync="groupsStatus"
      :limit="limit"
      :allowance="allowance"
      :gids="gids"
      @robotselectComplete="robotselectComplete"
    ></groups>
  </div>
</template>

<script>
import library from '@view/LiveBroadcastManagement/messageTemplate/details/robotSelect/library'
import groups from '@view/LiveBroadcastManagement/messageTemplate/details/robotSelect/groups'
import robotListLocalStorage from './robotListLocalStorage'

export default {
  name: 'automaticSpeech',
  components: {
    library,
    groups,
  },
  mixins: [robotListLocalStorage],
  data() {
    return {
      localStorageName: 'automaticSpeech',
      // 机器库和分组选择限制的个数
      limit: 3,
      // 选择的机器人库或者机器人分组列表
      robotList: [{ gid: '-1', name: '系统默认', status: '1', type: '1' }],
      groupsStatus: false,
      libraryStatus: false,
      toNewEdit: false,
      pickerOptions: {
        disabledDate(time) {
          //time跟页面没有关系   相当于日期数组
          return time.getTime() < Date.now() - 8.64e7 //设置选择今天以及今天之后的日期
        },
      },

      post2: {
        open_class_id: this.openClassId,
      },

      post: {
        open_class_id: this.openClassId,
        send_rate_type: '',
        msg_id: '',
        gids: [],
        id: '',
        time_type: '1',
        s_time: '',
      },

      loading: false,

      options: [],

      options2: [
        {
          id: 1,
          name: '1-3秒（极快）',
        },
        {
          id: 2,
          name: '4-8秒（快速）',
        },
        {
          id: 3,
          name: '9-20秒（正常）',
        },
        {
          id: 4,
          name: '21-60秒（慢速）',
        },
      ],
    }
  },

  computed: {
    allowance() {
      return this.robotList.length
    },

    gids() {
      return this.robotList.map(item => item.gid)
    },
  },

  filters: {
    getSendRateType(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '1-3秒（极快）'
          break
        case 2:
          text = '4-8秒（快速）'
          break
        case 3:
          text = '9-20秒（正常）'
          break
        default:
          text = '21-60秒（慢速）'
          break
      }
      return text
    },
  },

  props: {
    openClassId: [Number, String],
  },

  created() {
    this.getchatList()
  },

  methods: {
    // 删除已选标签
    handleClose(index) {
      this.robotList.splice(index, 1)
    },

    // 获取选择的库或者分组
    robotselectComplete(val) {
      this.robotList.push(...val)
    },

    // 获取话术本列表
    async getchatList() {
      const { data } = await this.$http({
        url: '/chatMessage/chatMsgList',
        data: { type: 1 },
      })
      this.options = data
    },
    // 取消自动发言
    async cancelAutoSendMsg(val) {
      await this.$confirm(
        '是否确定停用当前发言方案？确定后，正在发送中的会终止发言！',
        '停用自动发言',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
        }
      )
      this.$http({
        url: '/liveChat/removeTask',
        data: {
          id: val,
        },
        callback: () => {
          this.$refs.childDialogBox.reset()
        },
      })
    },
    // 方案详情
    loadProgrammeDetail(val) {
      this.$http({
        url: '/liveChat/getTask',
        data: {
          id: val,
        },
        callback: ({ data }) => {
          const post = {
            id: data.id,
            send_rate_type: Number(data.send_rate_type),
            s_time: data.s_time * 1000,
            msg_id: data.msg_id,
          }
          if (data.s_time === data.c_time) {
            post.time_type = '1'
          } else {
            post.time_type = '2'
            post.s_time = data.s_time * 1000
          }
          this.post = post
        },
      })
    },
    // 删除方案
    async removeProgramme(val) {
      await this.$confirm(
        '是否确定删除该方案？确定后，如正在发送中的会终止发言！',
        '删除方案',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
        }
      )
      this.$http({
        url: '/liveChat/delTask',
        data: {
          id: val,
        },
        callback: () => {
          this.$refs.childDialogBox.reset()
          this.$root.prompt({
            type: 'success',
            msg: '删除成功',
          })
        },
      })
    },

    toEdit(val) {
      this.toNewEdit = true
      // this.getliveChatList()
      this.$nextTick(() => {
        this.loadProgrammeDetail(val)
      })
    },

    tonew() {
      this.toNewEdit = true
    },

    liveChatFocus() {
      this.getchatList()
    },

    // 创建
    submit() {
      if (this.gids.length == 0) {
        this.$root.prompt('请选择发送人来源')
        return
      }
      if (this.post.msg_id == '') {
        this.$root.prompt('请选择话术本')
        return
      }

      if (this.post.send_rate_type == '') {
        this.$root.prompt('请选择发言频率')
        return
      }

      if (this.post.time_type == 2 && this.post.s_time == '') {
        this.$root.prompt('请选择发言时间')
        return
      }

      this.loading = true

      this.$http({
        url: '/liveChat/addTask',
        data: _.assign({}, this.post, {
          s_time: Math.floor(this.post.s_time / 1000),
          open_class_id: this.openClassId,
          gids: this.gids,
        }),
        callback: () => {
          this.$root.prompt({
            type: 'success',
            msg: '操作成功',
          })
          this.toNewEdit = false
          this.post = {
            open_class_id: this.openClassId,
            send_rate_type: '',
            msg_id: '',
            gids: [],
            id: '',
            time_type: '1',
            s_time: '',
          }
          // this.robotList = []
          this.$refs.childDialogBox.reset()
        },
        complete: () => {
          this.loading = false
        },
      })
    },
    // 获取方案状态
    getProgrammeStatus(status) {
      let res = {
        status,
      }
      switch (Number(status)) {
        case 1:
          res.color = '#fff'
          res.statusname = '未开始'
          break

        case 2:
          res.color = '#0AA29B'
          res.statusname = '发送中'
          break

        case 3:
          res.color = '#666666'
          res.statusname = '已结束'
          break

        case 4:
          res.statusname = '已失效'
          res.color = '#666666'
          break
      }
      return res
    },
    tonewWindow() {
      let url = this.$router.resolve({
        path: '/messageTemplate',
        query: { index: 2 },
      })
      this.$store.dispatch('open', url.href)
    },

    // 获取模板列表
    // async getliveChatList() {
    //   try {
    //     const { data } = await this.$http({
    //       url: '/liveChat/liveChatList',
    //       data: {},
    //     })
    //     this.options = data
    //   } catch (e) {
    //     console.log(e)
    //   }
    // },

    reset() {
      this.post = {
        open_class_id: this.openClassId,
        id: '',
        send_rate_type: 1,
        time_type: '1',
        s_time: '',
        msg_id: '',
      }
      this.toNewEdit = false
    },
  },
}
</script>

<style lang="scss" scoped>
.automaticSpeech {
  flex: 1;
  position: relative;
  padding: 20px 20px 0;
  .tips {
    font-size: 13px;
    color: #ffffff;
  }

  ::v-deep .el-tag {
    background-color: #323232;
    border-color: #323232;
    height: 25px;
    line-height: 25px;
    font-size: 12px;
    color: #fff;
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;

    .el-tag__close {
      color: #fff;
      &:hover {
        background-color: #323232;
      }
    }
    // .el-icon-close {
    //   position: absolute;
    //   top: 5px;
    //   right: 2px !important;
    // }
  }

  .el-tag--light {
    margin-right: 10px;
  }

  .heard {
    ::v-deep .el-button--info {
      background-color: #fa6400;
      border-color: #fa6400;
    }
    ::v-deep .btns {
      .el-button {
        width: 76px;
      }
    }
    .sp1 {
      font-size: 12px;
      color: #6a6a6a;
      margin-left: 20px;
    }
    .speechProgrammecontain {
      .sp2 {
        font-size: 14px;
        color: #ffffff;
      }
      .el-row {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
      }
      ::v-deep .el-row {
        width: 100%;
        display: flex;
        align-items: center;
        .el-input__inner {
          background-color: #333333;
          border-color: #171717;
          color: #fff;
        }
        .grid-content {
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
  ::v-deep .childDialogBox {
    position: relative;
    .el-divider {
      background-color: #fa6400;
    }

    .pagination {
      padding-top: 20px;
      .el-pager li.active + li,
      .el-pagination button {
        background-color: #343434 !important;
        color: #fff;
      }
      .el-pager li {
        background-color: #343434;
        color: #fff;
      }
    }
    .el-pagination span {
      color: #ffffff;
    }

    .el-pager li.active {
      color: #fff;
      background-color: #fa6400;
    }

    .el-input .el-input__inner {
      background-color: #3e3e3e;
      border-color: #3e3e3e;
      color: #fff;
    }
    .el-table {
      overflow-y: auto;
      @extend %liveRoomScroll;
    }
    .el-table td {
      border-color: #313131;
    }
    .el-table__body-wrapper {
      background-color: #171717;
    }
    .el-table--fit {
      background-color: #171717;
    }

    .el-table th.is-leaf {
      border-color: #3e3e3e;
    }
    .el-table--enable-row-hover .el-table__body tr:hover > td {
      background-color: #3e3e3e !important;
    }
    .el-table::before {
      background-color: #313131;
    }
    // .el-table th.is-leaf {
    //   border-color: #313131;
    // }
  }
}
</style>
<style lang="scss">
.automaticSpeech_popper {
  width: 122px !important;
  min-width: auto;
  .popover_item {
    .item {
      text-align: center;
      font-size: 14px;
      color: #333333;
      line-height: 40px;
      cursor: pointer;
      &:hover {
        color: #0aa29b;
      }
    }
  }
}
.robot-local-text {
  font-size: 12px;
  color: #ff3535;
  margin: 5px 0 0 20px;
}
</style>
