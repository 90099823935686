<template>
  <div id="OpenClass_openClassLive" :class="{ minWidthHeight: !isWindowsApp }">
    <template v-if="userInfo.sdkAppID && !consoleInfostatus">
      <div :class="{ title: true, leftCla: true }" v-if="openAndClose">
        <headers></headers>
      </div>
      <div
        class="bottom_contain"
        :style="isWindowsApp ? '' : 'padding-top: 30px;'"
      >
        <div
          :style="isWindowsApp ? 'width:100%; ' : ''"
          :class="{
            center: true,
            allOpen: openAndClose && !imErrorVal,
            leftFalse: openAndClose,
            h100: !openAndClose,
          }"
        >
          <centermain
            @ImError="ImError"
            :fixedHeight="fixedHeight"
            ref="centermain"
          ></centermain>
        </div>
        <div class="liveData-box" v-if="!isWindowsApp">
          <liveData></liveData>
        </div>
        <div
          class="right"
          :class="{ w0: !rightOpen || isWindowsApp }"
          ref="right"
        >
          <div class="cp" v-if="!isWindowsApp" @click="toCloseOpen()">
            <img
              title="收起成员列表"
              class="isclose"
              v-if="rightOpen"
              src="~@ass/img/1.4.5.9/icon_kzt_sq.svg"
              alt=""
            />
            <img
              title="展开成员列表"
              class="isopen"
              v-else
              src="~@ass/img/1.4.5.9/icon_kzt_zk.svg"
              alt=""
            />
          </div>
          <rightcomponent
            :rightOpen="rightOpen"
            :ImError="imErrorVal"
            @delfrind="delfrind"
            @otherfnContainsDel="otherfnContainsDel"
            @otherfnPrivateletter="otherfnPrivateletter"
            @otherfnQuote="otherfnQuote2"
            @closeHistory="closeHistory"
          ></rightcomponent>
        </div>
      </div>
    </template>
    <el-dialog
      title="温馨提示"
      :visible="dialogVisible"
      width="420px"
      @close="closetip"
    >
      <span>
        {{
          dialogVisible ||
            '您不是该直播间的上课老师或助教老师，请检查当前登录的账号！'
        }}
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closetip" size="small">
          管理后台
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapMutations, mapActions } = createNamespacedHelpers(
  'OpenClassopenClassLive'
)
import centermain from './components/centercomponents/main'
import rightcomponent from './components/rightcomponents/main'
import liveData from './components/liveDataComponents/main'
import headers from './components/header'
import pageOverflow from '@/mixin/pageOverflow'
// import courseList from './components/courseList'

export default {
  name: 'OpenClass_openClassLive',
  components: {
    centermain,
    // courseList,
    liveData,
    headers,
    rightcomponent,
  },

  mixins: [pageOverflow],

  data() {
    return {
      imErrorVal: false,
      fixedHeight: window.innerWidth * 0.5625 + this.$root.fontSize * 1.8,
      openLeftImage: require('@ass/img/liveChar/icon_zkmb.png'),
    }
  },

  created() {
    if (sessionStorage.getItem('openAndClose') == 1) {
      this.setOpenAndClose()
    }
    this.consoleInfo(this.$route.params.id)
    this.getliveSet(this.$route.params.id)
    this.changeOpenClassId(this.$route.params.id)
    this.resetData()
    document.title = '叮叮开课直播控制台'
    this.getInnerHeightWidth()
    window.onresize = this.getInnerHeightWidth
  },

  computed: {
    ...mapState([
      'userInfo',
      'openInfo',
      'openClassId',
      'openAndClose',
      'innerHeight',
      'innerWidth',
      'consoleInfostatus',
      'rightOpen',
    ]),

    isWindowsApp() {
      return this.$store.state.isWindowsApp
    },

    dialogVisible() {
      return this.consoleInfostatus
    },
  },

  mounted() {
    // document.body.addEventListener('mouseleave', this.mouseleavePage)
    // document.body.addEventListener('mouseenter', this.mouseenterPage)
  },

  methods: {
    ...mapMutations([
      'changerightOpen',
      'setInnerHeightWidth',
      'changeOpenClassId',
      'resetData',
      'mousePageEvent',
      'setOpenAndClose',
    ]),
    ...mapActions(['consoleInfo', 'getliveSet']),

    toCloseOpen() {
      const val = !this.rightOpen
      this.changerightOpen(val)
    },

    // 鼠标移出事件
    mouseleavePage() {
      this.mouseleaveTmer = setTimeout(() => {
        this.mousePageEvent(true)
      }, 5000)
    },

    // 鼠标移入事件
    mouseenterPage() {
      clearTimeout(this.mouseleaveTmer)
      this.mousePageEvent(false)
    },

    closetip() {
      this.dialogVisible = false
      this.$router.replace('/')
    },

    delfrind(userID) {
      this.$refs.centermain.delFriend(userID)
    },

    otherfnQuote2(val) {
      this.$refs.centermain.otherfnQuote(val, true)
    },

    otherfnPrivateletter(val) {
      this.$refs.centermain.otherfnPrivateletter(val)
    },
    closeHistory(){
      this.$refs.centermain.closeHistory()
    },

    otherfnContainsDel(val) {
      this.$refs.centermain.delchartroomMsg(val)
    },

    ImError(val) {
      this.imErrorVal = val
    },
    getInnerHeightWidth() {
      this.setInnerHeightWidth({
        innerHeight: window.innerHeight,
        innerWidth: window.innerWidth,
      })
    },
  },

  beforeDestroy() {
    // this.$refs.centermain.imOnError()
    // this.$refs.centermain.destory()
    // sessionStorage.removeItem('openAndClose')
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 20px !important;
}

#OpenClass_openClassLive {
  background-color: #111111;
  overflow: hidden;
  height: 100vh;
  position: relative;
  &.minWidthHeight {
    // min-width: 1366px;
  }

  .w0 {
    width: 0px !important;
    min-width: auto !important;
    flex: 0 !important;
    // transition: 0.2s ease-in;
  }
  .isclose {
    z-index: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }

  .isopen {
    z-index: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -14px;
  }
  .bottom_contain {
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    .left {
      width: 300px;
      height: 100%;
    }
    .center {
      width: 340px;
      height: 100%;
    }
    .liveData-box {
      flex: 1;
      height: 100%;
      padding: 10px;
      // min-width: 706px;
      box-sizing: border-box;
      background: inherit;
    }
    .right {
      width: 300px;
      height: 100%;
      flex-shrink: 0;
      position: relative;
    }
  }
  .title {
    width: 100%;
    position: absolute;
    height: 30px;
    right: 0;
    top: 0;
    background-color: #262626;
  }
}
.loading-contain {
  flex: 1;
  background-color: #111111;
}
.open-left {
  position: absolute;
}
.leftCla {
  width: 100% !important;
}
// .allOpen {
//   width: calc(100% - 140px) !important;
// }
.leftFalse {
  // width: 340px !important;
}
.h100 {
  height: 100% !important;
}
</style>
