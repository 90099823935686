<template>
  <div class="tagselect">
    <!-- 用不着 -->
    <!-- :popper-class="nothing ? 'tagselect-set-popover' : ''" -->
    <el-popover
      ref="popover"
      :visible-arrow="false"
      width="436"
      height="340"
      trigger="click"
      @hide="close"
      :placement="nothing ? 'bottom' : 'right'"
    >
      <div style="margin-left: 20px; margin-bottom: 12px">
        设置标签
        <span
          v-if="isLive"
          style="font-size: 12px; color: #ff3535; margin-left: 20px"
        >
          新设置的标签在学员重新进入并发言时生效
        </span>
      </div>
      <div style="margin-left: 10px">
        <el-tag
          style="margin-left: 10px; margin-bottom: 10px"
          :type="i.type"
          size="medium"
          v-for="(i, index) in list"
          :key="index"
          effect="plain"
        >
          <div v-if="i.type == 'warning' && noCancel">{{ i.tag_name }}</div>
          <div v-else @click.stop.prevent="display(i, index)">
            {{ i.tag_name }}
          </div>
        </el-tag>
      </div>
      <div style="margin-left: 20px; margin-top: 10px; cursor: pointer">
        <el-tag type="success" size="medium" @click.stop="newTag()">
          新建标签
        </el-tag>
        <el-tag
          type="success"
          class="ml20"
          style="width: 68px; text-align: center"
          size="medium"
          @click.stop="closepop"
        >
          关闭
        </el-tag>
      </div>
      <template v-if="isCustom" slot="reference">
        <div @click="getTag">
          <slot name="reference" :tag="tagList"></slot>
        </div>
      </template>
      <template v-else-if="nothing" slot="reference">
        <div @click="getTag">
          <slot name="opencouse"></slot>
        </div>
      </template>
      <template v-else>
        <div class="vm" slot="reference">
          <el-button
            style="color: rgba(243, 152, 2, 1)"
            type="text"
            @click="getTag"
            v-for="item in tagList ? tagList.split(',') : []"
            :key="item"
          >
            {{ item }}
          </el-button>
          <el-button type="text" slot="reference" @click="getTag">
            设置标签
          </el-button>
        </div>
      </template>
    </el-popover>

    <!-- 新建标签弹窗 -->
    <el-dialog
      title="新建标签"
      :visible.sync="newTags"
      width="436px"
      height="340px"
      custom-class="new-tag"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      append-to-body
    >
      <div style="margin: 20px 20px; margin-top: 0px">
        <el-input
          v-model.trim="inputTag"
          maxlength="8"
          show-word-limit
          placeholder="输入标签"
        ></el-input>
      </div>
      <div slot="footer" class="dialog-footer" style="margin: 0 auto">
        <el-button @click="newTags = false">取 消</el-button>
        <el-button type="primary" @click="UpdateUserTag">完 成</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
//说明
// 需要传uerlist列表，也就是换取全部列表的数据，用来展示该学员的标签

export default {
  naem: 'tagselect',
  data() {
    return {
      list: [],
      inputTag: '',
      newTags: false,

      tagList: this.tag || '',
    }
  },

  props: {
    tag: {
      type: String,
      default: '',
    },
    uids: [Array, String],
    // 不能取消
    noCancel: Boolean,
    isLive: Boolean,
    // 自定义样式
    isCustom: Boolean,
    // 是否多选
    multiple: Boolean,
    //不展示内容
    nothing: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.getTag()
  },

  watch: {
    tag(value) {
      this.tagList = value || ''
    },
  },

  methods: {
    closepop() {
      this.$refs.popover.doClose()
    },
    close() {
      if (this.noCancel) {
        this.tagList = ''
      }
      this.$emit('reset')
    },
    newTag() {
      //新建标签
      const that = this
      that.newTags = true
      console.log(that.newTags)
    },

    UpdateUserTag() {
      if (!this.inputTag) {
        this.newTags = false
      } else {
        this.$http({
          url: 'UserTag/updateUserTag',
          data: {
            tag_name: this.inputTag,
          },
          callback: ({ code }) => {
            if (code == 200) {
              this.newTags = false
              this.inputTag = ''
              this.getTag()
            }
          },
        })
      }
    },

    getTag() {
      this.$http({
        url: '/UserTag/getJigouTag',
        isMultipleSelf: 'initialSelf',
        callback: ({ data, code }) => {
          let arry = []
          let tag = []
          tag = this.tagList.split(',')
          if (code == 200) {
            data.forEach((item) => {
              arry.push(
                Object.assign({}, item, {
                  type: tag.indexOf(item.tag_name) > -1 ? 'warning' : 'info',
                })
              )
              this.list = arry
            })
          }
        },
      })
    },

    display(item, i) {
      //学员设置标签
      const that = this
      if (that.list[i].type == 'warning') {
        that.list[i].type = 'info'
      } else {
        that.list[i].type = 'warning'
      }

      // if (
      //   that.list.filter(item => item.type == 'warning').length >
      //   (this.$store.state.tagMaxCount || 999)
      // ) {
      //   that.$root.prompt(
      //     `设置失败,最多只能设置${this.$store.state.tagMaxCount || 999}个`
      //   )
      //   that.list[i].type = 'info'
      //   return
      // }
      // 每一次点击设置标签
      let arr = ''
      let tagNames = ''
      that.list.forEach((e) => {
        if (e.type == 'warning') {
          arr += `,${e.tag_id}`
          tagNames += `,${e.tag_name}`
        }
      })

      arr = arr.replace(/^,|,$/g, '')
      tagNames = tagNames.replace(/^,|,$/g, '')

      // console.log(arr)
      // console.log(
      //   tagNames,
      //   that.list.filter(item => item.type == 'warning')
      // )
      if (this.multiple) {
        that.$http({
          url: '/userTag/setAllUserTag',
          data: {
            uids: this.uids,
            tag_ids: item.tag_id,
          },
          callback({ code }) {
            if (code == 200) {
              that.tagList = tagNames
            }
          },
        })
      } else {
        that.$http({
          name: 'setUserTag',
          url: 'UserTag/setUserTag',
          data: {
            uid: this.uids,
            tag_ids: arr,
          },
          callback({ code }) {
            if (code == 200) {
              that.tagList = tagNames
              if (that.nothing) {
                that.$emit('tagChange')
              }
            }
          },
        })
      }
    },
  },
}
</script>
<style lang="less" >
.tagselect-set-popover {
  top: 57% !important;
}
</style>
<style lang="less" scoped>
/deep/ .el-tag.el-tag--success {
  background: rgba(10, 162, 155, 1);
  color: #fff;
  border: rgba(10, 162, 155, 1);
  padding-top: 1px;
}
/deep/ .el-tag--plain.el-tag--warning {
  background: rgba(10, 162, 155, 1);
  color: #fff;
  border-color: rgba(10, 162, 155, 1);
}
/deep/ .el-button {
  width: auto !important;
}
/deep/ .el-dialog__body {
  padding: 10px;
}
.el-button--text {
  padding: 0;
}
</style>
