<template>
  <div id="dataSelect">
    <div class="search">
      <el-input
        v-model="DialogBoxPost.search"
        placeholder="输入名称搜索"
        style="width: 215px"
        size="medium"
        class="ml20"
      ></el-input>
    </div>
    <pagination2
      :option="DialogBoxPost"
      url="/HomeTemplate/graphicList"
      ref="childDialogBox"
      :allFn="true"
    >
      <template v-slot:default="{ tableData }">
        <div class="pagination2Box">
          <el-table
            :row-key="getRowkey"
            @selection-change="DialogBoxSelectionChange"
            @row-click="handleRowClick"
            :data="tableData"
            ref="dialogTable"
            max-height:200
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333',
            }"
          >
            <el-table-column label width="50" v-if="isSingleSelect">
              <template slot-scope="scope">
                <el-radio
                  :disabled="'is_show' in scope.row && scope.row.status == 2"
                  :label="scope.row.graphic_id"
                  v-model="radioId"
                >
                  &nbsp;
                </el-radio>
              </template>
            </el-table-column>

            <el-table-column
              v-else
              reserve-selection
              type="selection"
              :selectable="selectionEvent"
              width="50"
            ></el-table-column>
            <el-table-column label="图文" min-width="280px">
              <template slot-scope="{ row }">
                <div class="contain">
                  <div class="tu">
                    <img :src="row.cover_img" alt="" />
                  </div>
                  <div class="right">
                    <div class="name" :title="row.name">
                      {{ row.name }}
                    </div>
                    <div class="type" v-if="'status' in row">
                      <!-- 1已上架  2已下架   -->
                      <img
                        v-if="row.status == 1"
                        src="~@ass/img/1.3.9.4/icon_dg.png"
                        alt=""
                      />
                      <img v-else src="~@ass/img/1.3.9.4/icon_cw.png" alt="" />

                      <span v-if="row.status == 1">
                        已上架
                      </span>
                      <span v-else>已下架</span>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="售价">
              <template slot-scope="{ row }">
                <span class="redcolor" v-if="row.price_type == 1">
                  ￥{{ row.price }}
                </span>
                <span class="redcolor" v-else-if="row.price_type == 3">
                  密码
                </span>
                <span class="redcolor" v-else>免费</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
    </pagination2>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listSave: [],
      radioId: '',
      DialogBoxPost: {
        is_naire: this.isnaire ? (this.template == 5 ? 1 : 2) : undefined,
        naire_id: this.isnaire ? this.$route.query.naire_id : undefined,
        p_group_id: this.p_group_id,
        seckill_id: this.seckill_id,
        is_seckill: this.is_seckill,
        installment_id: this.installment_id,
        goodlistType: this.goodlistType,
        search: '',
        graphic_ids: this.ids,
        goodList: this.goodList,
      },
    }
  },

  props: {
    // 秒杀
    is_seckill: {
      type: Boolean,
      default: undefined,
    },
    // 秒杀--一个课程或内容只能被秒杀选择一次
    seckill_id: {
      type: String,
      default: undefined,
    },
    // 信息采集
    isnaire: {
      type: Boolean,
      default: false,
    },

    //true是单选 false:多选
    isSingleSelect: {
      type: Boolean,
      default: false,
    },

    // 免息券选择
    installment_id: {
      type: String,
      default: undefined,
    },

    // 推广员 添加商品
    p_group_id: {
      type: String,
      default: undefined,
    },

    // 是否需要过滤免费课成
    goodlistType: {
      type: Boolean,
      default: false,
    },

    template: {
      type: Number,
    },

    goodList: [Array, Object, String],

    ids: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    DialogBoxSelectionChange(val) {
      //点击选择暂存选项数组
      this.listSave = val
      this.$emit('listSave', val)
    },

    getRowkey(row) {
      return row.graphic_id
    },

    handleRowClick(row) {
      clearTimeout(this.rowClicTimer)
      this.rowClicTimer = setTimeout(() => {
        if (row.status == 2) {
          return
        }
        this.radioId = row.graphic_id
        this.$emit('singleselectcontent', row)
        this.$refs.dialogTable.toggleRowSelection(row)
      }, 100)
    },

    // 判断课程是否可以被选择（下架 不展示到网校）
    selectionEvent(row) {
      return row.status == 1
    },
  },
}
</script>

<style lang="scss" scoped>
#dataSelect {
  height: 396px;
  margin-bottom: 10px;
  .search {
    display: flex;
    justify-content: flex-end;
  }
  .contain {
    display: flex;
    align-items: center;

    .tu {
      img {
        width: 60px;
        height: 34px;
      }
    }
    .right {
      width: calc(100% - 70px);
      margin-left: 10px;
      .name {
        font-size: 13px;
        color: #333333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .teacher_count {
        font-size: 12px;
        color: #666666;
      }
      .type {
        align-items: center;
        display: flex;
        font-size: 12px;
        color: #666666;
        img {
          width: 8px;
          height: 7px;
          margin-right: 6px;
        }
        span {
          font-size: 12px;
          color: #666666;
        }
      }
    }
  }

  .pagination2Box {
    margin-top: 20px;
    overflow: auto;
    height: 290px;
    @extend %scroll;
  }
}
</style>
