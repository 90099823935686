<template>
  <div id="centermain">
    <chatcontent
      :CONV_C2C="CONV_C2C"
      :openManagerMsg="openManagerMsg"
      :gifts="gifts"
      :loading="loading"
      :loading2="loading2"
      :ImError="ImError"
      :flowers="flowers"
      :memberEnterMsg="memberEnterMsg"
      :messageList="messageList"
      :privateMsg="privateMsg"
      :newMsgCount.sync="newMsgCount"
      :newMsgCountPrivate.sync="newMsgCountPrivate"
      ref="chatcontent"
      :autoScrollBotom.sync="autoScrollBotom"
      :autoScrollBotomPrivate.sync="autoScrollBotomPrivate"
      @relogin="login"
      @otherfnQuote="otherfnQuote"
      @otherfnPrivateletter="otherfnPrivateletter"
      @delchartroomMsg="delchartroomMsg"
      @isCheckList="isCheckList"
      @updateMessageItem="updateMessageItem"
      @sendmessage="sendmessage"
      :isC2Cmsg="isC2Cmsg"
      :otherfnPrivateletterval="otherfnPrivateletterval"

      :imageProgress="imageProgress"
      :imagecomplete="imagecomplete"

      @imgStart2="imagecomplete2 = false"
      @percentage2="imageProgress2 = arguments[0]"
      @imgCompress2="imageProgress2 = 0"
      :imageProgress2="imageProgress2"
      :imagecomplete2="imagecomplete2"
    ></chatcontent>

    <message
      ref="message"
      v-if="!ImError"
      @delsCancel="delsCancel"
      @multiSelectDelete="multiSelectDelete"
      @allDelete="allDelete"
      @isOpenManagerMsg="isOpenManagerMsg"
      @singleselectcontentval="singleselectcontentval"
      @sendmessage="sendmessage"
      @sendFlowers="sendFlowers"
      @imgStart="imagecomplete = false"
      @percentage="imageProgress = arguments[0]"
      @imgCompress="imageProgress = 0"
      :clientSequenceIds="clientSequenceIds"
      :openInfo="openInfo"
      :imageProgress="imageProgress"
      :imagecomplete="imagecomplete"
      :imagecomplete2="imagecomplete2"
      :isC2Cmsg="isC2Cmsg"
      :isPrivate="false"
    ></message>

    <!-- consoleInfostatus -->
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapMutations, mapActions } = createNamespacedHelpers(
  'OpenClassopenClassLive'
)
import TIM from 'tim-js-sdk/tim-js-friendship.js'
import TIMUploadPlugin from 'tim-upload-plugin' // 使用前请将 IM SDK 升级到v2.9.2或更高版本
import message from './message'
import chatcontent from './chatcontent'
import emojiSource from './emoji'
import {sensitiveWordsShield} from '../../../../utils/index'
import {checkDelList,delFriend} from '../../uilts/im'

/**
 *  在线成员显示逻辑
 * 1.获取学生端发送的数据，收集起来，并加上收集时的时间（本地时间）
 * 2.写一个定时器，遍历收集的数据，用收集时的时间对比，如果当前时
 * 间减去收集时的时间大于1分钟，表示这个人为离线状态，修改在线状态，
 * 跟学员列表合并
 * 3.
 * ...
 *
 */

export default {
  name: 'centermain',
  components: {
    chatcontent,
    message,
  },

  props: {
    fixedHeight: Number,
  },

  watch: {
    otherfnPrivateletterselectVal: {
      handler(){
        this.privateMsg = []
        if (this.isOnready) {
          this.loading2 = true
          // 获取私聊历史消息
          this.getHistory([],null,null,true)
        }
      },
    },

    questionArr(val) {
      this.changeQuestionList(val)
    },
  },

  computed: {
    ...mapState([
      'tim',
      'stopDisplayMsg',
      'sensitiveWords',
      'userInfo',
      'liveInfo',
      'controlInfo',
      'openInfo',
      'members',
      'membersMenu',
      'openClassId',
      'openAndClose',
      'newPriMsgs',
      'newQAMsgs',
      'friendList',
      'otherfnPrivateletterselectVal',
      'consoleInfostatus',
      'rightActiveName',
      'rightRadio4',
      'allMembers',
    ]),

    // 判断是否是私聊
    isHasPrivateVal() {
      return Object.keys(this.otherfnPrivateletterselectVal).length > 0
    },

    imInfo() {
      return {
        SDKAppID: Number(this.userInfo.sdkAppID),
        userID: this.userInfo.userID,
        userSig: this.userInfo.userSig,
        // 可以被历史消息拉取到的 包含私聊
        roomId: this.liveInfo.chat_room_id,
        // 一些隐性的自定义历史消息 包含私聊
        roomId2: this.liveInfo.chat_room_id2,
        // 图片和推送的内容、问答
        roomId3: this.liveInfo.chat_room_id3,
        type: TIM.TYPES.GRP_MEETING,
      }
    },
  },

  data() {
    return {
      newotherfnPrivateletterval: {},

      isaddFriend: false,
      // 表示这最新接收到的消息是私聊消息
      isC2Cmsg: false,

      // 获取学生问答的数据
      questionArr: [],

      loading2: true,

      isOnready: false,

      // 被选中数据的id集合
      clientSequenceIds: [],

      // 是否开启消息管理 true没有开启 false开启
      openManagerMsg: true,

      imagecomplete: false,
      imagecomplete2: false,

      //图片上传进度
      imageProgress: 0,

      //图片上传进度
      imageProgress2: 0,

      imgvalue: '',
      // 图片url地址
      imgurl:'',
      msg: '',

      // 最大消息展示数量
      maxMessageNumber: 90,
      newMsgCount: 0,
      newMsgCountPrivate: 0,

      privateMsg: [],

      // 消息列表
      messageList: [],
      // 消息队列
      msgQueue: [],
      msgQueueTimer: null,

      // 消息列表自动滚动到底部
      autoScrollBotom: true,
      autoScrollBotomPrivate: true,

      // 消息时间间隔
      msgTimeTnterval: 300,

      // 发送心跳包消息时间间隔
      msgHeartbeat: 15000,

      loading: true,
      
      // 发送消息输入框
      showSendMsgContain: false,

      // 表情
      showEmoji: false,

      // 礼物 打赏
      showGift: false,

      // 设置
      showSetting: false,

      // 鲜花列表
      flowers: [],

      memberEnterMsg: [],
      // 礼物列表
      gifts: [],

      // 送花间隔
      flowersTime: 3000,
      // 是否可以点击送花按钮
      flowersDisable: false,

      ImError: false,

      deBug: true,

      CONV_C2C: false,

      // 点击聊天消息 私聊
      otherfnPrivateletterval: {},
      // 群发队列
      allMemberQueue:[],
      allMemberTimer:null,

      // 好友列表是否第一次添加
      isFirstAddFriend:false,
      isAddFriendNums:false,
    }
  },

  created() {
    // 判断是否已监听
    let isBindEvent = false
    // 如果存在Im实例 就重复使用
    if (this.tim) {
      this.joinGroup()
      // 历史消息
      this.getHistory([],null,null,false)
      // 获取第三个群的历史消息
      this.pickQuestionlist()
      // 绑定监听事件
      if(isBindEvent) return
      isBindEvent = true
      this.bindEvent()
    } else {
      if(isBindEvent) return
      isBindEvent = true
      // 初始化聊天室
      this.init()
    }
    this.$root.$on('getFlist',this.getFlist)
  },

  filters: {
    getCourseType(val) {
      let text
      switch (Number(val)) {
        case 1:
          text = '直播课'
          break
        case 2:
          text = '小班课'
          break
        case 3:
          text = '录播课'
          break
        default:
          text = '系列课'
          break
      }
      return text
    },

    getInstallmentTypeText(type) {
      let text
      switch (Number(type)) {
        case 1:
          text = 3
          break
        case 2:
          text = 6
          break
        case 3:
          text = 12
          break
      }
      return text
    },
  },

  beforeDestroy() {
    this.offEvent()
  },

  methods: {
    ...mapActions(['consoleInfo']),
    ...mapMutations([
      'setMemberList',
      'setCustomMessage',
      'setStopDisplayMsg',
      'setFlower',
      'setMemberOnline',
      'setTim',
      'setCurrMember',
      'resetData',
      'qiandaoDialog',
      'chageFriednlist',
      'changeotherfnPrivateletterselectVal',
      'addFriendlist',
      'chageQuoteval',
      'changeQuestionList',
      'newtipChange',
      'newPriMsgsChange',
      'newQAMsgsChange',
      'changerightQuote',
      'hadstudentRtcJoin',
      'setallMembers',
    ]),

    // 取消多选的内容
    delsCancel() {
      // 通知message取消删除，重置数据
      this.clientSequenceIds = []
      this.questionSeq = []
      // 通知chatcontent重置数据
      this.$refs.chatcontent.delsCancel()
      this.$nextTick(() => {
        this.queueStart()
      })
    },

    // 多选框选中的数据
    isCheckList(val) {
      this.clientSequenceIds = val.map(item => item.clientSequence)
      this.questionSeq = val
        .filter(item => !!item.questionSequence)
        .map(item => item.questionSequence)
    },

    // 是否开启消息管理
    isOpenManagerMsg(val) {
      this.openManagerMsg = val
    },

    // 多选删除触发事件
    multiSelectDelete() {
      this.delchartroomMsg(this.clientSequenceIds)
    },

    // 全部选择
    allDelete() {
      this.$refs.chatcontent.allCheck()
    },

    // 修改源消息数据结构
    updateMessageItem(val,type) {
      const index = this.messageList.findIndex(
        item => item.clientSequence === val.clientSequence
      )
      if (index > -1) {
        this.messageList.splice(index, 1, val)
      }
      if(type==2){
        this.sendCustomMsg({
          data: 'updateMessage', // 用于标识该消息是骰子类型消息
          description: JSON.stringify(val), // 获取骰子点数
          extension: '',
          noParse: true,
          roomId: this.imInfo.roomId,
        })
      }
    },

    // 删除聊天室内容
    delchartroomMsg(item) {
      const data = {
        open_class_id: this.openClassId,
      }
      // 如果是数组 说明是历史消息多选删除
      if (Array.isArray(item)) {
        data.msgSeq = {
          1: item,
          2: this.questionSeq,
        }
      } else if (this.imInfo.roomId === item.to) {
        // 说明是历史消息的单个删除
        data.msgSeq = {
          1: [item.clientSequence],
          2: item.questionSequence ? [item.questionSequence] : [],
        }
      } else {
        // 说明是提问列表的单个删除
        const msgQuestionItem = this.messageList.find(
          v => v.questionSequence == item.clientSequence
        )
        data.msgSeq = {
          2: [item.clientSequence],
          1: msgQuestionItem ? [msgQuestionItem.clientSequence] : [],
        }
      }

      const msg =
        item instanceof Array
          ? '是否确定删除已选聊天内容？删除后不可恢复！'
          : '是否确定删除该聊天内容？删除后不可恢复！'

      this.$confirm(msg, '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        customClass: 'OpenClass_main_openClassLive_confirmcustomClass',
      })
        .then(() => {
          this.$http({
            url: '/console/delMsg',
            data: data,
            callback: () => {
              this.clientSequenceIds = []
              this.questionSeq = []
              if(Array.isArray(item)){
                item.forEach(v=>this.$refs.chatcontent.delGroupMsg({clientSequence:v}))
              } else {
                this.$refs.chatcontent.delGroupMsg(item)
              }
              this.$root.prompt({
                type: 'success',
                msg: '操作成功',
              })
            },
          })
          

        })
        .catch(() => {})
    },

    init() {
      // 创建 SDK 实例，TIM.create() 方法对于同一个 SDKAppID 只会返回同一份实例
      const options = {
        SDKAppID: this.imInfo.SDKAppID, // 接入时需要将0替换为您的即时通信应用的 SDKAppID
      }
      this.setTim(TIM.create(options)) // SDK 实例通常用 tim 表示
      // 设置 SDK 日志输出级别，详细分级请参见 setLogLevel 接口的说明
      this.tim.setLogLevel(1) // 普通级别，日志量较多，接入时建议使用
      // this.tim.setLogLevel(1); // release级别，SDK 输出关键信息，生产环境时建议使用

      // 注册腾讯云即时通信 IM 上传插件，即时通信 IM SDK 发送图片、语音、视频、文件等消息需要使用上传插件，将文件上传到腾讯云对象存储
      this.tim.registerPlugin({ 'tim-upload-plugin': TIMUploadPlugin })
      // 绑定监听事件
      this.bindEvent()
      // 开始登录
      this.login()

      // setInterval(() => {
      //   this.setCustomMessage({
      //     text: `${Math.random()}进入了直播间`,
      //     id: Math.random(),
      //     msgType: 'memberEnter',
      //     time: Date.now() / 1000,
      //   })
      // }, 1000)
    },

    destory() {
      this.offEvent()
      this.tim.logout()
      this.setTim(null)
      clearTimeout(this.msgQueueTimer)
    },

    onReady(event) {
      // 收到离线消息和会话列表同步完毕通知，接入侧可以调用 sendMessage 等需要鉴权的接口
      // event.name - TIM.EVENT.SDK_READY
      this.deBug && console.log('ready', event)
      this.ImError = false
      this.$emit('ImError', false)
      // 底部有新消息 弹窗提示
      this.bindMessageOnScroll()
      // 发送自己进入房间的消息 以及维护在线列表
      setTimeout(() => {
        // 历史消息
        this.getHistory([],null,null,false)
        this.isOnready = true
        this.getConversationList()

        // 获取第三个群的历史消息
        this.pickQuestionlist()
        // this.removeNeverSendFriend()
      }, 300)
    },
    removeNeverSendFriend(){
      let promise = this.tim.getFriendList();
      promise.then((imResponse)=> {
        const friendList = imResponse.data; // 好友列表
        this.deBug &&console.log('好友列表',friendList);
        checkDelList(this.tim,friendList).subscribe({
          next:(v)=>{
            delFriend(this.tim,v).then((vv)=>{
              this.deBug &&console.log('删除好友',vv);
            })
          }
        })
      })
    },
    // rightQuote提问中的引用
    otherfnQuote(val, rightQuote) {
      if (rightQuote) {
        this.changeotherfnPrivateletterselectVal({})
        this.changerightQuote(rightQuote)
      } else {
        this.changerightQuote(false)
      }
      this.chageQuoteval(val)
      this.$refs.message.focusInput()
    },

    otherfnPrivateletter(val) {
      // 只要私信，就要清空引用的内容
      this.changerightQuote(false)
      this.otherfnPrivateletterval = val
      // 添加好友
      const from = val.from || val.userID
      this.deBug&&console.log('私信人员数据',val,(this.friendList.findIndex(item => item.userID == from) == -1));
      // if(val.userID==this.userInfo.userID) return
      if (this.friendList.findIndex(item => item.userID == from) == -1) {
        this.isaddFriend = true
        this.addFriend(val, false)
      } else {
        this.changeotherfnPrivateletterselectVal(val)
      }
    },

    addFriend(val, fromStudent) {
      this.deBug&&console.log('添加好友信息',val);
      this.fromStudent = fromStudent
      // val.userID 点击学员详情中的私信
      this.deBug&&console.log('如果是自己就不添加好友',(val.userID==this.userInfo.userID));
      // if(val.userID==this.userInfo.userID) return
      let promise = this.tim.addFriend({
        to: val.from || val.userID,
        source: 'AddSource_Type_Web',
        // type: TIM.TYPES.SNS_ADD_TYPE_SINGLE,
        type: TIM.TYPES.SNS_DELETE_TYPE_BOTH,
        remark: val.inviter_name,
      })
      promise.then((res)=>{
        console.log(res,this.friendList);
        this.getConversationList()
      })
        .catch(imError => {
          console.warn('addFriend error9999:', JSON.stringify(imError)) // 添加好友失败的相关信息
          // 已经是好友关系 就直接选中
          if (imError.code == 2701 && !fromStudent) {
            this.changeotherfnPrivateletterselectVal(val)
          } else {
            this.$root.prompt('添加私信失败，请检查网络或重试')
          }
        })
        .finally(() => {
          // 如果是学生端主动私信 添加好友
          // if (fromStudent) {
          //   this.otherfnPrivateletterval = {}
          // }
        })
    },

    delFriend(val) {
      this.newotherfnPrivateletterval = _.assign(
        {},
        this.otherfnPrivateletterselectVal
      )
      this.isdel = true
      this.delUserid = val
      let promise = this.tim.deleteFriend({
        userIDList: [val],
        // type: TIM.TYPES.SNS_DELETE_TYPE_SINGLE,
        type: TIM.TYPES.SNS_DELETE_TYPE_BOTH,
      })
      promise.catch(function(imError) {
        this.$root.prompt('删除失败')
        console.warn('removeFromFriendList error:', imError)
      })
      // 更新私信数量 私信列表
      let flist = this.friendList.filter(i=>i.userID!==val)
      console.log('更新私信数量',val,flist);
      this.chageFriednlist(flist)
      let priMsgNums = this.friendList.map(v=>v.priMsgNums).reduce((n,o)=>n+o,0)
      this.newPriMsgsChange(priMsgNums)
    },

    // 获取好友列表
    getFlist(){
      // const promise = this.tim.getUserProfile({
      //   userIDList,
      // })
      // promise.then(()=>{
      // })
      // let promise = this.tim.deleteFriend({
      //   userIDList: [userData.userID],
      //   type: TIM.TYPES.SNS_DELETE_TYPE_SINGLE,
      // })
      // promise.catch(function(imError) {
      //   this.$root.prompt('删除失败')
      //   console.warn('removeFromFriendList error:', imError)
      // })
    },

    // 好友列表更行数据
    onFriendListUpdated(event) {
      this.deBug&&console.log('好友列表更行数据',event)
      if(this.isFirstAddFriend&&this.isAddFriendNums){
        let flist = this.friendList.map(v=>v.userID)
        event.data.forEach(v=>{
          if(flist.indexOf(v.userID)==-1){
            v.priMsgNums = 1
          }
        })
      }
      // const friendLen = this.friendList.length
      // const userList = event.data.reverse().filter(v=>v.userID!=this.userInfo.userID)
      // const userLen = userList.length
      // 如果人数一样则不更新
      // if(friendLen!=0&&friendLen===userLen) return
      const userList = event.data.reverse()
      // 修改私信列表数据
      this.chageFriednlist(userList)
      if(this.isFirstAddFriend&&this.isAddFriendNums){
        let priMsgNums = this.friendList.map(v=>v.priMsgNums).reduce((n,o)=>n+o)
        this.newPriMsgsChange(priMsgNums)
      }else{
        this.isFirstAddFriend = true
        this.isAddFriendNums = false
      }
      const data =
        this.newotherfnPrivateletterval.from ||
        this.newotherfnPrivateletterval.userID

      if (this.isaddFriend) {
        this.changeotherfnPrivateletterselectVal(
          this.isdel
            ? this.newotherfnPrivateletterval
            : this.otherfnPrivateletterval
        )
        this.isaddFriend = false
      } else {
        if (
          (data == this.delUserid && this.isdel) ||
          (!this.fromStudent && !this.isdel)
        ) {
          this.changeotherfnPrivateletterselectVal({})
        }
      }
      this.newotherfnPrivateletterval = {}
      this.delUserid = ''
      this.isdel = false
      this.fromStudent = false

      this.getConversationList()
    },

    getConversationList() {
      if (this.isOnready) {
        // 获取指定的会话列表
        let promise = this.tim.getConversationList()
        promise
          .then(imResponse => {
            const conversationList = imResponse.data.conversationList // 缓存中已存在的指定的会话列表
            this.deBug&&console.log(conversationList, '获取会话列表')
            this.addFriendlist(conversationList)
          })
          .catch(imError => {
            this.deBug && console.log('removeFromFriendList error:', imError)
          })
      }
    },

    onMessageRevoked(event) {
      // 被撤回的消息的id集合
      const revokedKey = event.data.map(item => Number(item.sequence))
      this.messageList = this.messageList.filter(
        item => revokedKey.indexOf(Number(item.clientSequence)) == -1
      )
      this.questionArr = this.questionArr.filter(
        item => revokedKey.indexOf(Number(item.clientSequence)) == -1
      )
    },

    // 获取监听数据中回调的数据
    queueStart() {
      if (this.msgQueueTimer || this.stopDisplayMsg) return
      this.msgQueueTimer = setTimeout(() => {
        if (this.stopDisplayMsg) {
          this.msgQueueTimer = null
          return
        }
        // console.log('监听回调事件', this.isC2Cmsg)

        this.hadC2c = false
        const fn = item => {
          this.debug&&console.log('监听回调事件',item,this.imInfo);
          if (item.to == this.imInfo.roomId3) {
            try {
              // 监听第三个群组消息
              let cloudCustomData = JSON.parse(item.cloudCustomData)
              if (cloudCustomData.isQuestion) {
                console.log('监听第三个群组消息',this.rightActiveName,this.rightRadio4);
                this.newtipChange(true)
                if(!(this.rightActiveName==5&&this.rightRadio4==2)){
                  this.newQAMsgsChange(this.newQAMsgs+1)
                }
                this.parseQuestion(item)
              }
            } catch (e) {
              console.error(item.cloudCustomData)
            }
          } else if (
            item.conversationType != 'C2C' ||
            item.to == this.imInfo.roomId || 
            item.to == this.imInfo.roomId2
          ) {
            this.parseMessage(item,false)
          } else if (item.conversationType == 'C2C') {
            // 监听私聊消息
            let isPrivate = true
            console.log('监听私聊消息',item);
            this.hadC2c = true
            this.newtipChange(true)
            // 提问数量增加
            // 设置该用户的私信数量
            
            let flist = this.friendList.map(i=>{
              let data = {
                changePriMsgTime:i.userID === item.from?Date.now():i.changePriMsgTime||0,
                priMsgNums:0,
              }
              if(i.userID === item.from&&item.from!==this.otherfnPrivateletterselectVal.userID) {
                if(i.priMsgNums == undefined){
                  data.priMsgNums = 1
                } else {
                  data.priMsgNums = i.priMsgNums+1
                }
              } else if(i.priMsgNums){
                data.priMsgNums = i.priMsgNums
              }
              return Object.assign(i,data)
            })
            if(this.friendList.filter(v=>v.userID===item.from).length===0) {
              flist.push(Object.assign(item,{
                changePriMsgTime:Date.now(),
                priMsgNums:0,
              }))
            }
            this.chageFriednlist(flist)
            let priMsgNums = this.friendList.map(v=>v.priMsgNums).reduce((n,o)=>n+o)
            this.newPriMsgsChange(priMsgNums)
            this.$root.$emit('changeFriendRanking')
            if (item.from == this.otherfnPrivateletterselectVal.userID) {
              // 监听他人发送消息的回调，学生端聊天
              this.parsePrivateMsg(item,isPrivate)
            }
            this.isC2Cmsg = true

            if (
              item.conversationType == 'C2C' &&
              this.friendList.findIndex(i => i.userID == item.from) == -1 &&
              !item.role
            ) {
              // 判断是其他学生发来的消息
              this.isAddFriendNums = true
              this.addFriend(item, true)
            }
          } 
        }
        this.msgQueue.slice(-100).forEach(fn)
        this.msgQueue = []
        this.msgQueueTimer = null
        // 判断是否有c2c消息，如果有，更新消息获取最后一条消息列表
        if (this.hadC2c) {
          this.getConversationList()
        }
      }, 200)
    },

    onReceived(event) {
      if (this.loading) return
      // 收到推送的单聊、群聊、群提示、群系统通知的新消息，可通过遍历 event.data 获取消息列表数据并渲染到页面
      // event.name - TIM.EVENT.MESSAGE_RECEIVED
      // event.data - 存储 Message 对象的数组 - [Message]
      console.log('加载消息', event)
      this.msgQueue.push(...event.data)
      this.queueStart()
    },

    onError(event) {
      // 收到 SDK 发生错误通知，可以获取错误码和错误信息
      // event.name - TIM.EVENT.ERROR
      // event.data.code - 错误码
      // event.data.message - 错误信息
      this.deBug && console.error(event)
      this.imOnError()
    },

    // 绑定监听事件
    bindEvent() {
      // 收到离线消息和会话列表同步完毕通知，接入侧可以调用 sendMessage 等需要鉴权的接口
      this.tim.on(TIM.EVENT.SDK_READY, this.onReady)

      // 收到推送的单聊、群聊、群提示、群系统通知的新消息，可通过遍历 event.data 获取消息列表数据并渲染到页面
      this.tim.on(TIM.EVENT.MESSAGE_RECEIVED, this.onReceived)

      // SDK 收到消息被撤回的通知
      this.tim.on(TIM.EVENT.MESSAGE_REVOKED, this.onMessageRevoked)

      // 收到 SDK 发生错误通知，可以获取错误码和错误信息
      this.tim.on(TIM.EVENT.ERROR, this.onError)
      this.tim.on(TIM.EVENT.SDK_NOT_READY, this.onError)

      // 收到被踢下线通知
      this.tim.on(TIM.EVENT.KICKED_OUT, this.onError)

      // 绑定发送自定义消息事件
      this.$root.$on('sendCustomMsg', this.sendCustomMsg)
      this.$root.$on('singleselectcontentval', this.singleselectcontentval)

      // 绑定群发消息事件
      this.$root.$on('sendAllPrivateMsg', this.sendAllPrivateMsg)

      //好友列表更新
      this.tim.on(TIM.EVENT.FRIEND_LIST_UPDATED, this.onFriendListUpdated)

      // this.tim.on(
      //   TIM.EVENT.CONVERSATION_LIST_UPDATED,
      //   this.onConversationListUpdated
      // )
    },

    // 解绑监听事件
    offEvent() {
      if (this.tim) {
        // 收到离线消息和会话列表同步完毕通知，接入侧可以调用 sendMessage 等需要鉴权的接口
        this.tim.off(TIM.EVENT.SDK_READY, this.onReady)

        // 收到推送的单聊、群聊、群提示、群系统通知的新消息，可通过遍历 event.data 获取消息列表数据并渲染到页面
        this.tim.off(TIM.EVENT.MESSAGE_RECEIVED, this.onReceived)

        // SDK 收到消息被撤回的通知
        this.tim.off(TIM.EVENT.MESSAGE_REVOKED, this.onMessageRevoked)

        // 收到 SDK 发生错误通知，可以获取错误码和错误信息
        this.tim.off(TIM.EVENT.ERROR, this.onError)
        this.tim.off(TIM.EVENT.SDK_NOT_READY, this.onError)

        // 收到被踢下线通知
        this.tim.off(TIM.EVENT.KICKED_OUT, this.onError)
        //好友列表更新
        this.tim.off(TIM.EVENT.FRIEND_LIST_UPDATED, this.onFriendListUpdated)
      }
      // 解绑发送自定义消息事件
      this.$root.$off('sendCustomMsg', this.sendCustomMsg)

      this.$root.$off('sendAllPrivateMsg', this.sendAllPrivateMsg)
      // 会话列表更新
      // this.tim.off(
      //   TIM.EVENT.CONVERSATION_LIST_UPDATED,
      //   this.onConversationListUpdated
      // )
    },

    // 聊天室错误
    imOnError() {
      this.messageList = []
      this.privateMsg = []
      this.msgQueue = []
      this.ImError = true

      this.$emit('ImError', true)
    },

    // 检查发言的学生是否在线
    checkOnline(msg) {
      // 带的自定义数据
      let cloudCustomData
      try {
        cloudCustomData = JSON.parse(msg.cloudCustomData)
      } catch (error) {
        cloudCustomData = {}
      }
      try {
        const item = this.members[msg.from]
        // 如果在 离线列表
        const condition1 = item && this.membersMenu == 3
        // 如果不在 在线列表
        const condition2 = !item && this.membersMenu == 1

        // console.log(item, this.membersMenu)

        if (condition1 || condition2) {
          // console/setOnline  open_class_id          user_id
          this.$http({
            name: `/console/setOnline/${msg.from}`,
            url: '/console/setOnline',
            data: {
              open_class_id: this.openClassId,
              user_id: cloudCustomData.uid,
            },
          })
        }
      } catch (e) {
        // console.info('error', e)
      }
    },

    // 存储消息
    saveMsg(msg, room,isPrivate) {
      const newMsg = Object.assign({}, msg)
      // 获取最后一个数据
      const lastData = isPrivate
        ? this.privateMsg[this.privateMsg.length - 1]
        : this.messageList[this.messageList.length - 1]
      // 如果添加和数据和最后一个数据 时间间隔差距有5分钟
      if (lastData && newMsg.time - lastData.time > this.msgTimeTnterval) {
        newMsg.timeTnterval = this.$options.filters.formatTimeStamp(
          newMsg.time,
          'hh:mm'
        )
      }
      // 私聊的情况下
      if (
        isPrivate &&
        msg.conversationType == 'C2C' &&
        (msg.from == this.otherfnPrivateletterselectVal.userID || msg.role)
      ) {
        this.privateMsg.push(newMsg)
      } else if (room == 3) {
        this.questionArr.unshift(newMsg)
      } else if (msg.conversationType != 'C2C') {
        this.messageList.push(newMsg)
      }

      if (room != 3 && msg.conversationType != 'C2C') {
        // 截取最大数量的消息
        if (this.autoScrollBotom == 0) {
          if (this.newMsgCount >= 100) {
            this.newMsgCount = 0
            this.messageList = this.messageList.slice(-this.maxMessageNumber)
            this.autoScrollBotom = true
          } else {
            this.newMsgCount++
          }
        } else {
          this.newMsgCount = 0
          this.messageList = this.messageList.slice(-this.maxMessageNumber)
        }
      }

      this.$nextTick(() => {
        if(!isPrivate){
          this.$refs.chatcontent.scrollBottom()
        }else{
          this.$refs.chatcontent.scrollBottomPrivate()
        }
      })
    },

    // 解析所有消息
    // isHistory 是否是历史消息
    parseMessage(msg, isHistory,isPrivate) {
      this.deBug && console.log(msg)
      // eslint-disable-next-line default-case
      switch (msg.type) {
        case 'TIMCustomElem':
          this.parseCustomMessage(msg, isHistory)
          break
        case 'TIMGroupSystemNoticeElem':
          //监听退出群组，退出时，会发送两次信息，flag为了只执行一次
          // if (msg.payload.operationType == 4 && !this.flag) {
          //   this.flag = true
          //   this.$alert(
          //     '您不是该直播间的上课老师或助教老师，请检查当前登录的账号！',
          //     '温馨提示',
          //     {
          //       confirmButtonText: '管理后台',
          //       callback: () => {
          //         this.$router.replace('/')
          //       },
          //     }
          //   )
          // } else
          if (msg.payload.operationType == 5) {
            this.$alert('直播间已重置，请重新进入', '温馨提示', {
              confirmButtonText: '重新进入',
              callback: () => {
                sessionStorage.setItem(
                  'openAndClose',
                  this.openAndClose ? 1 : 0
                )
                location.reload()
              },
            })
          }
          break
        case 'TIMTextElem':
        case 'TIMImageElem':
          // if (!isHistory) {
          //   this.checkOnline(msg)
          // }
          this.saveMsg(this.getMsgItem(msg),null,isPrivate)
          break
        case 'TIMSoundElem':
          this.saveMsg(this.getMsgItem(msg))
          break
      }
    },

    // 解析私聊消息
    parsePrivateMsg(msg,isPrivate) {
      this.saveMsg(this.getMsgItem(msg),null,isPrivate)
    },

    // 解析房间3中的提问信息
    parseQuestion(msg) {
      this.saveMsg(this.getMsgItem(msg), 3)
    },

    // 解析自定义消息
    parseCustomMessage(msg, isHistory) {
      const { data, description } = msg.payload
      // eslint-disable-next-line default-case
      switch (data) {
        case 'flowers':
          this.setFlowersMsg(msg)
          break
        // case 'revoke':
        //   var val = JSON.parse(description)
        //   this.deBug &&
        //     console.log(
        //       'revoke',
        //       msg,
        //       this.messageList,
        //       this.messageList.findIndex(
        //         item => item.clientSequence == val.clientSequence
        //       ),
        //       description
        //     )
        //   this.messageList.splice(
        //     this.messageList.findIndex(
        //       item => item.clientSequence == val.clientSequence
        //     ),
        //     1
        //   )
        //   break
        case 'gift':
          // 如果用户没有禁止礼物特效 就显示
          // debugger
          if (!isHistory) {
            this.setGiftsMsg(msg)
          }
          var giftMsg = this.getGiftData(msg)
          this.saveMsg(giftMsg)
          if (!isHistory) {
            this.setCustomMessage(giftMsg)
          }
          break
        case 'coupon':
          var couponMsg = this.getCouponData(msg)
          this.saveMsg(couponMsg)
          if (!isHistory) {
            this.setCustomMessage(couponMsg)
          }
          break
        case 'member':
          this.setMemberOnline(description)
          // this.$emit('update:memberOnlineCount', description)
          break
        case 'memberEnter':
          if (!isHistory) {
            this.setCustomMessage(this.setMemberEnterMsg(msg))
          }
          // this.memberOnlinefn(msg)
          break
        // 购买成功后推送的课程 或者 代金券 商品的消息
        case 'buyMsg':
          if (!isHistory) {
            this.setCustomMessage(this.buyMsg(msg))
          }
          break
        case 'memberOnline':
          // this.memberOnlinefn(msg)
          break
        // 推送内容
        case 'file':
          this.setFile(msg)
          break
        // 设置
        case 'updateConsole':
          this.consoleInfo(this.openClassId)
          break
        // 公告
        // 全体禁言
        // 敏感词
        // 设置
        case 'liveBbs':
        case 'allMute':
        case 'keywords':
        case 'setting':
          this.consoleInfo(this.openClassId)
          break
        // 签到
        case 'checkIn':
          if (description != 1) {
            this.$root.prompt({
              type: 'warning',
              message: `签到已结束！`,
            })
            this.qiandaoDialog()
          }
          break
        // 学生请求连麦
        case 'join':
          // console.log(msg)
          this.hadstudentRtcJoin({
            msg,
            add: true,
          })
          break
        // 学生取消连麦
        case 'cancelJoin':
          this.hadstudentRtcJoin({
            msg,
            add: false,
          })
          break
        // 拒接接听学生消息
        case 'hangUp':
          this.hadstudentRtcJoin({
            msg,
            add: false,
            isrecived: true,
          })
          break
        // 中间横幅消息
        case 'bannersMsg':
          var banner = this.getBannersMsg(msg)
          if (banner && !isHistory) {
            this.setCustomMessage(banner)
          }
          break
        // 修改消息数据结构
        case 'updateMessage':
          this.updateMessageItem(JSON.parse(description))
          break
      }
    },

    // 开始登录
    login() {
      this.loading = true
      this.ImError = false
      this.setStopDisplayMsg(0)
      this.tim
        .login({
          userID: this.imInfo.userID,
          userSig: this.imInfo.userSig,
        })
        .then(() => {
          // 加入群组
          this.joinGroup()
        })
        .catch(imError => {
          console.warn('login error:', imError) // 登录失败的相关信息
        })
    },

    // 拉取第三个群的消息
    pickQuestionlist(messageList = [], nextReqMessageID) {
      // 打开某个会话时，第一次拉取消息列表
      const promise = this.tim.getMessageList({
        conversationID: `GROUP${this.imInfo.roomId3}`,
        count: 15,
        nextReqMessageID,
      })
      promise.then(imResponse => {
        this.deBug && console.log(' 拉取第三个群的消息',imResponse)
        // 过滤私信的消息
        let arr = []
        imResponse.data.messageList.forEach(item => {
          if (item.cloudCustomData) {
            let cloudCustomData = JSON.parse(item.cloudCustomData)
            if (cloudCustomData.isQuestion) {
              arr.push(item)
            }
          }
        })

        messageList.push(...arr.reverse()) // 消息列表。
        const isCompleted = imResponse.data.isCompleted // 表示是否已经拉完所有消息。

        if (!isCompleted) {
          this.pickQuestionlist(messageList, imResponse.data.nextReqMessageID)
        } else {
          this.questionArr = []
          messageList.reverse().forEach(item => {
            if (!item.isRevoked && item.type == 'TIMTextElem') {
              this.parseQuestion(item)
            }
          })
          this.loading2 = false
        }
      })
    },

    // 获取历史消息
    // nextReqMessageID 用于续拉，分页续拉时需传入该字段。conversationID
    getHistory(messageList = [], nextReqMessageID, conversationID, isPrivate) {
      // isPrivate = isPrivate !== undefined ? isPrivate : this.isPrivate
      conversationID =
        conversationID ||
        (isPrivate
          ? `C2C${this.otherfnPrivateletterselectVal.userID}`
          : `GROUP${this.imInfo.roomId}`)
      // 打开某个会话时，第一次拉取消息列表
      const promise = this.tim.getMessageList({
        conversationID,
        count: 15,
        nextReqMessageID,
      })
      promise
        .then(imResponse => {
          // 如果是私聊 但是这次获取的消息 和 当前私聊ID不对等 说明在频繁切换私聊
          if (
            isPrivate &&
            conversationID !== `C2C${this.otherfnPrivateletterselectVal.userID}`
          )
            return

          messageList.push(...imResponse.data.messageList.reverse()) // 消息列表。
          const isCompleted = imResponse.data.isCompleted // 表示是否已经拉完所有消息。

          // 获取最大次数到了 就不获取了
          if (messageList.length < this.maxMessageNumber && !isCompleted) {
            this.getHistory(
              messageList,
              imResponse.data.nextReqMessageID,
              conversationID,
              isPrivate
            )
          } else {
            this.deBug && console.log('历史消息 消息列表。', messageList)
            isPrivate ? (this.privateMsg = []) : (this.messageList = [])
            messageList.reverse().forEach(item => {
              if (
                !item.isRevoked &&
                (item.type == 'TIMTextElem' ||
                  item.type == 'TIMImageElem' ||
                  item.type == 'TIMSoundElem' ||
                  item.payload.data == 'gift' ||
                  item.payload.data == 'coupon' ||
                  item.payload.data == 'file')
              ) {
                if (isPrivate) {
                  this.parsePrivateMsg(item,isPrivate)
                } else {
                  this.parseMessage(item, true)
                }
              }
            })
            this.loading = false
            this.loading2 = false
            if(!isPrivate){
              this.newMsgCount = 0
              this.autoScrollBotom = true
            } else {
              this.newMsgCountPrivate = 0
              this.autoScrollBotomPrivate = true
            }
          }
        })
        .catch(() => {
          this.loading = false
          this.loading2 = false
          if(!isPrivate){
            this.newMsgCount = 0
            this.autoScrollBotom = true
          } else {
            this.newMsgCountPrivate = 0
            this.autoScrollBotomPrivate = true
          }
        })
    },

    // 加入群组
    joinGroup() {
      this.tim.joinGroup({
        groupID: this.imInfo.roomId,
        type: this.imInfo.type,
      })
      this.tim.joinGroup({
        groupID: this.imInfo.roomId2,
        type: this.imInfo.type,
      })
      this.tim.joinGroup({
        groupID: this.imInfo.roomId3,
        type: this.imInfo.type,
      })
    },

    // 获取消息
    getMsgItem(msg) {
      // 管理员
      let val = {}
      try {
        val =
          this.userInfo.managerList.find(item => {
            return item.id == msg.from
          }) || {}
      } catch (e) {
        // console.info(e)
      }
      // 带的自定义数据
      let cloudCustomData
      try {
        cloudCustomData = JSON.parse(msg.cloudCustomData)
        cloudCustomData.tag_names2 = Array.isArray(cloudCustomData.tag_names)
          ? cloudCustomData.tag_names
          : []
        cloudCustomData.tag_names = cloudCustomData.tag_names2.join(' | ')
      } catch (error) {
        // console.info(error)
        cloudCustomData = {}
      }

      // 携带的数据description
      let description
      try {
        description = JSON.parse(msg.payload.description)
      } catch (error) {
        description = {}
      }

      const options = _.assign(
        {},
        {
          to: msg.to,
          ID: msg.ID,
          nick: msg.nick,
          from: msg.from,
          time: msg.time,
          role: val.role || '',
          avatar: msg.avatar,
          bannnerType: description.bannnerType,
          clientSequence: msg.sequence || msg.clientSequence,
          conversationType: msg.conversationType,
          type:
            msg.type == 'TIMImageElem'
              ? 'img'
              : msg.type == 'TIMSoundElem'
              ? 'sound'
              : msg.type == 'TIMCustomElem'
              ? 'file'
              : 'text',
          compressed:
            msg.type == 'TIMImageElem' ? msg.payload.imageInfoArray[1] : '',
          text:
            msg.type == 'TIMImageElem'
              ? msg.payload.imageInfoArray[0].url
              : msg.type == 'TIMSoundElem'
              ? msg.payload
              : msg.type == 'TIMCustomElem'
              ? description
              : this.textReplaceUrl(this.textReplaceEmoji(msg.payload.text)),
          qoute: msg.payload.text,
        },
        cloudCustomData,
        { cloudCustomData: cloudCustomData }
      )

      // 替换敏感词
      if (cloudCustomData.sensitiveWordsShield) {
        // options.text = this.sensitiveWordsShield(options.text)
        options.text = sensitiveWordsShield(options.text,this.sensitiveWords)
      }

      return options
    },

    // 替换成表情
    textReplaceEmoji(oldText) {
      let tempText = ''
      const text = this.transCharacterEntities(oldText)
      const exp1 = /(\[[\u4e00-\u9fa5]+\])|./g
      const exp2 = /\[[\u4e00-\u9fa5]+\]/
      const matchRes = text.match(exp1) || []

      matchRes.forEach(item => {
        // 如果该item 是表情
        if (exp2.test(item)) {
          try {
            tempText += `<span style="background-position:0px ${-(
              emojiSource[item].i * 20
            )}px" class="vm ${'openclass-emoji-item2'}"></span>`
          } catch (e) {
            tempText += item
          }
        } else {
          tempText += item
        }
      })
      return tempText
    },

    // 敏感词屏蔽
    // sensitiveWordsShield(value) {
    //   this.sensitiveWords.forEach(item => {
    //     const exp = new RegExp(item, 'ig')
    //     value = value.replace(exp, function($1) {
    //       return `<i style="color: #ff3535;text-decoration: line-through;">${$1}</i>`
    //     })
    //   })
    //   return value
    // },

    // 替换成url
    textReplaceUrl(msg) {
      return msg.replace(
        /(https?:\/\/)?([a-zA-Z0-9_-]+\.)+(cn|com|net|org)(:\d{1,4})?[\@\#\?\w\d&%=\.\/\~\-\;\:\*\+\$]*/g,
        function(val) {
          return `<a class="vm" style="color: #2f9bd3 ;vertical-align: baseline;" href="${
            /^https?:\/\//.test(val) ? val : '//' + val
          }" target="_blank">${val}</a>`
        }
      )
    },

    // 转义html
    transCharacterEntities(text) {
      return text ? text.replace(/</gi, '&lt;').replace(/>/gi, '&gt;') : ''
    },

    // 获取鲜花消息
    setFlowersMsg(msg) {
      // 在一定的间隔之内显示一条消息 其余抛弃
      const pop = this.flowers[this.flowers.length - 1]
      if (!pop || Date.now() - pop.time > 800) {
        this.flowers.push({
          text: `${msg.nick}送了一朵小花`,
          id: Math.random(),
          time: Date.now(),
        })
        this.setFlower()
      }
    },

    // 获取推送内容
    setFile(msg) {
      this.saveMsg(this.getMsgItem(msg))
    },

    // 进入房间消息
    setMemberEnterMsg(msg) {
      const text = {
        text: `${msg.nick}进入了直播间`,
        id: Math.random(),
        msgType: 'memberEnter',
        time: msg.time,
      }
      // this.memberEnterMsg.push(text)
      return text
    },

    buyMsg(msg) {
      const { description } = msg.payload
      const item = JSON.parse(description)
      const text = {
        text: `${item.name}：购买了《${item.good_name}》`,
        id: Math.random(),
        msgType: 'buyMsg',
        time: msg.time,
      }
      // this.memberEnterMsg.push(text)
      return text
    },

    // 获取被删除的数据
    getRevoke(msg) {
      return {
        nick: msg.nick,
        time: msg.time,
        avatar: msg.avatar,
        type: 'revoke',
        id: Math.random(),
      }
    },
    // 获取中间横幅消息
    getBannersMsg(msg) {
      // 王多鱼 来了，欢迎小伙伴光临直播间~ => 1
      // 王**鱼 正在报名课程《声乐基础体验班》 => 2
      // 王**鱼 刚刚领取了资料 => 3
      // 王**鱼 刚刚获得了会员VIP => 4
      // 王**鱼 刚刚分享了直播 => 5
      // 王** 刚刚订阅了直播间 => 6
      let text
      let val
      let color = '#CB633A'
      let bg = 'rgba(255, 243, 203, 0.8)'

      try {
        val = JSON.parse(msg.payload.extension)
      } catch (error) {
        val = null
      }

      // let nick2 = msg.nick.split('')
      // nick2 = `${nick2.shift() || ''}**${nick2.pop() || ''}`
      const nick2 = msg.nick

      switch (Number(msg.payload.description)) {
        case 1:
          if (this.controlInfo.is_show_welcome == 1) {
            text = `${msg.nick} 来了，${this.controlInfo.welcome_text}`
            color = '#fff'
            bg = 'rgba(159, 73, 73, 0.8)'
          }
          break
        case 2:
          if (this.controlInfo.is_show_visit_course == 1 && val) {
            text = `${nick2} 正在报名课程《${val.name}》`
          }
          break
        case 3:
          if (this.controlInfo.is_show_visit_electronics == 1) {
            text = `${nick2} 刚刚领取了资料`
          }
          break
        case 4:
          if (this.controlInfo.is_show_visit_member == 1) {
            text = `${nick2} 刚刚获得了会员VIP`
          }
          break
        case 5:
          if (this.controlInfo.is_show_share_live == 1) {
            text = `${nick2} 刚刚分享了直播`
          }
          break
        case 6:
          if (this.controlInfo.is_show_subscribe_live == 1) {
            text = `${nick2} 刚刚订阅了直播间`
          }
          break
        case 7:
          if (this.controlInfo.is_show_goods_push == 1) {
            text = `${nick2} 正在下单`
          }
          break
        default:
          text = ''
      }

      return text
        ? {
            id: Math.random(),
            color,
            bg,
            text,
            time: msg.time,
            msgType: 'banner',
          }
        : ''
    },

    // 获取礼物数据
    getGiftData(msg) {
      const desc = JSON.parse(msg.payload.description)
      return {
        nick: msg.nick,
        time: msg.time,
        avatar: msg.avatar,
        type: 'gift',
        giftIcon:
          desc.type == 1
            ? desc.giftIcon
            : 'https://f.dingdingkaike.com.cn/openClassLive/gift/img_dstb%402x.png',
        id: Math.random(),
        desc: desc.type == 1 ? `送出了${desc.name}` : `打赏了${desc.price}元`,
        text:
          msg.nick +
          (desc.type == 1 ? `送出了${desc.name}` : `打赏了${desc.price}元`),
        msgType: 'gift',
        conversationType:msg.conversationType,
      }
    },

    // 获取免息券数据
    getCouponData(msg) {
      const desc = JSON.parse(msg.payload.description)
      const text =
        desc.type == 3
          ? `抢到了${this.$options.filters.getInstallmentTypeText(
              desc.specs
            )}期免息券`
          : `抢到了代金券名称`
      return {
        nick: msg.nick,
        time: msg.time,
        type: 'coupon',
        id: Math.random(),
        desc: text,
        text: msg.nick + text,
      }
    },

    // 获取礼物消息
    setGiftsMsg(msg) {
      this.gifts.push(this.getGiftData(msg))
    },

    // 送鲜花
    sendFlowers() {
      if (this.flowersDisable) return
      this.$http({
        isMultipleSelf: 'all',
        data: { open_class_id: this.openInfo.open_class_id },
        url: '/console/sendFlowers',
        callback: () => {
          this.sendCustomMsg({
            data: 'flowers', // 用于标识该消息是骰子类型消息
            description: '', // 获取骰子点数
            extension: '',
            priority: TIM.TYPES.MSG_PRIORITY_LOW,
            roomId: this.imInfo.roomId2,
          })
        },
      })

      // 送鲜花间隔
      this.flowersDisable = true
      setTimeout(() => {
        this.flowersDisable = false
      }, this.flowersTime)
    },

    // 推动内容
    singleselectcontentval(val) {
      const data = val[0]

      let sendVal = {
        name: data.name,
        photo:
          data.photo ||
          data.img_url ||
          data.cover_img ||
          'https://f.dingdingkaike.com.cn/teacher/img_zpbg%402x.png',
        selectType: data.selectType,
        course_type: data.course_type,
        url: data.url,
        typeText: '',
      }

      switch (Number(sendVal.selectType)) {
        case 1:
          sendVal.typeText = this.$options.filters.getCourseType(
            sendVal.course_type
          )
          sendVal.bannnerType = 2
          break
        case 2:
          sendVal.typeText = '电子资料'
          sendVal.bannnerType = 3
          break
        case 3:
          sendVal.typeText = '会员'
          sendVal.bannnerType = 4
          break
        case 6:
          sendVal.typeText = '作业'
          sendVal.bannnerType = 7
          break
      }

      if (!this.isPrivate) {
        this.sendCustomMsg({
          data: 'file', // 用于标识该消息是骰子类型消息
          description: JSON.stringify(sendVal), // 获取骰子点数
          extension: '',
          noParse: true,
          roomId: this.imInfo.roomId3,
        })
      }
      this.sendCustomMsg({
        data: 'file', // 用于标识该消息是骰子类型消息
        description: JSON.stringify(sendVal), // 获取骰子点数
        extension: '',
        conversationType: this.isPrivate ? TIM.TYPES.CONV_C2C : undefined,
        roomId: this.isPrivate
          ? this.otherfnPrivateletterselectVal.userID
          : this.imInfo.roomId,
      })
    },

    // 滚动了消息列表 如果有消息就显示底部有新消息
    bindMessageOnScroll() {
      this.$refs.chatcontent.scrollt()
    },

    // 群发消息事件
    sendAllPrivateMsg({ val, type, replyMsg, isPrivate }){
      // console.log('群发消息事件',val, type, replyMsg,isPrivate);
      if(this.allMembers.length===0){
        this.$root.prompt('请先选择发送人')
        return
      }
      // 跟新私信位置
      let mlist = this.allMembers.map(v=>v.userID)
      let list = this.friendList.map(i=>Object.assign(i,{
        changePriMsgTime:mlist.indexOf(i.userID) !== -1?Date.now():i.changePriMsgTime,
      }))
      this.chageFriednlist(list)

      let fList = this.friendList.map(v=>v.profile.userID)
      let allList = this.allMembers.filter(v=>fList.indexOf(v.userID)==-1)
      // console.log('friendList',this.friendList,'allMembers',this.allMembers,'allList',allList);
      this.allMemberQueue.push(...allList)
      const fn = ()=>{
        if(this.allMemberQueue.length==0){
          clearInterval(this.allMemberTimer)
          this.allMemberTimer = null
          this.getConversationList()
        }else {
          let data = this.allMemberQueue.shift()
          
          let promise = this.joinAllMember(data)
          promise.then(()=>{
            this.getConversationList()
            // this.isaddFriend = true
            // this.addFriend(dv, false)
            // this.otherfnPrivateletter(dv)
            // if (type == 1) {
            //   this.sendAllImageMsg(data,val, replyMsg, isPrivate)
            // } else {
            //   this.sendAllTextMsg(data,val, replyMsg, isPrivate)
            // }
          })
          
        }
      }

      this.allMemberTimer = setInterval(fn,1000)

      if (type == 1) {
        const sendAllMembers = Promise.all(this.allMembers.map(v=>this.sendAllImageMsg(v,val, replyMsg, isPrivate)))
        sendAllMembers.then(()=>{
          this.$root.prompt({
            msg: '发送成功',
            type: 'success',
          })
    // 群发图片完成
          this.$root.$emit('sendMsgComplete')
        }).catch(imError => {
          console.warn('sendMessage error:', imError)
          this.$root.prompt(imError)
        }).finally(()=>{
          this.setallMembers([])
        })
      } else {
        const sendAllMembers = Promise.all(this.allMembers.map(v=>this.sendAllTextMsg(v,val, replyMsg, isPrivate)))
        sendAllMembers.then(()=>{
          this.$root.prompt({
            msg: '发送成功',
            type: 'success',
          })
        }).catch(imError => {
          console.warn('sendMessage error:', imError)
          this.$root.prompt(imError)
        }).finally(()=>{
          this.setallMembers([])
        })
      }
    },

    // 群发加好友
    joinAllMember(val){
      return new Promise((resolve,reject)=>{
        let promise = this.tim.addFriend({
          to: val.userID,
          source: 'AddSource_Type_Web',
          // type: TIM.TYPES.SNS_ADD_TYPE_SINGLE,
          type: TIM.TYPES.SNS_ADD_TYPE_BOTH,
          remark: this.userInfo.uname,
        })
        promise.then((res)=>{
          resolve(res)
        }).catch(imError => {
          reject(imError)
        })
      })
    },
    // 获取好友列表
    getAllFlist(){
      return new Promise((resolve,reject)=>{
        let promise = this.tim.getFriendList({})
        promise.then((res)=>{
          resolve(res)
        }).catch(imError => {
          reject(imError)
        })
      })
    },
    // 群发文字
    sendAllTextMsg(v,val, replyMsg, isPrivate){
      return new Promise((resolve,reject)=>{
        const message = this.tim.createTextMessage({
            to: v.userID,
            conversationType: TIM.TYPES.CONV_C2C,
            priority: TIM.TYPES.MSG_PRIORITY_HIGH,
            payload: {
              text: val.trim(),
            },
            cloudCustomData: JSON.stringify({
              invite_show: this.userInfo.invite_show,
              replyMsg,
            }),
          })
          const promise = this.tim.sendMessage(message)
          promise.then(imResponse => {
            this.parsePrivateMsg(imResponse.data.message,isPrivate)
            this.getConversationList()
            resolve()
          }).catch(imError => {
            // 发送失败
            console.warn('sendMessage error:', imError)
            reject()
          })
      })
    },
    // 群发图片
    sendAllImageMsg(v,val){
      return new Promise((resolve,reject)=>{
        const msg2 = this.tim.createImageMessage({
          noParse: true,
          to: v.userID,
          conversationType: TIM.TYPES.CONV_C2C,
          priority: TIM.TYPES.MSG_PRIORITY_HIGH,
          payload: {
            file: val,
          },
          onProgress: event => {
            this.deBug && console.log('file uploading:', event)
            // 群发图片进度
            this.$root.$emit('sendMsgProgress',event)
          },
          cloudCustomData: JSON.stringify({
            invite_show: this.userInfo.invite_show,
            imgurl:this.imgurl,
          }),
        })
        let promise = this.tim.sendMessage(msg2)
        promise.then(imResponse => {
          let isPrivate = true
          let isHistory = false
          this.parseMessage(imResponse.data.message,isHistory,isPrivate)
          resolve()
        }).catch(imError => {
          // 发送失败
          console.warn('sendMessage error:', imError)
          this.$root.prompt(imError)
          reject()
        })
      })
    },

    // 发送普通消息和图片
    sendmessage({ val, type, replyMsg,isPrivate,url, }) {
      if (type == 1) {
        this.imgvalue = val
        this.imgurl = url
        this.sendImgMsg(isPrivate)
      } else {
        this.msg = val.trim()
        this.replyMsg = replyMsg
        this.sendMessage(isPrivate)
      }
      if(isPrivate) {
        this.changePriTime(this.otherfnPrivateletterselectVal.userID)
      }
    },

    // 发送快捷消息
    sendFastMsg(item) {
      this.msg = item.text
      this.sendMessage()
    },

    // 发送消息 是否是私聊
    sendMessage(isPrivate) {
      if (!this.msg || this.ImError) return

      let value = this.msg
      this.msg = ''
      let value2 = this.replyMsg
      this.replyMsg = ''
      this.chageQuoteval({})

      // 敏感词过滤
      // if (this.sensitiveWords.length > 0) {
      //   this.sensitiveWords.forEach((item) => {
      //     const exp = new RegExp(item, 'ig')
      //     value = value.replace(exp, '**')
      //   })
      // }

      // 发送文本消息，Web 端与小程序端相同
      // 1. 创建消息实例，接口返回的实例可以上屏
      const message = this.tim.createTextMessage({
        to: isPrivate
          ? this.otherfnPrivateletterselectVal.userID
          : this.imInfo.roomId,
        conversationType: isPrivate
          ? TIM.TYPES.CONV_C2C
          : TIM.TYPES.CONV_GROUP,
        // 消息优先级，用于群聊（v2.4.2起支持）。如果某个群的消息超过了频率限制，后台会优先下发高优先级的消息，详细请参考：https://cloud.tencent.com/document/product/269/3663#.E6.B6.88.E6.81.AF.E4.BC.98.E5.85.88.E7.BA.A7.E4.B8.8E.E9.A2.91.E7.8E.87.E6.8E.A7.E5.88.B6)
        // 支持的枚举值：TIM.TYPES.MSG_PRIORITY_HIGH, TIM.TYPES.MSG_PRIORITY_NORMAL（默认）, TIM.TYPES.MSG_PRIORITY_LOW, TIM.TYPES.MSG_PRIORITY_LOWEST
        priority: TIM.TYPES.MSG_PRIORITY_HIGH,
        payload: {
          text: value,
        },
        // 消息自定义数据（云端保存，会发送到对端，程序卸载重装后还能拉取到，v2.10.2起支持）
        cloudCustomData: JSON.stringify({
          invite_show: this.userInfo.invite_show,
          replyMsg: value2,
          student_remarks:this.userInfo.student_remarks,
        }),
      })

      // 2. 发送消息
      const promise = this.tim.sendMessage(message)
      promise
        .then(imResponse => {
          // 发送成功
          this.deBug && console.log(imResponse)
          if (imResponse.data.message.conversationType == 'C2C') {
            this.parsePrivateMsg(imResponse.data.message,isPrivate)
            this.getConversationList()
          } else {
            // conversationType
            this.parseMessage(imResponse.data.message)
          }

          this.showSendMsgContain = false
          this.msg = ''
          if(!isPrivate){
            this.newMsgCount = 0
            this.autoScrollBotom = true
          } else {
            this.newMsgCountPrivate = 0
            this.autoScrollBotomPrivate = true
          }
        })
        .catch(imError => {
          // 发送失败
          console.warn('sendMessage error:', imError)
        })
    },

    // 发送图片
    sendImgMsg(isPrivate) {
      if (this.ImError) return

      if (isPrivate) {
        this.toRoom3(this.otherfnPrivateletterselectVal.userID, 2)
        console.log('修改私信位置',this.otherfnPrivateletterselectVal.userID,this.friendList);
      } else {
        this.imageProgress = 0.1
        let message = this.tim.createImageMessage({
          to: this.imInfo.roomId,
          conversationType: TIM.TYPES.CONV_GROUP,
          // 消息优先级，用于群聊（v2.4.2起支持）。如果某个群的消息超过了频率限制，后台会优先下发高优先级的消息，详细请参考：https://cloud.tencent.com/document/product/269/3663#.E6.B6.88.E6.81.AF.E4.BC.98.E5.85.88.E7.BA.A7.E4.B8.8E.E9.A2.91.E7.8E.87.E6.8E.A7.E5.88.B6)
          // 支持的枚举值：TIM.TYPES.MSG_PRIORITY_HIGH, TIM.TYPES.MSG_PRIORITY_NORMAL（默认）, TIM.TYPES.MSG_PRIORITY_LOW, TIM.TYPES.MSG_PRIORITY_LOWEST
          priority: TIM.TYPES.MSG_PRIORITY_HIGH,
          payload: {
            file: this.imgvalue,
          },
          // 消息自定义数据（云端保存，会发送到对端，程序卸载重装后还能拉取到，v2.10.2起支持）
          cloudCustomData: JSON.stringify({
            invite_show: this.userInfo.invite_show,
            imgurl:this.imgurl,
          }),
          onProgress: event => {
            this.imageProgress = event
            this.deBug && console.log('file uploading:', event)
          },
        })

        // 2. 发送消息
        let promise = this.tim.sendMessage(message)
        promise
          .then(imResponse => {
            // 发送成功
            this.deBug && console.log(imResponse)
            this.parseMessage(imResponse.data.message)
          })
          .catch(imError => {
            // 发送失败
            console.warn('sendMessage error:', imError)
            this.$root.prompt(imError)
          })
          .then(() => {
            this.toRoom3(this.imInfo.roomId3, 1)
          })
          .finally(() => {
            this.imgvalue = ''
            this.showSendMsgContain = false
            this.autoScrollBotom = true
            this.imagecomplete = true
            this.getConversationList()
          })
      }
    },

    // val 群组 或者 私聊id
    // val2 1=> 群组聊天 2=>私聊
    toRoom3(val, val2) {
      // 发送到第三个聊天室
      if (val2 == 2) {
        this.imageProgress2 = 0.1
      }
      const msg2 = this.tim.createImageMessage({
        noParse: true,
        to: val,
        conversationType: val2 == 1 ? TIM.TYPES.CONV_GROUP : TIM.TYPES.CONV_C2C,
        // 消息优先级，用于群聊（v2.4.2起支持）。如果某个群的消息超过了频率限制，后台会优先下发高优先级的消息，详细请参考：https://cloud.tencent.com/document/product/269/3663#.E6.B6.88.E6.81.AF.E4.BC.98.E5.85.88.E7.BA.A7.E4.B8.8E.E9.A2.91.E7.8E.87.E6.8E.A7.E5.88.B6)
        // 支持的枚举值：TIM.TYPES.MSG_PRIORITY_HIGH, TIM.TYPES.MSG_PRIORITY_NORMAL（默认）, TIM.TYPES.MSG_PRIORITY_LOW, TIM.TYPES.MSG_PRIORITY_LOWEST
        priority: TIM.TYPES.MSG_PRIORITY_HIGH,
        payload: {
          file: this.imgvalue,
        },
        onProgress: event => {
          if (val2 == 2) {
            this.imageProgress2 = event
          }
          this.deBug && console.log('file uploading:', event)
        },
        // 消息自定义数据（云端保存，会发送到对端，程序卸载重装后还能拉取到，v2.10.2起支持）
        cloudCustomData: JSON.stringify({
          invite_show: this.userInfo.invite_show,
          imgurl:this.imgurl,
        }),
      })

      // 2. 发送消息
      // this.tim.sendMessage(msg2)

      // 2. 发送消息
      let promise = this.tim.sendMessage(msg2)
      promise
        .then(imResponse => {
          // 发送成功
          this.deBug && console.log(imResponse)
          if (val2 == 2) {
            let isPrivate = true
            let isHistory = false
            this.parseMessage(imResponse.data.message,isHistory,isPrivate)
          }
        })
        .catch(imError => {
          // 发送失败
          console.warn('sendMessage error:', imError)
          this.$root.prompt(imError)
        })
        .finally(() => {
          this.imgvalue = ''
          this.imagecomplete2 = true
          this.showSendMsgContain = false
          if(val2 == 2){
            this.autoScrollBotomPrivate = true
          } else {
            this.autoScrollBotom = true
          }
          this.getConversationList()
        })
    },

    // 发送自定义消息
    sendCustomMsg(payload, callback, errorCallback) {
      if (this.ImError) return
      // console.log('自定义消息', {
      //   to: payload.roomId || this.imInfo.roomId,
      //   conversationType: TIM.TYPES.CONV_GROUP,
      //   // 消息优先级，用于群聊（v2.4.2起支持）。如果某个群的消息超过了频率限制，后台会优先下发高优先级的消息，详细请参考：https://cloud.tencent.com/document/product/269/3663#.E6.B6.88.E6.81.AF.E4.BC.98.E5.85.88.E7.BA.A7.E4.B8.8E.E9.A2.91.E7.8E.87.E6.8E.A7.E5.88.B6)
      //   // 支持的枚举值：TIM.TYPES.MSG_PRIORITY_HIGH, TIM.TYPES.MSG_PRIORITY_NORMAL（默认）, TIM.TYPES.MSG_PRIORITY_LOW, TIM.TYPES.MSG_PRIORITY_LOWEST
      //   // priority: TIM.TYPES.MSG_PRIORITY_HIGH,
      //   payload,
      // })

      const roomid = payload.roomId || this.imInfo.roomId

      // 2. 创建消息实例，接口返回的实例可以上屏
      const message = this.tim.createCustomMessage({
        to: roomid,
        conversationType: payload.conversationType || TIM.TYPES.CONV_GROUP,
        // 消息优先级，用于群聊（v2.4.2起支持）。如果某个群的消息超过了频率限制，后台会优先下发高优先级的消息，详细请参考：https://cloud.tencent.com/document/product/269/3663#.E6.B6.88.E6.81.AF.E4.BC.98.E5.85.88.E7.BA.A7.E4.B8.8E.E9.A2.91.E7.8E.87.E6.8E.A7.E5.88.B6)
        // 支持的枚举值：TIM.TYPES.MSG_PRIORITY_HIGH, TIM.TYPES.MSG_PRIORITY_NORMAL（默认）, TIM.TYPES.MSG_PRIORITY_LOW, TIM.TYPES.MSG_PRIORITY_LOWEST
        priority: payload.priority
          ? payload.priority
          : TIM.TYPES.MSG_PRIORITY_HIGH,
        payload,
      })
      // 3. 发送消息
      this.tim
        .sendMessage(message)
        .then(imResponse => {
          // 发送成功
          this.deBug && console.log(imResponse)
          // 如果不解析这类消息
          if (!payload.noParse) {
            this.parseMessage(imResponse.data.message)
          }
          if (callback) {
            callback()
          }
          this.getConversationList()
        })
        .catch(imError => {
          // 发送失败
          console.warn('sendMessage error:', imError)
          if (errorCallback) {
            errorCallback()
          }
        })
    },

    // 修改问答用户私信时间
    changePriTime(userID){
      let flist = this.friendList.map(i=>Object.assign(i,{
        changePriMsgTime:i.userID === userID?Date.now():i.changePriMsgTime||0,
      }))
      this.chageFriednlist(flist)
      this.$root.$emit('changeFriendRanking')
    },
    closeHistory(){
      this.$refs.chatcontent.closeHistory()
    },
  },
}
</script>

<style>
.OpenClass_main_openClassLive_confirmcustomClass {
  /* width: 230px !important; */
}

.OpenClass_main_openClassLive_rcolor {
  background-color: #ff3535 !important;
  border-color: #ff3535 !important ;
}
</style>
<style lang="scss" scoped>
#centermain {
  height: 100%;
  display: flex;
  flex-direction: column;
  .el-message-box {
    width: 311px;
  }
}
</style>
