import { render, staticRenderFns } from "./smallotherfnContain.vue?vue&type=template&id=644fe016&scoped=true&"
import script from "./smallotherfnContain.vue?vue&type=script&lang=js&"
export * from "./smallotherfnContain.vue?vue&type=script&lang=js&"
import style0 from "./smallotherfnContain.vue?vue&type=style&index=0&id=644fe016&prod&lang=scss&"
import style1 from "./smallotherfnContain.vue?vue&type=style&index=1&id=644fe016&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "644fe016",
  null
  
)

export default component.exports