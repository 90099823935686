<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-10-27 18:24:36
 * @LastEditTime: 2022-10-28 12:01:13
 * @LastEditors: cyy
 * @Description: 控制台选择话术本
 * @FilePath: \teacherdingding.com\src\views\OpenClass_openClassLive\components\liveDataComponents\changKong\speechBook.vue
-->
<template>
  <div class="speechBook">
    <el-dialog
      title="添加话术本（仅支持模拟对话类型）"
      class="dialogVisible"
      width="800px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="dialogVisible_bottom">
        <pagination2
          :option="post"
          url="/chatMessage/chatMsgList"
          ref="childDialogBox"
          @complete="complete"
        >
          <template v-slot:default="{}">
            <div class="timepicker">
              <!-- {{ speechBookIds }} -->
              <el-input
                @keyup.enter.native="toSearch"
                v-model="search"
                size="medium"
                placeholder="输入名称搜索"
                style="width:188px"
                class=" ml10 mr20"
              ></el-input>

              <el-button type="primary" size="medium" @click="toSearch">
                搜索
              </el-button>
            </div>

            <el-table
              :fit="true"
              ref="dialogTable"
              @row-click="rowClick"
              @sort-change="sortChange"
              @selection-change="handleSelectionChange"
              :header-cell-style="{
                background: 'rgba(245,245,245,1)',
                color: '#333333',
              }"
              height="300"
              :data="tempplateContentList"
            >
              <el-table-column width="45px" type="selection"></el-table-column>
              <el-table-column label="名称" min-width="100">
                <template slot-scope="{ row }">
                  <div class="people-contain">
                    <div class="name" :title="row.name">
                      {{ row.name }}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="评论条数">
                <template slot-scope="{ row }">
                  {{ row.num || '-' }}
                </template>
              </el-table-column>
              <el-table-column label="创建人">
                <template slot-scope="{ row }">
                  {{ row.uname }}
                </template>
              </el-table-column>
              <el-table-column label="创建时间" sortable="custom" prop="c_time">
                <template slot-scope="{ row }">
                  <span v-if="row.c_time">
                    {{ row.c_time | formatTimeStamp }}
                  </span>
                  <span v-else>-</span>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </pagination2>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="medium">
          取 消
        </el-button>
        <el-button type="primary" @click="submit" size="medium">
          确定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialogstatus: {
      type: Boolean,
      default: false,
    },
    speechBookIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectList: [],
      post: {
        type: 2,
        msg_ids: this.speechBookIds,
        search: '',
        sort: '',
      },
      search: '',
      tempplateContentList: [],
    }
  },

  created() {
    this.post = _.assign({}, this.post, { msg_ids: this.speechBookIds })
  },

  methods: {
    // 提交
    submit() {
      this.$emit('speechBookList', this.selectList)
      this.cancel()
    },

    // 列表排序
    sortChange(val) {
      this.post.sort = !val.order ? '' : val.order === 'ascending' ? '1' : '2'
    },

    rowClick(row) {
      this.$refs.dialogTable.toggleRowSelection(row)
    },
    handleSelectionChange(val) {
      this.selectList = val
    },

    toSearch() {
      this.post.search = this.search
    },

    complete(val) {
      this.tempplateContentList = val.data
    },

    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.speechBook {
  .timepicker {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
    margin-top: 10px;
  }
}
</style>
