<template>
  <div class="chatHistory flex1 flex-column">
    <div class="head mt20">
      <template>
        <el-row>
          <el-col
            :span="24"
            style="justify-content: space-between; flex-wrap: wrap;display: flex;"
          >
            <div>
              <el-button type="primary" @click="openText" size="medium" :disabled="tableList.length>0">
                配置话术本
              </el-button>
              <el-button type="text" @click="openHistory" size="medium" style="color: #FA6400;margin: 0 30px 0 30px;">
                发送记录
              </el-button>
              <div style="display: inline-block;vertical-align: bottom;height: 100%;">
                <div style="display: flex;flex-direction: column;justify-content: space-between;height: 100%;">
                  <div style="color: #707070;font-size: 0.6rem;">使用场景：用直播推回放视频，配合之前直播时的话术本做伪直播</div>
                  <div style="color: #707070;font-size: 0.6rem;">使用步骤：添加话术本，设置间隔时间，推流直播，推流成功后X秒开始发送话术本第一条消息</div>
                </div>
              </div>
            </div>

            <!-- <div class="flex-center">
              <el-input
                  size="medium"
                  v-model="post.search"
                  class="ml20"
                  style="width: 218px"
                  placeholder="输入话术本名称搜索"
              ></el-input>
            </div> -->
          </el-col>
        </el-row>
      </template>
    </div>
    <pagination2 :option="post" url="/console/getMsgRecords" ref="childDialogBox" class="mt20 childDialogBox flex1"
      @complete="complete">
      <template v-slot:default="{ tableData }">
        <el-table
          class="absolute"
          :header-cell-style="{
            background: '#212121',
            color: '#fff',
          }"
          :row-style="{ background: '#171717', color: '#fff' }"
          :data="tableData"
        >
          <el-table-column label="话术本名称" prop="name" width="100"></el-table-column>
          <el-table-column label="评论条数" prop="count" width="100"></el-table-column>
          <el-table-column label="添加人" prop="uname" width="120"></el-table-column>
          <el-table-column label="添加时间" width="150">
            <template slot-scope="{ row }">
              {{ row.c_time | formatTimeStamp }}
            </template>
          </el-table-column>
          <el-table-column label="间隔时间" width="100">
            <div slot="header">间隔时间
              <helpIcon
                style="margin-left: 6px"
                :content="stopContent"
              ></helpIcon>
            </div>
            <template slot-scope="{ row }">
              <span v-if="row.stop_time">{{ row.stop_time }}s</span>
            </template>
          </el-table-column>
          <el-table-column label="状态"  width="80">
            <template slot-scope="{ row }">
              <span :style="`color:${row.status==2?'#0AA216':'#FF3535'};`">{{ row.status | getStatus }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" min-width="180">
            <template slot-scope="{ row }">
              <el-button type="text" style="color: #FA6400" @click="edit(row)" :disabled="row.live_status==1||row.status==1">
                编辑
              </el-button>
              <el-button type="text" style="color: #FA6400" @click="del(row)">
                删除
              </el-button>
              <el-button type="text" style="color: #FA6400" @click="stopSend(row)" v-if="row.status == 1">
                停止发送
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>
    <addChat
      ref="addChat"
      :openClassId="openClassId"
      :dialogstatus.sync="addChat.dialogstatus"
      @submit="$refs.childDialogBox.reset()"
    ></addChat>
    <addText
      type="msgRecordLogList"
      :openClassId="openClassId"
      :dialogstatus.sync="addText.dialogstatus"
    ></addText>
  </div>
</template>

<script>
import addChat from './components/addChat'
import addText from './components/addText'
export default {
  name: 'chatHistory',
  props: {
    openClassId: [Number, String],
  },
  data() {
    return {
      post: {
        search: '',
        open_class_id: this.openClassId,
      },
      iscomplete: false,
      tableList:[],
      addChat:{
        dialogstatus:false,
      },
      addText:{
        dialogstatus:false,
      },
    }
  },
  components:{
    addChat,
    addText,
  },
  computed: {
    stopContent(){
      return this.tableList.length?'直播推流后'+this.tableList[0].stop_time+'s开始发送话术本第一条消息':'直播推流后多少秒开始发送话术本第一条消息'
    },
  },
  filters:{
    getStatus(e){
      let str = ''
      switch (Number(e)) {
        case 1:
          str='发送中'
          break;
        case 2:
          str='未发送'
          break;
      }
      return str
    },
  },
  methods: {
    openText(){
      this.addChat.dialogstatus = true
    },
    openHistory(){
      this.addText.dialogstatus=true
    },
    edit(row) {
      this.openText()
      this.$refs.addChat.edit(JSON.parse(JSON.stringify(row)))
    },
    async stopSend(row) {
      this.$confirm('是否确定停止发送', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          await this.$http({
            url: '/console/stopMsgRecord',
            data: { msg_records_id: row.msg_records_id },
            successMsg: true,
          })
          this.$refs.childDialogBox.reset()
        })
        .catch(() => {})
    },
    // 删除
    async del(row) {
      this.$confirm('确定删除后会停止发送话术本', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          await this.$http({
            url: '/console/delMsgRecord',
            data: { msg_records_id: row.msg_records_id },
            successMsg: true,
          })
          this.$refs.childDialogBox.reset()
        })
        .catch(() => {})
    },
    // 获取列表数据
    complete(val) {
      this.tableList = val.data
      this.iscomplete = true
    },
  },
}
</script>

<style lang="scss" scoped>
.chatHistory {
  position: relative;
  padding: 0 20px;
  ::v-deep .el-table::before {
    height: 0;
  }
  ::v-deep .el-input__inner {
    border: 0;
    color: #515151;
    background: #292929;
    font-family: MicrosoftYaHei;
    font-size: 13px;
  }
  ::v-deep .childDialogBox {
    position: relative;

    .el-divider {
      background-color: #fa6400;
    }

    .pagination {
      padding-top: 20px;
      .el-pager li.active + li,
      .el-pagination button {
        background-color: #343434 !important;
        color: #fff;
      }
      .el-pager li {
        background-color: #343434;
        color: #fff;
      }
    }
    .el-pagination span {
      color: #ffffff;
    }

    .el-pager li.active {
      color: #fff;
      background-color: #fa6400;
    }

    .el-input .el-input__inner {
      background-color: #3e3e3e;
      border-color: #3e3e3e;
      color: #fff;
    }
    .el-table {
      overflow-y: auto;
      @extend %liveRoomScroll;
    }
    .el-table td {
      border-color: #313131;
    }
    .el-table__body-wrapper {
      background-color: #171717;
    }
    .el-table--fit {
      background-color: #171717;
    }

    .el-table th.is-leaf {
      border-color: #3e3e3e;
    }
    .el-table--enable-row-hover .el-table__body tr:hover > td {
      background-color: #3e3e3e !important;
    }
    .el-table::before {
      background-color: #313131;
    }
  }
}
</style>