<!-- goodList:不能选择重复数据是,把已选择的数据传给后台进行筛除 -->
<template>
  <div id="networkschoolSelect">
    <el-dialog
      :title="title"
      :modal="modal"
      class="dialogVisible"
      width="840px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      :close-on-click-modal="true"
      :destroy-on-close="true"
      :modal-append-to-body="template == 13 || template === 22? true : false"
      :append-to-body="template == 13 || template === 22 ? true : false"
      @close="cancel"
    >
      <div slot="title">
        {{ title }}
        <span class="ml10 tips">{{ tip }}</span>
      </div>

      <div class="dialogVisible_contian">
        <slot name="dialogContain"></slot>
        <el-tabs
          v-model="activeName"
          @tab-click="handleClick"
          :before-leave="template == 7 ? beforeHandle : fn()"
          v-if="
            template == 1 ||
              template == 5 ||
              template == 7 ||
              template == 10 ||
              template == 11 ||
              template == 12 ||
              template == 13 ||
              template == 14 ||
              template == 15 ||
              template == 17 ||
              template == 20
          "
        >
          <template v-if="template == 5 && !isPrivate">
            <!-- 控制台商品 -->
            <el-tab-pane label="商品" name="7"></el-tab-pane>
            <el-tab-pane label="优惠券" name="21"></el-tab-pane>
            <el-tab-pane label="免息券" name="11"></el-tab-pane>
            <el-tab-pane label="代金券" name="8"></el-tab-pane>
          </template>

          <template
            v-if="
              template == 1 ||
                template == 2 ||
                template == 5 ||
                template == 7 ||
                template == 15
            "
          >
            <el-tab-pane label="课程" name="1"></el-tab-pane>
          </template>

          <template v-if="template == 15 || template == 20">
            <el-tab-pane label="图文" name="12"></el-tab-pane>
          </template>

          <template v-if="template == 1">
            <el-tab-pane label="电子资料" name="2"></el-tab-pane>
            <el-tab-pane label="会员" name="3"></el-tab-pane>
            <el-tab-pane label="作业本" name="4"></el-tab-pane>
          </template>

          <template v-if="template == 6 || template == 7">
            <el-tab-pane label="直播" name="5"></el-tab-pane>
          </template>

          <template v-if="template == 3 || template == 7">
            <el-tab-pane label="电子资料" name="2"></el-tab-pane>
            <el-tab-pane label="图文" name="12"></el-tab-pane>
          </template>

          <template v-if="template == 4">
            <el-tab-pane label="作业本" name="4"></el-tab-pane>
          </template>

          <template v-if="template == 5">
            <el-tab-pane label="会员" name="3"></el-tab-pane>
            <!-- 控制台作业 -->
            <el-tab-pane label="作业本" name="6"></el-tab-pane>
            <el-tab-pane label="电子资料" name="2"></el-tab-pane>
          </template>

          <template v-if="template == 8">
            <el-tab-pane label="会员" name="3"></el-tab-pane>
          </template>

          <template v-if="template == 9">
            <el-tab-pane label="活动" name="9"></el-tab-pane>
          </template>

          <!-- 免息券商品选择 -->
          <template v-if="template == 10">
            <el-tab-pane label="商品" name="10"></el-tab-pane>
            <el-tab-pane label="课程" name="1"></el-tab-pane>
            <el-tab-pane label="直播" name="5"></el-tab-pane>
            <el-tab-pane label="图文" name="12"></el-tab-pane>
          </template>

          <!-- 免息券商品选择 -->
          <template v-if="template == 17">
            <el-tab-pane label="课程" name="1"></el-tab-pane>
            <el-tab-pane label="图文" name="12"></el-tab-pane>
            <el-tab-pane label="商品" name="10"></el-tab-pane>
          </template>

          <template v-if="template == 11">
            <el-tab-pane label="课程" name="1"></el-tab-pane>
            <el-tab-pane label="直播" name="5"></el-tab-pane>
            <el-tab-pane label="图文" name="12"></el-tab-pane>
          </template>
          <template v-if="template == 12">
            <el-tab-pane label="课程" name="1"></el-tab-pane>
            <el-tab-pane label="直播" name="5"></el-tab-pane>
          </template>

          <!-- 电脑网校导航设置 -->
          <template v-if="template == 13">
            <el-tab-pane label="课程" name="1"></el-tab-pane>
            <el-tab-pane label="直播" name="5"></el-tab-pane>
            <el-tab-pane label="电子资料" name="2"></el-tab-pane>
            <el-tab-pane label="商品" name="10"></el-tab-pane>
          </template>

          <template v-if="template == 14">
            <el-tab-pane label="课程" name="1"></el-tab-pane>
            <el-tab-pane label="直播" name="5"></el-tab-pane>
            <el-tab-pane label="电子资料" name="2"></el-tab-pane>
            <el-tab-pane label="会员" name="3" v-if="hadMember"></el-tab-pane>
            <el-tab-pane
              label="商品"
              v-if="Boolean(goodlistType)"
              name="10"
            ></el-tab-pane>
            <el-tab-pane label="图文" name="12"></el-tab-pane>
          </template>
        </el-tabs>

        <!-- 图文 -->
        <div v-if="activeName == '12'" :key="12">
          <data-contain
            :seckill_id="seckill_id"
            :is_seckill="!!is_seckill"
            :installment_id="installment_id"
            :p_group_id="p_group_id"
            :goodlistType="goodlistType"
            :goodList="template != 15 ? goodList : undefined"
            @listSave="listSave"
            @singleselectcontent="singleselectcontent"
            :ids="template == 7 ? ids[3] : ids"
            :isnaire="template == 7 || template == 20"
            :isSingleSelect="template == 12 || (template == 17 && singleselect)"
            ref="dialogRef"
          ></data-contain>
        </div>

        <!-- 免息券 -->
        <div v-if="activeName == '11'">
          <Interestfree-coupon
            :open_class_id="open_class_id"
          ></Interestfree-coupon>
        </div>

        <!-- 控制台作业 -->
        <div v-if="activeName == '6'">
          <console-workselect
            :open_class_id="open_class_id"
            :template="template"
            @push="push"
            ref="dialogRef"
            @singleselectcontent="singleselectcontent"
          ></console-workselect>
        </div>

        <!-- 课程 -->
        <div v-if="activeName == '1'">
          <dialog-selectcourse
            :seckill_id="seckill_id"
            :is_seckill="!!is_seckill"
            :goodList="goodList"
            :goodlistType="goodlistType"
            :installment_id="installment_id"
            :p_group_id="p_group_id"
            :isnaire="template == 7"
            :singleselect="
              template == 1 ||
                template == 5 ||
                template == 12 ||
                template == 13 ||
                (template == 17 && singleselect)
            "
            :price_mold="price_mold"
            :showPassword="showPassword"
            :isnerworkselect="template != 7"
            :ids="template == 7 ? ids[0] : ids"
            ref="dialogRef"
            class="pd20 select-course-box"
            :type="2"
            :template="template"
            :isLeftMenu="isLeftMenu"
            :isCourseType="isCourseType"
            :isDefaultCourseType="isDefaultCourseType"
            
            :isOverSingleSelect="isOverSingleSelect"
            :isOtherSingleSelect="isOtherSingleSelect"
            :isOverMutipleSelect="isOverMutipleSelect"
            :isOtherMutipleSelect="isOtherMutipleSelect"
            @push="push"
            @listSave="listSave"
            @singleselectcontent="singleselectcontent"
          ></dialog-selectcourse>
        </div>

        <!-- 电子资料 -->
        <div v-if="activeName == '2'" :key="2">
          <dataSelect
            :isnaire="template == 7"
            :type="1"
            :goodList="goodList"
            :goodlistType="goodlistType"
            :template="template"
            @push="push"
            :ids="template == 7 ? ids[2] : ids"
            ref="dialogRef"
            :isSingleSelect="
              !(template == 3 || template == 7 || template == 14)
            "
            @listSave="listSave"
            @singleselectcontent="singleselectcontent"
          ></dataSelect>
        </div>

        <!-- 会员 -->
        <div v-if="activeName == '3'" :key="3">
          <dataSelect
            :type="2"
            :isnaire="template == 8"
            ref="dialogRef"
            :ids="ids"
            :goodList="goodList"
            :goodlistType="goodlistType"
            :template="template"
            @push="push"
            :isSingleSelect="!(template == 8 || template == 14)"
            @listSave="listSave"
            @singleselectcontent="singleselectcontent"
          ></dataSelect>
        </div>

        <!-- 作业 -->
        <div v-if="activeName == '4'" :key="4">
          <dataSelect
            :type="3"
            :ids="ids"
            ref="dialogRef"
            :isSingleSelect="!(template == 4)"
            @listSave="listSave"
            @singleselectcontent="singleselectcontent"
          ></dataSelect>
        </div>

        <!-- 直播 -->
        <div v-if="activeName == '5'" :key="5">
          <dataSelect
            :installment_id="installment_id"
            :p_group_id="p_group_id"
            :goodList="goodList"
            :goodlistType="goodlistType"
            :isnaire="template == 7"
            :id_type="template == 7 ? 3 : id_type"
            :group_id="group_id"
            :group_name="group_name"
            :id="id"
            :type="5"
            :ids="template == 7 ? ids[1] : ids"
            ref="dialogRef"
            :isSingleSelect="template == 12 || template == 13 || template == 16"
            @listSave="listSave"
            @singleselectcontent="singleselectcontent"
          ></dataSelect>
        </div>

        <!-- 推送商品 -->
        <div v-if="activeName == '7' && template != 5" :key="7">
          <commodity :isSingleSelect="template == 13"></commodity>
        </div>

        <!-- 代金卷 -->
        <div v-if="activeName == '8'" :key="8">
          <cash-coupon :open_class_id="open_class_id"></cash-coupon>
        </div>

        <!-- 活动 -->
        <div v-if="activeName == '9'" :key="9">
          <select-activity
            :isnaire="template == 9"
            :ids="ids"
            ref="dialogRef"
            :isSingleSelect="false"
            @listSave="listSave"
            @singleselectcontent="singleselectcontent"
          ></select-activity>
        </div>

        <!-- 商品选择 -->
        <div v-if="activeName == '10'" :key="10">
          <commodity-selection
            :seckill_id="seckill_id"
            :is_seckill="!!is_seckill"
            :goodList="goodList"
            :goodlistType="goodlistType"
            :goods_class="goods_class"
            @listSave="listSave"
            ref="dialogRef"
            :ids="ids"
            :installment_id="installment_id"
            :p_group_id="p_group_id"
            :isSingleSelect="
              !(template == 14) && template == 17 && singleselect
            "
            @singleselectcontent="singleselectcontent"
          ></commodity-selection>
        </div>

        <!-- 优惠券 -->
        <div v-if="activeName === '21'" :key="21">
          <coupon-coupons></coupon-coupons>
        </div>

        <!-- 添加优惠券 -->
        <div v-if="activeName === '23'" :key="23">
          <couponSelect 
            ref="dialogRef"
            :isSingleSelect="singleselect"
            @listSave="listSave"
          ></couponSelect>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <div v-if="activeName != '7' && activeName != '8'">
          <el-button @click="cancel" size="medium" style="width: 98px">
            取 消
          </el-button>
          <el-button
            v-if="template == 11"
            type="primary"
            @click="submit"
            size="medium"
            style="width: 98px"
          >
            下一步({{ listSaveCount.length }})
          </el-button>
          <el-button
            v-else-if="template == 14"
            type="primary"
            @click="submit"
            size="medium"
          >
            确 定
            <span v-if="goodtype == 2">
              {{ recommendList.length + listSaveCount.length }}/{{ limit }}
            </span>
            <span v-else>
              {{ goodList.length + listSaveCount.length }}/{{ limit }}
            </span>
          </el-button>
          <el-button
            v-else-if="(activeName == '5' || template == 10) && template != 12"
            type="primary"
            @click="submit"
            size="medium"
            style="width: 98px"
          >
            确定
            <template v-if="template != 16">
              ({{ listSaveCount.length }})
            </template>
          </el-button>

          <el-button
            v-else-if="template != 5"
            type="primary"
            @click="submit"
            size="medium"
            style="width: 98px"
          >
            确 定
            <span v-if="template == 7">({{ listSaveCount.length }})</span>
          </el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import dataContain from './graphic/dataContain'
import InterestfreeCoupon from './InterestfreeCoupon.vue'
import commoditySelection from './commoditySelection.vue'
import selectActivity from './selectActivity'
import consoleWorkselect from './consoleWorkselect'
import commodity from './commodity'
import cashCoupon from './cashCoupon'
import couponCoupons from './couponCoupons'
import couponSelect from './conponSelect.vue'
import { mapState } from 'vuex'
//电子资料
import dataSelect from '@cm/base/networktypeSelect/dataSelect'
//课程
import dialogSelectcourse from '@/components/base/dialogSelectcourse'
export default {
  components: {
    InterestfreeCoupon,
    dialogSelectcourse,
    dataSelect,
    consoleWorkselect,
    commodity,
    cashCoupon,
    couponCoupons,
    couponSelect,
    selectActivity,
    commoditySelection,
    dataContain,
  },

  data() {
    return {
      allval: [],

      listSaveCount: [],

      singleselectcontentval: {},

      course: [],
      // -1取消模板
      activeName:
        this.template == -1 
        ? '-1':
        this.template == 1 ||
        this.template == 2 ||
        this.template == 13 ||
        this.template == 14 ||
        this.template == 15 ||
        this.template == 17
          ? '1'
          : this.template == 5 && !this.isPrivate
          ? '7'
          : this.template == 5 && this.isPrivate
          ? '6'
          : this.template == 3
          ? '2'
          : this.template == 6 || this.template == 16 || this.template == 24
          ? '5'
          : this.template == 7
          ? '1'
          : this.template == 8
          ? '3'
          : this.template == 9
          ? '9'
          : this.template == 10
          ? '10'
          : this.template == 11 || this.template == 12
          ? '1'
          : this.template == 20
          ? '12'
          : this.template == 22
          ? '23'
          : '4', //tabs控制

      dialogVisible: false, //弹窗的开关
    }
  },

  created() {
    //重新拉取树形菜单
    if (this.activeName == '1') {
      this.getcoursedata()
    }
    if (this.recommendList && this.recommendList.length > 0) {
      this.goodList.push(...this.recommendList)
    }
  },

  watch: {
    activeName() {
      this.$emit('activeName', this.activeName)
    },
  },

  computed: {
    ...mapState(['userInfo']),
  },

  props: {
    // 秒杀--一个课程或内容只能被秒杀选择一次
    seckill_id: {
      type: String,
      default: undefined,
    },
    // 秒杀
    is_seckill: {
      type: Boolean,
      default: false,
    },
    //是否是单选
    singleselect: {
      type: Boolean,
      default: false,
    },
    // 是否需要过滤免费/密码课成---false 为不需要 ，当前超级会员不需要，在template == 14中，
    // 有优惠券和超级会员使用，搭配goodlist使用,默认是不需要过滤的
    goodlistType: {
      type: Boolean,
      default: false,
    },

    price_mold: {
      type: Number,
      default: undefined,
    },

    // 作业选择时，显示免费的选择项
    showPassword: {
      type: Number,
      default: undefined,
    },

    //template == 14中是否有会员选项
    hadMember: {
      type: Boolean,
      default: true,
    },

    goodtype: {
      type: Number,
      default: 1,
    },

    // 限制选择的个数
    limit: {
      type: Number,
      default: 100,
    },

    // 商品的选择
    goods_class: {
      type: Number,
      default: undefined,
    },

    // 红色提示
    tip: {
      type: String,
      default: '',
    },
    modal: {
      type: Boolean,
      default: true,
    },

    recommendList: {
      type: Array,
      default: () => [],
    },

    // 优惠券添加指定商品-只能选择付费的课程  默认应该为1，是否需要过滤已选择的课程
    goodList: [String, Array, Object],

    // 是否是私聊
    isPrivate: {
      type: Boolean,
      default: false,
    },

    open_class_id: {
      type: String,
      default: undefined,
    },

    // 免息券id 如果是免息券，只需要付费的
    installment_id: {
      type: String,
      default: undefined,
    },

    // 推广员 添加商品
    p_group_id: {
      type: String,
      default: undefined,
    },

    title: {
      type: String,
      default: '选择网校内容(单选)',
    },

    // 4,作业 5是公开课控制台推送课程 6直播 7信息采集报名时 8信息采集购买会员时 13,电脑网校导航设置
    template: {
      type: Number,
      default: 1,
    },

    dialogstatus: {
      type: Boolean,
      default: false,
    },

    ids: [Array, Object],

    // 作业-公开课选择用到
    // 作业类型
    id_type: {
      type: Number,
      default: undefined,
    },

    // 分组id
    group_id: {
      type: String,
      default: undefined,
    },
    // 分组名字
    group_name: {
      type: String,
      default: undefined,
    },

    // 作业id
    id: {
      type: Number,
      default: undefined,
    },
    isLeftMenu:{
      type:Boolean,
      default:true,
    },
    isCourseType:{
      type:Boolean,
      default:true,
    },
    isDefaultCourseType:{
      type: String,
      default:"",
    },
    /** 暂时只有公开课的添加直播课用到 */
    // 是否覆盖默认选择
    isOverSingleSelect:{
      type:Boolean,
      default:true,
    },
    // 是否赋值其他默认选择
    isOtherSingleSelect:{
      type:Boolean,
      default:false,
    },
    // 是否覆盖默认多项选择
    isOverMutipleSelect:{
      type:Boolean,
      default:false,
    },
    // 是否赋值其他默认多项选择
    isOtherMutipleSelect:{
      type:Boolean,
      default:false,
    },
  },

  methods: {
    listSave(val) {
      this.listSaveCount = val
    },

    singleselectcontent(val) {
      this.singleselectcontentval = val
    },

    // template === 5 推送
    push() {
      setTimeout(() => {
        this.submit()
      }, 50)
    },

    submit() {
      const flag =
        this.template == 14 &&
        this.goodList.length + this.listSaveCount.length > this.limit
      if (this.template == 14 && flag) {
        this.$root.prompt(`添加的商品不能超过${this.limit}个`)
        return
      }

      this.isopen = true
      this.$store.commit('setUserInfoRequest', null)
      this.$store.dispatch('getUserInfo').then(() => {
        //is_workbook作业   is_course_member 会员  is_electronics 电子资料
        if (this.activeName == 2 && this.userInfo.is_electronics == 2) {
          this.$root.prompt({
            msg: '请先开通电子资料功能！',
          })
          this.isopen = false
        }
        if (this.activeName == 3 && this.userInfo.is_course_member == 2) {
          this.$root.prompt({
            msg: '请先开通会员功能！',
          })
          this.isopen = false
        }
        if (
          (this.activeName == 4 ||
            this.activeName == 6 ||
            this.activeName == 16) &&
          this.userInfo.is_workbook == 2
        ) {
          this.$root.prompt({
            msg: '请先开通作业本功能！',
          })
          this.isopen = false
        }

        if (this.isopen) {
          var val =
            this.template == 1 ||
            this.template == 5 ||
            this.template == 10 ||
            this.template == 12 ||
            this.template == 16 ||
            this.template == 13
              ? this.singleselectcontentval
              : {}
          if (this.template == 5 && Object.keys(val).length > 0) {
            val.selectType = this.activeName
          }

          this.allval =
            this.template == 1 ||
            this.template == 5 ||
            this.template == 12 ||
            this.template == 16 ||
            this.template == 13
              ? val
              : this.$refs.dialogRef.listSave

          if (
            Object.keys(val).length > 0 ||
            (this.$refs.dialogRef.listSave &&
              this.$refs.dialogRef.listSave.length > 0)
          ) {
            this.$emit('singleselectcontentval', this.allval)
          } else {
            this.$root.prompt('请先选择内容')
            return
          }
          if (this.template == 13 || this.template == 16) {
            this.$emit('update:dialogstatus', false)
          } else {
            this.cancel()
          }
        }
      })
    },

    cancel() {
      this.$emit('selectChangenetworkschool')
    },

    //初始化课程的数据
    getcoursedata() {
      let _this = this
      //在信息采集中，发送过来一个对象id，分别是直播课，公开课，会员的，要区分，发送给后台过滤数据，
      // 一开始使用全部数据一起分别发送的方法，但有可能公开课中要过滤的内容id跟其他两的id重复了。
      let ids = _this.template == 7 ? _this.ids[0] : _this.ids
      this.$nextTick(() => {
        _this.$refs.dialogRef.getMenu(ids, _this.member_id)
        _this.$refs.dialogRef.clearSelect()
      })
    },

    fn() {},

    beforeHandle() {
      if (this.listSaveCount.length > 0) {
        var p = new Promise((resolve, reject) => {
          this.$confirm('现在切换已选的内容会丢失，是否确定？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          })
            .then(() => {
              this.listSaveCount = []
              // 你可以在这儿做些操作
              resolve()
            })
            .catch(err => {
              // 你可以在这儿做些操作
              reject(err)
            })
        })
        return p
      }
    },

    //切换tabs时触发的时间
    handleClick({ name }) {
      if (!(this.template == 7 && this.listSaveCount.length > 0)) {
        this.listSaveCount = []
      }
      if (name == '1') {
        this.getcoursedata()
      }

      if (this.template == 5) {
        this.singleselectcontentval = []
        this.listSaveCount = []
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .rightContent .pagination2Box {
  width: 97%;
}

// ::v-deep .pagination2Box {
//   margin: 20px 20px 0;
// }

::v-deep .el-dialog__body {
  padding: 10px 20px !important;
}
::v-deep .el-dialog__footer {
  padding-top: 0;
}
#networkschoolSelect {
  .tips {
    font-size: 14px;
    color: #ff3535;
  }

  ::v-deep .el-dialog {
    min-width: 558px;
  }

  .pd20 {
    padding: 10px 20px 20px;
    box-sizing: border-box;
  }

  .dialogVisible_contian {
    ::v-deep .el-tabs__nav-scroll {
      padding: 0 20px;
    }
  }
}
</style>
