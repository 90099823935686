<!--
 * @Author: cyy
 * @Date: 2021-12-14 15:21:56
 * @LastEditTime: 2022-11-25 16:59:23
 * @LastEditors: pengmingjun 1317525216@qq.com
 * @Description: 
 * @FilePath: \ teacher.dingdingkaike\src\views\OpenClass_openClassLive\components\rightcomponents\main.vue
-->  
<template>
  <div class="right-contain">
    <div
      v-if="(!isPrivate && !openAndClose) || openAndClose"
      class="menbers"
      ref="menbers"
      :style="
        !openAndClose
          ? 'position:fixed;left:0;width:300px;height:100%;background:#252629;top:0;width:100%'
          : ' '
      "
      :class="{
        height_auto: activeName == 7 && !currMember.uname,
        hide: chatFullScreen,
      }"
    >
      <div :style="!openAndClose ? 'width:80%; ' : ' '">
        <div class="filtersList" v-if="!isWindowsApp">
          <div class="nfont1">
            <el-select
              @change="assistantChange"
              size="medium"
              popper-class="rightcomponents-select"
              style="width: 100px"
              v-model="hadTeacherid"
              placeholder="请选择"
              :visible-arrow="false"
            >
              <el-option
                v-for="item in assistant"
                :key="item.teacher_id"
                :label="item.uname"
                :value="item.teacher_id"
              ></el-option>
              <div style="padding-left: 20px">
                <el-button
                  type="text"
                  @click="openclassDatastatisticsActive([0, 2])"
                  style="color: #3d91f7"
                >
                  查看邀请榜单
                </el-button>
              </div>
            </el-select>
          </div>
          <div class="nfont1 nfont2" v-if="hadTeacherid == 0">
            <el-checkbox
              v-model="invitationChecked"
              @change="invitationCheckValChange"
              true-label="1"
              false-label="0"
              size="mini"
            >
              只看我邀请的
            </el-checkbox>
          </div>
        </div>
        <el-tabs
          ref="tabs"
          class="maintabs"
          :value="activeName"
          @tab-click="handleClick"
        >
          <el-tab-pane label="消息" v-if="!openAndClose" name="7"></el-tab-pane>

          <!-- 客户端 -->
          <!-- <el-tab-pane name="6" v-if="isWindowsApp">
            <div slot="label" class="status_select">
              {{ selectStatusval.label }}
              <el-popover
                class="popover"
                popper-class="rightcomponents_popover"
                :visible-arrow="false"
                placement="bottom"
                width="89"
                trigger="click"
              >
                <div
                  @click.stop="selectStatus(i)"
                  class="popover_item"
                  v-for="(i, index) in options2"
                  :key="index"
                  :style="
                    selectStatusval.value == i.value ? 'color:#FA6400' : ''
                  "
                >
                  {{ i.label }}
                </div>

                <i
                  class="el-icon-arrow-down"
                  @click="activeName == 6 ? $event.stopPropagation() : ''"
                  slot="reference"
                ></i>
              </el-popover>
            </div>
          </el-tab-pane> -->

          <!-- web端-->
          <el-tab-pane :label="`在线`" name="8"></el-tab-pane>
          <el-tab-pane :label="`离线`" name="9"></el-tab-pane>

          <!-- <el-tab-pane :label="`连麦`" name="2"></el-tab-pane> -->

          <!-- 提示红点 -->
          <!-- :is-dot="newtip && activeName != 5" -->
          <el-tab-pane label="问答" name="5">
            <span slot="label" v-if="(newPriMsgs+newQAMsgs)>0">
              问答<el-badge :value="newPriMsgs+newQAMsgs"></el-badge>
            </span>
            <span v-else slot="label">问答</span>
          </el-tab-pane>

          <el-tab-pane label="助教" name="3" v-if="openAndClose"></el-tab-pane>

          <el-tab-pane name="4" v-if="openAndClose && isWindowsApp">
            <div
              slot="label"
              class="flex-center"
              style="height: 100%; justify-content: center"
            >
              <img
                v-if="activeName == 4"
                style="line-height: 40px"
                src="~@ass/img/OpenClass_openClassLive/icon_kzt_gnq_xz.svg"
                alt=""
              />
              <img
                v-else
                style="line-height: 40px"
                src="~@ass/img/OpenClass_openClassLive/icon_kzt_gnq.svg"
                alt=""
              />
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>

      <div class="uphoto fr" v-if="!openAndClose">
        <reset></reset>
        <oepnAndClose></oepnAndClose>
      </div>

      <template v-if="activeName != 7">
        <!-- 在线、离线、助教 -->
        <template
          v-if="
            activeName == 3 ||
            activeName == 6 ||
            activeName == 4 ||
            activeName == 8 ||
            activeName == 9
          "
        >
          <div
            class="online"
            :style="`${!openAndClose ? 'background:#000' : ''};${sendAll?'height: calc(100% - 266px);':'height: calc(100% - 96px);'}`"
            v-if="activeName != 4"
          >
            <div v-if="activeName == 6 || activeName == 8 || activeName == 9">
              <div
                class="tab"
                v-if="selectStatusval.value == 1 || activeName == 8"
              >
                <div
                  class="cp"
                  :style="
                    isSelect == 1 ? 'color:#FFFFFF;font-weight: bold;' : ''
                  "
                  @click="onlineClick(1)"
                >
                  在线
                  <span v-if="onlineCount">({{ copyMemberlistlength }})</span>
                </div>

                <div
                  class="ml20 cp"
                  :style="
                    isSelect == 2 ? 'color:#FFFFFF;font-weight: bold;' : ''
                  "
                  @click="onlineClick(2)"
                >
                  已禁言
                  <span v-if="forbiddenWordsCount">
                    ({{ copyMemberlistlength }})
                  </span>
                </div>
              </div>
              <template
                v-else-if="selectStatusval.value == 2 || activeName == 9"
              >
                <div class="tip2">
                  提示：仅显示本次直播的离线成员，以往直播的离线成员已过滤
                </div>
                <div class="tab">
                  <div
                    class="cp mr20"
                    :style="
                      isSelect == 1 ? 'color:#FFFFFF;font-weight: bold;' : ''
                    "
                    @click="onlineClick(1)"
                  >
                    离线
                    <span v-if="offLineCount">
                      ({{ copyMemberlistlength }})
                    </span>
                  </div>
                  <div
                    class="mr20 cp"
                    :style="
                      isSelect == 2 ? 'color:#FFFFFF;font-weight: bold;' : ''
                    "
                    @click="onlineClick(2)"
                  >
                    已拉黑
                    <span v-if="offBlockCount">
                      ({{ copyMemberlistlength }})
                    </span>
                  </div>
                  <div
                    class="cp mr20"
                    :style="
                      isSelect == 3 ? 'color:#FFFFFF;font-weight: bold;' : ''
                    "
                    @click="onlineClick(3)"
                  >
                    已踢出
                    <span v-if="kickOutCount">
                      ({{ copyMemberlistlength }})
                    </span>
                  </div>
                  <div
                    class="cp"
                    :style="
                      isSelect == 4 ? 'color:#FFFFFF;font-weight: bold;' : ''
                    "
                    @click="onlineClick(4)"
                  >
                    已禁言
                    <span v-if="offLineForbiddenWordsCount">
                      ({{ copyMemberlistlength }})
                    </span>
                  </div>
                </div>
              </template>
            </div>
            <div class="input_class" v-if="activeName != 3">
              <!-- <p style="color: #fff">
                {{ search_type }}
              </p> -->
              <el-input
                class="hadprepend_select"
                v-if="invitationChecked == 0 && hadTeacherid == 0"
                size="medium"
                placeholder="搜索"
                v-model="userName"
              >
                <el-select
                  @change="inviteType"
                  size="medium"
                  v-model="search_type"
                  slot="prepend"
                  placeholder="请选择"
                  style="padding-right: 0;"
                >
                  <el-option label="全部成员" value="1"></el-option>
                  <el-option label="内部邀请人" value="2"></el-option>
                  <el-option label="外部邀请人" value="3"></el-option>
                </el-select>
              </el-input>
              <el-input
                v-else
                size="medium"
                placeholder="搜索"
                prefix-icon="el-icon-search"
                v-model="userName"
              ></el-input>
              <el-button
                style="font-weight: bold;margin-left: 10px;"
                :style="sendAll?'color:#2A2A2A':'color:#fa6400'"
                type="text"
                size="mini"
                :disabled="sendAll"
                @click="sendAll=true"
                >
                群发私信
              </el-button>
            </div>
            <div class="online_filter" v-if="sendAll">
              <div :class="['nfont1', 'nfont2',copyMemberlist.length==0?'nfont3':'']">
                <el-checkbox v-model="selectAll" :disabled="copyMemberlist.length==0">全选</el-checkbox>
              </div>
              <div class="nfont1">
                <el-select
                  class="placeholder333"
                  multiple
                  @focus="getTagList"
                  v-model="search.tag_ids"
                  placeholder="学员标签"
                  style="width: 100px;"
                >
                  <el-option
                    @click.native="otherTag(-1)"
                    label="全部"
                    :value="-1"
                  ></el-option>
                  <el-option
                    @click.native="otherTag(-2)"
                    label="无标签"
                    :value="-2"
                  ></el-option>
                  <el-option
                    @click.native="otherTag(item.value)"
                    v-for="item in options4"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
              <div class="nfont1">
                <el-select
                  size="medium"
                  v-model="search.gid"
                  popper-class="rightcomponents-select"
                  placeholder="学员分组"
                  style="width: 100px;"
                >
                    <el-option label="全部" :value="-1"></el-option>
                    <el-option label="未分组" :value="-2"></el-option>
                    <el-option
                      v-for="item in options3"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                      <span style="float: left">
                        {{ item.label }}
                      </span>
                      <span style="float: right; color: #999999; font-size: 12px">
                        {{ item.count || 0 }}人
                    </span>
                  </el-option>
                </el-select>
              </div>
            </div>
            <div
              class="onlinecontain"
              v-loading="loading"
              element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
              :element-loading-background="openAndClose ? '#171717' : '#000'"
            >
              <div
                :ref="`popover-${i.uid}`"
                v-for="i in members"
                :key="i.uid"
                @click="
                  activeName != 3 && openAndClose ? currMemberSelect(i.uid) : ''
                "
                slot="reference"
                :style="
                  i.uid == currMember.uid ? 'background-color: #28292c;' : ''
                "
              >
                <smallotherfn-contain
                  v-if="!openAndClose"
                  class="online_main_contain"
                  :isMemberlist="true"
                  :currMemberlist="i"
                  @otherfnPrivateletter="sendPrivate(i)"
                  @smallotherfnContainComplete="getMemberList"
                >
                  <div class="tu">
                    <img :src="i.uphoto" alt="" />
                    <div class="equipment" v-if="selectStatusval.value == 1">
                      <!-- 电脑 -->
                      <img
                        v-if="i.user_from == 1"
                        src="~@ass/img/1.4.2.8/icon_dnzx.svg"
                        alt=""
                      />
                      <!-- 移动 -->
                      <img
                        v-else
                        src="~@ass/img/1.4.2.8/icon_sjzx.svg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="name_describe">
                    <div class="name" :title="i.uname">{{ `${i.student_remarks?'('+i.student_remarks+')':''}`+i.uname }}</div>
                    <div class="describe">
                      <div v-if="i.Invitee" class="mt10">
                        {{ i.Invitee }} 邀请
                      </div>
                      <div v-else-if="activeName == 3" class="mt10">
                        {{ i.role | getRole }}
                      </div>
                    </div>
                  </div>
                  <div class="selectFriend">
                    <el-checkbox v-model="i.checked" v-if="sendAll" @click.native="e=>e.stopPropagation()" @change.native="mChecked"></el-checkbox>
                  </div>
                </smallotherfn-contain>
                <div style="display: flex" v-else class="online_main_contain">
                  <div class="tu">
                    <img :src="i.uphoto" alt="" />
                    <div class="equipment" v-if="selectStatusval.value == 1">
                      <!-- 电脑 -->
                      <img
                        v-if="i.user_from == 1"
                        src="~@ass/img/1.4.2.8/icon_dnzx.svg"
                        alt=""
                      />
                      <!-- 移动 -->
                      <img
                        v-else
                        src="~@ass/img/1.4.2.8/icon_sjzx.svg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="name_describe">
                    <div class="name" :title="i.uname">{{ `${i.student_remarks?'('+i.student_remarks+')':''}`+ i.uname }}</div>
                    <div class="describe">
                      <div v-if="i.Invitee" class="mt10">
                        {{ i.Invitee }}邀请
                      </div>
                      <div v-else-if="activeName == 3" class="mt10">
                        {{ i.role | getRole }}
                      </div>
                    </div>
                  </div>
                  <el-checkbox v-model="i.checked" v-if="sendAll" @click.native="e=>e.stopPropagation()" @change.native="mChecked"></el-checkbox>
                </div>
              </div>

              <div v-if="memberEmpty && rightOpen" class="empty">
                <div class="text">暂无数据~</div>
              </div>
            </div>
            <message
              v-if="sendAll"
              ref="message"
              style="width:100%;height:170px;position:absolute;bottom:0px;"
              @sendmessage="sendPrivateAll"
              @cancelSend="cancelSend"
              :openInfo="openInfo"
              :isHasCancelSend="true"
              :isC2Cmsg="true"
              :isPrivate="true"
              :isRightComponent="true"
              :isNeedQuote="false"

              @imgStart2="imagecomplete2 = false"
              @percentage2="imageProgress2 = arguments[0]"
              @imgCompress2="imageProgress2 = 0"
              :imageProgress2="imageProgress2"
              :imagecomplete2="imagecomplete2"
            ></message>
          </div>
          <!-- <div
            class="online"
            :style="!openAndClose ? ' background:#000' : ''"
            v-else
          >
            <btns :openClassId="openClassId" :openInfo="openInfo"></btns>
          </div> -->
        </template>
        <!-- 问答 -->
        <template v-else-if="activeName == 5">
          <div
            class="questionAndAnswer"
            :style="!openAndClose ? ' background:#000' : ''"
          >
            <el-radio-group v-model="radio4" class="radio" size="mini">
              <el-radio-button :label="1">
                <!-- 私信 -->
                <el-badge
                  v-if="newPriMsgs>0"
                  class="item"
                  :value="newPriMsgs"
                >
                  私信
                </el-badge>
                <span v-else>私信</span>
              </el-radio-button>
              <el-radio-button :label="2">
                <!-- 提问 -->
                <el-badge
                  v-if="newQAMsgs>0"
                  class="item"
                  :value="newQAMsgs"
                >
                  提问
                </el-badge>
                <span v-else>提问</span>
              </el-radio-button>
            </el-radio-group>
            <template v-if="radio4 == 1">
              <el-popconfirm
                title="确定清空私信列表？"
                icon=""
                style="text-align: right;padding-right: 20px;"
                @confirm="delAllFriend()"
              >
                <span slot="reference" class="cp" style="color: #1b9d97;font-size: 14px;">一键清空私信列表</span>
              </el-popconfirm>
              <div class="listcontain" v-if="friendList.length > 0">
                <div
                  class="item"
                  @click="friendSelect(item, 1)"
                  :style="selectID == item.userID ? 'background:#303030FF' : ''"
                  v-for="(item, index) in friendList"
                  :key="index"
                >
                  <div class="leftPic">
                      <img :src="item.profile.avatar" alt="" />
                      <span class="el-badge__content flMsgNums" v-if="item.priMsgNums">{{ item.priMsgNums }}</span>
                  </div>
                  <div class="rigthcontain">
                    <div class="name_inviter">
                      {{ `${item.profile.selfSignature?'('+item.profile.selfSignature+')':''}`+ item.profile.nick }}
                      <span class="sp1" v-if="item.remark">
                        & {{ item.remark }}
                      </span>

                      <div class="popconfirmcontain" @click.stop>
                        <el-popconfirm
                          class="popconfirm"
                          @confirm="delfrind(item.userID)"
                          title="是否确定移出私信列表？"
                        >
                          <i
                            slot="reference"
                            class="el-icon-close fr cp"
                            style="padding: 5px"
                          ></i>
                        </el-popconfirm>
                      </div>
                    </div>
                    <div class="status">
                      [{{ item.status ? '在线' : '离线' }}]{{
                        item.messageForShow
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="isprivateEmpty && rightOpen" class="empty">
                <div class="text">暂无数据~</div>
              </div>
            </template>
            <template v-if="radio4 == 2">
              <!-- {{ questionList }} -->
              <!-- {{ questionList }}   
            v-loading="questionList.length == 0"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="#202124" -->
              <!-- 文本消息 -->
              <div class="question-contain">
                <div
                  class="aquestion"
                  v-for="(item, index) in questionList"
                  :key="index"
                >
                  <div class="photo">
                    <img :src="item.avatar" alt="" />
                  </div>
                  <div class="name_message">
                    <div class="name">
                      <span class="tname">
                        {{ `${item.student_remarks?'('+item.student_remarks+')':''}`+item.nick }}
                      </span>
                      <span class="invite-people" v-if="item.inviter_name">
                        & {{ item.inviter_name }}
                      </span>
                    </div>
                    <div class="tag" v-if="item.tag_names">
                      {{ item.tag_names }}
                    </div>

                    <div class="message">
                      <div style="position: relative">
                        <div
                          class="item"
                          v-html="item.text"
                          :style="item.isQuestion ? 'padding: 8px 20px;' : ''"
                        ></div>
                        <div class="question" v-if="item.isQuestion">
                          <img
                            src="~@ass/img/1.4.4.5/icon_kzt_twbs@2x.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <span class="time">
                        <p class="otherfn">
                          <otherfn-contain
                            :Quote="true"
                            :isManager="item.role ? true : false"
                            @otherfnQuote="$emit('otherfnQuote', item)"
                            @otherfnPrivateletter="
                              $emit('otherfnPrivateletter', item)
                            "
                            @otherfnContainsDel="
                              $emit('otherfnContainsDel', item)
                            "
                          >
                            <i class="el-icon-more"></i>
                          </otherfn-contain>
                        </p>
                        {{ item.time | formatTimeStamp('MM-dd hh:mm:ss') }}
                      </span>
                    </div>
                    <div class="quto" v-if="item.replyMsg">
                      {{ item.replyMsg }}
                    </div>
                  </div>
                </div>
                <div v-if="questionList.length == 0 && rightOpen" class="empty">
                  <div class="text">暂无数据~</div>
                </div>
              </div>
            </template>
          </div>
        </template>
      </template>
      <div class="memberDetail" v-if="currMember.uname">
        <div class="detail">
          <div class="popovercontian_menbers">
            <div class="people">
              <div class="righttu">
                <el-image :src="currMember.uphoto" lazy></el-image>
              </div>
              <div class="name_status">
                <div class="name">{{ currMember.student_remarks||currMember.uname }}</div>
                <div class="status" v-show="currMember.student_remarks" style="margin-bottom: 5px;">
                  {{ currMember.uname }}
                </div>
                <div class="status">
                  {{ currMember.user_from | getDev }}
                </div>
              </div>
            </div>
            <ul class="contain">
              <li>进入次数：{{ currMember.visit_num }}</li>
              <li>在线时长：{{ currMember.visit_duration }}</li>
              <li :title="currMember.tag">
                标签：{{ currMember.tag || '无' }}
              </li>
              <li :title="currMember.gname">
                分组：{{ currMember.gname || '无' }}
              </li>
              <li>
                注册来源：{{
                  currMember.reg_source == 1
                    ? 'h5网校'
                    : currMember.reg_source == 2
                    ? '叮叮开课直播小程序'
                    : '推广招生-抖音广告'
                }}
              </li>
              <li class="cp" @click.stop="addMemberDes">
                描述：{{ currMember.ujianjie||'无' }}
              </li>
            </ul>
            <div class="mb10">
              <el-button
                @click="sendPrivate(currMember)"
                type="primary"
                style="width: 97%; border-radius: 0"
                size="medium"
              >
                私信
              </el-button>
            </div>
            <div class="buttons">
              
              <div class="item" @click.stop="addMemberDes">备注</div>
              <span style="color: #fff">|</span>
              <tagselect
                class="item"
                :tag="currMember.tag"
                :uids="currMember.uid"
                :isLive="true"
                :nothing="true"
                @tagChange="tagChange"
              >
                <div slot="opencouse">设标签</div>
              </tagselect>
              <span style="color: #fff">|</span>
              <div class="item" @click="fenzu()">分组</div>
            </div>
            <div class="bottons2">
              <div
                class="item"
                @click="auth(1, 1),closeAddDes()"
                v-if="currMember.is_mute == 2"
              >
                禁言
              </div>
              <div class="item" @click="auth(1, 2)" v-else>取消禁言</div>
              <span>|</span>
              <div
                class="item"
                @click="auth(2, 1),closeAddDes()"
                v-if="currMember.is_kick_out == 2"
              >
                踢出
              </div>
              <div class="item" @click="auth(2, 2)" v-else>取消踢出</div>
              <span>|</span>
              <div
                class="item"
                @click="auth(3, 2),closeAddDes()"
                v-if="currMember.ustatus == 1"
              >
                拉黑
              </div>
              <div class="item" @click="auth(3, 1),closeAddDes()" v-else>取消拉黑</div>
            </div>
            <div class="bottons2">
              <div
                class="item"
                @click="auth(4, 1),closeAddDes()"
                v-if="currMember.is_shield == 2"
              >
                屏蔽发言<helpIcon class="ml5" content="学生发送的消息只能自己看到，其他学生看不到，老师和助教可以看到并能解除被屏蔽的发言消息"></helpIcon>
              </div>
              <div class="item" @click="auth(4, 2),closeAddDes()" v-else>取消屏蔽</div>
            </div>
          </div>
        </div>
      </div>
      <div class="addDescription" v-if="showAddDes">
        <p>真实姓名</p>
        <el-input v-model="descInfo.student_remarks" placeholder="填写真实姓名"></el-input>
        <p>描述</p>
        <el-input
          class="textarea"
          type="textarea"
          :rows="4"
          placeholder="填写描述"
          v-model="descInfo.ujianjie"
          maxlength="140"
          show-word-limit>
        </el-input>
        <div class="addDesBtn">
          <el-button class="desConfirm" @click="setStudentNotes">确定</el-button>
          <el-button class="desCancel" @click="closeAddDes()">取消</el-button>
        </div>
      </div>
      <div
        class="member_list_mask"
        v-if="currMember.uname"
        @click="resetMemberInfo"
      ></div>

      <!-- 分组 -->
      <category
        v-if="showCategory"
        :groupList="options3"
        :selectedData="showCategory2"
        @close="showCategory = false"
        @reset="reset"
      ></category>
    </div>
  </div>
</template>

<script>
import smallotherfnContain from '@view/OpenClass_openClassLive/components/centercomponents/smallotherfnContain'
import authfn from '../../uilts/auth'
import reset from '../reset'
import oepnAndClose from '../oepnAndClose'
import otherfnContain from '@view/OpenClass_openClassLive/components/centercomponents/otherfnContain'
// import btns from '@view/OpenClass_openClassLive/components/rightcomponents/btns'
import category from '@/components/tagselect/category'
import tagselect from '@/components/tagselect/index.vue'
import { createNamespacedHelpers } from 'vuex'
import message from '../centercomponents/message'
import emojiSource from '../centercomponents/emoji'
// import {friendDeleteAll} from '../../uilts/index'
import {delFriend} from '../../uilts/im'

const { mapState, mapMutations, mapGetters } = createNamespacedHelpers(
  'OpenClassopenClassLive'
)
export default {
  name: 'menbers',

  mixins: [authfn],

  components: {
    otherfnContain,
    smallotherfnContain,
    // btns,
    category,
    tagselect,
    reset,
    oepnAndClose,
    message,
  },

  props: {
    rightOpen: [Boolean],
  },

  data() {
    return {
      debug:true,
      teacher_id: '',

      hadTeacherid: '0',
      
      imagecomplete2: false,
      //图片上传进度
      imageProgress2: 0,

      // 助教列表
      assistant: [],

      invitationChecked: false,

      // 是否是私聊
      isPrivate: false,

      // 在线离线下拉框选中的内容
      selectStatusval: { value: '0', label: '在线' },

      // 在线离线的下拉数据
      options2: [
        {
          value: '1',
          label: '在线',
        },
        {
          value: '2',
          label: '离线',
        },
      ],

      // 学员数据分页，一页100
      count: 100,

      // 学员列表数据
      copyMemberlist: [],

      // 私聊信息为空
      isprivateEmpty: false,

      // 私聊列表
      newfriendList: [],

      selectID: undefined,

      // 问答，私聊切换
      radio4: 1,

      //分组
      showCategory: false,

      // 分组数据
      showCategory2: {},

      // 学员分组
      options3: [],

      // 学员标签
      options4: [],

      // 当前选择的学员，请求学员详情
      currMember: {},

      // 二级选择
      isSelect: 1,

      //当前tabs选中的位置
      activeName: '',

      //搜索类型
      search_type: '',

      // 搜索内容
      userName: '',

      // 学员数量为空
      memberEmpty: false,

      // 加载学员数据时的loading
      loading: true,

      // 全选
      selectAll:false,
      isOpenSelectWatch:true,
      // 搜索在线成员
      search:{},

      // 群发
      sendAll:false,

      // 添加描述
      showAddDes:false,
      descInfo:{
        student_remarks:'',
        ujianjie:'',
      },
    }
  },

  watch: {
    // 监听是大窗还是小窗
    openAndClose: {
      handler(val) {
        this.getActive()
        this.changerightActiveName(this.activeName)
        this.$nextTick(() => {
          this.resetTabActivePosition(this.$refs.tabs.$el)
        })
        if (!val) {
          this.clear()
        }
        this.resetMemberInfo()
        this.getMemberList()
      },
    },

    // 当连麦开启时，点击连麦图标，跳转连麦模块
    rightActiveName(val) {
      if (this.activeName != 2) {
        this.activeName = val
        // this.changerightActiveName(this.activeName)
      }
    },

    // 私信、提问状态切换
    radio4(val){
      if(val==2){
        this.newQAMsgsChange(0)
      }
      this.changerightRadio4(val)
      this.changeFriendRanking()
    },

    // 判断如果当前所在的模块是问答，就不需要新消息提示
    newtip(val) {
      if (val && this.activeName == 5) {
        this.newtipChange(false)
      }
    },

    // 监听选中学员数据的变化
    currMemberInfo: {
      handler() {
        if (this.currMemberInfo.from) {
          const arr = this.currMemberInfo.from.split('_')
          if (arr[2] != 'robot') {
            const userid = arr[arr.length - 1]
            this.currMemberSelect(userid)
          }
        }
      },
      deep: true,
      immediate: false,
    },

    otherfnPrivateletterselectVal: {
      handler(val) {
        // 判断的原因：初始话的时候，这个数据会被监听到，但是不需要
        if (Object.keys(val).length > 0) {
          this.isPrivate = true
          if (this.openAndClose) {
            // this.activeName = '5'
            this.radio4 = 1
          } else {
            // 如果在问答模块，返回的时候就在问答模块
            if (this.activeName == 5) {
              this.activeName = '5'
            } else if (this.activeName == 6) {
              this.activeName = '6'
            } else {
              this.activeName = '7'
            }
          }
        } else {
          this.isPrivate = false
        }

        this.friendSelect(val, 2)
        this.handleClick()
      },
      deep: true,
      immediate: false,
    },

    activeName: {
      handler(val) {
        /**
         * 1 在线
         * 2 在线禁言
         * 3 离线
         * 4 拉黑
         * 5 踢出
         * 6 禁言
         */
        switch (Number(this.isSelect)) {
          case 1:
            this.setMembersMenu(val == 1 ? 1 : 3)
            break
          case 2:
            this.setMembersMenu(val == 1 ? 2 : 4)
            break
          case 3:
            this.setMembersMenu(5)
            break
          case 4:
            this.setMembersMenu(6)
            break
        }
      },
      immediate: false,
    },

    selectStatusval: {
      handler(val) {
        switch (Number(this.isSelect)) {
          case 1:
            this.setMembersMenu(val.value == 1 ? 1 : 3)
            break
          case 2:
            this.setMembersMenu(val.value == 1 ? 2 : 4)
            break
        }
      },
      deep: true,
      immediate: true,
    },

    userName() {
      this.getMemberList()
    },

    /**
     * @desc:前端分页参数
     * @params {* currentPage}
     * @return: {*} 展示列表
     */
    // currentPage() {
    //   let num = this.currentPage * this.count
    //   let list = this.copyMemberlist.slice(0, num)
    //   // 获取除问答外的列表数据
    //   const val = {}
    //   list.forEach((item) => {
    //     val[item.userID] = Object.assign(
    //       { memberType: this.isSelect, activeName: this.activeName }, //选择的二级类型
    //       item
    //     )
    //   })
    //   this.setMemberList(val)
    // },

    search:{
      handler(){
        this.getMemberList()
      },
      deep:true,
    },
    // 全选
    selectAll(checked){
      if(this.isOpenSelectWatch){
        const val = {}
        this.copyMemberlist.forEach((item) => {
          val[item.userID] = Object.assign(
            { memberType: this.isSelect, activeName: this.activeName,checked:checked, }, //选择的二级类型
            item
          )
        })
        this.setMemberList(val)
      }
    },
  },

  created() {
    window.switchMenu = this.switchMenu
    this.search_type = this.hadTeacherid == 0 ? '1' : '2'
    this.getMemberList()
    this.getAssistant()
    // 群发图片进度
    this.$root.$on('sendMsgProgress',(e)=>{
      this.imageProgress2 = e
    })
    // 群发图片完成
    this.$root.$on('sendMsgComplete',()=>{
      this.imagecomplete2=true
    })
  },

  mounted() {
    this.$nextTick(() => {
      if (this.$route.query.switchMenu) {
        this.activeName = this.$route.query.switchMenu
      } else {
        this.getActive()
      }

      // if (this.isWindowsApp) {
      //   this.selectStatusval = {
      //     value: '1',
      //     label: '在线',
      //   }
      // }

      this.changerightActiveName(this.activeName)
      this.getGroupList()
      this.getTagList()
      // 更新私信位置
      this.$root.$on('changeFriendRanking',this.changeFriendRanking)
      // 获取私聊数据
      this.$root.$on('getManyHistory',this.getManyHistory)
      
    })
  },

  destroyed() {
    this.clear()
    if (this._http) {
      this._http.abort()
    }
  },

  filters: {
    getRole(type) {
      let text
      switch (Number(type)) {
        case 1:
          text = '上课老师'
          break

        default:
          text = '助教老师'
          break
      }
      return text
    },

    // 获取设备类型
    getDev(type) {
      let text
      switch (Number(type)) {
        case 1:
          text = '电脑在线'
          break
        case 2:
          text = '手机微信小程序在线'
          break
        case 3:
          text = '手机微信H5在线'
          break
      }
      return text
    },
  },

  computed: {
    ...mapState([
      'tim',
      'openClassId',
      'members',
      'liveInfo',
      'userInfo',
      'openInfo',
      'friendList',
      // 选中学员的数据
      'currMemberInfo',
      'otherfnPrivateletterselectVal',
      'questionList',
      'newtip',
      'newPriMsgs',
      'newQAMsgs',
      'quotelist',
      'rightQuote',
      'openAndClose',
      'rightActiveName',
      'allMembers',
      'allTutorList',
      'tutorHistorys',
    ]),

    ...mapGetters(['chatFullScreen']),

    /**
     * 如果位于在线模块的在线列表，且不是搜索内容的状态下，打开定时器
     */
    hadInterval() {
      return (
        (this.selectStatusval.value == 1 || this.activeName == 8) &&
        this.isSelect == 1 &&
        !this.userName
      )
    },

    copyMemberlistlength() {
      return this.copyMemberlist.length
    },

    onlineCount() {
      return (
        (this.selectStatusval.value == 1 || this.activeName == 8) &&
        this.isSelect == 1
      )
    },

    forbiddenWordsCount() {
      return (
        (this.selectStatusval.value == 1 || this.activeName == 8) &&
        this.isSelect == 2
      )
    },

    offLineCount() {
      return (
        (this.selectStatusval.value == 2 || this.activeName == 9) &&
        this.isSelect == 1
      )
    },

    offBlockCount() {
      return (
        (this.selectStatusval.value == 2 || this.activeName == 9) &&
        this.isSelect == 2
      )
    },

    kickOutCount() {
      return (
        (this.selectStatusval.value == 2 || this.activeName == 9) &&
        this.isSelect == 3
      )
    },

    offLineForbiddenWordsCount() {
      return (
        (this.selectStatusval.value == 2 || this.activeName == 9) &&
        this.isSelect == 4
      )
    },

    isWindowsApp() {
      return this.$store.state.isWindowsApp
    },
  },

  /**
   * 前端分页有待考量
   */
  // mounted() {
  //   this.$nextTick(() => {
  //     // 进入nexTick
  //     var bady = this.$refs.onlinecontain // 获取滚动条的dom
  //     // console.log("距顶部"+scrollTop+"可视区高度"+windowHeight+"滚动条总高度"+scrollHeight);
  //     bady.onscroll = () => {
  //       // 获取距离顶部的距离
  //       var scrollTop = bady.scrollTop
  //       // 获取可视区的高度
  //       var windowHeight = bady.clientHeight
  //       // 获取滚动条的总高度
  //       var scrollHeight = bady.scrollHeight

  //       let num = this.currentPage * this.count

  //       if (
  //         scrollTop + windowHeight >= scrollHeight &&
  //         num < this.copyMemberlist.length
  //       ) {
  //         // 把距离顶部的距离加上可视区域的高度 等于或者大于滚动条的总高度就是到达底部
  //         this.currentPage += 1
  //         // console.log(this.currentPage, '到底了')
  //       }
  //     }
  //   })
  // },

  methods: {
    ...mapMutations([
      'openclassDatastatisticsActive',
      'setMemberList',
      'setCurrMember',
      'setMembersMenu',
      'changeotherfnPrivateletterselectVal',
      'chageFriednlist',
      'newtipChange',
      'newPriMsgsChange',
      'newQAMsgsChange',
      'chageQuoteval',
      'changerightActiveName',
      'changerightRadio4',
      'setallMembers',
      'setallTutorList',
      'settutorHistorys',
    ]),

    inviteType() {
      this.getMemberList()
    },

    assistantChange() {
      this.search_type = this.hadTeacherid == 0 ? '1' : '2'
      this.getMemberList()
    },

    async getAssistant() {
      const { data } = await this.$http({
        url: '/console/teacherList',
        data: {
          open_class_id: this.openClassId,
        },
      })
      this.assistant = data
    },

    getActive() {
      if (this.isWindowsApp) {
        this.activeName = '7'
      } else {
        this.activeName = '8'
      }
    },

    // 是否只看自己邀请的
    async invitationCheckValChange(val) {
      this.is_inviter = val
      this.getMemberList()
    },

    rightcomponentsPopover($event) {
      if (this.activeName == 6) {
        $event.stoppropagation()
      }
    },

    // 客户端连麦时，点击连麦连麦列表，定位到连麦模块
    switchMenu(val) {
      // 连麦name = 2
      this.activeName = String(val)
      this.changerightActiveName(this.activeName)
    },

    // tab在切换大小窗时，样式兼容
    resetTabActivePosition($el) {
      setTimeout(() => {
        var activeEl = $el.querySelector('.el-tabs__item.is-active')
        var lineEl = $el.querySelector('.el-tabs__active-bar')
        var style = getComputedStyle(activeEl)
        var pl = style.paddingLeft.match(/\d+/)[0] * 1
        var pr = style.paddingRight.match(/\d+/)[0] * 1
        var w = style.width.match(/\d+/)[0] * 1
        lineEl.style.transform =
          'translateX(' + (activeEl.offsetLeft + pl) + 'px)'
        lineEl.style.width = w - pl - pr + 'px'
      }, 100)
    },

    // 选择在线离线的数据，切换下拉框的内容
    selectStatus(val) {
      this.selectStatusval = val
      document.body.click()
      this.handleClick()
    },

    // 发送私聊消息
    sendPrivate(val) {
      this.$emit('otherfnPrivateletter', val)
      this.getManyHistory(val)
      this.currMember = {}
      this.closeAddDes()
      this.$emit('closeHistory')
    },

    // 删除好友
    delfrind(userID) {
      this.debug&&console.log(userID,this.otherfnPrivateletterselectVal);
      if(userID==this.otherfnPrivateletterselectVal.userID) this.changeotherfnPrivateletterselectVal({})
      this.$emit('delfrind', userID)
    },

    /**
     * @desc: 选择私信的好友
     * @return: {*}
     * @param {*} val
     */
    friendSelect(val, val2) {
      // debugger
      this.selectID = val.userID || val.from
      if (val2 == 1) {
        this.chageQuoteval({})
      }
      // 清空私信数量
      // console.log('清空私信数量',val,this.friendList);
      this.chageFriednlist(this.friendList.map(i=>Object.assign(i,{
        priMsgNums:i.userID === val.userID?0:i.priMsgNums||0,
        changePriMsgTime:i.changePriMsgTime?i.changePriMsgTime||0:Date.now(),
      })))
      let priMsgNums = this.friendList.length==0?0:this.friendList.map(v=>v.priMsgNums).reduce((n,o)=>n+o)
      this.newPriMsgsChange(priMsgNums)
      this.getManyHistory(val)
      this.changeotherfnPrivateletterselectVal(val)
      this.$emit('closeHistory')
    },

    // 更新私信位置
    changeFriendRanking(){
      if(this.activeName==5&&this.radio4==1) {
        let friendList = this.friendList
        this.chageFriednlist(friendList.sort((v1,v2)=>v2.changePriMsgTime - v1.changePriMsgTime))
      }
      // console.log('更新私信位置',this.friendList);
    },

    // 设置定时器
    setTimer() {
      this.clear()
      this.setTimeoutConsole = setInterval(() => {
        this.getMemberList(1)
      }, this.intervalTime)
    },

    // 清除定时器
    clear() {
      clearInterval(this.setTimeoutConsole)
    },

    // 清除成员信息
    resetMemberInfo() {
      this.setCurrMember({})
      this.closeAddDes()
      this.currMember = {}
    },

    //刷新分组内容
    reset() {
      this.$http({
        url: '/console/userInfo',
        data: {
          uid: this.currMember.uid,
          open_class_id: this.openClassId,
        },
        callback: ({ data }) => {
          this.currMember = data
        },
      })
      this.getGroupList()
    },

    //分组
    fenzu() {
      this.showCategory2 = {
        uid: this.currMember.uid,
        gid: this.currMember.gid,
        uname: this.currMember.uname,
      }

      this.$nextTick(() => {
        this.showCategory = true
      })
      this.closeAddDes()
    },

    // 获取机构分组
    getGroupList() {
      this.$http({
        url: '/pcUser/getGroupList',
        callback: ({ data }) => {
          this.options3 = data.map((item) => {
            return {
              label: item.gname,
              value: Number(item.gid),
              count: item.number,
            }
          })
        },
      })
    },


    // 获取机构标签
    async getTagList() {
      const { data } = await this.$http({
        url: '/UserTag/getJigouTag',
      })
      this.options4 = data.map(item => {
        return { label: item.tag_name, value: Number(item.tag_id) }
      })
    },

    // 切换标签时重置数据
    tagChange() {
      this.reset()
      // this.currMemberSelect(this.selectedVal.uid)
      this.closeAddDes()
    },

    // 获取当前选中学员的详情
    currMemberSelect(val) {
      this.selectedVal = val
      this.$http({
        url: '/console/userInfo',
        data: {
          uid: val,
          open_class_id: this.openClassId,
        },
        callback: ({ data }) => {
          this.currMember = data
        },
      })
      this.getGroupList()
      this.$emit('iscloseProver', 1)
    },

    // 获取初次成员列表
    getMemberList(val) {
      this.newfriendList = _.assign([], this.friendList)
      // val=1是定时器中使用该方法的标识
      this.loading = val == 1 ? false : true

      this._http = this.$http({
        url: '/console/memberList',
        data: {
          search_type: this.search_type,
          teacher_id: this.hadTeacherid,
          //    1在线  2离线  3 管理组
          select:
            this.activeName == 5
              ? 1
              : this.activeName == 6
              ? this.selectStatusval.value
              : this.activeName == 8
              ? 1
              : this.activeName == 9
              ? 2
              : this.activeName,
          //    select = 1 （1在线  2已禁言）  select = 2 （1 离线 2已拉黑  3已踢出）
          type: this.isSelect,
          open_class_id: this.openClassId,
          uname: this.userName,
          is_inviter: this.is_inviter,
          ...this.search
        },
        callback: ({ data: { list } }) => {
          /**
           * 1,只有在在线模块的在线列表中且不是搜索状态，才去定时更新数据
           * 2,在线列表数量少于50个，定时器5s更新一次，否则1分钟更新一次
           * 3,列表中的img，需要用到懒加载
           * 4,数量获取，前端计算
           * 5,前端分页，100条一页，滚动加载,防止浏览器同时渲染过多数据出现渲染卡顿问题
           * 6,成员列表前端排序，time时间倒叙，提高后端mysql性能
           * 最新一次列表更改
           */

          // 定时器的更新频率 在线列表数量少于50个，定时器10s更新一次，否则1分钟更新一次

          if (list.length <= 50) {
            this.intervalTime = 10000
          } else {
            this.intervalTime = 60000
          }

          // 只有在在线模块且不是搜索状态，才去定时更新数据
          if (this.hadInterval) {
            this.setTimer()
          } else {
            this.clear()
          }

          // 以时间倒叙排列
          this.copyMemberlist = list.sort((a, b) => b.time - a.time)

          // 获取好友列表的在线状态
          this.newfriendList.forEach((item) => {
            if (list.findIndex((i) => i.userID == item.userID) > -1) {
              item.status = true
            } else {
              item.status = false
            }
          })
          this.chageFriednlist(this.newfriendList)
          this.isprivateEmpty = this.newfriendList.length == 0

          const val = {}
          this.copyMemberlist.forEach((item) => {
            val[item.userID] = Object.assign(
              { 
                memberType: this.isSelect, 
                activeName: this.activeName,
                checked:this.firstGetMember&&this.members[item.userID]?this.members[item.userID].checked:false, 
              }, //选择的二级类型
              item
            )
          })
          this.setMemberList(val)

          this.memberEmpty = list.length == 0
          this.loading = false
          this.firstGetMember = true
        },
      })
    },

    // 第一级类型选择
    handleClick($event) {
      if ($event) {
        this.activeName = $event.name
        this.changerightActiveName(this.activeName)
      }

      this.isSelect = 1
      if (this.activeName == 5) {
        this.newtipChange(false)
      }

      if (
        this.activeName == 6 ||
        this.activeName == 8 ||
        this.activeName == 9 ||
        this.activeName == 3
      ) {
        this.getMemberList()
      }
      // 关闭群发
      if(this.activeName!=8) this.sendAll = this.selectAll = false
      this.changeFriendRanking()
      this.cancelSend()
      if(this.activeName==5){
        if(this.radio4 == 2){
          this.newQAMsgsChange(0)
        } else if(this.radio4 == 1){
          this.changeFriendRanking()
        }
      }
    },

    // 第二级类型选择
    onlineClick(val) {
      // 重置一下数据，计算长度的时候不会先展示上一次的数据，在展示这次的数据
      this.copyMemberlist = []
      this.isSelect = val
      // 关闭群发
      if(val != 1) this.sendAll = this.selectAll = false
      this.getMemberList()
      this.cancelSend()
    },

    otherTag(value) {
      if (value < 0 || this.search.tag_ids[0] < 0) {
        this.search.tag_ids = [value]
      }
    },
    // 检查全选
    mChecked(){
      let nums = 0
      let arr = Object.keys(this.members)
      arr.forEach(v=>{
        if(this.members[v].checked) nums+=1
      })
      this.isOpenSelectWatch = false
      this.selectAll = nums === arr.length
      this.$nextTick(()=>{
        this.isOpenSelectWatch = true
      })
    },

    // 取消筛选
    cancelSend(){
      this.sendAll = false
      this.selectAll = false
      this.search = {}
      this.firstGetMember = false
      this.getMemberList()

    },

    selectConfirm(){
      let membersList = []
      Object.keys(this.members).forEach(v=>{
        if(this.members[v].checked) membersList.push(this.members[v])
      })
      this.setallMembers(membersList)
    },

    // 群发私信
    sendPrivateAll(value){
      // console.log('群发消息事件',value);
      this.selectConfirm()
      this.$root.$emit('sendAllPrivateMsg',value)
    },

    // 添加描述
    addMemberDes(){
      this.showAddDes = true
      this.descInfo = {
        student_remarks:this.currMember.student_remarks,
        ujianjie:this.currMember.ujianjie,
      }
    },
    closeAddDes(){
      this.showAddDes = false
    },
    // 修改学生备注姓名和描述
    setStudentNotes() {
      this.$http({
        url: '/pcUser/setStudentNotes',
        data:{
          open_class_id: this.openClassId,
          uid:this.currMember.uid,
          ...this.descInfo
        },
        callback: () => {
          this.closeAddDes()
          this.getMemberList()
          this.currMemberSelect(this.currMember.uid)
          // this.$root.$emit('getFlist',this.currMember)
          // const flist = this.friendList
          // flist.forEach(v=>{
          //   if(v.userID == this.currMember.userID){
          //     v.profile.student_remarks = this.descInfo.student_remarks
          //     v.profile.selfSignature = this.descInfo.student_remarks
          //   }
          // })
          // console.log('this.currMember',this.currMember,'this.descInfo',this.descInfo,'flist',flist)
          // debugger
          // this.chageFriednlist(flist)

          this.descInfo={}
        },
      })
    },

    // 获取学生的多个私聊记录 是否继续拉取
    async getManyHistory(val,isContinuePulling,LastMsgKey,LastMsgTime,item){
      // 如果是window嵌入的网页，则不获取其他助教私聊信息
      if(this.isWindowsApp) return
      if(isContinuePulling){
       const data =  await this.getOtherHistory(item.userID+'_'+this.openClassId,val.userID,LastMsgKey,LastMsgTime,item)
       let tutorHistorys = this.tutorHistorys
        if(data.Complete == 0&&data.MsgList.length==0) return
        let msglist = [].concat(data.MsgList).concat(this.tutorHistorys[item.userID].MsgList)
        tutorHistorys[item.userID].MsgList = msglist
        tutorHistorys[item.userID].Complete = data.Complete
        tutorHistorys[item.userID].MsgKey = data.MsgKey
        tutorHistorys[item.userID].MsgTimeStamp = data.MsgTimeStamp
        this.settutorHistorys(tutorHistorys)
        // this.debug&&console.log('继续拉取历史',tutorHistorys,this.tutorHistorys);
      } else {
        const list = await this.getTutor()
        this.setallTutorList(list)
        const allPromise = Promise.all(list.map(item=>this.getOtherHistory(item.userID+'_'+this.openClassId,val.userID,LastMsgKey,LastMsgTime,item)))
        allPromise.then(v=>{
          let tutorHistorys = {}
          v.forEach(k=>{
            if(k.MsgList.length>0){
              tutorHistorys[k.userID] = k
            }
          })
          this.settutorHistorys(tutorHistorys)
          this.debug&&console.log('获取学生的多个私聊记录',v,tutorHistorys);
        })
      }
    },

    // 获取助教成员
    getTutor(){
      return new Promise((resolve,reject)=>{
        this.$http({
          url: '/console/memberList',
          name:'getTutor',
          data: {
            search_type: 1,
            select:3,
            open_class_id: this.openClassId,
            all:1,
          },
          callback: ({data: { list }}) => {
            const tutorList = list.filter(v=>v.userID+'_'+this.openClassId!==this.userInfo.userID)
            resolve(tutorList)
          },
          error: (err) => {
            reject(err)
          },
        })
      })
    },

    // 获取改学生与其他助教的聊天记录
    getOtherHistory(operator,peer,LastMsgKey,LastMsgTime,item){
      return new Promise((resolve,reject)=>{
        let data = {
          operator, //教师userId
          peer,  //学生userId
          LastMsgKey, //继续拉
          LastMsgTime, //继续拉
        }
        this.debug&&console.log('聊天记录data',data);
        this.$http({
          url: 'console/getRoammsg',
          name:operator.toString()+(peer||'').toString()+(LastMsgKey||'').toString(),
          data,
          callback: (value) => {
            this.debug&&console.log('聊天记录',value,item);
            let list = []
            value.MsgList.forEach(v=>{
              list.push(this.getMsgItem(v,item,value))
            })
            let val = Object.assign(value,{
              MsgList:list
            })
            resolve(Object.assign(val,data,item,{
              uid:item.uid,
              userID:item.userID,
              uname:item.uname,
              uphoto:item.uphoto,
              role:item.role,
            }))
          },
          error: (err) => {
            reject(err)
          },
        })
      })
    },

    // 获取消息
    getMsgItem(msg,item) {
      // debugger
      // 管理员
      let val = {}
      try {
        val =
          this.userInfo.managerList.find(v => {
            return v.id == msg.To_Account
          }) || {}
      } catch (e) {
        // console.info(e)
      }
      // 带的自定义数据
      let cloudCustomData
      try {
        cloudCustomData = JSON.parse(msg.cloudCustomData)
        cloudCustomData.tag_names2 = Array.isArray(cloudCustomData.tag_names)
          ? cloudCustomData.tag_names
          : []
        cloudCustomData.tag_names = cloudCustomData.tag_names2.join(' | ')
      } catch (error) {
        cloudCustomData = {}
      }
      const options = _.assign(
        {},
        {
          to: msg.To_Account,
          ID: msg.uid,
          nick: msg.From_Account==this.otherfnPrivateletterselectVal.userID?this.otherfnPrivateletterselectVal.profile.nick:item.uname,
          student_remarks:item.student_remarks,
          from: msg.From_Account,
          time: msg.MsgTimeStamp,
          role: val.role || '',
          avatar: msg.From_Account==this.otherfnPrivateletterselectVal.userID?this.otherfnPrivateletterselectVal.profile.avatar:item.uphoto,
          type: msg.MsgBody[0].MsgType == 'TIMImageElem'
              ? 'img'
              : 'text',
          compressed:
            msg.MsgBody[0].MsgType == 'TIMImageElem'?msg.MsgBody[0].MsgContent.ImageInfoArray[0]:'',
          text:msg.MsgBody[0].MsgType == 'TIMImageElem'
              ? msg.MsgBody[0].MsgContent.ImageInfoArray&&msg.MsgBody[0].MsgContent.ImageInfoArray.length>0
              ?msg.MsgBody[0].MsgContent.ImageInfoArray[0].url
              :''
              : this.textReplaceUrl(this.textReplaceEmoji(msg.MsgBody[0].MsgContent.Text)),
          MsgKey:msg.MsgKey,
          MsgTimeStamp:msg.MsgTimeStamp,
        },
        cloudCustomData,
        { cloudCustomData: cloudCustomData }
      )
      // 替换敏感词
      if (cloudCustomData.sensitiveWordsShield) {
        options.text = this.sensitiveWordsShield(options.text)
      }
      
      return options
    },
    // 转义html
    transCharacterEntities(text) {
      return text ? text.replace(/</gi, '&lt;').replace(/>/gi, '&gt;') : ''
    },
    // 敏感词屏蔽
    sensitiveWordsShield(value) {
      this.sensitiveWords.forEach(item => {
        const exp = new RegExp(item, 'ig')
        value = value.replace(exp, function($1) {
          return `<i style="color: #ff3535;text-decoration: line-through;">${$1}</i>`
        })
      })
      return value
    },
    // 替换成url
    textReplaceUrl(msg) {
      return msg.replace(
        /(https?:\/\/)?([a-zA-Z0-9_-]+\.)+(cn|com|net|org)(:\d{1,4})?[\@\#\?\w\d&%=\.\/\~\-\;\:\*\+\$]*/g,
        function(val) {
          return `<a class="vm" style="color: #2f9bd3 ;vertical-align: baseline;" href="${
            /^https?:\/\//.test(val) ? val : '//' + val
          }" target="_blank">${val}</a>`
        }
      )
    },
    // 替换成表情
    textReplaceEmoji(oldText) {
      let tempText = ''
      const text = this.transCharacterEntities(oldText)
      const exp1 = /(\[[\u4e00-\u9fa5]+\])|./g
      const exp2 = /\[[\u4e00-\u9fa5]+\]/
      const matchRes = text.match(exp1) || []

      matchRes.forEach(item => {
        // 如果该item 是表情
        if (exp2.test(item)) {
          try {
            tempText += `<span style="background-position:0px ${-(
              emojiSource[item].i * 20
            )}px" class="vm ${'openclass-emoji-item2'}"></span>`
          } catch (e) {
            tempText += item
          }
        } else {
          tempText += item
        }
      })
      return tempText
    },
    // 清空私信列表
    delAllFriend(){
      let promise = this.tim.getFriendList();
      promise.then((imResponse)=> {
        const friendList = imResponse.data.map(v=>v.userID); // 好友列表
        this.debug&&console.log('好友列表',friendList);
        delFriend(this.tim,friendList).then((vv)=>{
          this.debug&&console.log('删除好友',vv);
        })
      })
      // friendDeleteAll(this,this.openClassId).then(()=>{
      this.chageFriednlist([])
      this.newPriMsgsChange(0)
      this.changeotherfnPrivateletterselectVal({})
      //   this.$root.prompt({
      //     msg: '删除成功',
      //     type: 'success',
      //   })
      // })
    },
  },
}
</script>


 
<style lang="scss" scoped>
::v-deep .el-badge__content {
  border: none;
  scale: 0.7;
}
::v-deep .el-badge__content.is-fixed {
  // transform: translateY(-20%) translateX(100%);
}
.right-contain {
  height: 100%;
}
.uphoto {
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #ffffff;
  line-height: 12px;
  position: absolute;
  width: 20%;
  background: #191919;
  height: 36px;
  .tu2 {
    margin-left: 0;
    width: 28px;
  }
}
/*灰色圆滑点的*/
.popperclassInMenbers {
  padding: 20px !important;
  box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.2);
  width: 280px !important;
  background: #323232 !important;
  border: 0 !important;
}
.onlinecontain,
.questionAndAnswer,
.lianmai {
  @extend %liveRoomScroll;
}
.height_auto {
  height: auto !important;
}
.menbers {
  // overflow: hidden;
  width: 100%;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  &.hide {
    display: none;
  }
  &.overflowHide {
    overflow: hidden;
  }
  .filtersList {
    padding: 10px 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #191919;
    .nfont1 {
      font-size: 12px;
      color: #ffffff;
      ::v-deep .el-checkbox__inner {
        background-color: #444444;
        border-color: #444444;
      }
      ::v-deep .el-input--suffix .el-input__inner {
        padding-left: 10px !important;
        background: #191919;
        border-color: #191919;
        color: #fff;
      }

      ::v-deep .el-icon-arrow-up:before {
        color: #fff;
      }
    }
    ::v-deep .nfont2 {
      color: #999999;
      .el-checkbox__label {
        font-size: 12px;
      }
      .is-checked .el-checkbox__label {
        color: #999999;
      }
    }
  }
  .question-contain {
    flex: 1;
    overflow-y: auto;
    @extend %liveRoomScroll;
  }
  .aquestion {
    display: flex;
    padding: 10px;
    .photo {
      width: 36px;
      height: 36px;
      border-radius: 4px;
      margin-right: 10px;
      cursor: pointer;
      overflow: hidden;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .name_message {
      width: calc(100% - 46px);
      .name {
        display: flex;
        font-size: 12px;
        color: #ffffff;
        line-height: 12px;
        margin-bottom: 10px;
        .invite-people {
          font-size: 12px;
          color: #ff9224;
          margin: 0 6px;
        }
      }
      .tag {
        font-size: 12px;
        color: #666666;
        margin-bottom: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .name {
        // .tname:hover {
        //   color: #f39802;
        //   text-decoration: underline;
        // }
      }
      .message,
      .rootmessage {
        display: flex;
        word-break: break-all;
        width: 100%;
        box-sizing: border-box;
        font-size: 13px;
        color: #eaeaea;
        line-height: 20px;
        align-items: flex-end;
        &:hover .time {
          visibility: visible;
        }
        .img {
          max-width: 245px;
          // max-height: 160px;
          ::v-deep .el-image {
            img {
              object-position: 0 0 !important;
            }
          }
          img {
            max-width: 100%;
            border-radius: 3px;
          }
        }
        .item {
          border-radius: 4px;
          padding: 8px 10px;
          background: #6e636344;
          word-break: break-all;
        }

        .time {
          visibility: hidden;
          margin-left: 10px;
          font-size: 12px;
          color: #474747;
          line-height: 12px;
          white-space: nowrap;
          .otherfn {
            margin-bottom: 12px;
            color: #fff;
            cursor: pointer;
          }
        }
      }

      .rootmessage {
        display: flex;
        word-break: break-all;
        width: 100%;
        box-sizing: border-box;
        font-size: 13px;
        color: #eaeaea;
        line-height: 20px;
        align-items: flex-end;
        &:hover .time {
          visibility: visible;
        }
        .img {
          max-width: 245px;
          // max-height: 160px;
          ::v-deep .el-image {
            img {
              object-position: 0 0 !important;
            }
          }
          img {
            max-width: 100%;
            border-radius: 3px;
          }
        }
        .item {
          border-radius: 4px;
          padding: 8px 10px;
          background: #6e636344;
          word-break: break-all;
        }

        .time {
          visibility: hidden;
          margin-left: 10px;
          font-size: 12px;
          color: #474747;
          line-height: 12px;
          white-space: nowrap;
          .otherfn {
            margin-bottom: 12px;
            color: #fff;
            cursor: pointer;
          }
        }
      }

      .quto {
        background: #191919;
        border-radius: 4px;
        padding: 6px 10px;
        display: inline-block;
        margin-top: 10px;
        font-size: 13px;
        color: #6d6b6bea;
      }
      .message {
        .question {
          position: absolute;
          right: 0;
          bottom: 0;
          img {
            height: 16px;
            width: 16px;
          }
        }
      }
    }
  }
  ::v-deep .el-loading-spinner {
    top: 10%;
    .el-icon-loading {
      color: #808080 !important;
    }
    .el-loading-text {
      color: #808080;
    }
  }

  ::v-deep .questionAndAnswer {
    display: flex;
    flex-direction: column;
    background: #171717;
    height: calc(100% - 96px);

    .radio {
      padding: 14px 0;
      text-align: center;
      width: 100%;
      min-height: 26px;
    }
    .el-radio-button__inner {
      background-color: #252629ff;
      border-color: #252629ff !important;
      box-shadow: none !important;
      width: 102px;
    }
    .el-radio-button__inner:hover {
      color: #fff;
    }
    .is-active {
      .el-radio-button__inner {
        color: #fff !important;
        background-color: #444444ff !important ;
      }
    }
    .listcontain {
      overflow-y: auto;
      cursor: pointer;
      flex: 1;
      @extend %liveRoomScroll;
      .item {
        position: relative;
        padding: 10px 14px;
        display: flex;
        box-sizing: border-box;
        .leftPic {
          width: 46px;
          height: 36px;
          border-radius: 4px;
          padding-right: 10px;
          min-width: 46px;
          // overflow: hidden;
          box-sizing: border-box;
          position: relative;
          img {
            height: 100%;
            width: 100%;
          }
          .flMsgNums{
            position: absolute;
            transform: translateY(-50%) translateX(50%);
            top: 0;
            right: 0;
            font-size: 12px;
          }
        }
        .rigthcontain {
          flex: 1;
          overflow: hidden;
          .name_inviter {
            display: flex;
            font-size: 14px;
            color: #ffffff;
            .sp1 {
              font-size: 12px;
              color: #ff9224;
              margin-left: 6px;
            }
          }
          .status {
            margin-top: 6px;
            white-space: nowrap;
            font-size: 12px;
            color: #666666;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 18px;
          }
        }
        &:hover {
          background: #303030ff;
        }

        &:hover .popconfirm {
          display: block;
        }
        .popconfirm {
          // display: none;
          // transform: translateY(-10px);
        }
        .popconfirmcontain {
          display: none;
          position: absolute;
          right: 10px;
          top: 10px;
        }
        &:hover .popconfirmcontain {
          display: block;
        }
      }
    }
  }

  ::v-deep .maintabs {
    // .status_select {
    //   .el-input--suffix .el-input__inner {
    //     background-color: transparent;
    //     padding: 0 0 0 5px;
    //     border: 0;
    //     color: #999999;
    //     font-size: 12px;
    //   }
    //   .el-input__icon {
    //     width: 9px !important;
    //   }
    // }

    img {
      width: 14px;
      height: 14px;
    }
    .el-badge__content.is-dot {
      height: 5px;
      width: 5px;
      padding: 0;
      border: 0;
      right: 0;
      border-radius: 50%;
      top: 9px;
    }
    .el-tab-pane {
      height: 100%;
    }
    .el-tabs__item {
      font-size: 12px;
      line-height: 36px;
      height: 36px;
      width: 25%;
      text-align: center;
      color: #999999 !important;
      padding: 0 !important;
    }
    .el-tabs__item.is-active {
      color: #ad470e !important;
    }
    .el-tabs__content {
      box-shadow: -11px 2px 9px 0px rgba(0, 0, 0, 0.16);
    }
    .el-tabs__nav-scroll {
      padding: 0 0px;
      background-color: #191919;
    }
    .el-tabs__header {
      margin: 0;
    }
    .el-tabs__active-bar {
      background-color: #ad470e;
    }
    .el-tabs__nav {
      display: flex;
      align-items: center;
      width: 100% !important;
    }
    .el-tabs__nav-wrap::after {
      height: 0;
    }
  }

  .member_list_mask {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 80;
    // background-color: rgba(0, 0, 0, 0.5);
  }

  .memberDetail {
    border-radius: 4px;
    z-index: 81;
    position: absolute;
    width: 290px;
    left: -292px;
    bottom: 44px;
    box-sizing: border-box;
    padding: 10px;
    box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.2);
    background: #323232;
    border: 0;
    .detail {
      width: 280px;
      box-sizing: border-box;
      background: #323232;
      border-radius: 4px;
      z-index: 81;
      .popovercontian_menbers {
        background-color: #323232;
        .people {
          display: flex;
          align-items: center;
          padding-bottom: 20px;
          border-bottom: 1px solid #434343;
          .righttu {
            width: 40px;
            height: 40px;
            border-radius: 4px;
            margin-right: 10px;
            overflow: hidden;
            margin-bottom:20px;
            position: absolute;
            top: 10px;
            img {
              width: 100%;
              height: 100%;
              display: block;
              border-radius: inherit;
            }
          }
          .name_status {
            width: calc(100% - 50px);
            margin-left:50px;
            .name {
              font-size: 15px;
              font-weight: bold;
              color: #ffffff;
              line-height: 15px;
              margin-bottom: 10px;
            }
            .status {
              font-size: 12px;
              color: #666666;
              line-height: 12px;
            }
          }
        }
      }
      .contain {
        margin: 10px 0;
        font-size: 13px;
        color: #ffffff;
        line-height: 30px;
        li {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .buttons {
        width: 97%;
        height: 36px;
        background: #484848;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        .item {
          font-size: 13px;
          font-weight: bold;
          color: #ffffff;
          flex: 1;
          line-height: 13px;
          cursor: pointer;
          white-space: nowrap;
          text-align: center;
        }
        // .item,
        // .item2,
        // .item3 {
        //   cursor: pointer;
        //   font-size: 13px;
        //   font-weight: bold;
        //   color: #ffffff;
        //   line-height: 13px;
        //   padding: 12px 9px 12px 12px;
        //   box-sizing: border-box;
        // }
        // .item2 {
        //   padding: 12px 23px 12px 20px;
        // }
        // .item3 {
        //   padding: 12px 23px 12px 30px;
        // }
      }
      .bottons2 {
        width: 97%;
        margin-top: 10px;
        height: 36px;
        background: #484848;
        border-radius: 2px;
        font-size: 13px;
        font-weight: bold;
        color: #ffffff;
        line-height: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        .item {
          flex: 1;
          line-height: 36px;
          cursor: pointer;
          white-space: nowrap;
          text-align: center;
        }
      }
    }
  }

  .addDescription {
    border-radius: 4px;
    z-index: 82;
    position: absolute;
    width: 280px;
    // height: 380px;
    left: -570px;
    bottom: 84px;
    padding:20px;
    box-sizing: border-box;
    padding: 10px;
    box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.2);
    background: #323232;
    border: 0;
    color:#fff;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #FFFFFF;
    ::v-deep .el-input__inner{
      border: 0;
      color: #fff;
      background: #292929;
    }
    ::v-deep .textarea {
      .el-textarea__inner {
        background-color: #2c2c2c;
        border-color: #2c2c2c;
        color: #fff;
      }
      .el-input__count {
        background-color: #2c2c2c;
        color: #9f9f9f;
      }
    }
    >p{
      margin:10px 0;
    }
    .addDesBtn{
      margin:10px 0;
      float:right;
    }
    .desConfirm {
        background-color: #0AA29B;
        border-color: #0AA29B;
        color: #FFFFFF;
    }
    .desCancel {
      background: #4D4D4D;
      border:1px solid #4D4D4D;
      color: #FFFFFF;
    }
  }

  .online {
    padding-top: 10px;
    // height: 100%;
    background: #171717;
    display: flex;
    flex-direction: column;
    @extend %liveRoomScroll;
    .tip2 {
      font-size: 12px;
      color: #ff3535;
      line-height: 16px;
      margin: 0 20px;
    }
    .tab {
      margin-left: 20px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-size: 13px;
      color: #808080;
      line-height: 42px;
    }

    ::v-deep .input_class {
      margin: 10px 20px 20px;
      .el-input__inner {
        color: #fff;
        font-size: 12px;
        border-color: #323232;
        background-color: #323232;
      }
      .hadprepend_select {
        width: 75%;
        .el-input-group__prepend{
          width:52px;
        }
        .el-input__inner {
          padding: 0 0 0 10px;
        }
      }
      .el-input-group__append,
      .el-input-group__prepend {
        background: #323232;
      }
      .el-input-group__append,
      .el-input-group__prepend {
        overflow: hidden;
        width: 72px;
        border-color: #323232;
      }
    }

    .online_filter{
      display:flex;
      justify-content: space-between;
      align-items: center;
      background-color: #191919;
      margin: 0 20px;
      .nfont1{
        font-size: 12px;
        ::v-deep .el-checkbox__inner {
          
          background-color: #444444;
          border-color: #444444;
        }
        ::v-deep .el-input--suffix .el-input__inner {
          padding-left: 10px !important;
          background: #191919;
          border-color: #191919;
          color: #fff;
        }
  
        ::v-deep .el-icon-arrow-up:before {
          color: #fff;
        }
      }
      ::v-deep .nfont2 {
        color: #999999;
        .el-checkbox__label {
          font-size: 12px;
          color: #A6A6A6;
        }
        .is-checked .el-checkbox__label {
          color: #999999;
        }
      }
      ::v-deep .nfont3{
        
        .el-checkbox__label {
          color: #999999 !important;
        }
      }
      ::v-deep .el-select__tags {
        height: 100%;
        overflow: hidden;
        display: flex;
        span {
          display:flex;
        }
      }
    }

    .onlinecontain {
      flex: 1;
      overflow: auto;
      ::v-deep .el-popover__reference {
        display: flex;
      }
      .online_main_contain {
        position: relative;
        cursor: pointer;
        padding: 10px 20px;
        display: flex;
        align-items: center;

        .tu {
          // background-color: #fff;
          width: 36px;
          height: 36px;
          border-radius: 4px;
          overflow: hidden;
          margin-right: 10px;
          position: relative;
          img {
            height: 100%;
            width: 100%;
          }
          .equipment {
            position: absolute;
            right: 0;
            bottom: 0;
            height: 12px;
            width: auto;
          }
        }
        .name_describe {
          width: calc(100% - 46px);
          .name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 12px;
            color: #ffffff;
          }
          .describe {
            font-size: 12px;
            color: #666666;
          }
        }
        &:hover {
          background-color: #28292c;
        }
        .selectFriend {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          align-items: center;
          display: flex;
        }
      }
    }
  }
  .empty {
    position: absolute;
    left: 50%;
    top: 65%;
    transform: translate(-50%, -50%);
    .img {
      width: 77px;
      margin: 0 auto;
      display: block;
    }
    .text {
      color: #666;
      font-size: 12px;
      padding: 20px 0;
      text-align: center;
    }
  }
}
.priSendAll{
  ::v-deep .el-dialog__header{
    background: #262626;
    color: #fff;
  }
  ::v-deep .el-dialog__body {
    background: #111111;
  }
  ::v-deep .el-dialog__footer {
    display: none;
    background: #111111;
  }
  .sendContain {
    .memberTabs {
      height: 90px;
      padding: 10px;
      margin-top: 10px;
      background: #2C2C2C;
      border-radius: 4px;
      .tbs {
        display: inline-block;
        text-align: center;
        align-items: center;
        // width: 68px;
        padding:0 5px;
        height: 24px;
        background: #393939;
        border-radius: 3px;
        border: 1px solid #404040;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #FFFFFF;
      }
    }
    .sendTabs {
      margin-top:20px;
      background: #393939;
      border: 1px solid #404040;
      border-radius: 3px;
      #message{
        height:200px;
      }
    }
  }
}
</style>
<style lang="scss">
.rightcomponents-select {
  border-color: #292929 !important;
  margin-top: 0 !important;
  width: 153px;
  .popper__arrow {
    display: none !important;
  }
  .el-select-dropdown__list {
    background-color: #292929 !important;
    color: #fff;
  }
  .el-select-dropdown__item.hover,
  .el-select-dropdown__item:hover {
    background-color: #292929 !important;
    color: #fff;
  }
  .el-select-dropdown__item.selected {
    color: #fa6400;
  }
  .el-select-dropdown__item {
    color: #fff;
  }
}

.rightcomponents_popover {
  min-width: 0 !important;
  padding: 0 !important;
  background-color: #252525 !important;
  border-color: #252525 !important;
  color: #ffffff !important;
  .popover_item {
    line-height: 30px;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      color: #ad470e !important;
    }
  }
}
</style>

