<!--
 * @Author: cyy
 * @version: 
 * @Date: 2023-01-30 15:47:44
 * @LastEditTime: 2023-02-02 15:19:06
 * @LastEditors: cyy
 * @Description: 控制台-营销互动-添加奖品-选择奖品弹出啊
 * @FilePath: \teacherdingding.com\src\components\base\networktypeSelect\coupon.vue
-->
<template>
  <div class="coupon">
    <pagination2
      :option="post"
      url="/coupon/liveCouponList"
      ref="childDialogBox"
    >
      <template v-slot:default="{ tableData }">
        <div class="pagination2Box1">
          <el-table
            @selection-change="DialogBoxSelectionChange"
            ref="dialogTable"
            :row-key="getRowkey"
            @row-click="handleRowClick"
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333',
            }"
            max-height:200
            :data="tableData"
            :show-header="false"
          >
            <el-table-column
              reserve-selection
              type="selection"
              width="50"
            ></el-table-column>
            <el-table-column>
              <template slot-scope="{ row }">
                <div class="contain ">
                  <div
                    class="tu_father"
                    :style="
                      row.pre_way == 1
                        ? 'background: #ef4a4a;'
                        : 'background: #f1a61c;'
                    "
                  >
                    <div class="tu_child">
                      <div class="type_name">
                        {{ row.pre_way == 1 ? '满减券' : '折扣券' }}
                      </div>
                    </div>
                  </div>
                  <div class="right">
                    <div class="top flex-center">
                      <span class="status">进行中</span>
                      <div class="ml10">
                        <span v-if="row.pre_way == 1">
                          满{{ row.threshold }}减{{ row.cou_price }}
                        </span>
                        <span v-else>{{ row.discount }}折券</span>
                      </div>
                    </div>
                    <div class="bottom">
                      <span style="margin-right:100px">
                        剩余：{{ row.unclaimed_count }}
                      </span>
                      <span v-if="row.time_type == 2">
                        领取当日起{{ row.days }}天内可用
                      </span>
                      <span v-else-if="row.time_type == 3">
                        领取次日起{{ row.days }}天内可用
                      </span>
                      <span v-else>
                        {{ row.start_time | formatTimeStamp }}-{{
                          row.end_time | formatTimeStamp
                        }}可用
                      </span>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
    </pagination2>
  </div>
</template>

<script>
export default {
  data() {
    return {
      post: {
        goodList: this.goodList,
      },
    }
  },
  props: {
    goodList: [Array],
  },
  methods: {
    getRowkey(row) {
      return row.coupon_id
    },

    DialogBoxSelectionChange(val) {
      //点击选择暂存选项数组
      this.listSave = val
      this.$emit('listSave', val)
    },
    handleRowClick(row) {
      this.$refs.dialogTable.toggleRowSelection(row)
    },
  },
}
</script>

<style lang="scss" scoped>
.coupon {
  .contain {
    display: flex;
    align-items: center;
    .tu_father {
      height: 90px;
      width: 100px;
      padding: 5px;
      .tu_child {
        border: #fff 1px solid;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .type_name {
          color: #fff;
        }
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 20px;
      height: 90px;
      .bottom {
        color: #979595;
      }
      .status {
        border-radius: 2px;
        color: #2e94eb;
        background-color: #dbe3e5;
        padding: 0 5px;
        border: 1px solid #f5f5f5;
      }
    }
  }
}
</style>
