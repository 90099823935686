/*
 * @Author: pengmingjun 1317525216@qq.com
 * @Date: 2022-11-04 17:47:08
 * @LastEditors: pengmingjun 1317525216@qq.com
 * @LastEditTime: 2022-11-04 18:05:14
 * @FilePath: \teacher.dingdingkaike\src\views\OpenClass_openClassLive\components\liveDataComponents\changKong\robotListLocalStorage.js
 * @Description: 快速应答、自动发言的发送人来源，将最新选择的来源记录到本地缓存，生命周期为1天时间，并给出文字提示：最近选择的发送人来源将保留一天有效期！
 */
export default {
  created() {
    let robotListLocalStorage = localStorage.getItem(this.localStorageName)
    if (robotListLocalStorage) {
      try {
        robotListLocalStorage = JSON.parse(robotListLocalStorage)
        if (new Date() - robotListLocalStorage.time > 86400000) {
          console.info('机器人缓存到期')
        } else {
          this.robotList = robotListLocalStorage.val
        }
      } catch (e) {
        console.info('机器人缓存 error', e)
      }
    }
  },

  watch: {
    robotList(val) {
      localStorage.setItem(
        this.localStorageName,
        JSON.stringify({
          time: +new Date(),
          val,
        })
      )
    },
  },
}
