<template>
  <div id="commodity">
    <div class="header">
      <el-button type="primary" @click="toCommerce">管理商品</el-button>
      <div class="flex-center">
        <el-select
          v-model="post.goods_class"
          style="width: 120px"
          size="medium"
          placeholder="请选择"
          class="mr20"
        >
          <el-option
            v-for="item in options2"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-select
          v-model="post.goods_type"
          style="width: 120px"
          size="medium"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-input
          size="medium"
          v-model="post.name"
          class="ml20"
          style="width: 218px"
          placeholder="输入名称搜索"
        ></el-input>
      </div>
    </div>
    <div class="redtip">
      提示：每点击上链接，学生端都会浮窗显示商品，连续点击则只显示最后的一个；建议讲解哪个商品就上哪个；已上链接的商品在学生端底部【小黄车】里展示
    </div>
    <pagination2
      :option="post"
      url="/LiveGoods/goodsList"
      ref="childDialogBox"
      class="childDialogBox"
    >
      <template v-slot:default="{ tableData }">
        <el-table
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
          :data="tableData"
        >
          <el-table-column label="商品名称">
            <template slot-scope="{ row }">
              <div class="good_contain">
                <div class="pic">
                  <img :src="row.cover_img" alt="" />
                </div>
                <div class="name_price">
                  <div class="name">{{ row.name }}</div>
                  <div class="price">
                    <span v-if="row.price">￥{{ row.price }}</span>
                    <span class="corss_price" v-if="row.cost_price">
                      ￥{{ row.cost_price }}
                    </span>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="类型">
            <template slot-scope="{ row }">
              {{ row.goods_class | gettype2 }}
            </template>
          </el-table-column>

          <el-table-column label="平台">
            <template slot-scope="{ row }">
              {{ row.goods_type | gettype }}
            </template>
          </el-table-column>

          <el-table-column label="操作" width="130px">
            <template slot-scope="{ row }">
              <div class="btns">
                <el-button
                  v-if="row.status == 2"
                  @click="upDownLink(row.live_goods_id, 1)"
                  type="text"
                >
                  上链接
                </el-button>

                <el-popconfirm
                  :key="row.live_goods_id"
                  v-if="row.status == 1"
                  confirm-button-text="确认"
                  cancel-button-text="取消"
                  icon="el-icon-info"
                  title="是否确定下链接？"
                  @confirm="upDownLink(row.live_goods_id, 2)"
                >
                  <el-button
                    type="text"
                    slot="reference"
                    style="color: #ff3535"
                  >
                    下链接
                  </el-button>
                </el-popconfirm>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>
  </div>
</template>

<script>
export default {
  name: 'commodity',
  data() {
    return {
      post: {
        goods_type: '0',
        name: '',
        open_class_id: this.$route.params.id,
        goods_class: '0',
      },

      options2: [
        {
          value: '0',
          label: '全部类型',
        },
        {
          value: '1',
          label: '知识商品',
        },
        {
          value: '2',
          label: '外部商品',
        },
      ],

      options: [
        {
          value: '0',
          label: '全部平台',
        },
        {
          value: '1',
          label: '淘宝',
        },
        {
          value: '2',
          label: '天猫',
        },
        {
          value: '3',
          label: '京东',
        },
        {
          value: '4',
          label: '其他',
        },
      ],
    }
  },

  filters: {
    gettype2(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '知识商品'
          break
        case 2:
          text = '外部商品'
          break
      }
      return text
    },

    gettype(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '淘宝'
          break
        case 2:
          text = '天猫'
          break
        case 3:
          text = '京东'
          break
        case 4:
          text = '其他'
          break
        default:
          text = '-'
      }
      return text
    },
  },
  
  methods: {
    toCommerce() {
      let url = this.$router.resolve({
        path: '/commerce',
      })
      this.$store.dispatch('open', url.href)
    },

    upDownLink(id, status) {
      this.$http({
        url: '/LiveGoods/push',
        data: {
          open_class_id: this.$route.params.id,
          live_goods_id: id,
          status: status,
        },
        callback: () => {
          this.$refs.childDialogBox.reset()
          this.$root.prompt({
            msg: status == 1 ? '链接已推送到直播间！' : '操作成功',
            type: 'success',
          })
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#commodity {
  padding: 10px 20px ;
  ::-webkit-scrollbar {
    width: 2px;
    height: 8px;
    background-color: #fff;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
    background-color: #fff;
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ccc;
  }

  ::v-deep .pagination {
    padding: 17px 0 0;
  }
  ::v-deep .el-table {
    height: 320px;
    .el-table__body-wrapper {
      overflow: auto !important;
      height: 88%;
    }
  }
  .redtip {
    font-size: 12px;
    color: #ff3535;
    line-height: 16px;
    margin: 8px 0 20px;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .childDialogBox {
    .good_contain {
      display: flex;

      .pic {
        background-color: transparent;
        width: 60px;
        height: 60px;
        min-width: 60px;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .name_price {
        flex: 1;
        overflow: hidden;
        .name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          color: #333333;
        }
        .price {
          margin-top: 20px;
          font-size: 14px;
          color: #ff3535;
          .corss_price {
            margin-left: 10px;
            text-decoration: line-through;
            color: #999999;
          }
        }
      }
    }
    .btns {
      display: flex;
      align-items: center;
      .sp_dir {
        color: #1b9d97;
        margin: 0 10px;
      }
    }
    .footer {
      margin-top: 20px;
      font-size: 14px;
      color: #333333;
    }
  }
}
</style>