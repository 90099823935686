<template>
  <div class="daihuocontain">
    <div class="daihuo">
      <!-- 选择网校内容 -->
      <networkschool-select
        :isPrivate="isPrivate"
        :open_class_id="openClassId"
        :modal="false"
        :dialogstatus="true"
        title="推送内容(单选)"
        :template="5"
        @activeName="getactiveName"
        @selectChangenetworkschool="selectChangenetworkschool"
        @singleselectcontentval="singleselectcontentval"
      ></networkschool-select>
    </div>
    <commodity v-if="activeName == 7"></commodity>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('OpenClassopenClassLive')
import networkschoolSelect from '@cm/base/networktypeSelect/networkschoolSelect'
import commodity from './commodity'

export default {
  name: 'daihuo',

  components: {
    networkschoolSelect,
    commodity,
  },

  computed: {
    ...mapState([
      'openClassId',
      'liveInfo',
      'openAndClose',
      'otherfnPrivateletterselectVal',
      'quotelist',
      'rightQuote',
      'userInfo',
    ]),
    // 判断是否是私聊
    isPrivate() {
      return Object.keys(this.otherfnPrivateletterselectVal).length > 0
    },
  },

  data() {
    return { activeName: 7 }
  },

  methods: {
    getactiveName(val) {
      this.activeName = val
    },

    //推送选择的内容
    singleselectcontentval(val) {
      this.$root.$emit('singleselectcontentval', [val])
    },

    selectChangenetworkschool() {
      this.selectChangestatus = false
    },
  },
}
</script>
<style lang="scss" scoped>
.daihuocontain {
  height: 100%;
  position: relative;
}
.daihuo {
  height: 100%;
  position: relative;
  ::v-deep .newPagination {
    height: calc(100% - 140px);
  }
  ::-webkit-scrollbar {
    width: 2px;
    height: 8px;
    background-color: #fff;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
    background-color: #fff;
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ccc;
  }
  .el-table {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    bottom: 86px;
    overflow-y: auto;
    @extend %liveRoomScroll;
    &::-webkit-scrollbar-track {
      background: none;
      box-shadow: none;
      border-radius: 0;
    }
    .el-table__body-wrapper {
      height: auto;
      overflow: hidden !important;
    }
  }
  ::v-deep #networkschoolSelect .el-dialog__wrapper {
    position: static !important;
    overflow: hidden;
  }
  ::v-deep #networkschoolSelect .el-dialog {
    position: static !important;
    margin-top: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    margin-bottom: 0 !important;
    border-radius: 0 !important;
    background: #171717 !important;
    box-shadow: none !important;
  }
  ::v-deep #networkschoolSelect .el-dialog__footer {
    padding: 0 20px;
  }
  ::v-deep #networkschoolSelect .el-dialog__header {
    display: none !important;
  }
  ::v-deep #networkschoolSelect .el-tabs__nav-scroll {
    padding: 0;
  }
  ::v-deep #networkschoolSelect .el-tabs__item:hover,
  ::v-deep #networkschoolSelect .el-tabs__item.is-active {
    color: #fa6400;
  }
  ::v-deep #networkschoolSelect #commodity {
    position: absolute;
    left: 0;
    top: 55px;
    right: 0;
    bottom: 0;
    padding: 20px 20px 0;
    display: flex;
    flex-direction: column;
    .childDialogBox {
      flex: 1;
      position: relative;
      .el-table {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: auto;
        bottom: 86px;
        overflow-y: auto;
        @extend %liveRoomScroll;
        &::-webkit-scrollbar-track {
          background: none;
          box-shadow: none;
          border-radius: 0;
        }
        .el-table__body-wrapper {
          height: auto;
          overflow: hidden !important;
        }
      }
      .pagination {
        padding: 0 !important;
      }
    }
  }
  ::v-deep #networkschoolSelect .select-course-box {
    padding: 10px 0;
  }
  ::v-deep #networkschoolSelect .el-tabs__nav-wrap::after {
    height: 1px;
    background-color: #222222;
  }
  ::v-deep #networkschoolSelect .el-tabs__item:hover,
  ::v-deep #networkschoolSelect .el-tabs__item.is-active {
    color: #fa6400;
  }
  ::v-deep #networkschoolSelect .el-tabs__item {
    color: #fff;
    font-size: 15px;
  }
  ::v-deep #networkschoolSelect .el-tabs__active-bar {
    background: #fa6400;
  }
  ::v-deep #networkschoolSelect .el-button--primary,
  ::v-deep #networkschoolSelect .el-button--primary:hover,
  ::v-deep #networkschoolSelect .el-button--primary:focus {
    border: 0;
    background: #fa6400;
  }
  ::v-deep #networkschoolSelect .el-input__inner {
    border: 0;
    color: #fff;
    background: #292929;
  }
  ::v-deep #networkschoolSelect .redtip {
    margin: 20px 0;
  }
  ::v-deep .el-table {
    color: #fff;
    background: #171717;
    .name {
      color: #fff !important;
    }
  }
  ::v-deep #networkschoolSelect .el-table--group::after,
  ::v-deep #networkschoolSelect .el-table--border::after {
    background-color: #171717 !important;
  }
  ::v-deep #networkschoolSelect .el-table::before {
    display: none;
  }
  ::v-deep #networkschoolSelect .el-table tr {
    background: #2a2a2a;
  }
  ::v-deep
    #networkschoolSelect
    .el-table--enable-row-hover
    .el-table__body
    tr:hover
    > td {
    background: #393939;
  }
  ::v-deep #networkschoolSelect .el-table th.is-leaf {
    border-bottom: 1px solid #171717;
    background: #212121 !important;
    color: #fff !important;
  }
  ::v-deep #networkschoolSelect .el-table td,
  ::v-deep #networkschoolSelect .el-table__empty-block {
    background: #171717;
    color: #fff !important;
    border-bottom: 1px solid #212121;
  }
  ::v-deep #networkschoolSelect .btn-prev .el-icon,
  ::v-deep #networkschoolSelect .btn-next .el-icon {
    color: #fff !important;
  }
  ::v-deep #networkschoolSelect .el-pagination {
    white-space: normal;
    word-break: break-all;
  }

  ::v-deep #networkschoolSelect .el-pagination button {
    color: #fff !important;
    background: #292929 !important;
  }

  ::v-deep #networkschoolSelect .el-pager li {
    color: #fff;
    background-color: #292929;
  }

  ::v-deep #networkschoolSelect .el-pager li.active {
    background-color: #fa6400 !important;
  }

  ::v-deep #networkschoolSelect .search {
    padding: 0;
  }
  ::v-deep #networkschoolSelect .pagination {
    height: auto;
    padding-bottom: 0;
    padding-top: 0;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }
  ::v-deep #networkschoolSelect .pagination2Box {
    position: absolute;
    left: 0;
    top: 106px;
    right: 0;
    bottom: 59px;
    height: auto;
    overflow-y: auto;
  }
  ::v-deep #networkschoolSelect .pagination2Box {
    // margin: 20px 0;
    @extend %liveRoomScroll;
    &::-webkit-scrollbar-track {
      background: none;
      box-shadow: none;
      border-radius: 0;
    }
  }
  ::v-deep #networkschoolSelect .dialog-footer .el-button:first-child {
    display: none;
  }
  ::v-deep #networkschoolSelect .selectDialogBox {
    position: absolute;
    left: 0;
    top: 77px;
    right: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    .rightContent {
      flex: 1;
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      padding: 0 20px;
      box-sizing: border-box;
      .el-table__body-wrapper {
        overflow: hidden;
      }
    }
    .pagination2Box {
      width: 100%;
      position: static;
      height: 100%;
      box-sizing: border-box;
      margin: 20px 0;
    }
  }
  ::v-deep #networkschoolSelect .leftMenu {
    height: 100%;
    flex-shrink: 0;
    border: 1px solid #212121;
    @extend %liveRoomScroll;
    &::-webkit-scrollbar-track {
      background: none;
      box-shadow: none;
      border-radius: 0;
    }
    .el-tree {
      background: #212121;
      .my-custom .label {
        color: #fff;
      }
      .el-tree-node__content:hover,
      .el-tree-node.is-current > .el-tree-node__content {
        background: #393939 !important;
      }
    }
  }
}
</style>
