<template>
  <div>
    <div
      class="contain"
      v-loading="loading"
      element-loading-spinner="el-icon-loading"
      element-loading-text="生成中..."
    >
      <div style="position: relative">
        <div ref="poster11" class="post1" id="poster11">
          <img class="bg_img" @load="loadImg" :src="selectVal.bgImg" alt=""/>
          <div class="contain_main">
            <div class="jigou_info">
              <div class="tu">
                <img :src="$store.state.userInfo.jg_logo" alt="" />
              </div>
              <div class="name">
                {{ $store.state.userInfo.jg_title }}
              </div>
            </div>
            <div class="course_name">{{ shareinfoval.name | sliceTitle(46) }}</div>
            <div class="start_time">
              <span v-if="shareinfoval.start_time > 0" class="timecontain" :class="selectValColor">
                {{
                  shareinfoval.start_time | formatTimeStamp('YYYY-MM-dd hh:mm')
                }}开播
              </span>
            </div>
            <div class="cover_img">
              <img :src="shareinfoval.cover_img" alt="" />
            </div>
            <div class="share_person_photo">
              <div class="left">
                <div class="person_img">
                  <img :src="$store.state.userInfo.uphoto" alt="" />
                </div>
                <div class="person_name">
                  {{ $store.state.userInfo.uname }}
                  <p>邀请你加入！</p>
                </div>
              </div>
              <div v-if="activeName == 1" class="erweima" id="qrcode"></div>
              <div v-else class="erweima">
                <img :src="shareinfoval.small_url" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="post1 resultPic" v-if="resultPic1">
          <img :src="resultPic1" alt="" />
        </div>
      </div>
      <div class="btns">
        <div
          @click="selectType(item)"
          class="bt"
          v-for="item in postList"
          :key="item.id"
          :style="`background:${item.bgColor}`"
        >
          <div class="ischeck" v-if="isSelect == item.id">
            <img src="~@ass/img/1.4.2.8/icon_dg@2x.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="tip">提示：鼠标指向图片，点按右键可直接复制图片</div>
  </div>
</template>
<script>
import html2canvas from 'html2canvas'
import QRCode from '@/assets/js/qrcode'
export default {
  name: 'post',
  data() {
    return {
      loading: true,

      resultPic1: '',

      selectVal: {},

      isSelect: 1,

      postList: [
        // {
        //   id: 1,
        //   bgColor: '#FC4349',
        //   bgImg: require('@/assets/img/1.4.2.8/img_hsbg@2x.png'),
        // },

        // {
        //   id: 2,
        //   bgColor: '#FB922B',
        //   bgImg: require('@/assets/img/1.4.2.8/img_csbg@2x.png'),
        // },
        // {
        //   id: 3,
        //   bgColor: '#4AD78B',
        //   bgImg: require('@/assets/img/1.4.2.8/img_lsbg@2x.png'),
        // },
        // {
        //   id: 4,
        //   bgColor: '#45B9DA',
        //   bgImg: require('@/assets/img/1.4.2.8/img_lsbg@2x (1).png'),
        // },
        // {
        //   id: 5,
        //   bgColor: '#201D18',
        //   bgImg: require('@/assets/img/1.4.2.8/img_heisbg@2x.png'),
        // },
        {
          id: 3,
          bgColor: '#2e7eff',
          bgImg: require('@/assets/img/1.4.43/bg/img_lansbg@2x.png'),
        },
        {
          id: 1,
          bgColor: '#030b0b',
          bgImg: require('@/assets/img/1.4.43/bg/img_heisbg@2x.png'),
        },
        {
          id: 2,
          bgColor: '#a62cdc',
          bgImg: require('@/assets/img/1.4.43/bg/img_ziqingsbg@2x.png'),
        },
        {
          id: 4,
          bgColor: '#ceabdc',
          bgImg: require('@/assets/img/1.4.43/bg/img_zisbg@2x.png'),
        },
        {
          id: 5,
          bgColor: '#c80076',
          bgImg: require('@/assets/img/1.4.43/bg/img_pinsbg@2x.png'),
        },
        {
          id: 6,
          bgColor: '#e6ebed',
          bgImg: require('@/assets/img/1.4.43/bg/img_qingsbg@2x.png'),
        },
        {
          id: 7,
          bgColor: '#ff4d83',
          bgImg: require('@/assets/img/1.4.43/bg/img_fensbg@2x.png'),
        },
        {
          id: 8,
          bgColor: '#ffe000',
          bgImg: require('@/assets/img/1.4.43/bg/img_huangsbg@2x.png'),
        },
      ],
    }
  },
  props: {
    shareinfoval: {
      type: Object,
      default: () => {},
    },
    activeName: {
      type: String,
      default: '1',
    },
  },
  filters: {
    sliceTitle(val, size) {
      return val.length > size ? `${val.slice(0, size - 1)}...` : val
    },
  },
  computed:{
    selectValColor(){
      let id = this.selectVal.id
      let str = ''
      switch (id) {
        case 6:
        case 8:
          str = 'color2'
          break;
        default:
          str = 'color1'
          break;
      }
      return str
    },
  },
  mounted() {
    this.resultPic1 = ''
    this.selectType(this.postList[0], 1)
  },

  methods: {
    selectType(item, val) {
      this.resultPic1 = ''
      this.selectVal = item
      this.isSelect = item.id
      this.loading = true

      if (this.activeName == 1 && val == 1) {
        this.$nextTick(() => {
          this.create_ewm()
        })
      }
    },

    // 生成二维码
    create_ewm() {
      //生成前先清空原有的，不然会有多张二维码的情况
      document.querySelector('#qrcode').innerHTML = ''
      const node = document.getElementById('qrcode')
      if (node) {
        this.qrcode = new QRCode(node, {
          text: this.shareinfoval.share_url, //地址
          width: 74 * 3,
          height: 74 * 3,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H,
        })
      }
    },

    start() {
      this.resultPic1 = ''
      this.create1()
    },

    restPost() {
      this.start()
    },

    loadImg() {
      this.start()
    },

    // 生成海报
    create1() {
      html2canvas(document.getElementById('poster11'), {
        useCORS: true,
        allowTaint: false,
        logging: false,
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        scale: 3,
        windowWidth: document.documentElement.offsetWidth,
        windowHeight: document.documentElement.offsetHeight,
      })
        .then(canvas => {
          this.resultPic1 = canvas.toDataURL('image/png')
          this.loading = false
          canvas = null
        })
        .catch(res => {
          console.log(res)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-loading-mask {
  background-color: #f5f5f5 !important;
}
.tip {
  margin-top: 25px;
  text-align: center;
  font-size: 13px;
  color: #ff3535;
  line-height: 13px;
}
.contain {
  display: flex;
  position: relative;
}
img {
  width: 100%;
  height: 100%;
}
.post1 {
  margin-left: 284px;
  position: relative;
  width: 232px;
  height: 373px;
  .bg_img {
    border-radius: 10px;
  }
  .contain_main {
    line-height: 33px;
    width: 202px;
    position: absolute;
    top: 15px;
    left: 15px;
    .jigou_info {
      display: flex;
      align-items: center;
      position: absolute;
      left: -10px;
      top: -8px;
      .tu {
        width: 15px;
        height: 15px;
        border-radius: 28px;
        margin-right: 10px;
      }
      .name {
        font-size: 9px;
        font-weight: 600;
        color: #ffffff;
        line-height: 9px;
      }
    }
    .course_name {
      height: 50px;
      overflow: hidden;
      margin: 30px 5px 28px 5px;
      font-size: 12px;
      line-height: 17px;
      font-weight: 800;
      color: #ffffff;
    }
    .start_time {
      display: table;
      height: 17px;
      .timecontain {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 600;
        color: #666666;
        line-height: 10px;
        background: #ffffff;
        border-radius: 9px;
        height: 100%;
        width: 100%;
        border-radius: 10px;
        padding: 0 3px;
      }
      .color1 {
        color: #ffffff;
        background: rgba(255,255,255,0.3);
      }
      .color2 {
        color: #313233;
        background: rgba(0,0,0,0.1);
      }
    }
    .cover_img {
      width: 100%;
      height: 114px;
      margin: 6px 0 23px;
      border-radius: 4px;
    }
    .share_person_photo {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .left {
        flex: 1;
        overflow: hidden;
        margin-top: 10px;
        display: flex;
        align-items: center;
        .person_img {
          width: 28px;
          height: 28px;
          border-radius: 28px;
          margin-right: 10px;
        }
        .person_name {
          flex: 1;
          font-size: 8px;
          color: #333333;
          line-height: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      ::v-deep .erweima {
        height: 60px;
        width: 60px;
        flex-shrink: 0;
        // margin-left: 10px;
        margin: 2px 0 0 10px;
        border: 1px solid black;
        padding: 2px;
        * {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }
  }
}
.resultPic {
  position: absolute;
  left: 0;
  top: 0;
  width: 232px;
  height: 373px;
}
.post1 {
  margin-left: 264px;
}

.btns {
  margin-left: 226px;
  margin-top: 48px;
  .bt {
    position: relative;
    width: 28px;
    height: 28px;
    border-radius: 15px;
    margin-bottom: 10px;
    cursor: pointer;
    .ischeck {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
