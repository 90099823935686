<template>
    <div id="commodity">
        <div class="header">
          <div class="header-bet">
            <el-button type="primary" @click="toCommerce">添加优惠券</el-button>
            <div class="flex-center">
                <el-input
                    size="medium"
                    v-model="post.search"
                    class="ml20"
                    style="width: 218px"
                    placeholder="请输入优惠券名称搜索"
                ></el-input>
                <el-button
                    type="text"
                    class="ml10"
                    @click="$refs.childDialogBox.reset()"
                >
                刷新
                </el-button>
            </div>
          </div>
          <div>
            <el-radio-group v-model="type" @change="radioChange" size="medium">
                <el-radio-button label="1">可发放</el-radio-button>
                <el-radio-button label="2">发放记录</el-radio-button>
            </el-radio-group>
          </div>
        </div>
        
        <pagination2
          @complete="complete"
          :option="post"
          :url="type == 1
            ?'/liveCoupon/couponList'
            :'/liveCoupon/recordList'"
          ref="childDialogBox"
        >
          <template>
            <div class="pagination2Box" ref="pagination2Box">
              <el-table
                max-height:200
                :header-cell-style="{
                  background: 'rgba(245,245,245,1)',
                  color: '#333333',
                }"
                :data="tableList"
                :key="'tableRefreshKey'+tableRefreshKey"
              >
                <el-table-column label="名称">
                  <template slot-scope="{ row }">
                    {{ row.name }}
                  </template>
                </el-table-column>

                <el-table-column label="类型">
                  <template slot-scope="{ row }">
                    <div v-if="row.type == 1">通用券</div>
                    <div v-else-if="row.type == 2">专属券</div>
                  </template>
                </el-table-column>

                <el-table-column label="优惠内容">
                  <template slot-scope="{ row }">
                    {{ row.threshold == 0 ? '无门槛' : `满${row.threshold}元` }},
                    {{
                      row.pre_way == 1
                        ? `减${row.cou_price}元`
                        : `折${row.discount}`
                    }}
                  </template>
                </el-table-column>

                <el-table-column label="库存数量" min-width="125">
                  <template slot-scope="{ row, $index }">
                    <div v-if="type == 1 || type == 2">
                      <div v-if="row.selectStatus">
                        <el-input-number
                          :min="0"
                          :max="99999"
                          :key="$index"
                          :precision="0"
                          :step="1"
                          size="small"
                          v-model="row.unclaimed_count"
                        ></el-input-number>
                        <div>
                          <el-button
                            type="text"
                            class="ml10"
                            style="color: #fa6400"
                            @click="saveNewNumber(row, 1, $index)"
                          >
                            保存
                          </el-button>
                          <el-button
                            style="color: #f5f5f5"
                            type="text"
                            class="ml10"
                            @click="saveNewNumber(row, 2)"
                          >
                            取消
                          </el-button>
                        </div>
                      </div>
                      <el-button
                        v-else
                        style="color: #fa6400"
                        type="text"
                        @click="changeStockNum($index, true)"
                      >
                        {{ row.unclaimed_count }}
                        <i class="el-icon-edit" style="color: #fa6400"></i>
                      </el-button>
                    </div>
                    <div v-else>
                      {{ row.unclaimed_count }}
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="使用情况" v-if="type == 2">
                  <template slot-scope="{ row }">
                    <p>已领取:{{ row.receive_num}}</p>
                    <p>已使用:{{ row.use_num }}</p>
                  </template>
                </el-table-column>

                <el-table-column label="领取失效时间" v-if="type == 2">
                  <template slot-scope="{ row }">
                    <van-count-down
                        v-show="false"
                        v-if="row.countdown != 0"
                        class="count-down" 
                        :time="row.countdown*1000"
                        @finish="refresh"
                    ></van-count-down>
                    {{ row.end_time | formatTimeStamp('YYYY-MM-dd hh:mm:ss') }}
                  </template>
                </el-table-column>

                <el-table-column label="状态" v-if="type == 1">
                  <template slot-scope="{ row }">
                      <span v-if="row.status == 1">未开始</span>
                      <span v-if="row.status == 2" style="color:rgb(61, 128, 239)">
                      进行中
                      </span>
                      <span v-if="row.status == 3" style="color:red">已失效</span>
                      <span v-if="row.status == 4" style="color:red">已结束</span>
                  </template>
                </el-table-column>

                <el-table-column label="操作" min-width="140">
                  <template slot-scope="{ row }">
                    <div class="btns">
                      <div>
                        <el-popover
                          ref="popover"
                          v-if="type == 1"
                          placement="top-start"
                          popper-class="InterestfreeCoupon-popover"
                          width="150"
                          trigger="click"
                          :visible-arrow="false"
                          :key="'conpon'+row.id"
                        >
                          <div class="popover">
                            <div class="popover-tip">领券时效：</div>
                            <div class="list">
                              <ul>
                                <li
                                  @click="
                                    item.value == 4
                                      ? showDialog(row, item.value)
                                      : sendOut(row, item.value)
                                  "
                                  v-for="item in options2"
                                  class="item"
                                  :key="item.value"
                                >
                                  {{ item.label }}
                                </li>
                              </ul>
                            </div>
                          </div>
                          <el-button
                            v-if="row.status == 1||row.status == 2"
                            slot="reference"
                            style="
                              width: 76px;
                              background: #fa6400;
                              border: 0;
                              color: #fff;
                              text-align: center;
                            "
                            size="medium"
                          >
                            发放
                            <i class="el-icon-arrow-down"></i>
                          </el-button>
                        </el-popover>

                      </div>
  
                      <div
                        v-if="type == 2&&row.countdown != 0"
                        class="cp cancel"
                        style="width:86px;color:#FA6400;text-align: right;"
                        @click="setExplain(row, 1)"
                      >
                        停止发放
                      </div>
                      <span class="ml10" style="color:#FA6400;" v-if="type == 2&&row.countdown != 0">|</span>
                      <div
                        class="cp cancel ml10"
                        style="width:86px;color:#FA6400;"
                        :style="`${type == 2&&row.countdown == 0?'margin-left: 86px;':''}`"
                        @click="remove(row)"
                      >
                        {{ type == 1?'移除':'删除' }}
                      </div>
                    </div>
                  </template>
                </el-table-column>

              </el-table>
            </div>
          </template>
        </pagination2>

        <!-- 自定义时间 -->
        <el-dialog
          title="自定义时效"
          :append-to-body="true"
          :visible.sync="dialogVisible"
          width="422px"
        >
          <el-date-picker
            style="width: 100%"
            v-model="etime"
            type="datetime"
            placeholder="选择日期时间"
            :picker-options="pickerOptions"
          ></el-date-picker>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="sendOut(dialogValrow, dialogValval)">
              发放
            </el-button>
          </span>
        </el-dialog>

        <networkschoolSelect2
          title="添加优惠券"
          v-if="addCouponBool"
          @selectChangenetworkschool="closeCouponConfirm"
          @singleselectcontentval="updateCourse"
          :dialogstatus.sync="addCouponBool"
          :template="22"
          :singleselect="false"
        ></networkschoolSelect2>
    </div>
</template>

<script>
export default {
    name: 'couponCoupons',
    components:{
      networkschoolSelect2:()=>import('./networkschoolSelect.vue'),
    },
    data(){
        return {
          type:'1',
          iscomplete: false,
          tableList:[],
          post:{
            search: '',
            // specs: 0,
            open_class_id:this.$route.params.id,
            // num:10,
          },
          dialogValrow: {},
          dialogValval: '',
          dialogVisible: false,
          pickerOptions: {
            disabledDate(time) {
              return time.getTime() < Date.now() - 8.64e7 + 180000 //设置选择今天以及今天之后的日期
            },
          },
          etime: '',
          addCouponBool:false,

          options2: [
            {
              value: 1,
              label: '1分钟',
            },
            {
              value: 2,
              label: '3分钟',
            },
            {
              value: 3,
              label: '15分钟',
            },
            {
              value: 4,
              label: '自定义',
            },
          ],
          tableRefreshKey:0
        }
    },
    methods:{
        refresh() {
          this.$refs.childDialogBox.reset()
          this.tableRefreshKey += 1
        },
        toCommerce(){
            // let url = this.$router.resolve({
            //     path: '/coupon',
            // })
            // this.$store.dispatch('open', url.href)
            this.addCouponBool = true
        },
        radioChange() {
          this.tableList = []
          this.refresh()
        },
        
        // 获取列表数据
        complete(val) {
          val.data.forEach(item => {
            item.selectStatus = false
          })
          this.tableList = val.data
          this.iscomplete = true
        },

        // 点击库存数量，修改状态
        changeStockNum(index, status) {
          this.tableList[index].selectStatus = status

          const arr = []

          this.tableList.forEach(item => {
            arr.push(item)
          })

          this.tableList = arr
        },

        // 修改库存数量
        async saveNewNumber(row, type) {
          if (type === 1) {
            await this.$http({
              url: '/liveCoupon/setInventory',
              data: {
                id:row.id,
                type:this.type,
                num: row.unclaimed_count,
                coupon_id: row.coupon_id,
              },
            })
            // this.changeStockNum(index, false)
            this.refresh()
          } else {
            this.refresh()
          }
        },

        showDialog(row, val) {
          this.dialogValrow = row
          this.dialogValval = val
          this.dialogVisible = true
        },

        // 发放
        async sendOut(row, val) {
          if (val == 4 && !this.etime) {
            this.$root.prompt('请选择时间！')
            return
          }
          if(row.status == 3||row.status == 4){
            this.$root.prompt(row.status == 3?'该优惠券已失效!':'该优惠券已结束!')
            return
          }
          document.body.click()
          await this.$http({
            url: '/liveCoupon/sending',
            data: {
              id:row.id,
              type: val,
              open_class_id: this.$route.params.id,
              etime: val == 4 ? this.etime / 1000 : undefined,
            },
          })
          this.type = 2
          this.radioChange()
          // this.refresh()
          this.$root.prompt({
            msg: '操作成功！',
            type: 'success',
          })

          if (val == 4) {
            this.dialogVisible = false
          }
        },
        closeCouponConfirm(){
          this.addCouponBool = false
        },
        // 添加可发放优惠券
        async updateCourse(val){
          const couponIdList = val.map((item)=>item.coupon_id)
          await this.$http({
            url: '/liveCoupon/add',
            data: {
              coupon_id: couponIdList,
              open_class_id:this.$route.params.id,
            },
            successMsg: true,
          })
          this.$refs.childDialogBox.reset()
        },

        async setExplain(row, type) {
          this.$refs.childDialogBox.reset()
          this.$confirm('是否停止该优惠券发放！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          })
            .then(async () => {
              await this.$http({
                url: '/liveCoupon/stop',
                data: {
                  id: row.id,
                  type,
                },
                successMsg: true,
              })
              this.$refs.childDialogBox.reset()
            })
            .catch(() => {})
        },

        async remove(row) {
          this.$confirm(this.type == 1
            ?'是否从列表移除该优惠券！'
            :'是否删除该发放记录！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          })
            .then(async () => {
              await this.$http({
                url: '/liveCoupon/remove',
                data: {
                  id: row.id,
                  type:this.type,
                },
                successMsg: true,
              })
              this.$refs.childDialogBox.reset()
            })
            .catch(() => {})
        },
    }
}
</script>

<style lang="scss" scoped>
#commodity {
  ::v-deep .el-input-number__increase,
  .el-input-number {
    background-color: #292929;
    border: 0;
    color: #fff;
  }
  ::v-deep .el-input-number__decrease,
  .el-input-number {
    color: #fff;
    background-color: #292929;
    border: 0;
  }

  ::v-deep .el-radio-button__inner {
    color: #fff;
    background-color: #292929 !important;
    border-color: #292929;
  }

  ::v-deep .pagination2Box {
    height: 380px;
    overflow: auto;
    top: 0 !important;
    @extend %liveRoomScroll;
  }

  ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: #fa6400;
    border-color: #292929;
    box-shadow: -1px 0 0 0 #373737;
  }
    .header {
      .header-bet{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
      }
    }

    .newPagination {
      flex: 1;
      position: relative;
    }
    .btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
}
</style>