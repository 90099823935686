<template>
    <div id="conponSelect">
        <div class="search">
            <el-button
                type="primary"
                size="medium"
                @click="toNewConpon"
            >
            创建优惠券
            </el-button>
            <div class="flex-center">
                <el-select
                    v-model="post.type"
                    style="width: 120px"
                    size="medium"
                    placeholder="请选择"
                    class="mr20"
                >
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                ></el-option>
                </el-select>

                <!-- <el-select
                    v-model="post.status"
                    style="width: 120px"
                    size="medium"
                    placeholder="请选择"
                >
                    <el-option
                        v-for="item in options2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select> -->

                <el-input
                    size="medium"
                    v-model="post.search"
                    class="ml20"
                    style="width: 218px"
                    placeholder="输入名称搜索"
                ></el-input>
            </div>
        </div>
        <pagination2 :option="post" url="/coupon/couponList" ref="childDialogBox">
            <template v-slot:default="{ tableData }">
                <div class="childDialogBox">
                    <el-table
                        ref="dialogTable"
                        @row-click="handleRowClick"
                        :header-cell-style="{
                        background: 'rgba(245,245,245,1)',
                        color: '#333333',
                        }"
                        :row-key="getRowkey"
                        :data="tableData"
                        @selection-change="handleSelectionChange"
                    >

                        <el-table-column label width="30" v-if="isSingleSelect">
                            <template slot-scope="scope">
                                <el-radio :label="scope.row.live_goods_id" v-model="radioId">
                                &nbsp;
                                </el-radio>
                            </template>
                        </el-table-column>
                        <el-table-column
                            min-width="45px"
                            type="selection"
                            v-else
                        ></el-table-column>
                        <el-table-column
                            label="优惠券名称"
                            min-width="180px"
                            prop="name"
                        >
                            <template slot-scope="{ row }">
                                <div class="hidetext" :title="row.name">{{ row.name }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="类型">
                            <template slot-scope="{ row }">
                                {{ row.type == 1 ? '通用券' : '专属券' }}
                            </template>
                        </el-table-column>
                        <el-table-column label="优惠内容" width="140px">
                            <template slot-scope="{ row }">
                                {{ row.threshold == 0 ? '无门槛' : `满${row.threshold}元` }},
                                {{
                                row.pre_way == 1
                                    ? `减${row.cou_price}元`
                                    : `折${row.discount}`
                                }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="发放数量"
                            prop="count_all"
                        ></el-table-column>
                        <el-table-column
                            label="可领取用户身份"
                            min-width="120px"
                            prop="identity"
                        >
                        <template slot-scope="{ row }">
                            <div
                            class="hidetext2"
                            :title="row.identity ? row.identity : '-'"
                            >
                            {{ row.identity || '-' }}
                            </div>
                        </template>
                        </el-table-column>
                        <el-table-column label="状态">
                            <template slot-scope="{ row }">
                                <span v-if="row.status == 1">未开始</span>
                                <span v-if="row.status == 2" style="color:rgb(61, 128, 239)">
                                进行中
                                </span>
                                <span v-if="row.status == 3" style="color:red">已失效</span>
                                <span v-if="row.status == 4" style="color:red">已结束</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </template>
        </pagination2>
    </div>
</template>

<script>
export default {
    name:'conponSelect',
    props:{
        //true是单选 false:多选
        isSingleSelect: {
            type: Boolean,
            default: true,
        },
    },
    data(){
        return {
            post:{
                type: 0,
                status: 2,
                search:'',
                open_class_id:this.$route.params.id,
            },

            radioId: '',
            listSave: [],
            options: [
                {
                value: 0,
                label: '全部类型',
                },
                {
                value: 1,
                label: '通用券',
                },
                {
                value: 2,
                label: '专属券',
                },
            ],
            options2: [
                {
                value: 0,
                label: '全部状态',
                },
                {
                value: 1,
                label: '未开始',
                },
                {
                value: 2,
                label: '进行中',
                },
                {
                value: 3,
                label: '已失效',
                },
                {
                value: 4,
                label: '已结束',
                },
            ],
        }
    },
    methods:{
        toNewConpon(){
            
            let url = this.$router.resolve({
                path: '/coupon',
            })
            this.$store.dispatch('open', url.href)
            // this.$router.push({ path: '/coupon/newCoupon/', query: { id: 'new' } })
        },
        getRowkey(row) {
            return row.live_goods_id
        },

        // 单选时需要
        handleRowClick(row) {
            this.radioId = row.live_goods_id
            if (this.isSingleSelect) {
                this.listSave = [row]
                this.$emit('singleselectcontent', row)
            } else {
                this.$refs.dialogTable.toggleRowSelection(row)
            }
        },
        
        handleSelectionChange(val) {
            this.listSave = val
            this.$emit('listSave', val)
        },
    }
}
</script>

<style lang="scss" scoped>
#conponSelect{
  height: 406px;
  display: flex;
  flex-direction: column;
  .search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .childDialogBox {
    height: 285px;
    overflow: auto;
    @extend %scroll;
    margin-bottom: 1px;
  }
}
</style>