<template>
  <div class="liveSetting flex-column">
    <myMenu :data="menuList" v-model="menuId" />
    <div class="childComponent flex1 flex-column">
      <quickt-response :openClassId="openClassId" v-if="menuId == 0" />
      <mockChat v-if="menuId == 1" />
      <automatic-speech :openClassId="openClassId" v-if="menuId == 2" />
      <click-farming :openClassId="openClassId" v-if="menuId == 3" />
      <send-flowers :openClassId="openClassId" v-if="menuId == 4" />
      <chat-history :openClassId="openClassId" v-if="menuId == 5" />
    </div>
  </div>
</template>
<script>
import myMenu from '../menu'
import mockChat from './mockChat'
import quicktResponse from './quicktResponse'
import automaticSpeech from './automaticSpeech'
import clickFarming from './clickFarming'
import sendFlowers from './sendFlowers'
import chatHistory from './chatHistory'
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('OpenClassopenClassLive')

export default {
  name: 'changKong',

  components: {
    myMenu,
    mockChat,
    quicktResponse,
    automaticSpeech,
    sendFlowers,
    clickFarming,
    chatHistory,
  },
  computed: {
    ...mapState(['openClassId']),
  },

  data() {
    return {
      menuId: 0,
      menuList: [
        {
          name: '快速应答',
          id: 0,
        },
        {
          name: '模拟对话',
          id: 1,
        },
        {
          name: '自动发言',
          id: 2,
        },
        {
          name: '聊天记录',
          id: 5,
          new:true,
        },
        {
          name: '机器人刷单',
          id: 3,
        },
        {
          name: '机器人送花',
          id: 4,
        },
      ],
    }
  },

  methods: {
    activeChange(val) {
      this.active = val
    },
  },
}
</script>
<style lang="scss" scoped>
.liveSetting {
  height: 100%;
}
</style>
