<!--
 * @Author: cyy
 * @version: 
 * @Date: 2023-01-31 16:38:37
 * @LastEditTime: 2023-04-26 17:27:06
 * @LastEditors: cyy 1346967714@qq.com
 * @Description: 
 * @FilePath: \teacherdingding.com\src\views\OpenClass_openClassLive\components\liveDataComponents\yingXiao\drawToPrize.vue
-->
<template>
  <div class="drawtoprize">
    <el-dialog
      title="立即开奖"
      class="dialogVisible"
      width="840px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="dialogVisible_bottom">
        <div class="search">
          <div>
            <span class="mr10">在线状态</span>
            <el-select
              v-model="post.online"
              size="medium"
              style="width:100px"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <span class="mr10 ml20">奖项</span>
            <el-select
              clearable
              v-model="post.prize_id"
              size="medium"
              style="width:100px"
              placeholder="请选择"
            >
              <el-option
                v-for="item in lotteryList.list"
                :key="item.prize_id"
                :label="item.prize_name"
                :value="item.prize_id"
              ></el-option>
            </el-select>
          </div>
          <div>
            <el-input
              v-model="post.search"
              size="medium"
              @keyup.enter.native="toSearch"
              style="width:180px"
              class="ml10 el-input"
              clearable
              placeholder="请输入参与人员昵称"
            ></el-input>
            <el-button
              @click="toSearch"
              type="primary"
              class="ml10"
              size="medium"
              icon="el-icon-search"
            >
              搜索
            </el-button>
          </div>
        </div>
        <pagination2
          :option="post2"
          url="/liveLottery/lotteryUserList"
          ref="childDialogBox"
          @complete="complete"
        >
          <template>
            <div class="pagination2Box">
              <!-- @row-click="rowClick" -->
              <el-table
                @select-all="selectAll"
                @selection-change="handleSelectionChange"
                ref="tablelist"
                :row-key="rowKey"
                :header-cell-style="{
                  background: 'rgba(245,245,245,1)',
                  color: '#333333',
                }"
                max-height:200
                :data="tableData"
              >
                <el-table-column
                  type="selection"
                  width="45px"
                  :reserve-selection="true"
                ></el-table-column>
                <el-table-column label="参与人员">
                  <template slot-scope="{ row }">
                    <div class="person">
                      <div class="tu">
                        <img :src="row.uphoto" alt="" />
                      </div>
                      <div class="right">
                        <div class="name hidetext">
                          {{ row.uname }}
                        </div>
                        <div class="status">
                          {{ row.online == 1 ? '在线' : '离线' }}
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="参与时间">
                  <template slot-scope="{ row }">
                    {{ row.c_time | formatTimeStamp }}
                  </template>
                </el-table-column>

                <el-table-column label="奖项">
                  <template slot-scope="{ row, $index }">
                    <el-select
                      v-model="row.prize_id"
                      @change="prizeidChange($event, $index)"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in lotteryList.list"
                        :key="item.prize_id"
                        :label="item.prize_name"
                        :value="item.prize_id"
                      ></el-option>
                    </el-select>
                  </template>
                </el-table-column>

                <el-table-column label="奖品">
                  <template slot-scope="{ row }">
                    {{ row.prize }}
                  </template>
                </el-table-column>

                <el-table-column label="操作">
                  <template slot-scope="{ row }">
                    <el-checkbox
                      v-if="aa"
                      v-model="row.status"
                      true-label="1"
                      false-label="2"
                    >
                      让他中奖
                    </el-checkbox>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
          <template #footer>
            <div class="mt10">
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checked"
                @change="boxChange($event)"
              >
                全选当前页
              </el-checkbox>
              <el-button
                class="ml20"
                :disabled="selected.length == 0"
                size="medium"
                @click="selectPrizes(1)"
              >
                批量设置中奖
              </el-button>
              <el-button
                class="ml20"
                :disabled="selected.length == 0"
                size="medium"
                @click="selectPrizes(2)"
              >
                批量取消中奖
              </el-button>
            </div>
          </template>
        </pagination2>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="submit(1)" type="primary" size="medium">
          保存并立即开奖
        </el-button>
        <el-button type="primary" @click="submit(2)" size="medium">
          保 存
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="批量选择奖项"
      :visible.sync="moreDialogVisible"
      width="30%"
      @close="moreCancel"
    >
      <span class="mr10 ml20">奖项</span>
      <el-select
        clearable
        style="width:308px"
        v-model="moreList.prize_id"
        size="medium"
        placeholder="请选择"
      >
        <el-option
          v-for="item in lotteryList.list"
          :key="item.prize_id"
          :label="item.prize_name"
          :value="item.prize_id"
        ></el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="moreCancel">
          取 消
        </el-button>
        <el-button
          @click="moreSelectprize(moreList.prize_id)"
          size="medium"
          type="primary"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      aa: true,
      moreDialogVisible: false,
      // 自定义全选框
      checked: false,
      isIndeterminate: false,
      // 当前选中的个数
      selectedNum: 0,
      selected: [],
      options: [
        {
          value: 0,
          label: '全部',
        },
        {
          value: 1,
          label: '在线',
        },
        {
          value: 2,
          label: '离线',
        },
      ],
      moreList: {
        prize_id: '',
      },
      lotteryList: [],
      tableData: [],
      post: {
        online: 0,
        prize_id: '',
        lottery_id: this.lottery_id,
        search: '',
      },
      post2: {
        lottery_id: this.lottery_id,
        search: '',
      },
    }
  },
  props: {
    lottery_id: {
      type: String,
      default: '',
    },
    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    selectedNum(val) {
      if (val > 0) {
        if (val == this.tableData.length) {
          this.checked = true
          this.isIndeterminate = false
        } else if (val < this.tableData.length) {
          this.isIndeterminate = true
          this.checked = false
        }
      } else {
        this.isIndeterminate = false
        this.checked = false
      }
    },
  },

  created() {
    this.setLottery()
  },
  methods: {
    rowKey(row) {
      return row.lu_id
    },
    selectPrizes(val) {
      this.moreDialogVisible = true
      this.modifyType = val
    },
    // rowClick(row) {
    //   this.$refs.tablelist.toggleRowSelection(row)
    // },
    // 全选状态
    selectAll(val) {
      if (val.length == 0) {
        this.checked = false
      } else {
        this.checked = true
      }
    },

    // 列表下边的多选
    boxChange(val) {
      // this.tablelistLength = val2.list.length
      if (val) {
        this.$refs.tablelist.toggleAllSelection()
      } else {
        this.$refs.tablelist.clearSelection()
      }
    },

    //多选后触发
    handleSelectionChange(val) {
      this.selected = val
      this.selectedNum = val.length
    },

    moreCancel() {
      this.moreDialogVisible = false

      this.moreList.prize_id = ''
    },

    // 批量选择奖品
    async moreSelectprize(val) {
      const data = this.lotteryList.list.find(item => item.prize_id == val)
      let newList = null
      if (this.modifyType == 1) {
        newList = this.selected.map(item => {
          let arr = _.assign({}, item, {
            prize: data.name,
            prize_name: data.prize_name,
            prize_id: data.prize_id,
            status: '1',
          })
          return arr
        })
      } else {
        /**
         *出现情况：先批量设置，在批量取消，选择的参数保留的之前的数据，不会因为设置中的更改改变
         * 由于数据更改不能取消列表勾选，:reserve-selection="true"，则会在数据更新之后保留之前选中的数据，selected也不会改变
         * 为了能获取勾选后改变的数据，只能从tableData中获取
         */
        let newarray = []
        this.selected.forEach(item => {
          let val = this.tableData.find(item2 => item2.lu_id == item.lu_id)
          newarray.push(val)
        })

        /**
         * 选择的数据中，是否有当前选择的奖项，筛选出来
         */
        let endarr = newarray.filter(
          item => item.prize_id == val && item.status == 1
        )

        newList = endarr.map(item => {
          let arr = _.assign({}, item, {
            status: '2',
          })
          return arr
        })
      }

      newList.forEach(item => {
        let index = this.tableData.findIndex(item2 => item2.lu_id == item.lu_id)
        if (index > -1) {
          this.tableData.splice(index, 1, item)
        }
      })

      this.$root.prompt({
        type: 'success',
        msg: '修改成功',
      })

      this.moreCancel()
    },

    // 列表中奖品的选择
    prizeidChange(val, index) {
      if (val) {
        const data = this.lotteryList.list.find(item => item.prize_id == val)
        this.tableData[index].prize = data.name
        this.tableData[index].prize_name = data.prize_name
      }
    },

    toSearch() {
      this.post2 = _.assign({}, this.post)
    },

    async submit(type) {
      const list = this.tableData.filter(item => item.status == 1)
      await this.$http({
        url: '/liveLottery/saveLotteryUser',
        data: _.assign(
          {},
          {
            list,
            type,
            lottery_id: this.lottery_id,
          }
        ),
        successMsg: true,
      })
      this.$emit('complete')
    },

    async setLottery() {
      const { data } = await this.$http({
        url: `/liveLottery/setLottery?lottery_id=${this.lottery_id}`,
      })
      this.$nextTick(() => {
        this.lotteryList = data
      })
    },

    complete({ data }) {
      this.tableData = data.map(item => {
        return {
          ...item,
          prize_id: !item.prize_id || item.prize_id <= 0 ? '' : item.prize_id,
          prize: item.prize || '',
        }
      })
    },

    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>
<style lang="scss" scoped>
.drawtoprize {
  ::v-deep .el-dialog__body {
    padding: 10px 20px;
  }
  .pagination2Box {
    margin-top: 20px;
    overflow: auto;
    height: 280px;
    @extend %scroll;
    .person {
      display: flex;
      align-items: center;
      .tu {
        flex-shrink: 0;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #f5f5f5;
        img {
          width: 100% !important;
          height: 100% !important;
        }
      }
      .right {
        overflow: hidden;
        line-height: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 50px;
        margin-left: 10px;
      }
    }
  }
  .dialogVisible_bottom {
    .search {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>
