<template>
  <!-- 选择课程 -->
  <div v-if="MenuTreeData">
    <div class="selectDialogBox">
      <div class="titleBefor" v-if="type == 1">
        <!-- <span>(多选)</span> -->
        <!-- <span v-if="type != 2">
          裂变活动仅支持付费课程；已被拼团活动关联的课程无法选择
        </span> -->
      </div>

      <div class="leftMenu" v-if="isLeftMenu">
        <el-tree
          :data="MenuTreeData"
          node-key="id"
          :props="defaultProps"
          :expand-on-click-node="false"
          :highlight-current="true"
          :check-on-click-node="true"
        >
          <span
            class="my-custom"
            slot-scope="{ node, data }"
            @click="handleNodeClick(node, data)"
          >
            <img
              :src="
                data.icon == ''
                  ? require('../../assets/img/1.3.9.6/icon_mrfl@2x.png')
                  : data.icon
              "
              class="tree-icon"
              alt=""
            />

            <!-- <i :class="[data.icon,'tree-icon']"></i> -->
            <span class="label" :title="node.label">
              {{ node.label + '(' + (data.count ? data.count : '0') + ')' }}
            </span>
          </span>
        </el-tree>
      </div>
      <div class="rightContent">
        <div class="headerSelect ml20">
          <div class="storeDialogBox"
            v-if="isCourseType"
          >
            <el-select
              size="medium"
              v-model="DialogBoxPost.course_type"
              placeholder="全部条件"
            >
              <el-option
                v-for="item in DialogBoxOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>

          <div
            class="storeDialogBox"
            v-if="!installment_id && !p_group_id && !goodlistType"
          >
            <el-select
              size="medium"
              v-model="DialogBoxPost.price_type"
              placeholder="全部售价"
            >
              <el-option
                v-for="item in showPassword || price_mold
                  ? DialogBoxOptions_3
                  : DialogBoxOptions_2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>

          <div class="inputDialogBox">
            <el-input
              size="medium"
              placeholder="输入课程名称搜索"
              v-model="DialogBoxPost.search"
              class="input-with-select"
            >
              <!-- 搜索 -->
            </el-input>
          </div>
        </div>
        <pagination2
          :option="DialogBoxPost"
          :url="
            isnerworkselect
              ? '/HomeTemplate/courseList'
              : '/courseSet/getCourseList'
          "
          ref="childDialogBox"
          :allFn="true"
        >
          <template v-slot:default="{ tableData }">
            <div class="pagination2Box">
              <el-table
                :data="tableData"
                ref="dialogTable"
                :row-key="getRowkey"
                @selection-change="DialogBoxSelectionChange"
                @row-click="handleRowClick"
                max-height:200
                :header-cell-style="{
                  background: 'rgba(245,245,245,1)',
                  color: '#333333',
                }"
              >
                <el-table-column label width="30" v-if="singleselect">
                  <template slot-scope="scope">
                    <el-radio
                      v-show="template != 5"
                      :label="scope.row.course_id"
                      v-model="radioId"
                      :disabled="
                        ((scope.row.is_show == 2 &&
                          template != 12 &&
                          template != 13) ||
                          scope.row.status == 2)&&isOverSingleSelect||isOtherSingleSelect
                      "
                    >
                      &nbsp;
                    </el-radio>
                  </template>
                </el-table-column>
                <el-table-column
                  v-else
                  type="selection"
                  :reserve-selection="true"
                  :selectable="selectionEvent"
                  width="55"
                ></el-table-column>

                <el-table-column prop="name" label="课程" min-width="220">
                  <template slot-scope="scope">
                    <div class="className">
                      <img
                        :src="scope.row.photo | imageslim"
                        alt=""
                        style="width: 60px; height: 34px"
                      />
                      <div>
                        <p :title="scope.row.name">{{ scope.row.name }}</p>
                        <div class="type" v-if="'is_show' in scope.row">
                          <!-- 1展示 2不展示 -->
                          <img
                            src="~@ass/img/1.3.9.4/icon_dg.png"
                            alt=""
                            v-if="scope.row.is_show == 1"
                          />
                          <img
                            src="~@ass/img/1.3.9.4/icon_cw.png"
                            alt=""
                            v-else
                          />

                          <span v-if="scope.row.is_show == 1">展示到网校</span>
                          <span v-else>不展示到网校</span>

                          <!-- 1已上架  2已下架   -->
                          <img
                            v-if="scope.row.status == 1"
                            class="ml20"
                            src="~@ass/img/1.3.9.4/icon_dg.png"
                            alt=""
                          />
                          <img
                            v-else
                            class="ml20"
                            src="~@ass/img/1.3.9.4/icon_cw.png"
                            alt=""
                          />

                          <span v-if="scope.row.status == 1">已上架</span>
                          <span v-else>已下架</span>
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column prop="file_size" label="类型">
                  <template slot-scope="scope">
                    <p>
                      {{
                        scope.row.course_type == 1
                          ? '直播课'
                          : scope.row.course_type == 2
                          ? '小班课'
                          : scope.row.course_type == 3
                          ? '录播课'
                          : '系列课'
                      }}
                    </p>
                  </template>
                </el-table-column>

                <el-table-column prop="proportion" label="售价">
                  <template slot-scope="scope">
                    <div class="titleActivit" v-if="showPassword">
                      <p style="color: #ff3535">
                        <span v-if="scope.row.price_type == 1">
                          {{ '￥' + scope.row.price }}
                        </span>
                        <span v-if="scope.row.price_type == 2">
                          {{ '免费' }}
                        </span>
                        <span v-if="scope.row.price_type == 3">
                          {{ '密码' }}
                        </span>
                      </p>
                    </div>
                    <div class="titleActivit" v-else>
                      <p style="color: #ff3535">
                        {{
                          scope.row.price == 0 ? '免费' : '￥' + scope.row.price
                        }}
                      </p>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="操作" v-if="template == 5">
                  <template slot-scope="{ row }">
                    <el-button
                      v-if="row.status == 2 || row.is_show == 2"
                      type="text"
                      disabled
                      style="color: #676767"
                    >
                      推送
                    </el-button>
                    <el-button
                      type="text"
                      v-else
                      style="color: #fa6400"
                      @click="$emit('push')"
                    >
                      推送
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
        </pagination2>
      </div>
    </div>
    <span
      slot="footer"
      class="dialog-footer"
      v-if="!isnerworkselect && !isnaire"
    >
      <div></div>
      <div>
        <el-button @click="dialogSelectBack">取 消</el-button>
        <el-button type="primary" @click="dialogSelectGo">
          确 定
          <template v-if="!singleselect">({{ listSave.length }})</template>
        </el-button>
      </div>
    </span>
  </div>
</template>
<script>
export default {
  data() {
    return {
      radioId: '', //单选的内容

      listSave: [],

      listAll: [],

      MenuTreeData: [],

      defaultProps: {
        children: 'children',
        label: 'set_name',
        count: 'count',
        icon: 'icon',
      },

      DialogBoxPost: {
        installment_id: this.installment_id,
        seckill_id: this.seckill_id,
        is_seckill: this.is_seckill,
        p_group_id: this.p_group_id,
        goodlistType: this.goodlistType,
        goodList: this.goodList
          ? this.goodList.length == 0
            ? 1
            : this.goodList
          : undefined,
        is_naire: this.isnaire ? 1 : undefined,
        naire_id: this.isnaire ? this.$route.query.naire_id : undefined,
        type: this.showPassword,
        price_mold: this.price_mold,
        set_id: '0',
        search: '',
        course_type: '0',
        price_type: '0',
        fission_id: '',
        course_ids: this.ids.map(item => item.course_id || item),
        is_workbook: this.is_workbook == 1 ? this.is_workbook : undefined,
        homework_id:
          this.is_workbook == 1 && this.$route.params.id !== 'new'
            ? this.$route.params.id
            : undefined,
      },

      DialogBoxOptions: [
        {
          value: '0',
          label: '全部类型',
        },
        {
          value: '1',
          label: '直播课',
        },
        {
          value: '2',
          label: '小班课',
        },
        {
          value: '3',
          label: '录播课',
        },
        {
          value: '4',
          label: '系列课',
        },
      ],

      DialogBoxOptions_2: [
        {
          value: '0',
          label: '全部售价',
        },
        {
          value: '1',
          label: '付费',
        },
        {
          value: '2',
          label: '免费',
        },
      ],

      // 需要显示密码时
      DialogBoxOptions_3: [
        {
          value: '0',
          label: '全部售价',
        },
        {
          value: '1',
          label: '付费',
        },
        {
          value: '2',
          label: '免费',
        },
        {
          value: '3',
          label: '密码',
        },
      ],
    }
  },

  props: {
    // 秒杀
    is_seckill: {
      type: Boolean,
      default: undefined,
    },

    seckill_id: {
      type: String,
      default: undefined,
    },
    ///HomeTemplate/courseList 是否显示密码，原来是密码和免费的合并成密码
    price_mold: {
      type: Number,
      default: undefined,
    },

    // 是否需要过滤免费课成
    goodlistType: {
      type: Boolean,
      default: false,
    },

    goodList: [Array, Object, String],
    // 免息券选择
    installment_id: {
      type: String,
      default: undefined,
    },

    // 推广员 添加商品
    p_group_id: {
      type: String,
      default: undefined,
    },

    // 是否时信息采集
    isnaire: {
      type: Boolean,
      default: false,
    },

    // 作业选择时，显示免费的选择项
    showPassword: {
      type: Number,
      default: undefined,
    },

    template: {
      type: Number,
    },

    // 是否是网校专修的课程选择
    isnerworkselect: {
      type: Boolean,
      default: false,
    },

    //是否是单选
    singleselect: {
      type: Boolean,
      default: false,
    },

    //作业本的标记 1是作业本
    is_workbook: {
      type: Number,
      default: 0,
    },

    //  1是裂变海报
    //  2是会员
    //  3作业本选择课程
    type: {
      type: Number,
      default: 1,
    },

    url: {
      type: String,
      default: '/courseSet/getCourseSetList',
    },

    ids: {
      type: Array,
      default() {
        return []
      },
    },
    // 是否显示左边菜单
    isLeftMenu:{
      type:Boolean,
      default:true,
    },
    // 是否显示课程
    isCourseType:{
      type:Boolean,
      default:true,
    },
    // 是否默认课程
    isDefaultCourseType:{
      type: String,
      default:"",
    },
    // 是否覆盖默认单项选择
    isOverSingleSelect:{
      type:Boolean,
      default:true,
    },
    // 是否赋值其他默认单项选择
    isOtherSingleSelect:{
      type:Boolean,
      default:false,
    },
    // 是否覆盖默认多项选择
    isOverMutipleSelect:{
      type:Boolean,
      default:false,
    },
    // 是否赋值其他默认多项选择
    isOtherMutipleSelect:{
      type:Boolean,
      default:false,
    },
  },
  mounted(){
    if(this.isDefaultCourseType){
      this.DialogBoxPost.course_type = this.isDefaultCourseType
    }
    console.log('this.goodList',this.goodList);
  },
  methods: {
    getRowkey(row) {
      return row.course_id
    },

    handleRowClick(row) {
      if (
        (this.singleselect &&
          row.is_show == 2 &&
          this.template != 12 &&
          this.template != 13) ||
        row.status == 2
      ) {
        return
      }
      clearTimeout(this.rowClicTimer)
      this.rowClicTimer = setTimeout(() => {
        if (
          this.is_workbook != 1 &&
          'is_show' in row &&
          (row.status == 2 ||
            (row.is_show == 2 &&
              this.template != 11 &&
              this.template != 12 &&
              this.template != 13))
        ) {
          return
        }
        //网校装修中的单选
        if (this.singleselect) {
          this.radioId = row.course_id
          this.$emit('singleselectcontent', row)
          this.listSave = [row]
        } else {
          if (this.type == 1) {
            if (row.price == 0 || row.is_ash == 1) {
              return
            } else {
              this.$refs.dialogTable.toggleRowSelection(row)
            }
          } else {
            this.$refs.dialogTable.toggleRowSelection(row)
          }
        }
      }, 100)
    },

    getMenu(ids = [], id) {
      this.DialogBoxPost.set_id = 0
      this.DialogBoxPost.course_ids = ids.map(item => item.course_id || item)
      this.DialogBoxPost.fission_id = id
      //获取树形菜单数据

      let post = {
        type: this.showPassword,
        installment_id: this.installment_id,
        seckill_id: this.seckill_id,
        is_seckill: this.is_seckill,
        goodlistType: this.goodlistType,
        price_mold: this.price_mold,
        goodList: this.goodList
          ? this.goodList.length == 0
            ? 1
            : this.goodList
          : undefined,
        p_group_id: this.p_group_id,
        is_naire: this.isnaire ? 1 : undefined,
        naire_id: this.isnaire ? this.$route.query.naire_id : undefined,
        course_ids: ids.map(item => item.course_id || item),
        is_workbook: this.is_workbook == 1 ? this.is_workbook : undefined,
        homework_id:
          this.is_workbook == 1 && this.$route.params.id !== 'new'
            ? this.$route.params.id
            : undefined,
      }
      this.$http({
        url: this.url,
        data: post,
        callback: ({ data }) => {
          this.MenuTreeData = data
          // this.$refs.childDialogBox.reset()
          this.$nextTick(() => {
            var span_1 = document.querySelectorAll('.el-tree-node__content')[0]
            // span_1..add("is-current")
            span_1.click()
          })
        },
      })
    },

    handleNodeClick(node, data) {
      if(!this.isLeftMenu) return
      var span_1 = document.querySelectorAll('.el-tree-node__content')[0]
      // span_1..add("is-current")
      span_1.style.background = ''
      //点击弹出的左侧菜单
      this.DialogBoxPost.set_id = data.set_id
    },

    DialogBoxSelectionChange(val) {
      //点击选择暂存选项数组
      this.listSave = val
      this.$emit('listSave', val)
    },

    selectionEvent(row) {
      //免费和已经关联过的课程选项不可选控制
      if (this.isOverMutipleSelect) {
        return this.isOtherMutipleSelect
      } else if (this.type == 1) {
        if (row.price == 0 || row.is_ash == 1) {
          return false
        } else {
          return true
        }
      } else if (this.type == 2) {
        // 推广员修改逻辑 课程展示再网校页能选中
        return (
          ((this.template == 11 || this.template == 12) && row.status == 1) ||
          !('is_show' in row) ||
          (row.is_show == 1 && row.status == 1)
        )
      } else if (this.type == 3) {
        return true
      }
    },

    clearSelect() {
      this.listSave = []
      this.$refs.dialogTable.clearSelection()
    },

    dialogSelectBack() {
      this.listSave = []
      this.$refs.dialogTable.clearSelection()

      this.$parent.$parent.cancelDialog()
    },

    dialogSelectGo() {
      //点击确定将暂存的数组添加到form里
      //调用父组件的关闭dialog框并将选择的课程list数据传过去
      this.$parent.$parent.gbDialog(this.listSave)
      for (let value of this.listSave.values()) {
        this.listAll.push(value)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.selectDialogBox {
  height: 396px;
  display: flex;
  position: relative;
  margin-bottom: 10px;

  .titleBefor {
    position: absolute;
    width: 552px;
    height: 12px;
    font-size: 12px;
    color: #ff3535;
    top: -40px;
    left: 77px;
  }

  .leftMenu {
    width: 200px;
    flex-shrink: 0;
    overflow: auto;
    height: 396px;
    overflow-x: hidden;
    border: 1px solid #e4e4e4;
    @extend %scroll;
    ::v-deep .el-tree-node__content {
      height: 36px;
    }

    ::v-deep .el-tree-node__expand-icon {
      font-size: 22px;
      padding: 0px;
    }

    ::v-deep .el-tree-node:focus > .el-tree-node__content {
      background: #ebf8f7;
      // color: #0AA29B;
    }

    ::v-deep .el-tree-node__content:hover {
      background: #ebf8f7;
      // color: #0AA29B;
    }

    ::v-deep .el-tree-node:focus > .el-tree-node__content {
      background: #ebf8f7;
    }

    .my-custom {
      display: flex;
      margin-left: 2px;
      height: 30px;
      width: 170px;

      img {
        width: 14px;
        height: 14px;
        margin-right: 11px;
        margin-top: 7px;
      }

      .label {
        width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 30px;
      }
    }
  }

  .rightContent {
    width: calc(100% - 200px);
    height: 398px;
    max-width: 100%;

    .headerSelect {
      display: flex;
      justify-content: flex-end;

      .storeDialogBox {
        width: 160px;
        margin-right: 10px;
      }

      .el-input {
        width: 190px;
      }
    }

    .pagination2Box {
      margin-top: 20px;
      overflow: auto;
      height: 290px;
      margin-left: 20px;
      @extend %scroll;
      ::v-deep .el-checkbox__input.is-disabled .el-checkbox__inner {
        background-color: #e5e5e5;
      }

      .className {
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        align-items: center;

        .type {
          margin-left: 10px;
          align-items: center;
          display: flex;
          font-size: 12px;
          color: #666666;

          img {
            width: 8px;
            height: 7px;
            margin-right: 6px;
          }

          span {
            font-size: 12px;
            color: #666666;
          }
        }

        img {
          width: 40px;
          height: 22px;
        }

        p {
          margin-left: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}

.dialog-footer {
  display: flex;
  justify-content: space-between;
}
</style>
