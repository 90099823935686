<!--
 * @Author: cyy
 * @Date: 2021-12-17 17:45:24
 * @LastEditTime: 2021-12-24 17:22:07
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \ teacher.dingdingkaike\src\views\OpenClass_openClassLive\components\centercomponents\emojiComponent.vue
-->
<template>
  <div class="emo">
    <transition name="fade">
      <div
        class="mask"
        style="background: none"
        v-if="showEmoji"
        @touchmove.prevent
        @click="$emit('update:showEmoji', false)"
      ></div>
    </transition>
    <ul class="emojis" v-if="showEmoji">
      <li
        class="emoji"
        v-for="(value, key, index) in emojiSource"
        :key="index"
        :title="key"
        @click="send(value)"
      >
        <div class="emoji-bg" :style="`background-position:0px ${-(value.i * 29.333)}px`"></div>
      </li>
    </ul>
  </div>
</template>
<script>
import emojiSource from './emoji'

import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('OpenClassopenClassLive')
export default {
  name: 'liveEmoji',

  props: {
    showEmoji: Boolean,
  },

  data() {
    return {
      emojiSource: {},
    }
  },

  computed: {
    ...mapState(['openAndClose']),
  },

  mounted() {
    this.emojiSource = emojiSource
  },

  methods: {
    send(val) {
      this.$emit('send', val.key)
      this.$emit('update:showEmoji', false)
    },
  },
}
</script>
<style lang="scss" scoped>
.emo {
  position: relative;
}
.emojis {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  z-index: 81;
  bottom: 15px;
  left: -33px;
  border-radius: 12px;
  background: #2c2c2c;
  width: 290px;
  // height: 350px;
  .emoji {
    width: 14.28%;
    height: 12.5%;
    cursor: pointer;
    .emoji-bg {
      width: 29px;
      height: 29px;
      margin: 10px auto;
      background: url(https://f.dingdingkaike.com.cn/emoji/emoji.png) no-repeat;
      background-size: 29px 1642px;
    }
  }
}
</style>
