/*
 * @Descripttion:
 * @version:
 * @Author: sueRimn
 * @Date: 2019-11-19 09:58:18
 * @LastEditors: sueRimn
 * @LastEditTime: 2019-12-12 09:53:13
 */
/**
 * 禁止页面滚动
 */
export default {
  created() {
    document.documentElement.style.overflow = 'hidden'
  },

  destroyed() {
    document.documentElement.style.overflow = 'auto'
  },
}
