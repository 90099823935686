<template>
  <div class="gongGao">
    <el-input
      rows="4"
      type="textarea"
      placeholder="请输入内容"
      v-model.trim="live_bbs"
      maxlength="225"
      show-word-limit
    ></el-input>
    <el-button class="save-btn" type="primary" @click="save">发送</el-button>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('OpenClassopenClassLive')

export default {
  name: 'gongGao',

  components: {},

  computed: {
    ...mapState(['openInfo', 'openClassId', 'liveInfo']),
  },

  watch: {
    'openInfo.live_bbs': {
      handler(val) {
        this.live_bbs = val
      },
      immediate: true,
    },
  },

  data() {
    return {
      live_bbs: '',
    }
  },

  methods: {
    async save() {
      await this.$http({
        url: '/console/setLiveBbs',
        data: {
          open_class_id: this.openClassId,
          live_bbs: this.live_bbs,
        },
      })
      this.$root.prompt({
        type: 'success',
        msg: '保存成功',
      })

      // 发送公告消息
      this.$root.$emit('sendCustomMsg', {
        data: 'liveBbs',
        description: this.live_bbs,
        extension: '',
        roomId: this.liveInfo.chat_room_id2,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.gongGao {
  margin: 20px;
  ::v-deep .el-textarea {
    .el-textarea__inner {
      height: 152px;
      resize: none;
      font-size: 14px;
      color: #fff;
      background: #2c2c2c;
      border-radius: 4px;
      border-color: #2c2c2c;
    }
    .el-textarea__inner::-webkit-input-placeholder {
      font-size: 12px;
      color: #5f5f5f;
    }
    .el-input__count {
      background: #2c2c2c;
    }
  }
  .save-btn {
    width: 76px;
    height: 36px;
    margin-top: 20px;
    border-radius: 6px;
    background: #fa6400;
    border-color: #fa6400;
  }
}
</style>