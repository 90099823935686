<template>
  <div
    id="message"
    :style="!imfoManager ? 'height:50px' : ''"
    :class="{
      hide: chatFullScreen,
    }"
  >
    <div v-if="imfoManager" class="contain">
      <div class="quto" v-if="isquote&&isNeedQuote&&!isPrivateQuote">
        {{ quotelist.nick }}：
        <span>
          {{ quotelist.qoute }}
        </span>
        <i class="el-icon-close" @click="chageQuoteval({})"></i>
      </div>
      <div class="quto" v-if="isHasPrivateQuote&&isNeedQuote&&isPrivateQuote">
        {{ privateQuotelist.nick }}：
        <span>
          {{ privateQuotelist.qoute }}
        </span>
        <i class="el-icon-close" @click="chageprivateQuotelist({})"></i>
      </div>

      <div :class="['functionBtns']">
        <div class="left">
          <div
            @click="emoji"
            @mouseover="isRightComponent&&isCheckedLen==0?'':hoverPicture(1)"
            @mouseleave="isRightComponent&&isCheckedLen==0?'':hoverPicture(undefined)"
            title="表情"
          >
            <img
              :class="['mr14']"
              :src="
                hover == 1
                  ? require('@ass/img/1.4.2.5/icon_fsbq_xz.svg')
                  : require('@ass/img/OpenClass_openClassLive/icon_bqtb.svg')
              "
              alt=""
            />
          </div>
          <emoji-component
            :showEmoji.sync="showEmoji"
            @send=";(msg = arguments[0]), doSeach()"
          ></emoji-component>

          <div
            class="selectimg"
            @mouseover="isRightComponent&&isCheckedLen==0?'':hoverPicture(2)"
            @mouseleave="isRightComponent&&isCheckedLen==0?'':hoverPicture(undefined)"
            >
            <!-- :style="`position:${isRightComponent&&isCheckedLen==0?'static':'relative'};`" -->
            <!-- style="position: static;" -->
            <div class="input" @click="uploadConfirm">
              <!-- <input
                :class="['mr14']"
                title="上传图片"
                type="file"
                accept="image/*"
                ref="inputer"
                @change="addImg"
              /> -->
            </div>

            <div class="upload-img">
              <!-- 本地上传弹窗 -->
              <upload
                v-if="uploadStart"
                @close="localUpload"
                :typeselection="1"
                title="上传图片(多选)"
                :uploadType="2"
                :list="group_id1"
              ></upload>
              <!-- 图片选择弹窗 localUpload 选中图片的回调 list  获取选中左侧列表的id group_id-->
              <addpicture
                :size="10"
                v-if="addpicturestatus"
                :dialogstatus="addpicturestatus"
                @locationUpload="localUpload"
                ref="addpicture"
                @group_id="groupIdChange"
                @list="uploadComplete"
                @close="uploadConfirm"
              ></addpicture>
              <!-- <changePhotoimg
                style="display: none;"
                :isshow="true"
                ref="changePhotoimg"
                @complete="uploadComplete"
                class="changePhotoimg"
                :size="5"
              ></changePhotoimg> -->
            </div>
            <div
              v-if="!isPrivate"
              :style="`width: 16px;`"
              class="mr14"
              v-loading="imageProgress > 0 && !imagecomplete"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
            >
              <img
                :class="['mr14']"
                :src="
                  hover == 2
                    ? require('@ass/img/1.4.2.5/icon_fstp_xz.svg')
                    : require('@ass/img/OpenClass_openClassLive/icon_tpbs.svg')
                "
                alt=""
              />
            </div>

            <div
              v-else
              :style="`width: 16px;`"
              class="mr14"
              v-loading="imageProgress2 > 0 && !imagecomplete2"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
            >
              <img
                :class="['mr14']"
                :src="
                  hover == 2
                    ? require('@ass/img/1.4.2.5/icon_fstp_xz.svg')
                    : require('@ass/img/OpenClass_openClassLive/icon_tpbs.svg')
                "
                alt=""
              />
            </div>
          </div>

          <el-dropdown
            placement="bottom-end"
            @command="dropdowncommand"
            v-if="!isPrivate && !isteacher"
          >
            <div
              @mouseover="hoverPicture(3)"
              @mouseleave="hoverPicture(undefined)"
            >
              <img
                title="快捷发言"
                class="mr14"
                :src="
                  hover == 3
                    ? require('@ass/img/1.4.2.5/icon_cyy_xz.svg')
                    : require('@ass/img/1.4.2.5/icon_cyy.svg')
                "
                style="width: 28px"
                alt=""
              />
            </div>
            <el-dropdown-menu slot="dropdown" class="dropdownmenu">
              <el-dropdown-item command="1">1</el-dropdown-item>
              <el-dropdown-item command="666">666</el-dropdown-item>
              <el-dropdown-item command="太棒啦">太棒啦~</el-dropdown-item>
              <el-dropdown-item command="为老师点赞">
                为老师点赞
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <!-- <el-tooltip
            v-if="!isPrivate"
            :visible-arrow="false"
            popper-class="messagepopperlass"
            class="item"
            placement="top-start"
          >
            <div slot="content" style="padding:0 10px; ">
              <template>
                <div class="flex-center">
                  <el-button style="width: 18px" type="text" title="全体禁言">
                    <div class="flex-center">
                      <img src="~@ass/img/1.4.2.5/icon_qtjy.svg" alt="" />
                    </div>
                  </el-button>

                  <span class="fs13 ml10">全体禁言</span>
                  <el-switch
                    @change="forbiddenSpeech"
                    class="ml20"
                    :value="liveInfo.is_all_mute"
                    active-value="1"
                    inactive-value="2"
                  ></el-switch>
                </div>
                <div class="flex-center cp" @click="managerMsg">
                  <el-button
                    @click="managerMsg"
                    type="text"
                    class="hightligth ml0"
                    v-if="imfoManager"
                    title="消息管理"
                  >
                    <div
                      @mouseover="hoverPicture(5)"
                      @mouseout="hoverPicture(undefined)"
                    >
                      <img
                        v-if="hover == 5"
                        src="~@ass/img/1.4.2.5/icon_xxgl2.svg"
                        alt=""
                      />
                      <img
                        v-else
                        src="~@ass/img/1.4.2.5/icon_xxgl.svg"
                        alt=""
                      />
                    </div>
                  </el-button>

                  <el-button
                    @click="managerMsg"
                    v-else
                    type="text"
                    class="hightligth ml0"
                    title="消息管理"
                  >
                    <img src="~@ass/img/1.4.2.5/icon_xxgl2.svg" alt="" />
                  </el-button>
                  <span class="fs13 ml10">消息管理</span>
                </div>
              </template>
            </div>
            <div>
              <div class="mr14" style="width: 18px">
                <el-button type="text">
                  <div
                    @mouseover="hoverPicture(6)"
                    @mouseleave="hoverPicture(undefined)"
                  >
                    <img
                      v-if="hover == 6"
                      src="~@ass/img/1.4.2.8/icon_gdcz_h.svg"
                      alt=""
                    />
                    <img v-else src="~@ass/img/1.4.2.8/icon_gdcz.svg" alt="" />
                  </div>
                </el-button>
              </div>
            </div>
          </el-tooltip> -->
          <div
            @click="forbiddenSpeech(1)"
            title="当前未禁言"
            v-if="!isPrivate"
            v-show="liveInfo.is_all_mute == 2"
          >
            <img
              class="mr14"
              src="@ass/img/OpenClass_openClassLive/icon_qtjy.svg"
            />
          </div>
          <div
            @click="forbiddenSpeech(2)"
            title="当前已禁言"
            v-if="!isPrivate"
            v-show="liveInfo.is_all_mute == 1"
          >
            <img
              class="mr14"
              src="@ass/img/OpenClass_openClassLive/icon_qtjy_dl.svg"
            />
          </div>
          <div
            @click="messageShield(1)"
            title="当前未屏蔽"
            v-if="!isPrivate"
            v-show="liveInfo.is_all_shield == 2"
          >
            <img
              class="mr14"
              src="@ass/img/OpenClass_openClassLive/icon_pbxx2.svg"
            />
          </div>
          <div
            @click="messageShield(2)"
            title="当前已屏蔽"
            v-if="!isPrivate"
            v-show="liveInfo.is_all_shield == 1"
          >
            <img
              class="mr14"
              src="@ass/img/OpenClass_openClassLive/icon_pbxx_xz.svg"
            />
          </div>
          <div @click="managerMsg" title="消息管理" v-show="imfoManager" v-if="!isPrivate">
            <img
              class="mr14"
              src="@ass/img/OpenClass_openClassLive/icon_xxgl.svg"
            />
          </div>
          <div
            @click="managerMic(2)"
            title="当前允许语音发言"
            v-if="liveInfo.is_all_sounds == 1&&!isPrivate"
          >
            <img class="mr14" src="@ass/img/mic/mic.svg" />
          </div>
          <div @click="managerMic(1)" title="当前不允许语音发言" v-else-if="liveInfo.is_all_sounds != 1&&!isPrivate">
            <img class="mr14" src="@ass/img/mic/cc-mic-close.svg" />
          </div>
          <!-- 助教聊天记录 -->
          <el-dropdown
            placement="top-end"
            @command="dropdownTutor"
            v-if="isPrivate&&isHasPrivateVal&&isHasTutorHistory&&!isRightComponent"
            >
            <div
              @mouseover="hoverPicture(7)"
              @mouseleave="hoverPicture(undefined)"
            >
              <img
                title="助教聊天记录"
                class="mr14"
                :src="
                  hover == 7
                    ? require('@ass/img/1.4.39/icon_xxjl_dj.svg')
                    : require('@ass/img/1.4.39/icon_xxjl.svg')
                "
                style="width: 16px"
                alt=""
              />
            </div>
            <el-dropdown-menu slot="dropdown" class="dropdownmenu">
              <el-dropdown-item :command="item" v-for="item in tutorHistorys" :key="item.uid">{{ item.uname }}助教</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="right" v-if="openAndClose&&!isPrivate||isHidHistory">
          <!-- <div class="right2 mr10" @click="pushCourse">
            <img src="~@ass/img/1.4.2.5/icon_ts.svg" alt="" />
            <span>推送</span>
          </div> -->
          <div class="right2" @click="$emit('sendFlowers')" v-if="!isPrivate">
            <img src="~@ass/img/OpenClass_openClassLive/icon_shxx.svg" alt="" />
            <span>{{ openInfo.flower_num }}</span>
          </div>
          <div class="right2" v-if="isHidHistory" @click="$emit('closeHistory')">
            <span>关闭历史记录</span>
          </div>
        </div>
      </div>

      <div :class="`maincontain${focus.toString().split('.')[1]}`">
        <el-input
          ref="sendinput"
          resize="none"
          class="message-placeholder"
          :autosize="{ minRows: 4, maxRows: 4 }"
          type="textarea"
          placeholder="输入文字，回车发送"
          @keyup.enter.native="doSeach"
          v-model="msg"
          :maxlength="inputTextMaxLength"
        ></el-input>
      </div>
    </div>
    <div class="cancelManager" v-else>
      <el-button
        size="medium"
        @click="$emit('allDelete')"
        style="background: #272727; border: 1px solid #272727; color: #ffffff"
      >
        全选
      </el-button>

      <el-button
        size="medium"
        @click="managerMsg"
        style="background: #272727; border: 1px solid #272727; color: #ffffff"
      >
        取消
      </el-button>
      <el-button
        size="medium"
        style="color: #fff; border: 0"
        :class="clientSequenceIds.length > 0 ? 'rcolor' : 'wcolor'"
        @click="$emit('multiSelectDelete')"
        :disabled="clientSequenceIds.length == 0"
      >
        删除（{{ clientSequenceIds.length }}）
      </el-button>
    </div>
    <!-- 选择网校内容 -->
    <!-- <networkschool-select
      :isPrivate="isPrivate"
      :open_class_id="openClassId"
      v-if="selectChangestatus"
      :dialogstatus="!!selectChangestatus"
      title="推送内容(单选)"
      :template="5"
      :ids="selectChangestatus.ids"
      @selectChangenetworkschool="selectChangenetworkschool"
      @singleselectcontentval="singleselectcontentval"
    ></networkschool-select> -->
    <div class="cancel_btn" v-if="imfoManager">
      <el-button
        v-if="isHasSendConfirm"
        size="mini"
        @click="doSeach"
        style="background: #FA6400; border: 1px solid #272727; color: #ffffff"
      >
        发送<span v-if="isRightComponent&&isCheckedLen">{{ isCheckedLen }}/{{ membersLen }}</span>
      </el-button>
      <el-button
        v-if="isHasCancelSend"
        size="mini"
        @click="cancelSend"
        style="background: #272727; border: 1px solid #323232; color: #ffffff;margin: 0 15px;"
      >
        取消
      </el-button>

    </div>
  </div>
</template>

<script>
// 选择网校内容
// import networkschoolSelect from '@cm/base/networktypeSelect/networkschoolSelect'
import { createNamespacedHelpers } from 'vuex'
const {
  mapState,
  mapActions,
  mapMutations,
  mapGetters,
} = createNamespacedHelpers('OpenClassopenClassLive')
import emojiComponent from './emojiComponent'
//本地上传弹窗
import upload from '@cm/base/UploadtextFile'
//图片组件
import addpicture from '@cm/base/addpicture'
// import changePhotoimg from '@cm/base/changePhotoimg'

export default {
  name: 'message',
  data() {
    return {
      deBug:false,
      value: 2,
      // selectChangestatus: false,
      // 消息管理
      imfoManager: true,
      hover: undefined,
      img: {},
      url:'',
      msg: '',
      showEmoji: false,
      focus:Math.random(9999),

      group_id1: '',
      uploadStart: false,
      addpicturestatus: false,
    }
  },
  components: {
    // networkschoolSelect,
    emojiComponent,
    upload,
    addpicture,
    // changePhotoimg,
  },
  computed: {
    ...mapState([
      'openClassId',
      'liveInfo',
      'openAndClose',
      'otherfnPrivateletterselectVal',
      'quotelist',
      'privateQuotelist',
      'rightQuote',
      'userInfo',
      'tutorHistorys',
      'members',
    ]),
    ...mapGetters(['chatFullScreen']),

    isquote() {
      return Object.keys(this.quotelist).length > 0
    },
    isHasPrivateQuote() {
      return Object.keys(this.privateQuotelist).length > 0
    },

    // 判断是否是私聊
    isHasPrivateVal() {
      return Object.keys(this.otherfnPrivateletterselectVal).length > 0
    },
    /**
     * @desc: 判断是否是上课老师
     * @params {*} isteacher
     * @return: {*} 当前登录者是否是老师
     */
    isteacher() {
      const val = this.userInfo.managerList.find(item => item.role == 1)
      return val.id == this.userInfo.userID
    },
    isHasTutorHistory(){
      return Object.keys(this.tutorHistorys).length>0
    },
    isCheckedLen(){
      return Object.keys(this.members).map(v=>this.members[v].checked).filter(v=>v).length
    },
    membersLen(){
      return Object.keys(this.members).length
    },
  },

  watch: {
    //1，《rightQuote》 右边私聊的中的引用，如果是私聊中，点击了引用，otherfnPrivateletterselectVal数据变化时，  要把引用的内容删掉，
    // 2，但是如果学生主动私聊老师，老师列表中没有该学员，这是如果群聊列表中正在引用，引用的内容不需要清除《isC2Cmsg》
    // 3，《isright》如果发生第二中情况，引用不删除，但是如果去私聊，就要删除掉

    otherfnPrivateletterselectVal() {
      this.msg = ''
      if (!this.rightQuote && !this.isC2Cmsg) {
        this.chageQuoteval({})
      }
    },
    // quotelist() {
    //   // 自动聚焦输入框
    //   document.querySelector(`.maincontain${this.focus.toString().split('.')[1]} textarea`).focus()
    // },
  },

  props: {
    isC2Cmsg: {
      type: Boolean,
      default: false,
    },
    clientSequenceIds: {
      type: Array,
      default: () => [],
    },
    imagecomplete: {
      type: Boolean,
      default: false,
    },
    imagecomplete2: {
      type: Boolean,
      default: false,
    },
    imageProgress: {
      type: Number,
    },
    imageProgress2: {
      type: Number,
    },
    openInfo: {
      type: Object,
      default: () => {},
    },
    isPrivate:{
      type: Boolean,
      default: false,
    },
    isHidHistory:{
      type: Boolean,
      default: false,
    },
    isHasSendConfirm: {
      type: Boolean,
      default: true,
    },
    isHasCancelSend: {
      type: Boolean,
      default: false,
    },
    isRightComponent: {
      type: Boolean,
      default: false,
    },
    inputTextMaxLength: {
      type:Number,
      default:1000
    },
    checkedNums: {
      type:Number,
      default:0
    },
    isNeedQuote: {
      type:Boolean,
      default:true,
    },
    isPrivateQuote: {
      type:Boolean,
      default:false,
    },
  },
  mounted() {
    // 自动聚焦输入框
    document.querySelector(`.maincontain${this.focus.toString().split('.')[1]} textarea`).focus()
  },

  methods: {
    ...mapActions(['consoleInfo', 'sendToWindowsSizeInOpenClass']),
    ...mapMutations([
      'setOpenAndClose',
      'setOpenLeftState',
      'setStopDisplayMsg',
      'chageQuoteval',
      'chageprivateQuotelist',
    ]),
    //开启或关闭语音功能
    async managerMic(val) {
      await this.$http({
        url: '/console/allSounds',
        data: {
          open_class_id: this.openClassId,
          status: val,
        },
      })

      this.consoleInfo(this.openClassId)
    },

    // pushCourse() {
    //   if (!this.openAndClose) {
    //     this.openCloseClick()
    //   }
    //   sessionStorage.setItem('openAndClose', this.openAndClose ? 1 : 0)
    //   this.selectChangestatus = true
    // },
    openCloseClick() {
      window.resizeBy(520, 0)
      if (this.$store.state.isWindowsApp) {
        this.sendToWindowsSizeInOpenClass({
          width: 520,
          height: 0,
        })
      }
      this.setOpenAndClose()
    },
    // 消息管理
    managerMsg() {
      this.hover = undefined
      this.imfoManager = !this.imfoManager
      this.$emit('isOpenManagerMsg', this.imfoManager)
      this.setStopDisplayMsg(this.imfoManager ? 0 : 3)
      this.$emit('delsCancel')
    },
    //推送选择的内容
    singleselectcontentval(val) {
      this.$emit('singleselectcontentval', [val])
    },

    // selectChangenetworkschool() {
    //   this.selectChangestatus = false
    // },

    // 图标的显示
    hoverPicture(val) {
      this.hover = val
    },

    // 发送图片
    addImg() {
      // if (!this.$refs.inputer.files || !this.$refs.inputer.files[0]) {
      //   return
      // }
      // this.img = this.$refs.inputer.files[0]
      // this.doSeach(1)
      // this.$refs.inputer.value = ''
    },
    uploadConfirm() {
      this.addpicturestatus = !this.addpicturestatus
      // this.$refs.changePhotoimg.changePicture()
    },
    //本地上传弹窗控制
    localUpload() {
      this.uploadStart = !this.uploadStart
    },
    //获取图片上传中的分组id
    groupIdChange(val) {
      this.group_id1 = val
    },
    async uploadComplete(val) {
      this.deBug&&console.log(val);
      if(!val) return
      try {
        if(this.isPrivate){
          this.$emit('imgStart2')
        } else {
          this.$emit('imgStart')
        }
        // let url = val
        // let urllist = url.split("/")
        // let name = urllist[urllist.length-1]
        this.img = await this.getImageFileFromUrl(val.url,val.name,this.isPrivate)
        this.url = val.url
        this.doSeach(1)
      } catch (e) {
        this.$root.prompt('图片获取失败！')
      }
    },
    // url to File
    getImageFileFromUrl(url, imageName,isPrivate) {
      return new Promise((resolve, reject) => {
        const isPri = isPrivate
        var blob = null;
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.setRequestHeader('Accept', 'image/jpeg');
        xhr.responseType = "blob";
        xhr.addEventListener("progress", (event) => {
          if (event.lengthComputable) {
            var percentage = Math.round((event.loaded * 100) / event.total);
            if(isPri){
              console.log('percentage2',percentage);
              this.$emit('percentage2',percentage)
              if(percentage==100){
                console.log('imgCompress2');
                this.$emit('imgCompress2')
              }
            } else {
              console.log('percentage',percentage);
              this.$emit('percentage',percentage)
              if(percentage==100){
                console.log('imgCompress');
                this.$emit('imgCompress')
              }
            }
          }
        }, false);
        xhr.onload = () => {
            blob = xhr.response;
            let imgFile = new File([blob], imageName, { type: 'image/jpeg' });
            resolve(imgFile);
        };
        xhr.onerror = (e) => {
            reject(e)
        };
        xhr.send();
      });
    },
    // 禁言
    forbiddenSpeech(val) {
      if (val == 1) {
        this.$confirm('是否全体禁言？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'messagecustomClass',
        })
          .then(() => {
            this.allSilence(val)
          })
          .catch(() => {})
      } else {
        this.allSilence(val)
      }
    },

    // 消息屏蔽
    async messageShield(val) {
      await this.$http({
        url: '/console/allShield',
        successMsg: val == 1 ? '全体屏蔽已开启' : '全体屏蔽已关闭',
        errorMessage: true,
        data: {
          open_class_id: this.openClassId,
          status: val,
        },
      })
      this.consoleInfo(this.openClassId)
      // 发送公告消息
      this.$root.$emit('sendCustomMsg', {
        data: 'setting',
        description: '',
        extension: '',
        roomId: this.liveInfo.chat_room_id2,
      })
    },

    // 全体禁言
    allSilence(val) {
      this.$http({
        url: '/console/allSilence',
        data: {
          open_class_id: this.openClassId,
          status: val,
        },
        callback: () => {
          this.consoleInfo(this.openClassId)
          if (val == 1) {
            this.$root.prompt({
              type: 'success',
              msg: '全体禁言已开启！',
            })
          } else {
            this.$root.prompt({
              type: 'success',
              msg: '全体禁言已关闭！',
            })
          }
          // 发送公告消息
          this.$root.$emit('sendCustomMsg', {
            data: 'allMute',
            description: '',
            extension: '',
            roomId: this.liveInfo.chat_room_id2,
          })
        },
      })
    },

    // 快捷发言
    dropdowncommand(val) {
      this.$emit('sendmessage', { val: val, type: 2 })
    },

    // 助教历史消息
    dropdownTutor(val){
      this.$emit('currTutor', { val: val, type: 7 })
    },

    // 表情
    emoji() {
      this.showEmoji = true
    },

    // 发送内容
    doSeach(val) {
      if(this.isRightComponent&&this.isCheckedLen>200){
        this.$root.prompt('群发私信上限为200人！')
        return
      }
      // 1是图片 2是文本消息
      if (val == 1) {
        if(!this.img){
          this.$root.prompt('请选择发送图片！')
          return
        }
        // if (this.img.size / 1024 / 1024 <= 10) {
        const msg = {
          val: this.img, 
          url:this.url,
          type: 1,
          isPrivate:this.isPrivate 
        }
        this.$emit('sendmessage', msg)
        // } else {
        //   this.$root.prompt('图片大小超过10M，无法发送')
        // }
      } else {
        this.msg = this.msg.replace(/[\r\n]/g, '')

        if(!this.msg){
          this.$root.prompt('请输入发送内容！')
          return
        }
        this.$emit('sendmessage', {
          val: this.msg,
          replyMsg: this.isquote&&this.isNeedQuote&&!this.isPrivateQuote
            ? `${this.quotelist.nick}：${this.quotelist.qoute}`
            : this.isHasPrivateQuote&&this.isPrivateQuote&&this.isNeedQuote
            ? `${this.privateQuotelist.nick}：${this.privateQuotelist.qoute}`
            : undefined,
          type: 2,
          isPrivate:this.isPrivate
        })
      }
      this.msg = ''
      document.querySelector(`.maincontain${this.focus.toString().split('.')[1]} textarea`).focus()
    },
    cancelSend(){
      this.$emit('cancelSend')
    },
    // 聚焦输入框
    focusInput(){
      document.querySelector(`.maincontain${this.focus.toString().split('.')[1]} textarea`).focus()
    },
  },
}
</script>
<style lang="scss">
.messagecustomClass {
  width: 252px !important;
}
.messagepopperlass {
  transform: translateY(18px);
  background-color: #323232 !important;
  padding: 0;
  border: 0 !important;
  color: #ffffff !important;
}
.dropdownmenu {
  &.el-dropdown-menu {
    background-color: #323232 !important;
    border: 0;
    .el-popper .popper__arrow::after {
      display: none;
    }
    .popper__arrow {
      display: none;
    }
    .el-dropdown-menu__item {
      color: #fff;
    }
    .el-dropdown-menu__item:not(.is-disabled):hover,
    .el-dropdown-menu__item:focus {
      background-color: #4a4d51;
      color: #e19429;
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep textarea::-webkit-input-placeholder {
  font-size: 12px;
}
.mr14 {
  margin-right: 14px;
}
::v-deep ::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background-color: #fff;
}

::v-deep .el-loading-spinner .circular {
  width: 10px;
  height: 16px;
  margin-top: 10px !important;
  background-color: rgba(0, 0, 0, 0.5);
}
::v-deep .el-loading-spinner i {
  color: white;
}
::v-deep .el-icon-loading {
  margin-top: 10px;
}

#message {
  padding: 10px;
  box-sizing: border-box;
  min-height: 170px;
  background-color: #272727;
  display: flex;
  flex-direction: column;
  &.hide {
    display: none;
  }
  .quto {
    background-color: #fff;
    font-size: 12px;
    font-weight: 400;
    color: #7b7b7b;
    background: #2d2d2d;
    position: absolute;
    left: -10px;
    right: -10px;
    bottom: 116px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 8px 20px 6px 10px;
    box-sizing: border-box;
  }
  .el-icon-close {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    padding: 8px;
  }
  .rcolor {
    background-color: #ff3535;
  }
  .wcolor {
    background-color: #391515;
  }
  .cancelManager {
    height: 100%;
    font-size: 12px;
    color: #e19429;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .hightligth {
    color: #e19429 !important;
  }

  ::v-deep .el-textarea {
    height: 100%;
  }

  ::v-deep .el-textarea__inner {
    padding: 0px;
    background-color: #272727;
    border: 1px solid #272727;
    color: #fff;
  }

  ::v-deep .el-textarea__inner::placeholder {
    color: #505050 !important;
  }

  .el-button--text {
    font-size: 12px;
    border-color: transparent;
    color: #fff;
    background: transparent;
    padding-left: 0;
  }
  .el-dropdown {
    font-size: 12px;
  }
  .el-dropdown-menu {
    background-color: #323232 !important;
  }
  .el-dropdown-link {
    cursor: pointer;
    color: #ffffff;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .contain {
    position: relative;
    display: flex;
    flex-direction: column;
    // height: 100%;
  }
  .functionBtns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    min-height: 30px;
    .left {
      display: flex;
      align-items: center;
      .selectimg {
        position: relative;
        cursor: pointer;
        .input {
          cursor: pointer;
          opacity: 0;
          position: absolute;
          top: 0px;
          width: 20px;
          height: 16px;
          overflow: hidden;
        }
      }
      img {
        cursor: pointer;
        // width: 16px;
        height: auto;
      }
    }

    .right {
      display: flex;
      align-items: center;
      .right2 {
        min-width: 63px;
        height: 30px;
        background: #212121;
        border-radius: 15px;
        display: flex;
        align-items: center;
        padding: 7px 11px;
        box-sizing: border-box;
        font-size: 12px;
        color: #999999;
        line-height: 12px;
        cursor: pointer;
        img {
          border-radius: initial;
          width: 16px;
          height: auto;
          margin-right: 4px;
        }
      }
    }
  }
  .hidBtns {
    cursor:not-allowed !important;
  }
  ::v-deep .maincontain {
    flex: 1;
    .el-textarea__inner {
      height: 100% !important;
      min-height: auto !important;
    }
  }

  .cancel_btn {
    width:100%;
    height: 28px;
    >button{
      float:right;
    }
  }
}
</style>
