<!--
 * @Author: cyy
 * @Date: 2022-02-18 14:21:18
 * @LastEditTime: 2022-02-25 15:06:59
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \ teacher.dingdingkaike\src\views\OpenClass_openClassLive\components\liveDataComponents\openclassDatastatistics\components\online.vue
-->

<template>
  <div id="online" class="flex-column">
    <div class="tip">提示：数据仅显示当天的记录，统计频率：每5分钟记录一次</div>
    <div ref="onlineMember" class="onlineMember"></div>
  </div>
</template>

<script>
import echarts from 'echarts'
export default {
  name: 'online',
  data() {
    return {
      listData: [],
    }
  },

  props: {
    rightOpen: [Boolean],
    openClassId: [String, Number],
  },

  watch: {
    rightOpen() {
      this.onResize()
    },
  },

  async mounted() {
    this.listData = await this.todayLiveNum()
    this.init(this.listData)
    this.timer()
  },

  destroyed() {
    clearInterval(this.myTimer)
  },

  methods: {
    async todayLiveNum(startTime) {
      const { data } = await this.$http({
        url: '/record/todayLiveNum',
        data: {
          startTime: startTime || '',
          open_class_id: this.openClassId,
        },
      })
      return data
    },

    // 开启获取数据定时器
    timer() {
      this.myTimer = setInterval(async () => {
        // 通过最后一个数据的时间 来获取到最新的数据
        const data = await this.todayLiveNum(
          this.listData.length > 0 && this.listData[this.listData.length - 1]
            ? this.listData[this.listData.length - 1][0]
            : ''
        )
        // 空数据不管
        if (data.length > 0) {
          this.listData = [].concat(this.listData, data)
          this.myChart.setOption(this.getOptionData(this.listData), true, false)
        }
      }, 60000)
    },

    init(list) {
      const myChart = (this.myChart = echarts.init(
        this.$refs.onlineMember,
        null,
        { devicePixelRatio: window.devicePixelRatio }
      ))

      this.offResize()
      window.addEventListener('resize', this.onResize)

      // 指定图表的配置项和数据
      const option = this.getOptionData(list)

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option)
    },

    // 页面大小变化
    onResize() {
      if (this.myChart) {
        // 延迟执行
        clearTimeout(this.echartsTimer)
        this.echartsTimer = setTimeout(() => {
          this.myChart.resize()
        }, 200)
      }
    },

    offResize() {
      window.removeEventListener('resize', this.onResize)
    },

    getOptionData(list) {
      const timeData = list.map((item) => ({
        value: item[0],
        textStyle: { color: '#666' },
      }))

      return {
        legend: {
          left: 0,
          data: ['在线人数', '学习人数'],
          width: '50%',
          show: false,
        },

        dataZoom: [
          {
            startValue: timeData[0] ? '' : timeData[0].value,
            left: '21%',
            right: '21%',
            bottom: '12%',
            height: 20,
            textStyle: {
              color: '#fff',
            },
          },
        ],

        grid: {
          height: 200,
          width: '90%',
          left: '5%',
        },

        axisLabel: {
          interval: 0,
        },

        xAxis: {
          type: 'category',
          data: timeData,
          axisLabel: {
            rotate: 50,
            height: 8,
          },

          axisTick: {
            show: false,
          },

          axisLine: {
            lineStyle: {
              color: '#666',
              width: 2.5,
            },
          },
        },
        yAxis: {
          type: 'value',
          min: 0,
          max(value) {
            return value.max > 4 ? null : value.max + 4
          },
          minInterval: 1,
          splitLine: {
            lineStyle: {
              type: 'dashed', //虚线
              color: '#666',
            },
            show: true,
          },
          axisLine: {
            show: false,
            onZero: false,
            lineStyle: {
              color: '#666',
            },
          },
          axisTick: {
            show: false,
          },
        },

        tooltip: {
          trigger: 'axis',
        },
        series: [
          {
            name: '在线人数',
            type: 'line',
            data: list.map((item) => item[1]),
            lineStyle: {
              color: '#0093f8',
            },
            itemStyle: {
              color: '#1A66E9',
            },
            smooth: true,
          },
          //   {
          //     name: '学习人数',
          //     type: 'line',
          //     data: list.map((item) => item[2]),
          //     lineStyle: {
          //       color: '#FFD84D',
          //     },
          //     itemStyle: {
          //       color: '#FFD84D',
          //     },
          //     smooth: true,
          //   },
        ],
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#online {
  height: 100%;
  .tip {
    padding-left: 20px;
    font-size: 12px;
    color: #a8a8a8;
  }
  .onlineMember {
    height: 380px;
    min-height: 380px;
    margin: 0 auto;
    width: 100%;
    overflow: hidden;
    position: relative;
  }
}
</style>

 