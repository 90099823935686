<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-06-07 10:23:33
 * @LastEditTime: 2022-06-13 14:11:12
 * @LastEditors: cyy
 * @Description: 机器人送花
 * @FilePath: \teacher.dingdingkaike.com\src\views\OpenClass_openClassLive\components\liveDataComponents\changKong\sendFlowers.vue
-->
<template>
  <div class="sendFlowers">
    <div class="item">
      <div class="name">送花模式</div>
      <el-radio-group class="ml30" v-model="post.type" @change="radioChange">
        <el-radio label="1" :disabled="dogoing">快速送花</el-radio>
        <el-radio label="2" :disabled="dogoing">自动送花</el-radio>
      </el-radio-group>
    </div>
    <div class="item" v-if="post.type == 1">
      <div class="name">
        送花规则：
        <el-input-number
          :disabled="dogoing"
          size="medium"
          v-model="post.mtime"
          controls-position="right"
          :step="1"
          :min="1"
          style="width:150px"
          class="mr10 number"
        ></el-input-number>
        <span class="mr10">秒内送出</span>
        <el-input-number
          :disabled="dogoing"
          size="medium"
          v-model="post.num"
          controls-position="right"
          :step="1"
          :min="1"
          style="width:150px"
          class="mr10 number"
        ></el-input-number>
        <span>次</span>
      </div>
    </div>
    <template v-else>
      <div class="item">
        <div class="name">
          每隔
          <el-input-number
            size="medium"
            :disabled="dogoing"
            v-model="post.mtime"
            controls-position="right"
            :min="1"
            :step="1"
            style="width:150px"
            class="mr10 ml10 number"
          ></el-input-number>
          <span>秒送一次花</span>
        </div>
      </div>
      <div class="item">
        <div class="name">
          停止发送时长：

          <el-input-number
            :disabled="dogoing"
            size="medium"
            v-model="times[0]"
            controls-position="right"
            :step="1"
            :min="0"
            :max="2"
            style="width:90px"
            class="mr10 number"
          ></el-input-number>
          <span class="mr10">时</span>
          <el-input-number
            :disabled="dogoing"
            size="medium"
            v-model="times[1]"
            controls-position="right"
            :step="1"
            :min="0"
            :max="60"
            style="width:90px"
            class="mr10 number"
          ></el-input-number>
          <span class="mr10">分</span>
          <el-input-number
            :disabled="dogoing"
            size="medium"
            v-model="times[2]"
            controls-position="right"
            :step="1"
            :min="0"
            :max="60"
            style="width:90px"
            class="mr10 number"
          ></el-input-number>
          <span>秒</span>
          <span>后停止</span>
        </div>
      </div>
    </template>
    <el-button type="primary" size="medium" @click="submit" v-if="!dogoing">
      启用送花
    </el-button>
    <el-button
      type="danger"
      size="medium"
      style="background:#FF3535;border-color:#FF3535"
      v-else
      @click="stopRobotSendFlowers"
    >
      停止送花
    </el-button>
    <div v-if="dogoing && post.type == 1" class="timer">
      停止倒计时：{{ sendsuccess }}
    </div>
    <div v-if="dogoing && post.type == 2" class="timer">
      停止发送时间：{{
        (new Date().getTime() / 1000 + post.time_remaining) | formatTimeStamp
      }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'sendFlowers',

  data() {
    return {
      sendsuccess: '',
      times: [0, 0, 1],
      timer: null,
      dogoing: false,
      post: {
        type: '1',
        mtime: 1,
        num: 1,
        duration: '',
        open_class_id: '',
      },
    }
  },

  created() {
    this.robotSendFlowersFind()
  },

  props: {
    openClassId: [Number, String],
  },

  destroyed() {
    clearInterval(this.timer)
  },

  methods: {
    //  停止送花
    async stopRobotSendFlowers() {
      clearInterval(this.timer)
      await this.$http({
        url: '/console/stopRobotSendFlowers',
        data: {
          open_class_id: this.openClassId,
        },
        successMsg: true,
      })
      this.robotSendFlowersFind()
    },

    // 获取当前正在送花的参数
    async robotSendFlowersFind() {
      const { data } = await this.$http({
        url: '/console/robotSendFlowersFind',
        data: {
          open_class_id: this.openClassId,
        },
      })

      this.dogoing = Object.keys(data).length > 0

      if (this.dogoing) {
        const hours = Math.floor(data.duration / 60 / 60)
        const minutes = Math.floor((data.duration - hours * 60 * 60) / 60)
        const second = data.duration - hours * 60 * 60 - minutes * 60

        this.times = [hours, minutes, second]

        this.post = _.assign({}, this.post, data)

        this.settimmer()
      }
    },

    radioChange() {
      this.times = [0, 0, 1]
      this.post = _.assign({}, this.post, {
        mtime: 1,
        num: 1,
        duration: '',
      })
    },

    async submit() {
      // if (this.post.mtime < 1) {
      //   this.$root.prompt(
      //     '发送规则错误，请重新输入（1秒发送上限为1条，发送时长上限为60秒）'
      //   )
      //   return
      // }

      // if (this.post.num < 1) {
      //   this.$root.prompt(
      //     '发送规则错误，请重新输入（1秒发送上限为1条，发送时长上限为60秒）'
      //   )
      //   return
      // }

      if (!this.post.mtime) {
        this.$root.prompt('请输入发送规则')
        return
      }

      if (!this.post.num) {
        this.$root.prompt('请输入发送规则')
        return
      }

      if (this.post.mtime > 60 && this.post.type == 1) {
        this.$root.prompt(
          '发送规则错误，请重新输入（1秒发送上限为1条，发送时长上限为60秒）'
        )
        return
      }

      if (this.post.mtime < this.post.num && this.post.type == 1) {
        this.$root.prompt(
          '发送规则错误，请重新输入（1秒发送上限为1条，发送时长上限为60秒）'
        )
        return
      }

      const duration =
        (this.times[0] || 0) * 60 * 60 +
        (this.times[1] || 0) * 60 +
        (this.times[2] || 0)

      if (this.post.type == 2 && duration <= 0) {
        this.$root.prompt('请选择停止发送时间')
        return
      }

      // console.log(this.post.type == 2, duration, this.mtime)

      // if (this.post.type == 2 && duration < this.post.mtime) {
      //   this.$root.prompt('停止发送时长必须大于送花间隔时间！')
      //   return
      // }

      const post = _.assign({}, this.post, {
        open_class_id: this.openClassId,
        duration,
      })
      await this.$http({
        url: '/console/robotSendFlowers',
        data: post,
        successMsg: true,
      })

      await this.robotSendFlowersFind()

      this.settimmer()
    },

    settimmer() {
      clearInterval(this.timer)
      this.sendsuccess = this.post.time_remaining
      this.timer = setInterval(() => {
        if (this.sendsuccess <= 0) {
          clearInterval(this.timer)
          this.robotSendFlowersFind()
        } else {
          this.sendsuccess--
        }
      }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
.sendFlowers {
  padding: 20px;
  color: #ffffff;
  .timer {
    font-size: 12px;
    color: #ffffff;
    margin-top: 14px;
  }
  .el-button--primary {
    color: #ffffff;
    background-color: #fa6400;
    border-color: #fa6400;
  }
  ::v-deep .item {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    .el-input__inner,
    .el-input-number__increase,
    .el-input-number__decrease {
      background-color: #323232;
      border-color: #323232;
      .el-icon-arrow-up,
      .el-icon-arrow-down {
        color: #fff;
      }
    }
    .el-input__inner {
      color: #fff;
    }
    .el-radio__input.is-checked .el-radio__inner {
      border-color: #fa6400;
      background: #fa6400;
    }
    .el-radio {
      color: #ffffff;
      .el-radio__input.is-checked + .el-radio__label {
        color: #fa6400;
      }
    }
    .name {
      font-size: 14px;
    }
  }
}
</style>
