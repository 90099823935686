<template>
  <div
    id="chatcontent"
    :style="!openManagerMsg ? 'height: calc(100% - 50px);' : ''"
    :class="{
      // flex: !isPrivate,
      // pt40: !isPrivate && !openAndClose,
      hide: chatFullScreen,
      pt40: !openAndClose,
    }"
    class="flex"
  >
    <!-- 大窗公开聊天区域 -->
    <!-- 大窗公开聊天区域 -->
    <!-- <div style="color:#Fff ;width:300px">{{ newMessageList }}</div> -->
    <!-- !isPrivate && openAndClose -->
    <div
      v-if="openAndClose"
      class="left message-contain"
      style="!openandclose?'margin-top:54px': ''"
      ref="chatcontentmain"
    >
      <div
        style="padding-bottom: 15px"
        v-for="(item,index) in newMessageList"
        :key="index"
      >
        <!--计算消息时间间隔-->
        <template v-if="item.timeTnterval">
          <div class="msg-time-tnterval">
            {{ item.timeTnterval }}
          </div>
        </template>
        <div class="flex">
          <!-- 聊天内容-->
          <!-- 选择 -->
          <div
            v-if="
              !openManagerMsg && (item.type != 'gift' || item.type == 'coupon')
            "
          >
            <el-checkbox
              @change.self="changeCheck($event, item)"
              v-model="item.checked"
              style="font-size: 0"
              class="ml10"
            ></el-checkbox>
          </div>
          <!-- 语音聊天消息 -->
          <div class="flex" v-if="item.type == 'sound'" style="width: 100%; ">
            <div
              class="photo"
              @click="!item.role && openAndClose ? openUserInfo(item) : ''"
            >
              <img :src="item.avatar" alt="" />
            </div>

            <div class="name_message">
              <div class="name">
                <span>
                  <span
                    class="tname"
                    @click="!item.role && openAndClose ? openUserInfo(item) : ''"
                  >
                    {{ item.student_remarks||item.nick }}
                  </span>
                  <span
                    v-if="!item.role && !item.isRobot && item.inviter_name"
                    style="margin: 0 5px;"
                  >
                    &
                  </span>
                  <span class="invite-people" v-if="item.inviter_name">
                    {{ item.inviter_name }}
                  </span>
                  <span v-if="item.role" style="margin: 0 5px;display: inline-block;">
                    <!-- 老师 -->
                    <img
                      style="height: 13xp; width: auto; margin-left: 3px;"
                      v-if="item.role == 1"
                      src="~@ass/img/OpenClass_openClassLive/img_lsbs.svg"
                      alt=""
                    />
                    <!-- 助教 -->
                    <img
                      style="height: 13xp; width: auto; margin-left: 3px;"
                      v-else
                      src="~@ass/img/OpenClass_openClassLive/img_zjlsbs.svg"
                      alt=""
                    />
                  </span>
                </span>
              </div>
              <div class="tag" v-if="item.tag_names">
                {{ item.tag_names }}
              </div>
              <div class="rootmessage" v-if="item.from == userInfo.userID">
                <div style="position: relative;width:100%">
                  <audio
                    @play="handlePlay(`${item.ID}`)"
                    :data-id="`${item.ID}`"
                    style="height: 30px;margin-right: 20px;width:100%;"
                    controls
                    controlsList="nodownload nofullscreen noremoteplayback"
                  >
                    <source :src="item.text.url" type="audio/ogg" />
                    您的浏览器不支持 audio 元素。
                  </audio>
                </div>

                <span class="time">
                  <p class="otherfn">
                    <otherfn-contain
                      :Quote="true"
                      :isManager="item.role || item.isRobot ? true : false"
                      @otherfnQuote="$emit('otherfnQuote', item)"
                      @otherfnPrivateletter="
                        $emit('otherfnPrivateletter', item)
                      "
                      @otherfnContainsDel="delchartroomMsg(item)"
                    >
                      <i class="el-icon-more"></i>
                    </otherfn-contain>
                  </p>
                  {{ item.time | formatTimeStamp('MM-dd hh:mm:ss') }}
                </span>
              </div>
              <div class="message" v-else>
                <div style="position: relative;width:100%">
                  <audio
                    @play="handlePlay(`${item.ID}`)"
                    :data-id="`${item.ID}`"
                    style="height: 30px;margin-right: 20px; width:100%"
                    controls
                    controlsList="nodownload nofullscreen noremoteplayback"
                  >
                    <source :src="item.text.url" type="audio/ogg" />
                    您的浏览器不支持 audio 元素。
                  </audio>

                  <div class="question" v-if="item.isQuestion">
                    <img src="~@ass/img/1.4.4.5/icon_kzt_twbs@2x.png" alt="" />
                  </div>
                </div>

                <span class="time">
                  <p class="otherfn">
                    <otherfn-contain
                      :Quote="true"
                      :isManager="item.role || item.isRobot ? true : false"
                      :noYinyong="true"
                      @otherfnQuote="$emit('otherfnQuote', item)"
                      @otherfnPrivateletter="
                        $emit('otherfnPrivateletter', item)
                      "
                      @otherfnContainsDel="delchartroomMsg(item)"
                    >
                      <i class="el-icon-more"></i>
                    </otherfn-contain>
                  </p>
                  {{ item.time | formatTimeStamp('MM-dd hh:mm:ss') }}
                </span>
              </div>
              <div class="quto" v-if="item.replyMsg">
                <div class="qutocotian" v-html="item.replyMsg"></div>
                <!-- {{ item.replyMsg }} -->
              </div>
              <div class="flex-center shield-contain" v-if="item.shield">
                <div class="shield-tag">该消息屏蔽中</div>
                <el-button
                  class="shield-btn"
                  type="text"
                  @click="messageShield(item,1)"
                >
                  解除
                </el-button>
              </div>
            </div>
          </div>
          <!-- 文本消息 -->
          <div class="flex" v-if="item.type == 'text'" style="width: 100%">
            <div
              class="photo"
              @click="!item.role && openAndClose ? openUserInfo(item) : ''"
            >
              <img :src="item.avatar" alt="" />
            </div>

            <div class="name_message">
              <div class="name">
                <span>
                  <span
                    class="tname"
                    @click="!item.role && openAndClose ? openUserInfo(item) : ''"
                  >
                    {{ item.student_remarks||item.nick }}
                  </span>
                  <span
                    v-if="!item.role && !item.isRobot && item.inviter_name"
                    style="margin: 0 5px"
                  >
                    &
                  </span>
                  <span class="invite-people" v-if="item.inviter_name">
                    {{ item.inviter_name }}
                  </span>
                  <span v-if="item.role" style="margin: 0 5px;display: inline-block;">
                    <!-- 老师 -->
                    <img
                      style="height: 13xp; width: auto; margin-left: 3px"
                      v-if="item.role == 1"
                      src="~@ass/img/OpenClass_openClassLive/img_lsbs.svg"
                      alt=""
                    />
                    <!-- 助教 -->
                    <img
                      style="height: 13xp; width: auto; margin-left: 3px"
                      v-else
                      src="~@ass/img/OpenClass_openClassLive/img_zjlsbs.svg"
                      alt=""
                    />
                  </span>
                </span>
              </div>
              <div class="tag" v-if="item.tag_names">
                {{ item.tag_names }}
              </div>
              <div class="rootmessage" v-if="item.from == userInfo.userID">
                <div class="item" v-html="item.text"></div>
                <span class="time">
                  <p class="otherfn">
                    <otherfn-contain
                      :Quote="true"
                      :isManager="item.role || item.isRobot ? true : false"
                      @otherfnQuote="$emit('otherfnQuote', item)"
                      @otherfnPrivateletter="
                        $emit('otherfnPrivateletter', item)
                      "
                      @otherfnContainsDel="delchartroomMsg(item)"
                    >
                      <i class="el-icon-more"></i>
                    </otherfn-contain>
                  </p>
                  {{ item.time | formatTimeStamp('MM-dd hh:mm:ss') }}
                </span>
              </div>
              <div class="message" v-else>
                <div style="position: relative">
                  <div
                    class="item"
                    v-html="item.text"
                    :class="{ robot: item.isRobot }"
                    :style="
                      item.isQuestion ? 'padding: 8px 20px 8px 10px;' : ''
                    "
                  ></div>
                  <div class="question" v-if="item.isQuestion">
                    <img src="~@ass/img/1.4.4.5/icon_kzt_twbs@2x.png" alt="" />
                  </div>
                </div>

                <span class="time">
                  <p class="otherfn">
                    <otherfn-contain
                      :Quote="true"
                      :isManager="item.role || item.isRobot ? true : false"
                      @otherfnQuote="$emit('otherfnQuote', item)"
                      @otherfnPrivateletter="
                        $emit('otherfnPrivateletter', item)
                      "
                      @otherfnContainsDel="delchartroomMsg(item)"
                    >
                      <i class="el-icon-more"></i>
                    </otherfn-contain>
                  </p>
                  {{ item.time | formatTimeStamp('MM-dd hh:mm:ss') }}
                </span>
              </div>
              <div class="quto" v-if="item.replyMsg">
                <div class="qutocotian" v-html="item.replyMsg"></div>
                <!-- {{ item.replyMsg }} -->
              </div>
              <div class="flex-center shield-contain" v-if="item.shield">
                <div class="shield-tag">该消息屏蔽中</div>
                <el-button
                  class="shield-btn"
                  type="text"
                  @click="messageShield(item,2)"
                >
                  解除
                </el-button>
              </div>
            </div>
          </div>

          <!--礼物消息-->
          <template v-else-if="item.type == 'gift' || item.type == 'coupon'">
            <div class="gift-contain">
              <div class="gift-content">
                <span class="vm gift_text">
                  {{ item.student_remarks||item.nick }} {{ item.desc }}
                </span>
                <img
                  class="vm gift_icon"
                  v-if="item.giftIcon"
                  :src="item.giftIcon"
                  alt=""
                />
              </div>
            </div>
          </template>

          <!--图片消息-->
          <div class="flex" v-else-if="item.type == 'img'">
            <div class="photo">
              <img :src="item.avatar" alt="" />
            </div>
            <div class="name_message">
              <div class="name">
                <span class="tname">
                  {{ item.student_remarks||item.nick }}
                </span>
                <span v-if="item.role" style="margin: 0 5px;display: inline-block;">
                  <!-- 老师 -->
                  <img
                    style="height: 13xp; width: auto; margin-left: 3px"
                    v-if="item.role == 1"
                    src="~@ass/img/OpenClass_openClassLive/img_lsbs.svg"
                    alt=""
                  />
                  <!-- 助教 -->
                  <img
                    style="height: 13xp; width: auto; margin-left: 3px"
                    v-else
                    src="~@ass/img/OpenClass_openClassLive/img_zjlsbs.svg"
                    alt=""
                  />
                </span>
              </div>
              <div class="message">
                <div class="img">
                  <el-image
                    :lazy="true"
                    scroll-container=".message-contain"
                    style="height: 100%; width: 100%"
                    fit="contain"
                    :src="item.compressed.url"
                    :style="
                      `width:${
                        item.compressed.width > 180
                          ? 180
                          : item.compressed.width
                      }px;height:${
                        item.compressed.width > 180
                          ? item.compressed.height *
                            (180 / item.compressed.width)
                          : item.compressed.height
                      }px`
                    "
                    :preview-src-list="[item.text]"
                  ></el-image>
                  <!-- <img :src="item.text" alt="" /> -->
                </div>
                <span class="time">
                  <p class="otherfn">
                    <otherfn-contain
                      :isPictureFile="true"
                      :isManager="item.role || item.isRobot ? true : false"
                      @otherfnQuote="$emit('otherfnQuote', item)"
                      @otherfnPrivateletter="
                        $emit('otherfnPrivateletter', item)
                      "
                      @otherfnContainsDel="delchartroomMsg(item)"
                    >
                      <i class="el-icon-more"></i>
                    </otherfn-contain>
                  </p>
                  {{ item.time | formatTimeStamp('MM-dd hh:mm:ss') }}
                </span>
              </div>
            </div>
          </div>

          <!-- 推送内容 -->
          <div class="flex" v-else-if="item.type == 'file'">
            <div class="photo">
              <img :src="item.avatar" alt="" />
            </div>
            <div class="name_message">
              <div class="name">
                <span>
                  <span class="tname">
                    {{ item.student_remarks||item.nick }}
                  </span>
  
                  <span v-if="item.role" style="margin: 0 5px">
                    <!-- 老师 -->
                    <img
                      style="height: 13xp; width: auto; margin-left: 3px"
                      v-if="item.role == 1"
                      src="~@ass/img/OpenClass_openClassLive/img_lsbs.svg"
                      alt=""
                    />
                    <!-- 助教 -->
                    <img
                      style="height: 13xp; width: auto; margin-left: 3px"
                      v-else
                      src="~@ass/img/OpenClass_openClassLive/img_zjlsbs.svg"
                      alt=""
                    />
                  </span>
                </span>
              </div>
              <div class="message">
                <div
                  class="file_contain"
                  @click="
                    item.bannnerType == 5
                      ? $root.prompt('暂不支持打开该类型消息')
                      : openCourse(item.text.url)
                  "
                  style="cursor: pointer"
                >
                  <el-image :src="item.text.photo"></el-image>
                  <div class="name_type">
                    <div class="course_name" :title="item.text.name">
                      {{ item.text.name }}
                    </div>
                    <div class="course_type" v-if="item.text.selectType == 1">
                      {{ item.text.course_type | getCourseType }}
                    </div>
                    <div
                      class="course_type"
                      v-else-if="item.text.selectType == 2"
                    >
                      电子资料
                    </div>
                    <div
                      class="course_type"
                      v-else-if="item.text.selectType == 3"
                    >
                      会员
                    </div>
                    <div
                      class="course_type"
                      v-else-if="item.text.selectType == 6"
                    >
                      作业
                    </div>
                  </div>
                </div>
                <span class="time">
                  <p class="otherfn">
                    <otherfn-contain
                      :isPictureFile="true"
                      :isManager="item.role || item.isRobot ? true : false"
                      @otherfnQuote="$emit('otherfnQuote', item)"
                      @otherfnPrivateletter="
                        $emit('otherfnPrivateletter', item)
                      "
                      @otherfnContainsDel="delchartroomMsg(item)"
                    >
                      <i class="el-icon-more"></i>
                    </otherfn-contain>
                  </p>
                  {{ item.time | formatTimeStamp('MM-dd hh:mm:ss') }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- 撤回消息提示 -->
        <div class="withdraw" v-if="item.restroe">
          {{ item.student_remarks||item.nick }} 撤回一条消息
        </div>
        <div
          class="del_msg"
          @click.stop="delchartroomMsg(item)"
          v-if="
            !openManagerMsg && (item.type != 'gift' || item.type == 'coupon')
          "
        >
          删除
        </div>
      </div>
      <div
        class="empty-message"
        v-if="newMessageList.length === 0 && !loading && !ImError"
      >
        暂无直播互动消息
      </div>
    </div>
    <!-- 小窗聊天区域 -->
    <!-- !openAndClose && !isPrivate -->
    <div
      v-if="!openAndClose"
      class="left message-contain"
      ref="chatcontentmain"
    >
      <div
        v-for="item in newMessageList"
        :key="item.ID"
        style="margin-bottom: 20px"
      >
        <template v-if="!item.sensitiveWordsShield">
          <!--计算消息时间间隔-->
          <template v-if="item.timeTnterval">
            <div class="msg-time-tnterval">
              {{ item.timeTnterval }}
            </div>
          </template>

          <!-- 文本 -->
          <div style="display: flex">
            <div
              v-if="
                !openManagerMsg &&
                  (item.type != 'gift' || item.type == 'coupon') &&
                  rightActiveName == 7
              "
              style="font-size: 0"
              class="ml10"
            >
              <el-checkbox
                @change.self="changeCheck($event, item)"
                :checked="item.checked"
              ></el-checkbox>
            </div>
            <!-- 语音 -->
            <div class="flex smallchat" v-else-if="item.type == 'sound'">
              <div class="tu">
                <img :src="item.avatar" alt="" />
              </div>
              <div class="name_message">
                <div class="name">
                  <span
                    class="tname"
                    :style="
                      item.role
                        ? 'color:#FA6400'
                        : item.isRobot
                        ? 'color:#4C72B3'
                        : 'color:#fff'
                    "
                  >
                    <smallotherfn-contain
                      :isPictureFile="true"
                      :noYinyong="true"
                      :isRobot="item.isRobot ? true : false"
                      @otherfnQuote="$emit('otherfnQuote', item)"
                      @otherfnPrivateletter="
                        $emit('otherfnPrivateletter', item)
                      "
                      @otherfnContainsDel="delchartroomMsg(item)"
                    >
                      {{ item.student_remarks||item.nick }}：
                    </smallotherfn-contain>
                  </span>
                </div>
                <div class="message ml10">
                  <div style="position: relative;width:100%">
                    <audio
                      @play="handlePlay(`${item.ID}`)"
                      :data-id="`${item.ID}`"
                      style="height: 30px;margin-right: 20px;width:90%;"
                      controls
                      controlsList="nodownload nofullscreen noremoteplayback"
                    >
                      <source :src="item.text.url" type="audio/ogg" />
                      您的浏览器不支持 audio 元素。
                    </audio>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="smallchat"
              :style="item.replyMsg ? 'margin-bottom:6px' : ''"
              v-if="item.type == 'text'"
            >
              <div class="tu">
                <smallotherfn-contain
                  :currMemberlist="item.role || item.isRobot ? {} : item"
                  :isRobot="item.isRobot ? true : false"
                  :isrole="item.role ? true : false"
                  @otherfnQuote="$emit('otherfnQuote', item)"
                  @otherfnPrivateletter="$emit('otherfnPrivateletter', item)"
                  @otherfnContainsDel="delchartroomMsg(item)"
                >
                  <img :src="item.avatar" alt="" />
                </smallotherfn-contain>
              </div>

              <div
                class="name"
                :style="
                  item.role
                    ? 'color:#FA6400'
                    : item.isRobot
                    ? 'color:#4C72B3'
                    : 'color:#fff'
                "
              >
                <smallotherfn-contain
                  :currMemberlist="item.role || item.isRobot ? {} : item"
                  :isRobot="item.isRobot ? true : false"
                  :isrole="item.role ? true : false"
                  @otherfnQuote="$emit('otherfnQuote', item)"
                  @otherfnPrivateletter="$emit('otherfnPrivateletter', item)"
                  @otherfnContainsDel="delchartroomMsg(item)"
                >
                  {{ item.student_remarks||item.nick }}：
                </smallotherfn-contain>
                <span v-if="item.isQuestion" style="color: #0aa29b !important">
                  <span class="fs12">[提问]</span>
                  <span
                    class="contain"
                    v-html="item.text"
                    style="color: #0aa29b !important"
                  ></span>
                </span>
                <span class="contain" v-else v-html="item.text"></span>
                <p style="color: #fff"></p>
              </div>
            </div>
            <!-- 图片 -->
            <div class="smallchat" v-else-if="item.type == 'img'">
              <div class="tu">
                <img :src="item.avatar" alt="" />
              </div>
              <div
                class="name"
                :style="
                  item.role
                    ? 'color:#FA6400'
                    : item.isRobot
                    ? 'color:#4C72B3'
                    : 'color:#fff'
                "
              >
                <smallotherfn-contain
                  :isPictureFile="true"
                  :isRobot="item.isRobot ? true : false"
                  @otherfnQuote="$emit('otherfnQuote', item)"
                  @otherfnPrivateletter="$emit('otherfnPrivateletter', item)"
                  @otherfnContainsDel="delchartroomMsg(item)"
                >
                  {{ item.student_remarks||item.nick }}：
                </smallotherfn-contain>
                <div class="sendpic">
                  <el-image
                    :lazy="true"
                    scroll-container=".message-contain"
                    style="height: 100%; width: 100%"
                    fit="contain"
                    :src="item.compressed.url"
                    :style="
                      `width:${
                        item.compressed.width > 180
                          ? 180
                          : item.compressed.width
                      }px;height:${
                        item.compressed.width > 180
                          ? item.compressed.height *
                            (180 / item.compressed.width)
                          : item.compressed.height
                      }px`
                    "
                    :preview-src-list="[item.text]"
                  ></el-image>
                </div>
              </div>
            </div>
            <!-- 推送内容 -->
            <div class="flex smallchat" v-else-if="item.type == 'file'">
              <div class="tu">
                <img :src="item.avatar" alt="" />
              </div>
              <div class="name_message">
                <div class="name">
                  <span
                    class="tname"
                    :style="
                      item.role
                        ? 'color:#FA6400'
                        : item.isRobot
                        ? 'color:#4C72B3'
                        : 'color:#fff'
                    "
                  >
                    <smallotherfn-contain
                      :isPictureFile="true"
                      :isRobot="item.isRobot ? true : false"
                      @otherfnQuote="$emit('otherfnQuote', item)"
                      @otherfnPrivateletter="
                        $emit('otherfnPrivateletter', item)
                      "
                      @otherfnContainsDel="delchartroomMsg(item)"
                    >
                      {{ item.student_remarks||item.nick }}：
                    </smallotherfn-contain>
                  </span>
                </div>
                <div class="message ml10">
                  <div
                    class="file_contain"
                    @click="
                      item.bannnerType == 5
                        ? $root.prompt('暂不支持打开该类型消息')
                        : openCourse(item.text.url)
                    "
                    style="cursor: pointer"
                  >
                    <el-image :src="item.text.photo"></el-image>
                    <div class="name_type">
                      <div class="course_name" :title="item.text.name">
                        {{ item.text.name }}
                      </div>
                      <div class="course_type" v-if="item.text.selectType == 1">
                        {{ item.text.course_type | getCourseType }}
                      </div>
                      <div
                        class="course_type"
                        v-else-if="item.text.selectType == 2"
                      >
                        电子资料
                      </div>
                      <div
                        class="course_type"
                        v-else-if="item.text.selectType == 3"
                      >
                        会员
                      </div>
                      <div
                        class="course_type"
                        v-else-if="item.text.selectType == 6"
                      >
                        作业
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--礼物消息-->
          <template v-if="item.type == 'gift' || item.type == 'coupon'">
            <div class="gift-contain">
              <div class="gift-content">
                <span class="vm gift_text">
                  {{ item.student_remarks||item.nick }} {{ item.desc }}
                </span>
                <img
                  class="vm gift_icon"
                  v-if="item.giftIcon"
                  :src="item.giftIcon"
                  alt=""
                />
              </div>
            </div>
          </template>

          <!-- 撤回消息提示 -->
          <div class="withdraw" v-if="item.restroe">
            {{ item.student_remarks||item.nick }} 撤回一条消息
          </div>
          <!-- 引用 -->
          <div
            class="smalquto"
            v-if="item.replyMsg"
            v-html="item.replyMsg"
          ></div>
          <div
            class="flex-center shield-contain"
            v-if="item.shield"
            style="margin-left: 36px;"
          >
            <div class="shield-tag">该消息屏蔽中</div>
            <el-button
              class="shield-btn"
              type="text"
              @click="messageShield(item,3)"
            >
              解除
            </el-button>
          </div>
        </template>
      </div>
    </div>

    <!-- 聊天室错误提示-->
    <ul>
      <li class="item ImErrorclass" v-if="ImError">
        <div class="reconnection-text">聊天室已断开，请点击重连</div>
        <div class="reconnection-btn" @click="login">立即重连</div>
      </li>

      <!--聊天室loading -->
      <li class="item ImErrorclass" v-if="loading && !ImError">
        <div class="reconnection-text" style="color: #999; font-size: 0.5rem">
          进入聊天室中，请稍后...
        </div>
      </li>

      <!--图片发送中-->
      <li class="img_sending" v-if="imageProgress > 0 && !imagecomplete">
        <div style="color: #ff3535; font-size: 0.5rem">图片发送中...</div>
      </li>

      <!--底部有新消息-->
      <li
        class="bottomNewMsg"
        @click="toNewMsg"
        v-if="autoScrollBotom === 0"
      >
        底部有新消息 {{ newMsgCount }}
      </li>
    </ul>

    <!--鲜花-->
    <fiftAs
      :list="flowers"
      @leaveEnd="flowers.splice(0, 1)"
      :max="1"
      class="flowers"
      :style="openAndClose ? '' : 'top:40px'"
      :duration="1000"
      direction="toLeft"
    >
      <template v-slot:default="{ list }">
        <li v-for="item in list" :key="item.id" class="flowers-item">
          <div class="icon"></div>
          <div class="text">{{ item.text }}</div>
        </li>
      </template>
    </fiftAs>

    <!--进入房间-->
    <fiftAs
      :list="memberEnterMsg"
      @leaveEnd="memberEnterMsg.splice(0, 1)"
      :max="1"
      :duration="1000"
      direction="toLeft"
      class="memberEnterMsg"
    >
      <template v-slot:default="{ list }">
        <li v-for="item in list" :key="item.id" class="memberEnterMsg-item">
          <div class="text">{{ item.text }}</div>
        </li>
      </template>
    </fiftAs>

    <!-- 礼物 -->
    <fiftAs
      :list="gifts"
      @leaveEnd="gifts.splice(0, 1)"
      :style="openAndClose ? 'top:50px' : 'bottom:0px'"
      :max="2"
      direction="toRight"
      class="gifts"
    >
      <!-- :style="`top:${giftPosition}px`" -->
      <template v-slot:default="{ list }">
        <li v-for="item in list" :key="item.id" class="gifts-item">
          <img class="avatar" :src="item.avatar" alt="" />
          <div class="user">
            <div class="nick">{{ item.student_remarks||item.nick }}</div>
            <div class="desc">{{ item.desc }}</div>
          </div>
          <img class="gift-icon" :src="item.giftIcon" alt="" />
        </li>
      </template>
    </fiftAs>

    <!-- 私聊区域 -->
    <template v-if="isHasPrivateVal">
      <div :class="['private',isWindowsApp&&'winAppPrivate']">
        <!-- <div class="priLRContent"> -->
          <div class="leftPrivate">
            <div class="privatePersonName cp" v-drag>
              <div class="name">
                {{ otherfnPrivateletterval.student_remarks ||otherfnPrivateletterselectVal.profile.selfSignature ||otherfnPrivateletterselectVal.profile.nick }}
              </div>
              <i
                slot="reference"
                class="el-icon-close fr cp"
                style="padding: 5px"
                @click="changeotherfnPrivateletterselectVal({}),closeHistory()"
              ></i>
            </div>
            <div class="privateContain" ref="showPrivate">
              <div
                style="padding-bottom: 20px"
                v-for="(item, index) in privateMsg"
                :key="index"
              >
                <!--计算消息时间间隔-->
                <template v-if="item.timeTnterval">
                  <div class="msg-time-tnterval">
                    {{ item.timeTnterval }}
                  </div>
                </template>
                <!-- 文本和表情 -->
                <div
                  v-if="item.type == 'text'"
                  class="otherParty"
                  :style="item.role ? 'justify-content: flex-end; position: relative;' : 'position: relative;'"
                >
                  <!-- order 3 -->
                  <div class="pic" :style="item.role ? 'order:3' : ''">
                    <img :src="item.avatar" alt="" />
                  </div>
                  <!-- order 2 -->
                  <div
                    class="contain"
                    v-html="item.text"
                    :style="item.role ? 'order:2;background:#005753FF' : ''"
                  ></div>
                  <div class="sendTime">
                    <!-- @otherfnQuote="$emit('otherfnQuote', item)" -->
                    <otherfn-contain
                      :noPrivate="true"
                      :Quote="true"
                      :isRobot="item.isRobot ? true : false"
                      @otherfnQuote="setPrivateQuote(item)"
                      @otherfnPrivateletter="$emit('otherfnPrivateletter', item)"
                      @otherfnContainsDel="delchartroomMsg(item)"
                      class="mb10 cp"
                      :style="item.role ? 'text-align:right' : ''"
                    >
                      <i class="el-icon-more" style="color: #fff"></i>
                    </otherfn-contain>
                    {{ item.time | formatTimeStamp('MM-dd hh:mm:ss') }}
                  </div>
                </div>
                <!-- 图片 -->
                <div
                  v-else-if="item.type == 'img'"
                  class="otherParty"
                  :style="item.role ? 'justify-content: flex-end;position: relative;' : 'position: relative;'"
                >
                  <!-- order 3 -->
                  <div class="pic" :style="item.role ? 'order:3' : ''">
                    <img :src="item.avatar" alt="" />
                  </div>
                  <!-- order 2 -->
                  <div class="imgprivate" :style="item.role ? 'order:2' : ''">
                    <!-- <img :src="item.compressed.url" alt="" /> -->
                    <el-image
                      fit="contain"
                      style="width: 100%; height: 100%"
                      :src="item.compressed.url"
                      :preview-src-list="[item.compressed.url]"
                    ></el-image>
                  </div>
                  <div class="sendTime">
                    {{ item.time | formatTimeStamp('MM-dd hh:mm:ss') }}
                  </div>
                </div>
    
                <div style="text-align: right">
                  <div class="quto" v-if="item.replyMsg">
                    <div class="qutocotian" v-html="item.replyMsg"></div>
                  </div>
                </div>
                <!-- {{ privateMsg }} -->
              </div>
              <ul>
                <li class="item ImErrorclass" v-if="ImError">
                  <div class="reconnection-text">聊天室已断开，请点击重连</div>
                  <div class="reconnection-btn" @click="login">立即重连</div>
                </li>
    
                <!--聊天室loading -->
                <li class="item ImErrorclass" v-if="loading2">
                  <div
                    class="reconnection-text"
                    style="color: #999; font-size: 0.5rem"
                  >
                    进入聊天室中，请稍后...
                  </div>
                </li>
                <!--底部有新消息-->
                <!-- <li
                  class="bottomNewMsg"
                  @click="toNewMsgPrivate"
                  v-if="autoScrollBotomPrivate === 0"
                >
                  底部有新消息 {{ newMsgCountPrivate }}
                </li> -->
              </ul>
            </div>
            <message
              class="privateSend"
              ref="privateSend"
              v-if="!ImError"
              @sendmessage="$emit('sendmessage',arguments[0]),$root.$emit('changeFriendRanking'),chageprivateQuotelist({})"
              @currTutor="currTutor"
              @closeHistory="closeHistory"
              @imgStart2="$emit('imgStart2')"
              @percentage2="$emit('percentage2',arguments[0])"
              @imgCompress2="$emit('imgCompress2',arguments[0])"
              :openInfo="openInfo"
              :imageProgress2="imageProgress2"
              :imagecomplete2="imagecomplete2"
              :isC2Cmsg="true"
              :isPrivate="true"
              :isHidHistory="isShowHistory"
              :isPrivateQuote="true"
            ></message>
          </div>
          <div class="rightHistory" v-if="isHasTutorHistory&&isShowHistory&&currHistory">
            <div class="privatePersonName cp" v-drag>
              <div class="name">
                {{ this.tutorHistorys[this.currHistory].uname }}
              </div>
              <i
                slot="reference"
                class="el-icon-close fr cp"
                style="padding: 5px"
                @click="closeHistory()"
              ></i>
            </div>
            <div class="tutorHistory">
              <div class="privateContain" ref="scrollHistoryC">
                <div
                  style="padding-bottom: 20px"
                  v-for="(item) in rHistory"
                  :key="item.ID"
                >
                  <!--计算消息时间间隔-->
                  <template v-if="item.timeTnterval">
                    <div class="msg-time-tnterval">
                      {{ item.timeTnterval }}
                    </div>
                  </template>
                  <!-- 文本和表情 -->
                  <div
                    v-if="item.type == 'text'"
                    class="otherParty"
                    :style="item.role ? 'justify-content: flex-end;position: relative;' : 'position: relative;'"
                  >
                    <!-- order 3 -->
                    <div class="pic" :style="item.role ? 'order:3' : ''">
                      <img :src="item.avatar" alt="" />
                    </div>
                    <!-- order 2 -->
                    <div class="textContain">
                      <div class="tcTop">
                        <span class="tcNick" :style="item.role ?'text-align: right;right: 40px;':'text-align: left;left: 40px;'">{{ item.nick }}</span>
                        <span class="historyTime" :style="`font-size:12px;color:#474747;position: absolute;${item.role ? 'left:20px;' : 'right:20px;'}`">{{ item.time | formatTimeStamp('MM-dd hh:mm:ss') }}</span>
                      </div>
                      <div class="tcText" :style="item.role ?'justify-content: flex-end;':'justify-content: flex-start;'">
                        <div
                          class="contain"
                          v-html="item.text"
                          :style="item.role ? 'order:2;background:#005753FF' : ''"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <!-- 图片 -->
                  <div
                    v-else-if="item.type == 'img'"
                    class="otherParty"
                    :style="item.role ? 'justify-content: flex-end;position: relative;' : 'position: relative;'"
                  >
                    <!-- order 3 -->
                    <div class="pic" :style="item.role ? 'order:3' : ''">
                      <img :src="item.avatar" alt="" />
                    </div>
                    <!-- order 2 -->
                    <div class="imgprivate" :style="item.role ? 'order:2' : ''">
                      <!-- <img :src="item.compressed.url" alt="" /> -->
                      <el-image
                        fit="contain"
                        style="width: 100%; height: 100%"
                        :src="item.compressed.URL"
                        :preview-src-list="[item.compressed.URL]"
                      ></el-image>
                    </div>
                    <div class="sendTime">
                      {{ item.time | formatTimeStamp('MM-dd hh:mm:ss') }}
                    </div>
                    <span class="historyTime" :style="`font-size:12px;color:#474747;position: absolute;${item.role ? 'left:20px;' : 'right:20px;'}`">{{ item.time | formatTimeStamp('MM-dd hh:mm:ss') }}</span>
                  </div>
      
                  <div style="text-align: right">
                    <div class="quto" v-if="item.replyMsg">
                      <div class="qutocotian" v-html="item.replyMsg"></div>
                    </div>
                  </div>
                  <!-- {{ privateMsg }} -->
                </div>
                <ul>
                  <li class="item ImErrorclass" v-if="ImError">
                    <div class="reconnection-text">聊天室已断开，请点击重连</div>
                    <div class="reconnection-btn" @click="login">立即重连</div>
                  </li>
      
                  <!--聊天室loading -->
                  <li class="item ImErrorclass" v-if="loading2">
                    <div
                      class="reconnection-text"
                      style="color: #999; font-size: 0.5rem"
                    >
                      进入聊天室中，请稍后...
                    </div>
                  </li>
                  <!--底部有新消息-->
                  <!-- <li
                    class="bottomNewMsg"
                    @click="toNewMsgPrivate"
                    v-if="autoScrollBotomPrivate === 0"
                  >
                    底部有新消息 {{ newMsgCount }}
                  </li> -->
                </ul>
              </div>
            </div>

          </div>
        <!-- </div> -->
      </div>

    </template>

    <!-- 连麦提示 -->
    <!-- <lianmai-tip v-if="!isPrivate"></lianmai-tip> -->
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import smallotherfnContain from './smallotherfnContain'
import otherfnContain from './otherfnContain'
// import gift from './gift'
import fiftAs from './giftAnimation'
import message from './message'
const {
  mapState,
  mapActions,
  mapMutations,
  mapGetters,
} = createNamespacedHelpers('OpenClassopenClassLive')

export default {
  name: 'chatcontent',
  data() {
    return {
      allCheckstatus: false,

      // 是否是私聊
      isPrivate: false,

      // 聊天内容
      newMessageList: [],

      // 删除时选择的数据集合
      checkList: [],

      //是否有历史消息
      ishistory: false,

      // 礼物 打赏
      showGift: false,

      width: 0,

      height: 0,

      // 当前历史记录
      currHistory:null,
      isShowHistory:false,
    }
  },

  computed: {
    ...mapState([
      'sensitiveWords',
      'userInfo',
      'liveInfo',
      'openInfo',
      'openClassId',
      'openAndClose',
      'innerHeight',
      'innerWidth',
      'otherfnPrivateletterselectVal',
      'friendList',
      'rightActiveName',
      'allTutorList',
      'tutorHistorys',
    ]),
    ...mapGetters(['chatFullScreen']),
    isWindowsApp() {
      return this.$store.state.isWindowsApp
    },
    // 当前多选框选中的数据
    isCheckList() {
      return this.newMessageList.filter(item => item.checked)
    },
    // 判断是否是私聊
    isHasPrivateVal() {
      return Object.keys(this.otherfnPrivateletterselectVal).length > 0
    },
    isHasTutorHistory(){
      return Object.keys(this.tutorHistorys).length>0
    },
    rHistory(){
      return this.currHistory?this.tutorHistorys[this.currHistory].MsgList:[]
    },
  },

  watch: {
    isCheckList(val) {
      this.allCheckstatus =
        val.length > 0 && this.newMessageList.length == val.length
      // const listlength = this.newMessageList.length
      // const valLength = val.length
      // if (valLength > 0) {
      //   if (valLength == listlength) {
      //     this.allCheckstatus = true
      //   } else if (valLength < listlength) {
      //     this.allCheckstatus = false
      //   }
      // } else {
      //   this.allCheckstatus = false
      // }
    },

    // 消息管理
    openManagerMsg() {
      this.tobottom()
    },

    // 窗口切换大小时，要自动聊天窗要自动滚到底部
    openAndClose() {
      this.tobottom()
    },

    // 监听消息列表
    messageList: {
      handler() {
        this.newMessageList = _.merge([], this.messageList)
        // this.tobottom()
      },
      deep: true,
    },

    // 监听私聊数据的变更
    otherfnPrivateletterselectVal:{
      handler(val) {
        this.selectval = val
        this.isPrivate = Object.keys(val).length > 0
        if(Object.keys(val).length > 0){
          this.$nextTick(()=>{
            this.scrolltPrivate()
          })
          this.scrollBottomPrivate()
        }
      },
      deep: true,
      immediate: false,
    },
    // 滚动框拉到最底部
    isShowHistory:{
      handler(val){
        if(val){
          this.$nextTick(()=>{
            this.$refs.scrollHistoryC.scrollTop = 999999
            this.$refs.scrollHistoryC.addEventListener('scroll', this.handleScroll)
          })
        }
      },
    },
    // 加载历史记录后停留在滚动框当前位置
    rHistory:{
      handler(list){
        if(list.length>0&&this.lastScrollH){
          this.$nextTick(()=>{
            let scrollHeight = this.$refs.scrollHistoryC.scrollHeight
            if(this.lastScrollH!==scrollHeight){
              this.$refs.scrollHistoryC.scrollTop = scrollHeight - this.lastScrollH
            }
          })
        }
      },
    },
    currHistory:{
      handler(val){
        if(val){
          this.$nextTick(()=>{
            this.handleScroll()
          })
        }
      },
    },
  },

  components: {
    fiftAs,
    smallotherfnContain,
    otherfnContain,
    // lianmaiTip,
    message,
  },

  props: {
    CONV_C2C: {
      type: Boolean,
      default: false,
    },
    imagecomplete: {
      type: Boolean,
      default: false,
    },
    imagecomplete2: {
      type: Boolean,
      default: false,
    },
    imageProgress: {
      type: Number,
    },
    imageProgress2: {
      type: Number,
    },
    gifts: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
    loading2: {
      type: Boolean,
      default: true,
    },
    ImError: {
      type: Boolean,
      default: false,
    },
    flowers: {
      type: Array,
      default: () => [],
    },
    memberEnterMsg: {
      type: Array,
      default: () => [],
    },
    privateMsg: {
      type: Array,
      default: () => {},
    },
    messageList: {
      type: Array,
      default: () => {},
    },
    openManagerMsg: {
      type: Boolean,
      default: true,
    },
    isC2Cmsg: {
      type: Boolean,
      default: false,
    },

    newMsgCount: Number,
    newMsgCountPrivate: Number,

    // 消息列表自动滚动到底部
    autoScrollBotom: [Boolean, Number],
    autoScrollBotomPrivate: [Boolean, Number],
    otherfnPrivateletterval:Object
  },

  filters: {
    getCourseType(val) {
      let text
      switch (Number(val)) {
        case 1:
          text = '直播课'
          break
        case 2:
          text = '小班课'
          break
        case 3:
          text = '录播课'
          break
        default:
          text = '系列课'
          break
      }
      return text
    },
  },

  methods: {
    ...mapActions(['consoleInfo', 'setInnerHeightWidth']),
    ...mapMutations(['setCurrMember', 'changeotherfnPrivateletterselectVal','chageprivateQuotelist']),

    // 控制audio的播放
    handlePlay(id) {
      this.videoElement = document.getElementsByTagName('audio')
      // console.log('aaaa', id, this.videoElement)
      Array.from(this.videoElement).forEach(item => {
        // console.log('bbbb', item.dataset.id)
        if (item.dataset.id == id) {
          item.play()
        } else {
          item.pause()
        }
      })
    },

    // 自动往下滚动到底部
    tobottom() {
      this.$nextTick(() => {
        let chatcontentmain = this.$refs.chatcontentmain
        chatcontentmain.scrollTop = 999999999
      })
    },

    // 恢复数据
    delsCancel() {
      let arr = []
      this.newMessageList.forEach(item => {
        if (item.checked) {
          item.checked = false
        }
        arr.push(item)
      })
      this.newMessageList = arr
    },

    // 多选框
    changeCheck($event, val) {
      const index = this.newMessageList.findIndex(item => item.ID == val.ID)
      if (index > -1) {
        this.newMessageList[index].checked = $event
      }

      // 重新复制触发页面重新渲染
      let arr = []
      this.newMessageList.forEach(item => {
        arr.push(item)
      })
      this.newMessageList = arr

      this.$emit('isCheckList', this.isCheckList)
    },

    // 全选
    allCheck() {
      // 初次是没有选中的
      // 每次进入反选当前选中

      this.allCheckstatus = !this.allCheckstatus
      this.newMessageList = this.newMessageList.map(item => {
        return _.assign({}, item, { checked: this.allCheckstatus })
      })

      // if (!this.allCheckstatus) {
      //   this.newMessageList = this.newMessageList.map((item) => {
      //     return _.assign({}, item, { checked: true })
      //   })
      //   this.allCheckstatus = true
      // } else {
      //   this.newMessageList = this.newMessageList.map((item) => {
      //     return _.assign({}, item, { checked: false })
      //   })
      //   this.allCheckstatus = false
      // }

      this.$emit('isCheckList', this.isCheckList)
    },

    // 新窗口打开推送的课程
    openCourse(val) {
      this.$store.dispatch('open', val)
    },

    // 删除聊天信息
    delchartroomMsg(item) {
      this.$emit('delchartroomMsg', item)
    },

    // 删除群聊消息
    delGroupMsg(item){
      this.newMessageList.forEach((v,i)=>{
        if(v.clientSequence===item.clientSequence){
          this.newMessageList.splice(i,1)
        }
      })
    },
    handleClickItem() {
      // this.$nextTick(() => {
      //   // 获取遮罩层dom
      //   let domImageMask = document.querySelector('.el-image-viewer__mask')
      //   let domImage = document.querySelector('.el-image-viewer__img')
      //   if (!domImageMask || !domImage) {
      //     return
      //   }
      //   domImageMask.addEventListener('click', () => {
      //     // 点击遮罩层时调用关闭按钮的 click 事件
      //     document.querySelector('.el-image-viewer__close').click()
      //   })
      //   domImage.addEventListener('click', () => {
      //     // 点击遮罩层时调用关闭按钮的 click 事件
      //     document.querySelector('.el-image-viewer__close').click()
      //   })
      // })
    },

    // 解除屏蔽消息
    async messageShield(item,type) {
      await this.$http({
        url: '/console/modifyGroupMsg',
        data: {
          GroupId: item.to,
          MsgSeq: item.clientSequence,
          CloudCustomData: Object.assign({}, item.cloudCustomData, {
            shield: false,
            tag_names: item.tag_names2,
          }),
        },
      })
      item.shield = false
      this.$emit('updateMessageItem', Object.assign({}, item),type)
    },

    toNewMsg() {
      this.$emit('update:autoScrollBotom', true)
      this.$emit('update:newMsgCount', 0)

      this.$nextTick(() => {
        this.scrollBottom()
      })
    },
    toNewMsgPrivate(){
      this.$emit('update:autoScrollBotomPrivate', true)
      this.$emit('update:newMsgCountPrivate', 0)

      this.$nextTick(() => {
        this.scrollBottomPrivate()
      })
    },
    // 滚动
    scrollt() {
      this.$refs.chatcontentmain.removeEventListener(
        'scroll',
        this.setAutoBootmState
      )
      this.$refs.chatcontentmain.addEventListener(
        'scroll',
        this.setAutoBootmState
      )
    },
    scrolltPrivate() {
      this.$refs.showPrivate.removeEventListener(
        'scroll',
        this.setAutoBootmStatePrivate
      )
      this.$refs.showPrivate.addEventListener(
        'scroll',
        this.setAutoBootmStatePrivate
      )
    },

    // 查看学员信息
    openUserInfo(val) {
      this.setCurrMember(Object.assign({}, val))
    },
    // 设置滚动状态
    setAutoBootmState() {
      if (this.$refs.chatcontentmain) {
        const val = !(
          this.$refs.chatcontentmain.clientHeight +
            this.$refs.chatcontentmain.scrollTop <
          this.$refs.chatcontentmain.scrollHeight - 100
        )
        if (val != this.autoScrollBotom) {
          this.$emit('update:autoScrollBotom', val)
        }
      }
    },
    // 设置私聊滚动状态
    setAutoBootmStatePrivate() {
      if (this.$refs.showPrivate) {
        const val = !(
          this.$refs.showPrivate.clientHeight +
            this.$refs.showPrivate.scrollTop <
          this.$refs.showPrivate.scrollHeight - 100
        )
        if (val != this.autoScrollBotomPrivate) {
          this.$emit('update:autoScrollBotomPrivate', val)
        }
      }
    },

    // 刷新页面
    login() {
      this.$emit('relogin')
    },

    //滚动到底部
    scrollBottom() {
      if (this.autoScrollBotom) {
        try {
          clearTimeout(this.scrollBottomTimer)
          this.scrollBottomTimer = setTimeout(() => {
            if (this.autoScrollBotom) {
              let chatcontentmain = this.$refs.chatcontentmain
              chatcontentmain.scrollTop = 999999999
            }
          }, 100)
        } catch (e) {
          console.log(e)
        }
      } else {
        this.$emit('update:autoScrollBotom', 0)
      }
    },
    scrollBottomPrivate(){
      if (this.autoScrollBotomPrivate) {
        try {
          clearTimeout(this.scrollBottomTimerPrivate)
          this.scrollBottomTimerPrivate = setTimeout(() => {
            if (this.autoScrollBotomPrivate && this.$refs.showPrivate) {
              let showPrivate = this.$refs.showPrivate
              showPrivate.scrollTop = 999999999
            }
          }, 200)
        } catch (e) {
          console.log(e)
        }
      } else {
        this.$emit('update:autoScrollBotomPrivate', 0)
      }
    },
    // 设置历史记录
    currTutor({val}){
      // console.log('获取个人信息',this.otherfnPrivateletterselectVal);
      this.lastScrollH = null
      this.currHistory = val.userID
      this.isShowHistory = true
    },
    closeHistory(){
      this.currHistory = null
      this.isShowHistory = false
    },
    // 历史记录上拉加载
    handleScroll(){
      let scrollHeight = this.$refs.scrollHistoryC.scrollHeight // 盒子被卷去的高度
      let scrollTop = this.$refs.scrollHistoryC.scrollTop // 盒子被卷去的高度
      if (scrollTop < 5) {
        if(this.lastScrollH!==scrollHeight){
          this.lastScrollH = scrollHeight
        }
        let curr = this.tutorHistorys[this.currHistory]
        if(curr.Complete == 1) return //拉取完
        let lastData = curr.MsgList[0]
        let tutorInfo = this.allTutorList.filter(v=>v.userID==this.currHistory)[0]
        if(this.isPullScroll)return //三秒加载一次
        this.isPullScroll = true
        this.$root.$emit('getManyHistory',this.otherfnPrivateletterselectVal,true,lastData.MsgKey,lastData.MsgTimeStamp,tutorInfo)
        setTimeout(()=>{
          this.isPullScroll = false
        },3000)
      }
    },

    setPrivateQuote(item){
      this.chageprivateQuotelist(item)
      this.$refs.privateSend.focusInput()
    },
  },
}
</script>

<style lang="scss">
audio::-webkit-media-controls-volume-control-container {
  display: none;
}
audio::-webkit-media-controls-time-remaining-display {
  display: block !important;
}
audio::-webkit-media-controls-timeline,
audio::-webkit-media-controls-mute-button {
  display: none;
}
.openclass-emoji-item2 {
  width: 20px;
  height: 20px;
  background: url(https://f.dingdingkaike.com.cn/emoji/emoji.png) no-repeat;
  background-size: 20px 1120px;
}
.openclass-emoji-item {
  width: 29px;
  height: 29px;
  background: url(https://f.dingdingkaike.com.cn/emoji/emoji.png) no-repeat;
  background-size: 29px 1642px;
}
</style>
<style lang="scss" scoped>
/**
公共样式
*/
.flex_end {
  justify-content: flex-end;
}
.or1 {
  order: 1;
}
.or2 {
  order: 2;
}
.or3 {
  order: 3;
}
.flex {
  display: flex;
  width: 100%;
}

.h-40-t-40 {
  height: calc(100% - 40px);
  margin-top: 40px;
}

.h-80-t-80 {
  height: calc(100% - 80px);
  margin-top: 80px;
}

::v-deep #otherfnContain {
  display: inline-block;
  font-size: 13px;
  line-height: 18px;
  cursor: pointer;
}
.smallchat {
  display: flex;
  margin: 0 14px;
  width: 85%;

  &.align_center {
    align-items: center;
  }
  .tu {
    border-radius: 50%;
    height: 18px;
    width: 18px;
    min-width: 18px;
    overflow: hidden;
    img {
      width: 18px;
      height: 18px;
    }
  }
  .name {
    font-size: 0;
    flex: 1;
    margin-left: 10px;
    .contain {
      font-size: 13px;
      line-height: 18px;
      color: #eaeaea;
      word-break: break-all;
    }
    .sendpic {
      width: 190px;
      height: auto;
      .el-image {
        width: 100% !important;
      }
    }
  }
}

.pt40 {
  padding-top: 40px !important;
}
::v-deep .el-image-viewer__canvas {
  img {
    max-height: 90% !important;
    max-width: 90% !important;
  }
}
::v-deep .el-icon-circle-close {
  color: #fff;
}
.flex {
  display: flex;
}
.file_contain {
  flex: 1;
  height: 64px;
  padding: 10px;
  box-sizing: border-box;
  background: #212121;
  border-radius: 4px;
  display: flex;
  overflow: hidden;
  ::v-deep .el-image {
    flex-shrink: 0;
    img {
      width: 78px;
      height: 44px;
    }
  }
  img {
    width: 78px;
    height: 44px;
    flex-shrink: 0;
  }
  .name_type {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;
    white-space: nowrap;
    flex-shrink: 0;
    overflow: hidden;
    .course_name {
      line-height: 15px;
      font-size: 13px;
      width: 100%;
      color: #ffffff;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 0;
    }

    .course_type {
      font-size: 12px;
      color: #ffffff;
    }
  }
}
.smalquto {
  font-size: 13px;
  color: #747474;
  text-decoration: underline;
  margin-left: 46px;
  line-height: 17px;
  width: calc(85% - 37px);
  word-break: break-all;
}
.quto {
  .qutocotian {
    max-width: calc(100% - 119px);
    background: #191919;
    border-radius: 4px;
    padding: 6px 10px;
    display: inline-block;
    margin-top: 10px;
    font-size: 13px;
    color: #6d6b6bea;
    word-break: break-all;
  }
}
.shield-contain {
  padding-left: 10px;
  .shield-tag {
    font-size: 12px;
    color: #666666;
  }
  .shield-btn {
    font-size: 12px;
    margin-left: 10px;
    color: #0aa29b;
  }
}
#chatcontent {
  flex: 1;
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: #171717;
  box-sizing: border-box;

  &.hide {
    height: 100% !important;
    padding: 0 !important;
  }

  .msg-time-tnterval {
    width: 100%;
    color: #999;
    font-size: 11px;
    text-align: center;
    padding-bottom: 20px;
  }
    .private {
      display: flex;
      position: fixed;
      top: 100px;
      right: 300px;
      min-width: 400px;
      max-width: 800px;
      height: 70%;
      z-index: 9;
      background-color: #171717;
      #otherfnContain {
        display: block;
        cursor: pointer;
      }
      .privatePersonName {
        padding: 0 10px;
        height: 40px;
        min-height: 40px;
        background-color: #252629ff;
        color: #999;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .name {
          flex: 1;
          text-align: center;
          font-size: 14px;
          color: #ffffff;
        }
      }
      // .priLRContent{
      //   display:flex;
        .leftPrivate{
          width:400px;
          .privateContain {
            padding: 20px 10px 20px 10px;
            flex: 1;
            overflow-y: auto;
            height: calc(100% - 250px);
            @extend %liveRoomScroll;
            .otherParty {
              display: flex;
              // align-items: flex-end;
              .imgprivate {
                margin: 0 10px;
                height: 134px;
                max-height: 134px;
                ::v-deep .el-image {
                  img {
                    object-position: 100% 100% !important;
                  }
                }
                // img {
                //   object-fit: contain;
                //   height: 100%;
                //   width: 100%;
                // }
              }
              .pic {
                width: 36px;
                height: 36px;
                border-radius: 4px;
                min-width: 36px;
                img {
                  object-fit: contain;
                  width: 100%;
                  height: 100%;
                }
              }
              .contain {
                word-break: break-all;
                display: flex;
                font-size: 13px;
                color: #eaeaea;
                padding: 10px;
                background: #212121;
                border-radius: 4px;
                margin: 0 10px;
                line-height: 1.5;
              }
              .sendTime {
                font-size: 12px;
                color: #474747;
                visibility: hidden;
                white-space: nowrap;
                padding-right: 10px;
              }
              &:hover .sendTime {
                visibility: visible;
              }
            }
          }
    
          .privateSend{
            // height:164px;
          }
        }
        .rightHistory{
          width:400px;
          height: 100%;
          // max-height: 535px;
          background-color: #171717;
          // border-left:1px solid #fff;
          .tutorHistory{
            height: 100%;
            .privateContain {
              padding: 20px 10px 20px 10px;
              flex: 1;
              overflow-y: auto;
              height: calc(100% - 80px);
        
              @extend %liveRoomScroll;
              .otherParty {
                display: flex;
                // align-items: flex-end;
                
                .imgprivate {
                  margin: 0 10px;
                  height: 134px;
                  max-height: 134px;
                  ::v-deep .el-image {
                    img {
                      object-position: 100% 100% !important;
                    }
                  }
                  // img {
                  //   object-fit: contain;
                  //   height: 100%;
                  //   width: 100%;
                  // }
                }
                .pic {
                  width: 36px;
                  height: 36px;
                  border-radius: 4px;
                  min-width: 36px;
                  img {
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                  }
                }
                .textContain {
                    width:100%;
                  .tcTop {
                    height:15px;
                    width:100%;
                    display: flex;
                    justify-content: flex-end;
                    .tcNick {
                      font-size: 12px;
                      color: #fff;
                      position: absolute;
                      width: 220px;
                      height: 15px;
                      overflow: hidden;
                      text-overflow:ellipsis;
                      white-space:nowrap;
                    }
                  }
                  .tcText {
                    display: inline-block;
                    display: flex;
                  }
                }
                .contain {
                  word-break: break-all;
                  display: flex;
                  font-size: 13px;
                  color: #eaeaea;
                  padding: 10px;
                  background: #212121;
                  border-radius: 4px;
                  margin: 0 10px;
                  line-height: 1.5;
                }
                .sendTime {
                  font-size: 12px;
                  color: #474747;
                  visibility: hidden;
                  white-space: nowrap;
                  padding-right: 10px;
                }
                &:hover .sendTime {
                  visibility: visible;
                }
              }
            }
          }
        }

      // }
    }
    .winAppPrivate {
      left: 0 !important;
      top:0 !important;
      min-width: 100%;
      max-width: 100%;
      height: 100%;
      .leftPrivate {
        width: 100%;
        height: 100%;
      }
    }
  .withdraw {
    text-align: center;
    font-size: 12px;
    color: #474747;
    margin-top: 10px;
  }

  .del_msg {
    cursor: pointer;
    font-size: 12px;
    color: #ff3535;
    line-height: 12px;
    margin-top: 6px;
    margin-left: 47px;
  }
  .msg-list {
    .item {
      display: flex;
      flex-wrap: wrap;
      padding: 0.5rem 0.7rem;
      &.msg-item {
        display: block;
        flex-wrap: initial;
        > .content {
          display: flex;
        }
      }
      .gift-contain {
        font-size: 0;
        width: 100%;
        text-align: center;
        margin-bottom: -0.5rem;
        .gift-content {
          display: inline-block;
          padding: 0.2rem 0.5rem;
          background: #292929;
          border-radius: 0.2rem;
          .gift_text {
            font-size: 0.5rem;
            font-weight: 500;
            display: inline-block;
            color: rgba(255, 53, 53, 1);
          }
          .gift_icon {
            width: 1rem;
            margin-left: 0.2rem;
          }
        }
      }

      .avatar {
        img {
          width: 1.4rem;
          height: 1.4rem;
          border-radius: 0.1rem;
        }
      }
      .right {
        margin-left: 0.5rem;
        .nick {
          display: flex;
          align-items: center;
          .name {
            font-size: 0.55rem;
            font-weight: 500;
            color: #9b9b9b;
            line-height: 0.8rem;
          }
          .tag {
            width: 20px;
            height: 13px;
            // background: url('../../../../assets/student/img/1.4.2.3/img_ggbs@2x.png')
            //   no-repeat;
            background-size: 100%;
            margin-left: 0.2rem;
          }
        }
        .text {
          margin-top: 0.25rem;
          padding: 0.35rem 0.5rem;
          background: #ffffff;
          border-radius: 0.2rem;
          font-size: 0.65rem;
          font-weight: 500;
          color: #4a4a4a;
          line-height: 0.9rem;
          word-break: break-all;
        }
        .img {
          width: 134px;
          min-height: 40px;
          max-height: 134px;
          margin-top: 0.25rem;
          border-radius: 0.2rem;
          img {
            display: block;
            max-width: 100%;
            border-radius: 0.2rem;
          }
        }
      }
      &.self {
        .text {
          color: #fff;
          background: #0aa29b;
        }
      }
    }
  }
  .ImErrorclass {
    position: absolute;
    top: 100px;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
  }
  .reconnection-text,
  .reconnection-btn {
    font-size: 14px;
    cursor: pointer;
    padding-top: 15vh;
    color: #dadada;
  }
  .img_sending {
    position: fixed;
    top: 50px;
    left: 50%;
  }
  .reconnection-text {
    flex: 1;
    text-align: center;
  }
  .reconnection-btn {
    color: #fff;
    background: #0e746f;
    border-radius: 3px;
    text-align: center;
    line-height: 40px;
    margin: 30px auto 0;
    padding: 0 0.5rem;
    width: 60%;
    display: block;
    font-size: 14px;
    transition: all 0.25s;
    &:hover {
      background: #0aa29b;
    }
  }
  .bottomNewMsg {
    background: #fa6400;
    position: absolute;
    bottom: 50px;
    right: 30px;
    color: #fff;
    cursor: pointer;
    font-size: 0.6rem;
    border-radius: 3px;
    padding: 0 0.35rem;
    line-height: 1.3rem;
  }
  .left {
    width: 100%;
    padding: 10px 0 0 0px;
    overflow-y: scroll;
    overflow-x: hidden;
    @extend %liveRoomScroll;
    .empty-message {
      position: absolute;
      left: 50%;
      top: 50%;
      color: #666;
      font-size: 12px;
      white-space: nowrap;
      transform: translate(-50%, -50%);
    }
    ::v-deep .el-checkbox__inner {
      background-color: #111111;
    }
    ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #0aa29b;
    }
    .open-left {
      position: absolute;
      top: 182px;
      left: 0px;
      cursor: pointer;
    }

    .gift-contain {
      font-size: 0;
      width: 100%;
      text-align: center;
      margin-bottom: -0.5rem;
      .gift-content {
        display: inline-block;
        padding: 0.2rem 0.5rem;
        background: #292929;
        border-radius: 0.2rem;
        .gift_text {
          font-size: 0.5rem;
          font-weight: 500;
          display: inline-block;
          color: rgba(255, 53, 53, 1);
        }
        .gift_icon {
          width: 1rem;
          margin-left: 0.2rem;
        }
      }
    }
    .photo {
      width: 36px;
      height: 36px;
      border-radius: 4px;
      margin: 0 10px;
      cursor: pointer;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .name_message {
      flex: 1;
      overflow: hidden;
      .name {
        display: flex;
        cursor: pointer;
        font-size: 12px;
        color: #ffffff;
        line-height: 12px;
        margin-bottom: 12px;
        .invite-people {
          font-size: 12px;
          color: #ff9224;
          margin: 0 6px;
          white-space: nowrap;
        }
      }
      .tag {
        font-size: 12px;
        color: #666666;
        margin-bottom: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .name {
        .tname:hover {
          color: #f39802;
          text-decoration: underline;
        }
      }
      .message,
      .rootmessage {
        display: flex;
        align-items: flex-end;
        word-break: break-all;
        width: 100%;
        box-sizing: border-box;
        font-size: 13px;
        color: #eaeaea;
        line-height: 20px;
        &:hover .time {
          visibility: visible;
        }
        .img {
          max-width: 180px;
          // max-height: 160px;
          ::v-deep .el-image {
            img {
              object-position: 0 0 !important;
            }
          }
          img {
            max-width: 100%;
            border-radius: 3px;
          }
        }
        .item {
          border-radius: 4px;
          padding: 8px 10px;
          background: #212121;
          &.robot {
            border: 1px solid #5f8ec2;
          }
        }

        .time {
          visibility: hidden;
          margin: 0 10px;
          font-size: 12px;
          color: #474747;
          line-height: 12px;
          white-space: nowrap;

          .otherfn {
            margin-bottom: 12px;
            color: #fff;
            cursor: pointer;
          }
        }
      }

      .message {
        .question {
          position: absolute;
          right: 0;
          bottom: 0;
          img {
            height: 16px;
            width: 16px;
          }
        }
      }
      .rootmessage {
        .item {
          background-color: #005753;
        }
      }
    }
  }
  .right {
    position: fixed;
    .rightcontain {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      color: #c25325;
      line-height: 12px;
      .main {
        border-radius: 13px;
        padding: 7px;
        background-color: #272422;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .other {
          font-size: 12px;
          font-weight: 500;
          color: #ffffff;
          white-space: nowrap;
        }
        img {
          width: 14px;
          height: auto;
          margin-right: 10px;
        }
      }
    }
  }
}
.flowers,
.memberEnterMsg {
  position: absolute;
  z-index: 5;
  right: 10px;
  top: 49px;
  height: 1.5rem;
}
.flowers {
  top: 20px;
  .flowers-item {
    color: #c25325;
  }
}
.flowers-item,
.memberEnterMsg-item {
  display: flex;
  align-items: center;
  font-size: 0.6rem;
  font-weight: 500;
  color: #ffffff;
  padding: 0.35rem 0.7rem 0.35rem 0.35rem;
  border-radius: 0.65rem;
  white-space: nowrap;
  margin-bottom: 0.5rem;
  background: #272422;
  &:last-child {
    margin-bottom: 0;
  }
  .icon {
    width: 0.7rem;
    height: 0.8rem;
    background: url('~@ass/img/OpenClass_openClassLive/icon_shxx.svg') no-repeat;
    background-size: 100%;
  }
  .text {
    margin-left: 0.5rem;
  }
}
.gifts {
  position: absolute;
  z-index: 999;
  left: 0;
  height: 9.3rem;
}
.gifts-item {
  display: flex;
  align-items: center;
  // width: 206px;
  height: 60px;
  margin-bottom: 0.5rem;
  // border-radius: 0 2.2rem 2.2rem 0;
  box-shadow: 5px 5px 12px 0 rgba(0, 0, 0, 0.15);
  background: rgba(0, 0, 0, 0.5);
  border-radius: 30px;

  // background: linear-gradient(to right, #3fa3ff, #6fc0eb);
  &:last-child {
    margin-bottom: 0;
  }
  .avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 0 0.55rem 0 0.9rem;
  }
  .user {
    flex: 1;
    overflow: hidden;
    padding-right: 0.7rem;
    .nick {
      font-size: 0.55rem;
      font-weight: 600;
      color: #ffeb9a;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .desc {
      font-size: 12px;
      margin-top: 10px;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .gift-icon {
    height: 40px;
    border-radius: 0;
    margin-right: 20px;
  }
}
</style>
