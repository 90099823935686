<!--
 * @Author: cyy
 * @Date: 2022-02-18 14:21:57
 * @LastEditTime: 2022-02-25 11:19:54
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \ teacher.dingdingkaike\src\views\OpenClass_openClassLive\components\liveDataComponents\openclassDatastatistics\components\enterRecord.vue
-->

<template>
  <div id="enterRecord">
    <div class="search">
      <div class="tip">提示：数据仅显示当天的记录</div>
      <div class="right">
        <el-select
          size="medium"
          v-model="post.type"
          style="width: 140px"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-input
          v-model="post.search"
          size="medium"
          style="width: 210px"
          class="ml20"
          placeholder="输入名称搜索"
        ></el-input>
      </div>
    </div>

    <pagination2
      :option="post"
      url="/console/studentData"
      class="childDialogBox flex-column"
      ref="childDialogBox"
    >
      <template v-slot:default="{ tableData }">
        <div class="table-box">
          <el-table
            class="absolute"
            :header-cell-style="{
              background: '#212121',
              color: '#fff',
            }"
            :row-style="{ background: '#171717', color: '#fff' }"
            :data="tableData"
          >
            <el-table-column label="学员" prop="">
              <template slot-scope="{ row }">
                <div class="student_contain">
                  <div class="tu">
                    <img :src="row.user_photo" alt="" />
                  </div>
                  <div
                    class="name"
                    :title="`
                    ${row.user_name}${
                      row.student_remarks ? `(${row.student_remarks})` : ''
                    }`"
                  >
                    {{ row.user_name }}
                    <span v-if="row.student_remarks">
                      ({{ row.student_remarks }})
                    </span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="类型" prop="">
              <template slot-scope="{ row }">
                <span v-if="row.new == 1" style="color: #29bc53">新学员</span>
                <span v-else style="color: #5990c0">老学员</span>
              </template>
            </el-table-column>
            <el-table-column label="注册时间" prop="">
              <template slot-scope="{ row }">
                <span>{{ row.regdate | formatTimeStamp('yyyy-MM-dd hh:mm') }}</span>
              </template>
            </el-table-column>
            <el-table-column label="今日首次进入" prop="">
              <template slot-scope="{ row }">
                {{ row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
              </template>
            </el-table-column>
            <el-table-column label="今日最后在线" prop="">
              <template slot-scope="{ row }">
                {{ row.e_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
              </template>
            </el-table-column>
            <el-table-column
              label="今日进入次数"
              prop="count"
            ></el-table-column>
          </el-table>
        </div>
      </template>
    </pagination2>
  </div>
</template>

<script>
export default {
  name: 'enterRecord',
  data() {
    return {
      heigth: 'calc(100% - 86px)',
      post: {
        open_class_id: this.openClassId,
        search: '',
        type: 0,
      },
      options: [
        {
          value: 0,
          label: '全部类型',
        },
        {
          value: 1,
          label: '新学员',
        },
        {
          value: 2,
          label: '老学员',
        },
      ],
    }
  },

  props: {
    openClassId: [String, Number],
  },
}
</script>

<style lang="scss" scoped>
#enterRecord {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  height: 100%;
  img {
    height: 100%;
    width: 100%;
  }
  ::v-deep .search {
    height: 36px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tip {
      font-size: 12px;
      color: #a8a8a8;
    }
    .el-input__inner {
      background-color: #171717;
      border-color: #333333;
      color: #fff;
    }
  }
  ::v-deep .childDialogBox {
    flex: 1;
    position: relative;
    .student_contain {
      display: flex;
      align-items: center;
      .tu {
        width: 26px;
        height: 26px;
        min-width: 26px;
        border-radius: 13px;
      }
      .name {
        flex: 1;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        margin-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .pagination {
      padding-top: 20px;
      .el-pager li.active + li,
      .el-pagination button {
        background-color: #343434 !important;
        color: #fff;
      }
      .el-pager li {
        background-color: #343434;
        color: #fff;
      }
    }
    .el-pagination span {
      color: #ffffff;
    }

    .el-pager li.active {
      color: #fff;
      background-color: #fa6400;
    }

    .el-input .el-input__inner {
      background-color: #3e3e3e;
      border-color: #3e3e3e;
      color: #fff;
    }
    .el-table td {
      border-color: #313131;
    }
    .el-table__body-wrapper {
      background-color: #171717;
    }
    .el-table--fit {
      background-color: #171717;
    }

    .el-table th.is-leaf {
      border-color: #3e3e3e;
    }
    .el-table--enable-row-hover .el-table__body tr:hover > td {
      background-color: #3e3e3e !important;
    }
    .table-box {
      flex: 1;
      min-height: 270px;
      position: relative;
    }
    .el-table {
      overflow-y: auto;
      @extend %liveRoomScroll;
      .el-table__body-wrapper {
        overflow: hidden;
      }
    }
    .el-table::before {
      background-color: #313131;
    }
    // .el-table th.is-leaf {
    //   border-color: #313131;
    // }
  }
}
</style>