<template>
  <div id="consoleWorkSelect">
    <div class="top_show">
      <div class="font_left">
        <span v-if="workName" :title="workName">
          已关联作业： {{ workName }}
        </span>
        <span v-else>暂无关联作业，请先创建作业并关联到该公开课</span>
      </div>
      <el-button type="text" size="medium" @click="newopen">管理作业</el-button>
    </div>
    <div class="table_contain">
      <pagination2
        @complete="complete"
        :option="DialogBoxPost"
        url="/console/themeList"
        ref="childDialogBox"
        :allFn="true"
      >
        <template v-slot:default="{ tableData }">
          <div class="pagination2Box">
            <el-table
              :row-key="getRowkey"
              @selection-change="DialogBoxSelectionChange"
              @row-click="handleRowClick"
              :data="tableData"
              ref="dialogTable"
              max-height:200
              :header-cell-style="{
                background: 'rgba(245,245,245,1)',
                color: '#333333',
              }"
            >
              <el-table-column label width="30">
                <template slot-scope="scope">
                  <el-radio
                    v-show="template != 5"
                    :label="scope.row.homework_theme_id"
                    v-model="radioId"
                  >
                    &nbsp;
                  </el-radio>
                </template>
              </el-table-column>
              <el-table-column label="作业">
                <template slot-scope="{ row }">
                  <span :title="row.name" class="rowname">{{ row.name }}</span>
                </template>
              </el-table-column>
              <el-table-column label="点评老师">
                <template slot-scope="{ row }">
                  <span :title="row.uname">
                    {{ row.uname }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="操作" v-if="template == 5">
                <template>
                  <el-button
                    type="text"
                    style="color: #fa6400"
                    @click="$emit('push')"
                  >
                    推送
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </pagination2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'consoleWorkSelect',
  props: {
    open_class_id: {
      type: String,
      default: undefined,
    },
    template: Number,
  },
  data() {
    return {
      listSave: {},
      workName: '',
      radioId: '',
      DialogBoxPost: {
        open_class_id: this.open_class_id,
      },
    }
  },
  methods: {
    DialogBoxSelectionChange() {},
    getRowkey(row) {
      return row.homework_theme_id
    },
    handleRowClick(row) {
      this.radioId = row.homework_theme_id
      this.$emit('singleselectcontent', row)
      this.$refs.dialogTable.toggleRowSelection(row)
    },
    complete(val) {
      this.workName = val.name
    },
    newopen() {
      let url = this.$router.resolve({
        path: '/courseWorkbook',
      })
      this.$store.dispatch('open', url.href)
    },
  },
}
</script>
<style lang="scss" scope>
::v-deep .el-table .cell {
  width: 100%;
}

.rowname {
  display: block;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap !important;
  word-break: break-all;
}
#consoleWorkSelect {
  .pagination2Box {
    height: 380px;
    overflow: auto;
    @extend %liveRoomScroll;
  }

  .top_show {
    margin: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .font_left {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-all;
      font-size: 14px;
      color: #666;
    }
  }
}
</style>
