<template>
  <div class="robotSpeak flex1 flex-column">
    <div class="  mt20" style="display:flex">
      <el-radio-group
        v-model="radio1"
        size="medium"
        class="mr20"
        style="flex-shrink:0"
      >
        <el-radio-button label="2">手动模式</el-radio-button>
        <el-radio-button label="1">剧本模式</el-radio-button>
      </el-radio-group>
      <div class="robotSpeak-tip" v-if="radio1 == 2">
        升级公告：由于多个老师与助教可以同时添加同一个机器人，操作机器人时会产生冲突，现已将机器人功能升级，
        多个老师与助教不可以添加同一个机器人，2022年3月15日前已添加的机器人已重置，请重新添加！
      </div>
    </div>
    <!-- 剧本模式 -->
    <template v-if="radio1 == 1">
      <div class="flex-center mt20">
        <el-button type="primary" @click="addScript" class="mr20" size="medium">
          添加话术本
        </el-button>
        <div class="robotSpeak-tip" v-if="isSendingList.length > 0">
          {{ isSendingList[0].name }} 正在发送中...
          <el-button
            type="danger"
            size="medium"
            class="ml10"
            @click="toSendStop(isSendingList[0], 2)"
          >
            停止
          </el-button>
        </div>
      </div>
      <pagination2
        :option="post"
        url="/console/scriptList"
        ref="childDialogBox"
        class="mt20 childDialogBox flex1"
        @complete="complete"
      >
        <template v-slot:default="{ tableData }">
          <el-table
            class="absolute"
            :header-cell-style="{
              background: '#212121',
              color: '#fff',
            }"
            :row-style="{ background: '#171717', color: '#fff' }"
            :data="tableData"
          >
            <el-table-column label="话术本名称" prop="name"></el-table-column>
            <el-table-column label="消息条数" prop="num"></el-table-column>
            <el-table-column label="创建人" prop="uname"></el-table-column>
            <el-table-column label="创建时间" width="180">
              <template slot-scope="{ row }">
                {{ row.c_time | formatTimeStamp }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="240">
              <template slot-scope="{ row }">
                <el-button
                  type="text"
                  style="color: #fa6400"
                  @click="editSpeek(row)"
                >
                  编辑
                </el-button>
                <el-divider direction="vertical" class="ml10 mr10"></el-divider>
                <el-button
                  type="text"
                  style="color: #fa6400"
                  @click="remove(row)"
                >
                  移除
                </el-button>
                <el-button
                  v-if="row.status == 2"
                  type="warning"
                  size="small"
                  class="ml20"
                  :loading="row.msg_id === loading"
                  :disabled="!!loading"
                  @click="send(row)"
                  style="background: #FA6400;border-color: #FA6400 ;"
                >
                  发送
                </el-button>
                <el-button
                  @click="toSendStop(row, 2)"
                  v-else
                  type="danger"
                  size="small"
                  class="ml20"
                >
                  停止
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </pagination2>
    </template>

    <!-- 手动模式 -->
    <template v-if="radio1 == 2">
      <div slot="title" class="title">
        <el-button
          size="medium"
          class="add-robot-btn"
          type="primary"
          @click="robotSelectStatus = true"
        >
          +添加机器人
        </el-button>
        <div class="rigt-btns flex-center">
          <div class="fill-contain flex-center">
            <el-input
              class="input"
              v-model="fillText"
              placeholder="请输入内容"
              maxlength="200"
              show-word-limit
              type="textarea"
              resize="none"
            />
            <el-button
              size="medium"
              type="primary"
              class="fill-btn"
              @click="fillAll(fillText)"
            >
              全部填充
            </el-button>
          </div>
          <el-button
            size="medium"
            type="primary"
            class="clear_class"
            @click="allClear"
          >
            清空全部
          </el-button>
          <el-button
            size="medium"
            type="primary"
            class="all-send-btn"
            @click="allSend"
          >
            一键发送
          </el-button>
        </div>
      </div>
      <div class="dialogVisible_bottom flex1">
        <div class="absolute scroll">
          <div class="header">
            <div class="d_1 ml20">机器人</div>
            <div class="d_2">发送内容</div>
            <div class="d_3">操作</div>
          </div>
          <div class="main_contain">
            <div
              class="message_contain"
              v-for="(item, index) in newRobotVal"
              :key="index"
            >
              <div class="d_1 0">
                <div class="photo">
                  <img :src="item.photo" alt="" />
                </div>
                <div class="name hidetext" :title="item.name">
                  {{ item.name }}
                </div>
                <i
                  class="el-icon-edit fs12 ml10"
                  @click="editRobor(item)"
                  style="color:#fff ;flex-shrink: 0;"
                ></i>
              </div>
              <div class="d_2">
                <el-input
                  :autosize="{ minRows: 1 }"
                  @focus="facusInput($event, index)"
                  @input="changeInput(item, index)"
                  v-model="item.text"
                  @blur="blurInput"
                  placeholder="请输入内容"
                  class="mb10 input"
                  maxlength="200"
                  show-word-limit
                  type="textarea"
                  resize="none"
                ></el-input>
                <div class="el-icon-error" @click="clearSinggle(index)"></div>
              </div>
              <timer :item="item"></timer>
              <el-button
                type="text"
                class="el-icon-close remove-robot"
                @click="removeRobot(item)"
              ></el-button>
            </div>
          </div>
        </div>
      </div>
    </template>

    <!-- 编辑机器人 -->
    <add-robot
      v-if="status"
      @addComplete="addComplete"
      :addRobotList="addRobotList"
      :dialogstatus.sync="status"
      :robot_relation_id="robot_relation_id"
    ></add-robot>

    <!-- 添加机器人 -->
    <robotSelect
      @robotSelectVal="robotSelectVal"
      v-if="robotSelectStatus"
      :dialogstatus.sync="robotSelectStatus"
    />

    <!-- 添加话术本 -->
    <speech-book
      v-if="speechBookStatus"
      :dialogstatus.sync="speechBookStatus"
      :speechBookIds="speechBookIds"
      @speechBookList="speechBookList"
    ></speech-book>
  </div>
</template>
<script>
import addRobot from '@view/liveRobot/components/addRobot'
import speechBook from './speechBook'
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('OpenClassopenClassLive')
import timer from './timer'
import robotSelect from '@/components/openCourse/robotSelect'

export default {
  name: 'mockChat',

  components: { addRobot, timer, robotSelect, speechBook },

  data() {
    return {
      post: { open_class_id: this.openClassId },

      addRobotList: {},
      status: false,
      robot_relation_id: '',

      speechBookIds: [],
      speechBookStatus: false,
      completeList: [],
      isSendingList: [],

      radio1: 2,
      isFacus: '',
      fillText: '',
      newRobotVal: [],

      robotSelectStatus: false,

      loading: '',
    }
  },

  computed: {
    ...mapState(['openClassId']),
  },

  watch: {
    completeList(val) {
      this.isSendingList = val.filter(item => item.status == 1)
      if (this.isSendingList.length > 0) {
        this.getScriptStatus(this.isSendingList[0])
        this.scriptStatus = 1
      } else {
        clearInterval(this.timer)
      }
    },

    radio1() {
      this.destroy()
    },
  },

  created() {
    this.robotList()
    this.post.open_class_id = this.openClassId
  },

  beforeDestroy() {
    this.destroy()
  },

  methods: {
    addComplete() {
      this.status = false
      this.robotList()
    },

    editRobor(val) {
      this.robot_relation_id = val.robot_relation_id
      this.addRobotList = {
        gid: val.gid,
        isedit: true,
        robot_id: val.robot_id,
        list: [
          {
            name: val.name,
            photo: val.photo,
            id: Math.random(),
          },
        ],
      }

      this.$nextTick(() => {
        this.status = true
      })
    },

    destroy() {
      clearInterval(this.timer)
      this.timer = null
      if (this.xhr && this.xhr.abort) {
        this.xhr.abort()
      }
    },

    send(row) {
      this.loading = row.msg_id
      this.$nextTick(() => {
        this.toSendStop(row, 1)
      })
    },

    // 话术本发送
    toSendStop(val, status) {
      this.xhr = this.$http({
        url: '/console/setScriptSwitch',
        data: {
          msgs_id: val.msgs_id,
          status,
        },
        // successMsg: this.scriptStatus ? true : false,
      })
      this.xhr
        .then(() => {
          this.$refs.childDialogBox.reset()
        })
        .finally(() => {
          this.loading = ''
        })
    },

    // 获取发言的状态，手动停止
    async getScriptStatus(val) {
      this.timer = setInterval(async () => {
        if (this.scriptStatus == 1) {
          const { data } = await this.$http({
            url: 'console/getScriptStatus',
            data: {
              msgs_id: val.msgs_id,
            },
          })
          this.scriptStatus = data
        } else {
          if (this.timer) {
            clearInterval(this.timer)
            this.$refs.childDialogBox.reset()
          }
        }
      }, 1000)
    },

    //控制台话术本编辑
    editSpeek(row) {
      let url = this.$router.resolve({
        path: '/messageTemplate/messageDetail',
        query: { msg_id: row.msg_id },
      })
      this.$store.dispatch('open', url.href)
    },

    // 控制台话术本移除
    async remove(row) {
      await this.$http({
        url: '/console/delScript',
        data: {
          msgs_id: row.msgs_id,
        },
        successMsg: true,
      })
      this.$refs.childDialogBox.reset()
    },

    // 添加话术本
    addScript() {
      this.speechBookIds = this.completeList.map(item => item.msg_id)
      this.$nextTick(() => {
        this.speechBookStatus = true
      })
    },

    // 控制台话术本列表
    complete({ data }) {
      this.completeList = _.concat([], data)
    },

    // 所选择的话术本
    async speechBookList(val) {
      await this.$http({
        url: '/console/addScript',
        data: {
          msg_ids: val.map(item => item.msg_id),
          open_class_id: this.openClassId,
        },
        successMsg: true,
      })
      this.$refs.childDialogBox.reset()
    },

    // 获取控制台已选择的机器人列表
    robotList() {
      this.$http({
        url: '/console/robotList',
        data: {
          open_class_id: this.openClassId,
        },
        callback: ({ data }) => {
          this.newRobotVal = data.map(item => {
            let oldText = this.newRobotVal.find(
              val => val.robot_relation_id == item.robot_relation_id
            )
            oldText = oldText ? oldText.text : ''
            return Object.assign({}, item, {
              time: 0,
              text: oldText,
            })
          })
        },
      })
    },

    // 获取选择的机器人并添加数据
    robotSelectVal(val) {
      const robotId = val.map(item => item.robot_id)
      this.$http({
        url: '/console/addRobot',
        data: {
          open_class_id: this.openClassId,
          robot_id: robotId,
        },
        callback: () => {
          this.robotList()
        },
      })
    },

    // 单个清除
    clearSinggle(index) {
      this.newRobotVal.splice(
        index,
        1,
        _.assign({}, this.newRobotVal[index], { text: '' })
      )
      this.msgSuccess()
    },

    msgSuccess() {
      this.$root.prompt({
        msg: '操作成功',
        type: 'success',
      })
    },

    // 全部清空
    allClear() {
      if (this.newRobotVal.length > 0) {
        this.newRobotVal = this.newRobotVal.map(item =>
          _.assign({}, item, { text: '' })
        )
        this.isFacus = ''
        this.msgSuccess()
      } else {
        this.$root.prompt('请先添加机器人！')
      }
    },

    // 填充到全部
    fillAll(text) {
      if (this.fillText) {
        if (this.newRobotVal.length > 0) {
          this.newRobotVal = this.newRobotVal.map(item =>
            _.assign({}, item, { text: text })
          )
          this.isFacus = ''
          this.msgSuccess()
        } else {
          this.$root.prompt('请先添加机器人！')
        }
      } else {
        this.$root.prompt('请先输入内容！')
      }
    },

    // 全部发送
    allSend() {
      const data = this.newRobotVal
        .filter(item => !!item.text)
        .map(item => _.assign({}, item, { data: item.text.trim() }))

      if (this.newRobotVal.length > 0) {
        if (data.length > 0) {
          this.$http({
            url: '/console/speakAll',
            data,
            callback: () => {
              this.$root.prompt({
                msg: '操作成功',
                type: 'success',
              })
            },
          })
        } else {
          this.$root.prompt('请先输入内容！')
        }
      } else {
        this.$root.prompt('请先添加机器人！')
      }
    },

    // 更改输入框中的数据
    changeInput(data, index) {
      this.newRobotVal[index].text = data.text
      let arr = []
      this.newRobotVal.forEach(item => {
        arr.push(item)
      })
      this.newRobotVal = arr
    },

    // 监听聚焦时间
    facusInput(event, index) {
      this.isFacus = index
    },

    blurInput() {
      this.isFacus = ''
    },

    // 删除机器人
    removeRobot(val) {
      this.$http({
        url: '/console/delRobot',
        data: {
          robot_relation_id: val.robot_relation_id,
        },
        callback: ({ info }) => {
          this.$root.prompt({
            msg: info,
            type: 'success',
          })
          this.robotList()
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.robotSpeak {
  padding: 0 20px;

  ::v-deep .el-table::before {
    height: 0;
  }
  ::v-deep .childDialogBox {
    position: relative;

    .el-divider {
      background-color: #fa6400;
    }

    .pagination {
      padding-top: 20px;
      .el-pager li.active + li,
      .el-pagination button {
        background-color: #343434 !important;
        color: #fff;
      }
      .el-pager li {
        background-color: #343434;
        color: #fff;
      }
    }
    .el-pagination span {
      color: #ffffff;
    }

    .el-pager li.active {
      color: #fff;
      background-color: #fa6400;
    }

    .el-input .el-input__inner {
      background-color: #3e3e3e;
      border-color: #3e3e3e;
      color: #fff;
    }
    .el-table {
      overflow-y: auto;
      @extend %liveRoomScroll;
    }
    .el-table td {
      border-color: #313131;
    }
    .el-table__body-wrapper {
      background-color: #171717;
    }
    .el-table--fit {
      background-color: #171717;
    }

    .el-table th.is-leaf {
      border-color: #3e3e3e;
    }
    .el-table--enable-row-hover .el-table__body tr:hover > td {
      background-color: #3e3e3e !important;
    }
    .el-table::before {
      background-color: #313131;
    }
  }
  ::v-deep .el-button--danger {
    color: #fff;
    border-color: #ff3535;
    background-color: #ff3535;
  }
  ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background-color: #4d4d4d;
    border-color: #4d4d4d;
    color: #ffffff;
    box-shadow: -1px 0 0 0 #4d4d4d;
  }
  ::v-deep .el-radio-button__inner {
    background: #292929;
    border: 1px solid #292929;
    color: #cccccc;
  }
  .robotSpeak-tip {
    font-size: 12px;
    color: #fa6400;
    line-height: 1.5;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .dialogVisible_bottom {
    flex: 1;
    position: relative;
    .scroll {
      overflow-y: auto;
      @extend %liveRoomScroll;
    }
    .d_1 {
      display: flex;
      align-items: center;
      width: 132px;
      .photo {
        flex-shrink: 0;
        margin-right: 10px;
        width: 30px;
        height: 30px;
        border-radius: 3px;
      }
      .name {
        font-size: 14px;
        color: #fff;
        line-height: 14px;
      }
    }
    .d_2 {
      flex: 1;
      margin: 0 30px;
      position: relative;
      .el-icon-error {
        position: absolute;
        right: 8px;
        top: 3px;
        color: #fff;
        font-size: 14px;
        cursor: pointer;
      }
      .btns {
        display: flex;
        align-items: center;
        .driver {
          width: 1px;
          margin: -5px 10px 0;
          color: #fa6400;
        }
        ::v-deep .el-button {
          color: #fa6400;
        }
      }
    }
    .d_3 {
      width: 120px;
      flex-shrink: 0;
    }
    .header {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      color: #fff;
      line-height: 14px;
      width: 100%;
      height: 42px;
      background: #212121;
      border-radius: 1px;
    }
    .main_contain {
      .message_contain {
        border-bottom: 1px #252525 solid;
        padding: 20px 20px 10px 20px;
        display: flex;
        align-items: flex-start;
      }
    }
  }
  .title {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 14px;
    justify-content: space-between;
    .all-send-btn,
    .fill-btn,
    .add-robot-btn {
      width: 128px;
      margin: 20px 0;
      font-size: 14px;
      border-radius: 6px;
    }
    .all-send-btn {
      background: #fa6400;
      border-color: #fa6400;
      width: 76px;
      padding: 10px 0;
    }
    .clear_class {
      width: 76px;
      height: 36px;
      color: #fff;
      margin: 20px 0;
      font-size: 14px;
      padding: 10px 0;
      background: #292929;
      border-radius: 6px;
      border-color: #292929;
      margin-right: 20px;
    }
  }
  .remove-robot {
    color: #999999;
    margin-left: -30px;
  }
  .rigt-btns {
    flex: 1;
  }
  .fill-contain {
    flex: 1;
    margin: 0 20px;
    .fill-btn {
      border-radius: 0 2px 2px 0;
      padding: 10px 0;
    }
    ::v-deep .el-textarea__inner {
      line-height: 1.2;
      height: 36px !important;
      min-height: 36px !important;
    }
  }
  ::v-deep .input .el-textarea__inner,
  ::v-deep .input .el-input__count {
    color: #fff;
    background: #171717;
    border-color: #333333;
  }
  ::v-deep .input .el-input__count {
    color: #666;
  }
  ::v-deep .el-textarea__inner {
    height: 36px;
    min-height: 36px;
    padding: 8px 50px 9px 10px;
    @extend %liveRoomScroll;
  }
}
</style>
