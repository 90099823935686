<template>
  <!-- 添加音频或者视频中的某一个 ，建议使用components/dataBaseConfirm-->
  <el-dialog
    :title="pictureTitle"
    class="dialogVisible"
    width="850px"
    :append-to-body="true"
    :visible="dialogstatus"
    @update:visible="v => $emit('update:dialogstatus', v)"
    v-if="dialogstatus"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @close="cancel"
  >
    <div slot="title">
      <div class="title_style">
        <div class="font1">{{ pictureTitle }}</div>
      </div>
    </div>
    <div class="main">
      <div class="header2">
        <div>
          <el-button
            type="primary"
            size="medium"
            @click="$emit('locationUpload')"
          >
            本地上传
          </el-button>
          <el-button
            type="text"
            size="medium"
            class="ml20"
            style="margin-right: 86px"
            @click="renew"
          >
            刷新列表
          </el-button>
        </div>
        <div>
          <el-select
            v-if="type == 1"
            class="mr20"
            style="width: 160px"
            size="medium"
            v-model="post.format"
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>

          <el-input
            v-if="type == 1"
            style="width: 318px"
            size="medium"
            v-model="post.search"
            class="input-with-select"
            :placeholder="
              post.searchType == 1 || post.searchType == ''
                ? '请输入文件名称搜索'
                : '请输入上传者名称搜索'
            "
          >
            <el-select
              size="medium"
              v-model="post.searchType"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option label="文件" value="1"></el-option>
              <el-option label="上传者" value="2"></el-option>
            </el-select>
          </el-input>
          <el-input
            size="medium"
            v-if="type == 2"
            v-model="post.search"
            placeholder="输入音频名称搜索"
          ></el-input>
        </div>
      </div>
      <div class="bottom">
        <div class="selectGroup">
          <el-menu :default-active="activeIndex" class="el-menu-vertical-demo">
            <el-menu-item
              v-for="(item, index) in grouplist"
              :key="item.group_id"
              :index="'' + index"
              @click="isSelect(item)"
            >
              <div class="flex-center" style="justify-content: space-between">
                <div class="flex-center">
                  <img
                    style="margin-right: 6px; width: 15px; height: 14px"
                    src="~@ass/img/1.4.0.6/icon_wjj.png"
                    alt=""
                  />

                  <span slot="title" class="font1">
                    {{ item.name }}
                  </span>
                </div>
                <span slot="title" class="font3">
                  {{ item.count }}
                </span>
              </div>
            </el-menu-item>
          </el-menu>
        </div>
        <div class="tablelist">
          <!-- <div class="search">
              <el-input
                style="width: 198px"
                v-model="post.search"
                placeholder="输入学员/真实姓名搜索"
              ></el-input>
            </div> -->
          <pagination2
            :option="post"
            url="/DataBank/uploaded"
            ref="childDialogBox"
            :allFn="true"
          >
            <template v-slot:default="{ tableData }">
              <div class="pagination2">
                <el-table
                  ref="dialogTable"
                  @row-click="handleRowClick"
                  @selection-change="handleSelectionChange"
                  :header-cell-style="{
                    background: 'rgba(245,245,245,1)',
                    color: '#333333',
                  }"
                  :data="tableData"
                  style="width: 100%"
                >
                  <el-table-column
                    label=""
                    type="selection"
                    v-if="isCheckbox"
                  ></el-table-column>
                  <el-table-column v-else label width="35">
                    <template slot-scope="scope">
                      <el-radio
                        :label="scope.row.images_id || scope.row.audio_id"
                        v-model="radioId"
                      ></el-radio>
                    </template>
                  </el-table-column>

                  <el-table-column label="文件" min-width="100px">
                    <template slot-scope="{ row }">
                      <div class="membership">
                        <div class="tu">
                          <el-image
                            lazy
                            v-if="type == 1"
                            :src="row.url | imageslim(5)"
                            fit="contain"
                          ></el-image>
                          <div v-else style="height: 100%; width: 100%">
                            <img
                              src="~@ass/img/1.4.0.4/icon_yptb@2x.png"
                              alt=""
                            />
                          </div>
                        </div>

                        <div class="right" :title="row.name">
                          {{ row.name }}
                        </div>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column label="格式" v-if="type == 1">
                    <template slot-scope="{ row }">
                      <span v-if="row.format">
                        {{ row.format }}
                      </span>
                      <span v-else>-</span>
                    </template>
                  </el-table-column>

                  <el-table-column label="大小" v-if="type == 1">
                    <template slot-scope="{ row }">{{ row.size }}MB</template>
                  </el-table-column>

                  <el-table-column label="上传者" v-if="type == 1">
                    <template slot-scope="{ row }">
                      {{ row.uname }}
                    </template>
                  </el-table-column>

                  <el-table-column
                    label="上传时间"
                    prop="address"
                    min-width="90px"
                  >
                    <template slot-scope="{ row }">
                      {{ row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </template>
          </pagination2>
        </div>
      </div>

      <div class="btns">
        <el-button type="" size="medium" style="width: 86px" @click="cancel">
          取消
        </el-button>
        <el-button
          type="primary"
          size="medium"
          style="width: 86px"
          @click="submit"
        >
          确定
          <span v-if="isCheckbox">({{ listSelect.length }})</span>
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'addpicture',
  data() {
    return {
      listSelect: [],
      audio: {}, //音频选中
      list: {}, //图像选中的数据
      radioId: '', //被选选中的值
      uploadStart: false, //本地上传的弹窗
      post: {
        type: this.type == 1 ? 4 : 5,
        search: '',
        searchType: '1',
        format: 0,
        group_id: '',
      },
      activeIndex: '0', //左侧默认选中
      grouplist: [], //左侧列表
      tableData: [], //右侧列表数据
      //搜索关键字
      searchKey: '',
      //控制弹窗的显示
      dialogVisible: false,
      //视频规格
      options: [
        {
          value: 0,
          label: '全部格式',
        },
        {
          value: 'jpg',
          label: 'jpg',
        },
        {
          value: 'jpeg',
          label: 'jpeg',
        },
        {
          value: 'png',
          label: 'png',
        },
      ],
      value: 0, //g格式
      value1: '1',
    }
  },

  props: {
    // 已经选择的个数
    hadselectNum: {
      type: Number,
      default: undefined,
    },
    // 限制选择的总数
    limit: {
      type: Number,
      default: undefined,
    },
    // 是否时多选
    isCheckbox: {
      type: Boolean,
      default: false,
    },
    dialogstatus: {
      type: Boolean,
      default: false,
    },
    pictureTitle: {
      type: String,
      default: '添加图片(单选)',
    },
    //1图片 2音频
    type: {
      type: Number,
      default: 1,
    },
    // 图片大小
    size: {
      type: Number,
      default: undefined,
    },
  },
  created() {
    this.studentGroup()
  },
  computed: {
    member_id() {
      return this.$route.query.member_id
    },
  },
  methods: {
    renew() {
      this.studentGroup()
      this.$refs.childDialogBox.reset()
    },
    handleRowClick(row) {
      this.radioId = row.images_id || row.audio_id
      this.list = row
      this.audio = row
      this.$refs.dialogTable.toggleRowSelection(row)
    },
    //点击确认
    submit() {
      if (this.radioId || this.isCheckbox) {
        if (this.type == 1) {
          if (this.size && this.size <= this.list.size) {
            this.$root.prompt(`图片选择不可超过${this.size}MB`)
            return
          }
          if (this.isCheckbox) {
            if (this.hadselectNum + this.listSelect.length > this.limit) {
              this.$root.prompt(`机器人数量已达${this.limit}个上限`)
              return
            }
            this.$emit('list', this.listSelect)
          } else {
            this.$emit('list', this.list)
          }
        } else {
          this.$emit('audio', this.audio)
        }
        this.$emit('close')
      } else {
        this.$emit('close')
      }
    },
    //选各种的数据
    handleSelectionChange(val) {
      this.listSelect = val
    },
    //选中左侧分组
    isSelect(item) {
      this.post.group_id = item.group_id
      this.$emit('group_id', item.group_id)
    },

    //左侧列表的获取
    studentGroup() {
      this.$http({
        url: '/DataBank/groupList',
        data: {
          type: this.type == 1 ? 4 : 5,
        },
        callback: ({ data }) => {
          this.grouplist = data
        },
      })
    },
    cancel() {
      // 取消后回复选择数据原样
      this.$emit('close')
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-radio__label {
  padding-left: 11px;
}
::v-deep .el-image {
  height: 100%;
  width: 100%;
}
::v-deep .pagination {
  padding: 10px;
}
.pagination2 {
  height: 287px;
  overflow: hidden;
  overflow-y: auto;
  @extend %scroll;
}
.tablelist {
  .membership {
    display: flex;
    align-items: center;
    .tu {
      width: 40px;
      height: 40px;
      border-radius: 2px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      img {
        height: 40px;
        width: 100%;
        object-fit: contain;
      }
    }
    .right {
      width: calc(100% - 50px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 14px;
      color: #333333;
      line-height: 15px;
    }
  }
}

::v-deep .el-table__header-wrapper {
  height: 42px;
}
::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-dialog {
  height: 526px;
}
.title_style {
  display: flex;
  align-items: center;
  .font1 {
    font-size: 14px;
    color: #333333;
    line-height: 14px;
  }
}
.main {
  padding: 20px;
  .header2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .guige {
      margin-right: 30px;
      font-size: 14px;
      color: #333333;
      line-height: 14px;
    }

    ::v-deep .input-with-select {
      .el-input__inner {
        color: #333333;
      }
      .el-input-group__prepend {
        background-color: #fff;
        width: 60px;
      }
    }
  }
  .bottom {
    display: flex;
    margin-top: 20px;
    .selectGroup {
      height: 334px;
      width: 214px;

      ::v-deep .el-menu {
        height: 334px;
        overflow-y: auto;
        overflow-x: hidden;
        border: solid 1px #e6e6e6;
      }
      ::v-deep .el-menu-item:hover,
      .el-menu-item:focus {
        background-color: #f0f8ff;
      }
      ::v-deep .el-menu-item {
        height: 36px;
        line-height: 36px;
      }
      .font1 {
        font-size: 12px;
        color: #333333;
      }
      .font3 {
        font-size: 12px;
        color: #999999;
        line-height: 12px;
      }
    }
    .tablelist {
      width: calc(100% - 200px);
      .search {
        margin-bottom: 10px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }
    .pagenation {
      margin-top: 20px;
      text-align: center;
    }
  }
  .btns {
    text-align: right;
    margin-top: 10px;
  }
}
</style>
