<template>
  <!-- 选择活动 -->
  <div id="selectActivity">
    <div class="search">
      <el-select
        class="ml20"
        v-model="DialogBoxPost.unexpired"
        placeholder="请选择"
        style="width: 140px"
        size="medium"
      >
        <el-option label="全部状态" :value="0"></el-option>
        <el-option label="进行中" :value="1"></el-option>
        <el-option label="已过期 " :value="2"></el-option>
      </el-select>

      <el-input
        v-model="DialogBoxPost.name"
        placeholder="输入名称搜索"
        style="width: 215px"
        size="medium"
        class="ml20"
      ></el-input>
    </div>
    <pagination2
      :option="DialogBoxPost"
      url="/redeem/redeemActionList"
      ref="childDialogBox"
      :allFn="true"
    >
      <template v-slot:default="{ tableData }">
        <div class="pagination2Box">
          <el-table
            :row-key="getRowkey"
            @selection-change="DialogBoxSelectionChange"
            @row-click="handleRowClick"
            :data="tableData"
            ref="dialogTable"
            max-height:200
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333',
            }"
          >
            <el-table-column label width="30" v-if="isSingleSelect">
              <template slot-scope="scope">
                <el-radio :label="scope.row.redeem_id" v-model="radioId">
                  &nbsp;
                </el-radio>
              </template>
            </el-table-column>
            <el-table-column
              v-else
              reserve-selection
              type="selection"
              width="50"
            ></el-table-column>
            <el-table-column label="兑换码名称">
              <template slot-scope="{ row }">
                <div class="contain">
                  {{ row.name }}
                </div>
              </template>
            </el-table-column>

            <el-table-column label="创建人">
              <template slot-scope="{ row }">
                {{ row.uname }}
              </template>
            </el-table-column>

            <el-table-column label="活动期限">
              <template slot-scope="{ row }">
                {{ row.validity | formatTimeStamp('YYYY-MM-dd hh:mm:ss') }}
              </template>
            </el-table-column>

            <el-table-column label="状态">
              <template slot-scope="{ row }">
                <span v-if="row.out_date" class="bluecolr">进行中</span>
                <span v-else class="redcolor">已过期</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
    </pagination2>
  </div>
</template>

<script>
export default {
  props: {
    isnaire: {
      type: Boolean,
      default: false,
    },
    //true是单选 false:多选
    isSingleSelect: {
      type: Boolean,
      default: true,
    },
    // 多选的id
    ids: {
      type: Array,
      default() {
        return []
      },
    },
  },

  data() {
    return {
      listSave: [],
      radioId: '',
      DialogBoxPost: {
        is_naire: this.isnaire ? 1 : undefined,
        naire_id: this.isnaire ? this.$route.query.naire_id : undefined,
        unexpired: 0,
        name: '',
        redeem_ids: this.ids,
      },
    }
  },
  methods: {
    DialogBoxSelectionChange(val) {
      //点击选择暂存选项数组
      this.listSave = val
      console.log(this.listSave)
      this.$emit('listSave', val)
    },
    getRowkey(row) {
      let val = row.redeem_id

      return val
    },
    handleRowClick(row) {
      clearTimeout(this.rowClicTimer)
      this.rowClicTimer = setTimeout(() => {
        if (this.type == 2 && this.isSingleSelect) {
          if ('is_show' in row && (row.status == 2 || row.is_show == 2)) {
            return
          }
        }

        switch (this.type) {
          case 1:
            this.radioId = row.ed_id
            row.type = 1
            break
          case 2:
            this.radioId = row.member_id
            row.type = 2
            break
          case 3:
            this.radioId = row.workbook_id
            row.type = 3
        }
        if (this.isSingleSelect) {
          this.$emit('singleselectcontent', row)
        }
        this.$refs.dialogTable.toggleRowSelection(row)
      }, 100)
    },
    // 判断课程是否可以被选择（下架 不展示到网校）
    // selectionEvent(row) {
    //   if (this.type == 2 && !this.isSingleSelect) {
    //     return true
    //   } else {
    //     return !('is_show' in row) || (row.is_show == 1 && row.status == 1)
    //   }
    // },
  },
}
</script>

<style lang="scss" scoped>
#selectActivity {
  .search {
    padding: 10px 20px;
    display: flex;
    justify-content: flex-end;
  }
  .contain {
    display: flex;
    align-items: center;

    .tu {
      img {
        width: 60px;
        height: 34px;
      }
    }
    .right {
      width: calc(100% - 70px);
      margin-left: 10px;
      .name {
        font-size: 13px;
        color: #333333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .teacher_count {
        font-size: 12px;
        color: #666666;
      }
      .type {
        align-items: center;
        display: flex;
        font-size: 12px;
        color: #666666;
        img {
          width: 8px;
          height: 7px;
          margin-right: 6px;
        }
        span {
          font-size: 12px;
          color: #666666;
        }
      }
    }
  }

  .pagination2Box {
    margin-top: 20px;
    overflow: auto;
    height: 338px;
  }
  .pagination2Box::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 3px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }

  .pagination2Box::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
    background: #aaaaaa;
  }

  .pagination2Box::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #ededed;
  }
}
</style>
