<template>
  <div class="uploadRecordCourse">
    <slot
      :queue="newQueue"
      name="default"
      :removeFile="removeFile"
      :againUpload="againUpload"
    ></slot>
    <div class="vm uploadBtn" v-if="selectButtonStatus">
      <slot
        :uploadingstatus="uploadingstatus"
        :uploading="uploading"
        :queue="newQueue"
        name="uploadBtn"
        :removeFile="removeFile"
        :againUpload="againUpload"
      ></slot>
      <input
        ref="Upload_file"
        class="absolute"
        id="Upload_file"
        type="file"
        @change="addFile"
        :accept="accept"
        :multiple="typeselection == 1 ? true : false"
      />
    </div>
    <!-- 录播本地添加视频的按钮位置 -->
    <slot name="editsection_bt"></slot>

    <slot
      name="select"
      :uploadCompleteList="uploadCompleteList"
      :uploadError="uploadError"
    ></slot>
    <slot
      name="list"
      :uploadingstatus="uploadingstatus"
      :newQueue="newQueue"
      :selectButtonStatus="selectButtonStatus"
      :queue="newQueue"
      :removeFile="removeFile"
      :againUpload="againUpload"
    ></slot>
    <slot
      name="footer"
      :newQueue="newQueue"
      :beginUploadStatus="beginUploadStatus"
      :uploadingstatus="uploadingstatus"
    ></slot>
  </div>
</template>
<script>
/**
 * // 点击第一层开始上传按钮 最后一个文件上传时中途取消,并且列表中没有上传成功的 在第二层
   // 初始化的时候 以及 第三层 继续上传的时候
   // 所有文件上传完毕，包括成功与失败，没有上传中的
 */
import * as qiNiu from 'qiniu-js'
import COS from 'cos-js-sdk-v5'
import getEnv from '@/config/getEnv'
// 修改了源码
// var opt = {
//     method: method,
//     url: url,
//     headers: params.headers,
//     qs: params.qs,
//     body: body,
//     json: json,
//     timeout: 30000
// }
// cos-js-sdk-v5 E:\htdocs\teacher.dingdingkaike\node_modules\cos-js-sdk-v5\src\base.js _submitRequest()

export default {
  name: 'Upload',

  props: {
    //1资料库文档上传，可多选 2，只能添加单个文档
    typeselection: {
      type: Number,
      default: 1,
    },
    // 上传数量限制
    limit: {
      type: Number,
      default: 0,
    },
    // 同时上传数量
    meanwhile: {
      type: Number,
      default: 1,
    },
    accept: {
      type: String,
      default: '',
    },
    isUploadServe: {
      type: Boolean,
      default: true,
    },
    maxSize: {
      type: Number,

      default: 1024 * 1024 * 1024 * 0.5,
    },
    //分组id
    list: {
      default: 0,
    },
    //分辨率的数值
    value: {
      type: Number,
      default: 0,
    },
    //1是加密转码 2是普通转码
    radio: {
      type: Number,
      default: 2,
    },
    menuName: String,
    uploadType: {
      //上传的引入模块1为文档,2图片,3音频
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      //上传按钮的状态
      selectFileButton: true,
      //开始上传按钮的显示状态
      beginUploadStatus: true,
      token: '',
      // 所有文件
      fs: {},
      // 上传队列
      queue: [],
      // 下一步需要上传的index
      nextIndex: undefined,
      start: 0,
      s: 0,
    }
  },

  destroyed() {
    this.destroyed11()
  },

  created() {
    this.getToken()
  },

  computed: {
    // 当前正在上传中的文件
    currUploadingFile() {
      return this.newQueue
        .filter(item => item.status === 2)
        .map(item => item.fid)
    },
    //正在上传中的状态
    uploadingstatus() {
      return (
        !this.beginUploadStatus &&
        (this.uploading ||
          (this.uploadCompleteList.length == 0 && this.uploadError.length == 0))
      )
    },

    //选择文件按钮的状态判断
    selectButtonStatus() {
      return this.newQueue.length == 0 || this.uploading
    },

    //当前待上传和上传中的文件
    uploading() {
      return (
        this.newQueue.filter(item => item.status === 2 || item.status === 1)
          .length > 0
      )
    },

    //上传成功的文件
    uploadCompleteList() {
      return this.newQueue.filter(item => item.status === 0)
    },

    // 上传失败的文件
    uploadError() {
      return this.newQueue.filter(item => item.status === 3)
    },

    // 最终的队列值
    newQueue() {
      return this.queue
        .filter(item => item.status != -1)
        .map(m => {
          let array = _.assign({}, m, {
            file_name: m.file_name.substring(m.file_name.length - 50),
          })

          return array
        })
    },
  },

  methods: {
    //继续上传前的数据销毁
    destroyed11() {
      this.beginUploadStatus = true
      this.queue.forEach(this.stopUpload)
      this.token = ''
      // 所有文件
      this.fs = {}
      // 上传队列
      this.queue = []
      // 下一步需要上传的index
      this.nextIndex = undefined
      this.getToken()
    },

    continueupload() {
      this.destroyed11()
      this.$emit('continueupload', true)
    },

    // 获取七牛上传token
    // 或者获取上传cos
    getToken() {
      if (this.uploadType != 4) {
        return new Promise(resolve => {
          this.$root.$http({
            name: 'getQiniuToken',
            url: '/Index/getQiniuToken',
            isMultipleSelf: 'all',
            callback: ({ data }) => {
              this.token = data
              resolve(data)
            },
            error: () => {
              this.$root.prompt('获取上传凭证失败，请重新检查网络或重试')
              this.queue
                .filter(item => item.status == 1 || item.status == 2)
                .forEach(this.stopUpload)
              // 全部停掉之后 nextIndex 手动指定
              // 否则就会出现错误的情况 状态对的 但是nextIndex错的
              this.nextIndex = this.queue.length
            },
          })
        })
      } else {
        this.cos = new COS({
          Domain: 'f.dingdingkaike.com',
          Protocol: 'https:',
          // getAuthorization 必选参数
          getAuthorization: (options, callback) => {
            this.$root.$http({
              url: '/index/getTxCosToken',
              isMultipleSelf: 'all',
              data: options.Scope,
              callback: ({ data }) => {
                callback({
                  TmpSecretId: data.credentials.tmpSecretId,
                  TmpSecretKey: data.credentials.tmpSecretKey,
                  SecurityToken: data.credentials.sessionToken,
                  // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
                  StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
                  ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000000
                })
              },
            })
          },
        })
      }
    },

    // 验证上传文件
    verify(file) {
      if (this.$store.state.userInfo.leftStorage <= 0) {
        this.$root.prompt('文件超出剩余空间，请扩容')
        return true
      }
      if (file.size > this.maxSize) {
        this.$root.prompt('文件过大，请压缩后重新上传')
        return true
      }
      const testType =
        this.uploadType == 1
          ? /.ppt|.pptx|.pdf|.doc|.docx|.xls|.xlsx|.zip|.rar|.xmind|.txt/
          : this.uploadType == 2
          ? /.jpg|.jpeg|.png/
          : this.uploadType == 3
          ? /.mp3|.m4a/
          : /.ppt|.pptx|.pdf|.doc|.docx|.xls|.xlsx/
      if (!testType.test(file.name)) {
        this.$root.prompt(
          this.uploadType == 1
            ? '支持格式.ppt, .pptx , .doc , .docx , .xls , .xlsx, .pdf , .txt , .rar , .zip, .xmind格式'
            : this.uploadType == 2
            ? '支持格式.jpg, .jpeg , .png格式'
            : this.uploadType == 3
            ? '支持格式.mp3, .m4a格式'
            : '支持格式.ppt, .pptx, .pdf , .doc , .docx , .xls , .xlsx格式'
        )
        return true
      }
      // if (
      //   this.queue.filter((item) => item.status === 1 || item.status === 2)
      //     .length >= this.limit
      // ) {
      //   this.$root.prompt(`上传队列已达上限，请等上传列表完成后再选择`)
      //   return true
      // }
    },

    // 上传前的处理 验证
    beforeVerify() {
      // 如果只允许上传一个 每次删除掉第一个
      if (this.limit === 1) {
        this.removeFile(0, true)
      }
      this.$emit('start')
    },

    // 获取上传文件
    addFile({ target }) {
      // 上传前的处理
      this.beforeVerify()
      // 开始上传
      Array.prototype.forEach.call(target.files, item => {
        console.log(item)
        if (!this.verify(item)) {
          // console.log(item)
          const fid = Math.random()
          // 添加文件ID
          this.fs[fid] = item
          // 添加队列
          this.queue.push({
            // 添加一些渲染视图信息
            fid,
            // status 1 待上传 2 上传中 3 上传失败 4 上传到服务器失败 5 删除中 6 删除失败 0 上传成功 -1 隐藏它
            status: 1,
            // 进度
            percent: 0,
            //上传速度
            speed: 0,
            // 文件类型
            type: item.type,
            // 文件大小
            file_size: item.size,
            // 文件名称
            file_name: item.name,
            // 上传实例
            subscription: {},
            // 顺序号
            $index: this.queue.length,
          })
        }
      })
      if (!this.beginUploadStatus) {
        this.next()
      }
      target.value = ''
    },

    // begin_upload() {
    //   if (this.queue.length == 0) {
    //     this.$root.prompt({ msg: '请先选择视频文件！' })
    //     return
    //   }
    //   this.beginUploadStatus = false
    //   this.next()
    //   this.$emit('click_begin_upload')
    // },

    // 开始上传两个
    next() {
      // 只有一次性同时上传2个
      if (this.currUploadingFile.length < this.meanwhile) {
        // 初始化没有下一步ID
        if (this.nextIndex === undefined) {
          this.nextIndex = 0
        }
        // 必须下一步上传的文件存在
        if (this.queue[this.nextIndex]) {
          if (this.fs[this.queue[this.nextIndex].fid]) {
            const index = this.nextIndex
            // 设置下一步ID
            this.nextIndex += 1
            this.uploadFileStart(index)
          } else {
            // 设置下一步ID
            this.nextIndex += 1
          }
          // 继续执行下一步
          this.next()
        }
      }
    },

    /**
     * @description 删除 停止上传文件不包括成功的
     * @param 队列index
     * @pass 是否直接删除 不删除服务器文件
     */

    /**
     * @description 取消上传操作说明
       @author cyy
       上传和取消上传操作为异步操作
       取消上传时，上传文件的两种状态：1，上传中  2，上传成功
       1，上传中。确认取消后，状态为-1，此时上传的代码要判断上传文件的状态是否为2即正在上传中，否则停止上传
       2，上传成功。文件状态为0，此时不允许进行删除（改变队列数据状态的操作）操作。
     */
    removeFile(index, pass) {
      const item = this.queue[index]
      // console.log(index, item, this.queue, this.fs, this.nextIndex)

      if (item) {
        // 删除通用函数
        const deleteCallback = () => {
          // 删除的是否在上传中
          // const i = this.currUploadingFile.indexOf(item.fid)
          // 停止掉上传进度
          this.stopUpload(item)
          // 删除文件 因为会冗余数据
          delete this.fs[item.fid]
          // 隐藏文件
          item.status = -1
          //如果是初始化状态，直接删除
          if (this.beginUploadStatus) {
            this.queue.splice(index, 1)
            this.queue = this.queue.map((item, index) => {
              return _.assign({}, item, { $index: index })
            })
          } else {
            this.next()
          }
          if (pass) {
            this.queue.splice(index, 1)
            this.nextIndex = undefined
          }

          // 如果当前上传的只有一个 且这个被删除
          // if (!this.currUploadingFile.length && i > -1) {
          //   this.next()
          // }
        }
        // 如果需要删除服务器文件
        if (item.serverId && !pass) {
          this.$confirm(
            '是否删除已选文件，删除后可在回收站中查看！',
            '删除文件',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
            }
          ).then(() => {
            item.status = 5
            this.$http({
              name: `deleteRecording${item.serverId}`,
              url: '/DataBank/deleteRecording',
              data: {
                type: 1,
                ids: item.serverId,
              },
              callback: () => {
                this.$emit('getJgInfo')
                deleteCallback()
              },
              error() {
                item.status = 6
              },
            })
          })
        } else if (pass || item.serverId || item.status == 3) {
          deleteCallback()
        } else {
          if (this.currUploadingFile.length > 0 && item.status == 2) {
            this.$confirm('是否取消当前文件的上传？', '取消上传', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
            }).then(() => {
              // 上传成功的文件不允许被删除
              if (item.status != 0) {
                if (item.serverId && !pass) {
                  this.$root.prompt('操作异常，请重试')
                } else {
                  deleteCallback()
                }
              }
            })
          } else {
            if (item.serverId && !pass) {
              this.$root.prompt('操作异常，请重试')
            } else {
              deleteCallback()
            }
          }
        }
      }
      // this.beginUploadStatus = true
      // this.$emit('continueupload', true)t
      // this.$emit('currUploadingFile', this.currUploadingFile)
    },

    // 停止七牛上传
    stopUpload(item) {
      item.status = 3
      // 腾讯云cos上传
      if (
        item.taskId &&
        this.cos &&
        typeof this.cos.cancelTask === 'function'
      ) {
        this.cos.cancelTask(item.taskId)
      } else if (
        item.subscription &&
        typeof item.subscription.unsubscribe === 'function'
      ) {
        // 七牛
        item.subscription.unsubscribe()
      }
      // this.next()
    },

    // 重新上传
    againUpload(index) {
      const { fid, status, avinfo } = this.queue[index]
      // 如果是上传到资料库失败
      if (status === 4) {
        this.uploadServer(index, { avinfo })
      } else {
        const file = this.fs[fid]
        if (!file) {
          throw new Error('againUpload() 上传文件 不存在')
        }
        this.removeFile(index)

        // 取出文件重新上传
        this.addFile({
          target: { files: [file] },
        })
      }
    },

    // 开始上传
    uploadFileStart(uploadIndex) {
      // console.log(uploadIndex)
      if (typeof uploadIndex !== 'number') {
        throw new Error('uploadIndex 不是一个正确的队列下标')
      }
      const { fid } = this.queue[uploadIndex]
      if (!fid) {
        throw new Error('uploadFileStart() fid 不存在')
      }
      const file = this.fs[fid]
      if (!file) {
        throw new Error('uploadFileStart() 上传文件 不存在')
      }
      // console.log(this.queue)
      this.queue = this.queue.map(m => {
        let array = _.assign({}, m, {
          file_name: m.file_name.substring(m.file_name.length - 50),
        })
        return array
      })
      // 添加正在上传中
      this.queue[uploadIndex].status = 2
      //   const uploader = (this.queue[
      //     uploadIndex
      //   ].subscription = this.tcVod.upload({ mediaFile: file }))

      //   uploader.on('media_progress', info => {
      //     /**
      //      * 1.3.9.4此处获取上传同步信息
      //      */
      //     //上传百分比
      //     info.speed / 1024 / 1024
      //     this.queue[uploadIndex].percent = Math.floor(info.percent * 100)
      //     this.queue[uploadIndex].speed = Math.floor(info.speed / 1024 / 1024)
      //   })

      //   uploader
      //     .done()
      //     .then(doneResult => {
      //       //当上传完成后，判断当前文件的上传状态是上传中，才允许执行下一步操作
      //       if (this.queue[uploadIndex].status == 2) {
      //         // // 上传到服务器资料
      //         this.uploadServer(uploadIndex, doneResult)
      //       }
      //     })
      //     .catch(() => {
      //       this.queue[uploadIndex].status = 3
      //       this.next()
      //     })

      // 初始化上传速度
      this.queue[uploadIndex].total = {
        time0: Date.now(),
        oldTotal: 0,
      }

      // 判断不同的上传方式
      if (this.uploadType == 4) {
        this.txCosUpload(uploadIndex, file)
      } else {
        this.qiNiuUpload(uploadIndex, file)
      }
    },

    // 七牛上传
    qiNiuUpload(uploadIndex, file) {
      const ext = file.name.split('.').pop()
      const fileExt = `${`${Number(this.$store.state.userInfo.uid) +
        Math.random()}`.replace('.', '-')}.${ext}`

      const newFileName = `teacher/image/${
        this.$store.state.userInfo.jg_id
      }/${this.$options.filters.formatTimeStamp(
        new Date() / 1000,
        'yyyyMMddhhmmss'
      )}/${fileExt}`

      this.queue[uploadIndex].subscription = qiNiu
        .upload(
          file,
          newFileName,
          this.token,
          { fname: '', params: {} },
          {
            retryCount: 3,
            useCdnDomain: true,
            disableStatisticsReport: true,
          }
        )
        .subscribe({
          next: ({ total: { loaded, percent } }) => {
            this.queue[uploadIndex].percent = Math.floor(percent)

            this.queue[uploadIndex].total.time1 = Date.now()
            const { time0, time1, oldTotal } = this.queue[uploadIndex].total

            // 500ms 计算一次
            if (time1 - time0 > 500) {
              // 计算每秒上传速度
              this.queue[uploadIndex].speed = (
                (loaded - oldTotal) /
                ((time1 - time0) / 1000) /
                1024 /
                1024
              ).toFixed(2)

              this.queue[uploadIndex].total = { time0: time1, oldTotal: loaded }
            }
          },
          error: () => {
            this.queue[uploadIndex].status = 3
            this.next()
          },
          complete: result => {
            // 上传到服务器资料库
            if (this.queue[uploadIndex].status == 2) {
              this.uploadServer(uploadIndex, result)
            }
          },
        })
    },

    // 腾讯cos上传
    txCosUpload(uploadIndex, file) {
      const ext = file.name.split('.').pop()
      const fileExt = `${`${Number(this.$store.state.userInfo.uid) +
        Math.random()}`.replace('.', '-')}.${ext}`

      const env =
        getEnv() === 'test' || getEnv() === 'localhost'
          ? 'dev/'
          : getEnv() === 'release'
          ? 'release/'
          : ''

      const newFileName = `courseware/${env}${
        this.$store.state.userInfo.jg_id
      }/${this.$options.filters.formatTimeStamp(
        new Date() / 1000,
        'yyyyMMdd'
      )}/${fileExt}`

      this.cos.putObject(
        {
          Bucket: 'dingdingkaike-1255882016' /* 填入您自己的存储桶，必须字段 */,
          Region: 'ap-chengdu' /* 存储桶所在地域，例如ap-beijing，必须字段 */,
          Key: newFileName /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */,
          StorageClass: 'STANDARD',
          Body: file, // 上传文件对象
          onTaskReady: taskId => {
            this.queue[uploadIndex].taskId = taskId
          },
          onProgress: ({ loaded, percent }) => {
            this.queue[uploadIndex].percent = Math.floor(percent * 100)

            this.queue[uploadIndex].total.time1 = Date.now()
            const { time0, time1, oldTotal } = this.queue[uploadIndex].total

            // 500ms 计算一次
            if (time1 - time0 > 500) {
              // 计算每秒上传速度
              this.queue[uploadIndex].speed = (
                (loaded - oldTotal) /
                ((time1 - time0) / 1000) /
                1024 /
                1024
              ).toFixed(2)

              this.queue[uploadIndex].total = { time0: time1, oldTotal: loaded }
            }
          },
        },
        (err, data) => {
          if (err) {
            this.queue[uploadIndex].status = 3
            this.next()
          } else {
            // 上传到服务器资料库
            if (this.queue[uploadIndex].status == 2) {
              this.uploadServer(uploadIndex, {
                key: data.Location,
                txcos: true,
              })
            }
          }
        }
      )
    },

    // 上传到服务器资料库
    uploadServer(index, { avinfo, key, audio, txcos }) {
      // console.log(index,{ avinfo, key })
      const item = this.queue[index]

      const url = item.url
        ? item.url
        : txcos
        ? `https://${key}`
        : `https://f.dingdingkaike.com.cn/${key}`

      // 存储一些数据 重新上传会使用
      item.url = url
      item.avinfo = avinfo
      item.audio = audio

      // 上传成功通用函数
      const success = () => {
        // 上传成功
        this.uploadComplete(index)
        // 向上传递
        this.$emit(
          'complete',
          _.merge({}, item, {
            url,
            avinfo,
          })
        )
        // console.log(
        //   _.merge({}, item, {
        //     url,
        //     doneResult,
        //   })
        // )
        this.next()
      }

      /**
       * 1.3.9.4此处需要更改
       */
      // 如果需要上传到服务器
      if (this.isUploadServe) {
        let _this = this
        this.$http({
          url: '/course/uploadFile',
          isMultipleSelf: 'all',

          data: {
            url: url,
            size: item.file_size,
            // name: this.uploadType == 1||this.uploadType == 2 ? item.file_name : item.file_name.replace(/\s*/g, ''),
            name: item.file_name,
            avinfo: avinfo,
            audio: audio,
            // format: 'zip',
            group_id: this.list,
            fileType: this.uploadType,
          },
          callback: ({ id, format }) => {
            item.serverId = id
            item.format = format
            success()
          },
          error: () => {
            item.status = 4 //到时候改回来
            // item.status = 3
            _this.next()

            // success()//到时候改回来
          },
        })
      } else {
        success()
      }
    },

    // 上传成功
    uploadComplete(currIndex) {
      // 如果ID存在说明是上传成功回调 删除上传完成的
      // 如果上传失败了 也删除！！！
      const { fid } = this.queue[currIndex]
      if (fid) {
        const index = this.currUploadingFile.indexOf(fid)
        if (index > -1) {
          this.queue[currIndex].status = 0
          // 成功就上传文件 记得是成功（也可以不删除） 因为还有重新上传功能
          delete this.fs[fid]
        }
      } else {
        throw new Error('uploadComplete() fid 不存在')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.warning-opennew {
  display: flex;
  justify-content: center;
  font-size: 13px;
  margin-top: 18px;
  i {
    color: #ff3535;
    margin-right: 10px;
  }
  a {
    cursor: pointer;
    color: #1b9d97;
    text-decoration: underline !important;
  }
}
.uploadBtn {
  position: relative;
  input[type='file'] {
    opacity: 0;
    text-indent: -9999px;
  }
}
.absolute {
  width: 126px;
  height: 42px;
}
</style>
