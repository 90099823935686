<!--
 * @Author: cyy
 * @Date: 2021-12-17 17:45:24
 * @LastEditTime: 2022-03-01 18:04:00
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \ teacher.dingdingkaike\src\views\OpenClass_openClassLive\components\header.vue
-->
<template>
  <div>
    <div id="header">
      <div class="font2">叮叮直播控制台</div>
      <div class="font1">{{ openInfo.name }}</div>
      <div class="uphoto">
        <div class="flex-center">
          <el-button
            @click="toggle"
            style="color: #fa6400; font-weight: bold"
            type="text"
            size="mini"
            class="mr20"
          >
            {{ fullscreenState ? '退出全屏' : '全屏模式' }}
          </el-button>
          <el-button
            @click="shareinfo = true"
            style="color: #fa6400; font-weight: bold"
            type="text"
            size="mini"
            class="mr20"
          >
            邀请学员
          </el-button>
          <div class="font3">{{ userInfo.uname }}</div>
          <div class="tu">
            <img :src="userInfo.uphoto" alt="" />
          </div>
        </div>
        <template v-if="isWindowsApp">
          <reset></reset>
          <oepnAndClose></oepnAndClose>
        </template>
      </div>
    </div>
    <!-- 分享 -->
    <shareinfo
      fatherPath="/publicClass"
      v-if="shareinfo"
      :dialogstatus.sync="shareinfo"
      :shareinfoval="openInfo"
    ></shareinfo>
  </div>
</template>

<script>
import shareinfo from '@/components/openCourse/shareinfo'
import reset from './reset'
import oepnAndClose from './oepnAndClose'
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('OpenClassopenClassLive')
export default {
  name: 'myheader',
  data() {
    return {
      shareinfo: false,
      fullscreenState: false,
      isRequestFullScreen: false,
    }
  },

  computed: {
    ...mapState(['userInfo', 'openInfo', 'userInfo', 'openAndClose']),
    isWindowsApp() {
      return this.$store.state.isWindowsApp
    },
  },

  components: {
    reset,
    oepnAndClose,
    shareinfo,
  },

  created() {
    // const isWindowsApp = this.$store.state.isWindowsApp
    // const isclick = !sessionStorage.getItem('openAndClose')
    // if (!isWindowsApp && isclick) {
    //   this.setOpenAndClose(true)
    // }
    this.fullscreenchange()
  },
  methods: {
    // ...mapMutations(['setOpenAndClose']),
    toggle() {
      if (this.fullscreenState) {
        this.exitFullscreen()
      } else {
        this.requestFullScreen()
      }
    },

    //开始全屏
    requestFullScreen() {
      var docElm = document.documentElement
      //W3C
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen()
      }
      //FireFox
      else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen()
      }
      //Chrome等
      else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen()
      }
      //IE11
      else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen()
      }
    },

    //退出全屏
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    },

    fullscreenchange() {
      //事件监听
      document.addEventListener(
        'fullscreenchange',
        () => {
          this.fullscreenState = !!document.fullscreen
        },
        false
      )

      document.addEventListener(
        'mozfullscreenchange',
        () => {
          this.fullscreenState = !!document.mozFullScreen
        },
        false
      )

      document.addEventListener(
        'webkitfullscreenchange',
        () => {
          this.fullscreenState = !!document.webkitIsFullScreen
        },
        false
      )

      document.addEventListener(
        'msfullscreenchange',
        () => {
          this.fullscreenState = !!document.msFullscreenElement
        },
        false
      )
    },
  },
}
</script>

<style lang="scss" scoped>
#header {
  padding: 0px 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .font1 {
    font-size: 12px;
    color: #ffffff;
    margin-right: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1.5;
  }
  .font2 {
    font-size: 12px;
    color: #666666;
    line-height: 1.5;
    flex-shrink: 0;
  }
  .font3 {
    font-size: 12px;
    color: #838383;
    margin-right: 10px;
  }
  .tu {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .uname {
    margin-left: 10px;
    font-size: 12px;
    color: #a1a1a1;
  }
  .uphoto {
    margin-right: 10px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    font-size: 12px;
    color: #ffffff;
    line-height: 12px;
  }
}
</style>
